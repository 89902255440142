import React, { useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Permission, getPermissionByKey } from './permissions';

const allUserPermissions = new Map();

export const can = (permissions, or = false) => {
  if (typeof permissions === 'function') {
    return permissions(can);
  }

  if (!allUserPermissions) {
    return false;
  }

  if (permissions instanceof Permission) {
    return allUserPermissions.has(permissions.name);
  }

  if (Array.isArray(permissions)) {
    const has = (permission) => allUserPermissions.has(permission.name);
    return or ? permissions.some(has) : permissions.every(has);
  }

  return false;
};

const Authorize = ({
  permissions,
  or,
  children,
  fallback,
}) => {
  if (can(permissions, or)) {
    return children;
  }

  return fallback || null;
};

export default compose(
  connect(({
    authentication: {
      roles: userRoles,
    },
    roles: {
      roles,
    },
  }) => ({ userRoles, roles })),
  (WrappedAuthorize) => ({ userRoles, roles, ...props }) => {
    useMemo(() => {
      if (!userRoles) {
        return;
      }

      allUserPermissions.clear();

      userRoles.forEach((userRoleName) => {
        const role = roles.find(({ name }) => name === userRoleName);

        if (role) {
          role.permissions.forEach((permissionName) => {
            const permission = getPermissionByKey(permissionName);
            if (permission) {
              allUserPermissions.set(permission.name, permission);
            } else {
              console.warn(`Unknown permission ${permissionName}`);
            }
          });
        } else {
          console.warn('User role does not exist');
        }
      });
    }, [userRoles, roles]);

    return <WrappedAuthorize {...props} />;
  },
)(Authorize);
