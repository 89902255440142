import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

import {
  UPDATE_CONFIG,
  CONFIG_UPDATED,
  ADD_FILES,
  REMOVE_FILE,
  UPLOAD_ALL_FILES,
  UPLOAD_ALL_FILES_DONE,
  UPLOAD_FILE,
} from './types';
import { reloadConfig, uploadFile as sendFile, deleteConfigFiles } from './api';
import { RequestError } from '../../../helpers/fetch';
import { ReactSwal } from '../../../helpers/swal';


const fetchingUpdateConfig = () => ({ type: UPDATE_CONFIG });
const updateConfigDone = () => ({ type: CONFIG_UPDATED });

export const STATUSES = {
  PENDING: 'pending',
  UPLOADING: 'uploading',
  UPLOADED: 'uploaded',
  ERROR: 'error',
};

export const updateConfig = () => (dispatch) => {
  dispatch(fetchingUpdateConfig());
  reloadConfig().then(() => {
    toast('Config updated', { containerId: 'mapping', type: 'success' });
  }).catch((err) => {
    console.error(err);
    toast('Error updating config', { containerId: 'mapping', type: 'error' });
  }).finally(() => {
    dispatch(updateConfigDone());
  });
};

const addFiles = (payload) => ({
  type: ADD_FILES,
  payload,
});

export const setFiles = (files, isUploaded) => (dispatch) => {
  let updatedFiles = files;

  if (!isUploaded) {
    updatedFiles = files.map((file) => {
      // eslint-disable-next-line no-param-reassign
      file.stamp = new Date().getTime();
      return file;
    });
  }

  dispatch(addFiles({ files: updatedFiles, isUploaded }));
};

export const removeFileByIndex = (index) => ({
  type: REMOVE_FILE,
  payload: { index },
});

export const removeFile = ({ file, isUploaded }, index) => async (dispatch) => {
  if (isUploaded) {
    const swalResult = await ReactSwal.fire({
      type: 'error',
      title: 'Delete File Permanently ',
      text: 'Removing any of the files cannot be undone',
      confirmButtonText: 'Delete Permanently',
      showConfirmButton: true,
      showCancelButton: true,
      customClass: {
        container: 'sweetAlertCustomContainer deleteRoleConfirm',
        confirmButton: 'btn btn-danger btn-lg mx-2',
        cancelButton: 'btn btn-default btn-lg mx-2',
        actions: 'text-center',
      },
    });

    if (swalResult.value) {
      try {
        await deleteConfigFiles(file.name);
        dispatch(removeFileByIndex(index));
        toast('File removed from server', {
          containerId: 'mapping',
          type: 'success',
        });
      } catch (error) {
        toast('Error removing file', { containerId: 'mapping', type: 'error' });
        console.error(error);
      }
    }
  } else {
    dispatch(removeFileByIndex(index));
  }
};

const uploadingFiles = () => ({ type: UPLOAD_ALL_FILES });
const filesUploadDone = (responses) => ({
  type: UPLOAD_ALL_FILES_DONE,
  payload: responses,
});

export const uploadAllFiles = () => (dispatch, getState) => {
  const { mappingInfo: { files: { items: files } } } = getState();
  dispatch(uploadingFiles());

  const filesToUpload = files.filter(({ isUploaded }) => !isUploaded);
  const promises = filesToUpload.map(async ({ file }) => {
    try {
      return await sendFile(file);
    } catch (err) {
      if (err instanceof RequestError) {
        console.warn('File upload failed with error:', err.body.error);
      } else {
        console.log(err);
      }

      return { success: false, name: file.name };
    }
  });

  if (!isEmpty(filesToUpload)) {
    Promise.all(promises).then((responses) => {
      let updated = 0;
      let errors = 0;

      responses.forEach(({ success }) => {
        if (success) {
          updated += 1;
        } else {
          errors += 1;
        }
      });

      if (updated > 0) {
        toast(`${updated} file${updated % 10 !== 1 ? 's' : ''} uploaded successfully`, {
          containerId: 'mapping',
          type: 'success',
        });
        dispatch(updateConfig());
      }

      if (errors > 0) {
        toast(`${errors} file${errors % 10 !== 1 ? 's' : ''} failed to upload`, {
          containerId: 'mapping',
          type: 'error',
        });
      }

      dispatch(filesUploadDone(responses));
    });
  }
};

const uploadFileStart = (file) => ({
  type: UPLOAD_FILE,
  payload: { file },
});

const uploadFileDone = ({ file, success }) => ({
  type: UPLOAD_FILE,
  payload: { file, success },
});

export const uploadFile = (file) => (dispatch) => {
  dispatch(uploadFileStart(file));

  sendFile(file).then(({ success }) => {
    dispatch(uploadFileDone({ success, file }));
  }).catch((err) => {
    if (err instanceof RequestError) {
      console.warn('File upload failed with error:', err.body.error);
    } else {
      console.error(err);
    }

    dispatch(uploadFileDone({ success: false, file }));
  });
};
