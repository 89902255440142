import {
  TOGGLE_FILTER_BAR,
  TOGGLE_DISPLAY_OVERLAY,
  SET_CURRENT_ALBUM_TAB,
  SET_CURRENT_ALBUM_PAGE,
  TOGGLE_AUTO_UPDATE,
} from '../types';

const initialState = {
  filterBarDisplayed: false,
  overlaysDisplayed: false,
  autoUpdateEnabled: true,
  currentPage: 1,
  itemsPerPage: 100,
  currentTab: 'all',
};

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
  case TOGGLE_FILTER_BAR: {
    return {
      ...state,
      filterBarDisplayed: !state.filterBarDisplayed,
    };
  }

  case TOGGLE_DISPLAY_OVERLAY: {
    return {
      ...state,
      overlaysDisplayed: !state.overlaysDisplayed,
    };
  }

  case SET_CURRENT_ALBUM_TAB: {
    return {
      ...state,
      currentTab: payload,
    };
  }

  case SET_CURRENT_ALBUM_PAGE: {
    return {
      ...state,
      currentPage: payload,
    };
  }

  case TOGGLE_AUTO_UPDATE: {
    return {
      ...state,
      autoUpdateEnabled: !state.autoUpdateEnabled,
    };
  }

  default: {
    return state;
  }
  }
};
