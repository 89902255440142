import React, { forwardRef } from 'react';

import style from './style.module.scss';

const DropdownItem = forwardRef(({ children }, ref) => (
  <div ref={ref} className={style.item}>
    {children}
  </div>
));

export default DropdownItem;
