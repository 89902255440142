import { OPEN_SNAPSHOTS_EMAIL_MODAL, CLOSE_SNAPSHOTS_EMAIL_MODAL } from '../types';

const initialState = { isOpen: false };

export default (state = initialState, { type }) => {
  switch (type) {
  case OPEN_SNAPSHOTS_EMAIL_MODAL: {
    return {
      ...state,
      isOpen: true,
    };
  }
  case CLOSE_SNAPSHOTS_EMAIL_MODAL: {
    return {
      ...state,
      isOpen: false,
    };
  }
  default: {
    return state;
  }
  }
};
