import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import Select from 'react-select';
import { customSelectStylesOptions } from './helper';

import { fetchEvents } from '../../store/actions/events';
import { updatePath } from './actions';

const OverlayDropdown = () => {
  const dispatch = useDispatch();
  const overlaysObj = useSelector(({ overlays = {} }) => overlays);
  const options = useMemo(() => {
    const entries = Object.entries(overlaysObj);
    return entries.map(([label, { urlMediumSize }]) => ({
      label: label.substring(label.length - 12),
      value: urlMediumSize,
    }));
  }, [overlaysObj]);

  useEffect(() => {
    dispatch(fetchEvents(dispatch));
  }, []);

  return (
    <Select
      menuPlacement="top"
      placeholder="Overlay"
      options={options}
      styles={customSelectStylesOptions}
      onChange={(selected) => dispatch(updatePath('overlayURL', get(selected, 'value', '')))}
      isClearable
    />
  );
};

export default OverlayDropdown;
