import urlJoin from 'url-join';

const adminNamespace = 'admin';

export const urls = {
  auth: {
    checkSession: 'users/user',
    login: 'users/login',
    logout: 'users/logout',
  },
  passwordReset: {
    email: '/resetPasswordEmail',
    reset: '/resetPassword',
  },
  users: '/users',
  events: {
    root: '/events',
    event: '/event',
    delete: '/delete',
    share: '/share',
    geofence: '/geofence',
    language: '/languages',
    images: '/images',
  },
  cameras: {
    root: '/cameras',
    status: '/status',
    settings: {
      root: '/settings',
    },
  },
  websocket: urlJoin(process.env.WS_PROTOCOL, process.env.BRIZI_API_AUTHORITY, adminNamespace),
  baseAPIUrl: process.env.NODE_ENV === 'production'
    ? urlJoin(process.env.HTTP_PROTOCOL, process.env.BRIZI_API_AUTHORITY, 'api')
    : '/api/',
  snapshots: {
    list: 'snapshots',
    ban: 'snapshots/ban',
    favorite: 'snapshots/favorite',
    delete: 'snapshots/delete',
    stats: 'snapshots/stats',
  },
  mapping: {
    root: '/mapping',
    download: '/download',
  },
};

export const buildURL = (...args) => urlJoin(urls.baseAPIUrl, ...args);

export const removeOrigin = (image) => {
  if (image) {
    if (image.includes('static-assets') || image.includes('app/assets')) {
      // if image is served from static files, removing origin will result with an invalid URL
      // and download will not work, by doing this the URL will have a different origin, file will
      // not be downloaded as an attachment, but link will be correct and will open in a new tab
      return image;
    }

    const { origin } = new URL(image);
    return image.replace(origin, '');
  }

  return image;
};
