import React, { useEffect } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import {
  faHeart,
  faEnvelope,
  faArrowCircleDown,
  faTrash,
  faBan,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { selectAll, deselectAll, openEmailModal } from '../actions';
import { toggleFavorite, toggleBanned } from './actions';
import { openDeleteConfirmationAlert } from './confirmationAlerts';
import { getDownloadUrl } from './helpers';
import HoverDropdown from '../../../../vendor/libs/hover-dropdown';

import style from './style.module.scss';

const stopPropagation = (evt) => evt.stopPropagation();

const Bar = (props) => {
  const {
    selectedSnapshots,
    selectAllAction,
    deselectAllAction,
    openEmailModalAction,
    toggleFavoriteAction,
    toggleBannedAction,
  } = props;

  useEffect(() => {
    const handler = (evt) => {
      if (evt.key === 'Escape' || evt.key === 'Esc') {
        deselectAllAction();
      }

      if (evt.key.toLowerCase() === 'a' && evt.ctrlKey) {
        evt.preventDefault();
        selectAllAction();
      }
    };

    if (selectedSnapshots.length > 0) {
      document.addEventListener('keydown', handler);
    }

    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [selectedSnapshots]);

  if (selectedSnapshots.length === 0) {
    return null;
  }

  const getFavoriteTooltip = () => {
    if (selectedSnapshots.every(({ favorite }) => favorite)) {
      return <Tooltip>Unfavorite</Tooltip>;
    }

    if (selectedSnapshots.every(({ favorite }) => !favorite)) {
      return <Tooltip>Favorite</Tooltip>;
    }

    return <Tooltip>Toggle favorite</Tooltip>;
  };

  const getBannedTooltip = () => {
    if (selectedSnapshots.every(({ hidden }) => hidden)) {
      return <Tooltip>Unban</Tooltip>;
    }

    if (selectedSnapshots.every(({ hidden }) => !hidden)) {
      return <Tooltip>Ban</Tooltip>;
    }

    return <Tooltip>Toggle ban</Tooltip>;
  };

  return (
    <div className={classNames('navbar', style.controlsWrapper)}>
      <div>
        <span className="mr-2">{selectedSnapshots.length}</span>
        <span>Selected</span>
      </div>
      <div>
        <OverlayTrigger placement="bottom" overlay={getFavoriteTooltip()}>
          <Button
            variant="link"
            size="lg"
            className="icon-btn borderless"
            onClick={() => toggleFavoriteAction(selectedSnapshots)}
          >
            <FontAwesomeIcon icon={faHeart} size="lg" />
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          overlay={getBannedTooltip()}
        >
          <Button
            variant="link"
            size="lg"
            className="icon-btn borderless"
            onClick={() => toggleBannedAction(selectedSnapshots)}
          >
            <FontAwesomeIcon icon={faBan} size="lg" />
          </Button>
        </OverlayTrigger>

        <HoverDropdown
          as={DropdownButton}
          title={<FontAwesomeIcon icon={faArrowCircleDown} size="lg" />}
          onClick={stopPropagation}
          variant="default"
          className="downloadDropdown dropdown-toggle-hide-arrow"
        >
          <Dropdown.Item href={getDownloadUrl(selectedSnapshots)}>
            Raw
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href={getDownloadUrl(selectedSnapshots, 'sharedURI')}>
            Overlayed
          </Dropdown.Item>
        </HoverDropdown>

        <OverlayTrigger
          placement="bottom"
          trigger="hover"
          overlay={<Tooltip>Delete</Tooltip>}
        >
          <Button
            variant="link"
            size="lg"
            className="icon-btn borderless"
            onClick={() => openDeleteConfirmationAlert(selectedSnapshots)}
          >
            <FontAwesomeIcon icon={faTrash} size="lg" />
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          trigger="hover"
          overlay={<Tooltip>Email</Tooltip>}
        >
          <Button variant="link" size="lg" className="icon-btn borderless" onClick={openEmailModalAction}>
            <FontAwesomeIcon icon={faEnvelope} size="lg" />
          </Button>
        </OverlayTrigger>
      </div>
      <div>
        <Button variant="link" className="borderless" size="lg" onClick={selectAllAction}>
          Select All
        </Button>
        <span className={style.separator} />
        <Button variant="link" className="borderless pr-0" size="lg" onClick={deselectAllAction}>
          Deselect All
        </Button>
      </div>
    </div>
  );
};

export default connect(
  ({
    snapshots: { items: snapshots },
  }) => ({
    selectedSnapshots: snapshots.filter((snap) => snap.selected),
  }),
  {
    selectAllAction: selectAll,
    deselectAllAction: deselectAll,
    openEmailModalAction: openEmailModal,
    toggleFavoriteAction: toggleFavorite,
    toggleBannedAction: toggleBanned,
  },
)(Bar);
