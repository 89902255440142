import React, { useCallback, useEffect, useMemo } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';

import EventContainer from './EventContainer';
import ToolbarContainer from './ToolbarContainer';
import Alert from '../../../components/Alert';
import { can } from '../../../components/Authorize';
import { setEventForm, fetchEvents, fetchCurrentEvent } from '../../../store/actions/events';
import { deleteEvent } from '../../../helpers/api';
import { dateTimeFormat } from '../../../helpers/constants';
import { EVENT_WRITE } from '../../../components/Authorize/permissions/resources';
import { DAY_CLICK_CREATE_EVENT } from '../permissions';

import '../../../vendor/libs/react-big-calendar/react-big-calendar.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import style from './style.module.scss';

const localizer = momentLocalizer(moment);
const formats = {
  dateFormat: 'D',
  weekdayFormat: (date, culture) => localizer.format(date, 'ddd', culture),
};

const CalendarContainer = (props) => {
  const {
    allEvents,
    timezone,
    roles,
    setEventFormAction,
    fetchEventsAction,
    fetchCurrentEventAction,
  } = props;

  useEffect(() => {
    fetchEventsAction();
  }, []);

  const canCreateEvent = useMemo(() => can([EVENT_WRITE, DAY_CLICK_CREATE_EVENT], true), [roles]);

  const onDaySelectedHandler = useCallback((event) => {
    if (!canCreateEvent) {
      return;
    }

    const eventObj = { ...event };
    eventObj.className = 'upcoming-event';
    const startDate = moment(event.start).format(dateTimeFormat);
    const endDate = moment(event.end).format(dateTimeFormat);
    const now = moment().tz(timezone).format(dateTimeFormat);
    const isStartInPast = moment(startDate).diff(now, 'd') < 0;
    const isEndInPast = moment(endDate).diff(now, 'd') < 0;

    if (isStartInPast || isEndInPast) {
      Alert.fire({
        title: 'Past Event',
        text: 'You cannot create events in the past',
        confirmButtonText: 'Got it!',
        customClass: {
          confirmButton: 'small btn btn-primary',
          content: 'text-nowrap',
        },
      });
      return;
    }

    const eventsSameDay = allEvents.some((savedEvent) => {
      const saveEventDate = moment(savedEvent.start).startOf('day');
      const repeatedEvents = event.slots.some((newEvent) => (
        moment(newEvent).startOf('day').diff(saveEventDate.startOf('day'), 'day') === 0));
      return repeatedEvents;
    });

    if (eventsSameDay) {
      Alert.fire({
        title: 'Attention',
        text: 'Please, check your selection, you cannot create events on days that you already have events',
      });
      return;
    }

    setEventFormAction({ selectedSlots: event.slots, fromDate: event.start, toDate: event.end });
  }, [canCreateEvent, allEvents]);

  const editButtonHandler = useCallback(async (selectedEvent) => {
    const {
      title,
      start: gameDate,
      startTimeObject,
      endTimeObject,
      slugDate,
    } = allEvents.filter((ev) => ev.slugDate === selectedEvent.slugDate)[0];

    const gameDateF = moment.tz(gameDate, timezone).toDate();

    const eventObj = {
      fromDate: gameDateF,
      startTime: new Date(moment(startTimeObject).tz(timezone).format(dateTimeFormat)),
      endTime: new Date(moment(endTimeObject).tz(timezone).format(dateTimeFormat)),
      slugDate,
      eventTitle: title,
      formMode: 'update',
      isNextDay: startTimeObject.getDay() !== endTimeObject.getDay(),
    };

    setEventFormAction(eventObj);
  }, [allEvents, timezone]);

  const deleteButtonHandler = useCallback((e, selectedEvent) => {
    e.preventDefault();

    Alert.fire({
      title: 'Do you want to delete the event?',
      type: 'question',
      input: 'text',
      html: `Type the slug date to confirm deletion: <b>${selectedEvent.slugDate}</b>.`,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      // eslint-disable-next-line consistent-return
      inputValidator: (value) => {
        if (value !== selectedEvent.slugDate) {
          return 'Please, enter the correct slug date';
        }
      },
      preConfirm: () => deleteEvent(selectedEvent.slugDate)
        .then(async () => {
          await fetchEventsAction();
          fetchCurrentEventAction();
          await Alert.fire({ title: 'Event has been deleted' });
        })
        .catch((err) => {
          console.error('Error trying to delete the event from database', err);
          Alert.showValidationMessage('Error trying to delete event from database. Please, try again or contact support!');
        }),
    });
  }, []);

  return (
    <Calendar
      className={style.calendarContainer}
      style={{ minHeight: '80vh', maxHeight: '90vh' }}
      events={allEvents}
      formats={formats}
      popup
      selectable={canCreateEvent}
      resizable={false}
      defaultDate={new Date(moment.tz(timezone).format('ll'))}
      localizer={localizer}
      eventPropGetter={({ className }) => (className ? { className } : null)}
      onSelectSlot={onDaySelectedHandler}
      components={{
        toolbar: ToolbarContainer,
        event: (componentProps) => (
          <EventContainer
            {...componentProps}
            editEventHandler={editButtonHandler}
            deleteEventHandler={deleteButtonHandler}
          />
        ),
      }}
    />
  );
};

export default connect(
  ({
    events: { allEvents, timezone },
    roles: { roles },
  }) => ({
    allEvents,
    timezone,
    roles,
  }),
  {
    setEventFormAction: setEventForm,
    fetchEventsAction: fetchEvents,
    fetchCurrentEventAction: fetchCurrentEvent,
  },
)(CalendarContainer);
