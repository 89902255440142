import React, { useState } from 'react';

const Image = ({ src }) => {
  const [imgLoaded, setImgLoaded] = useState(false);

  return (
    <>
      {!imgLoaded && <div className="glow" />}
      <img className="image" src={src} alt="" onLoad={() => setImgLoaded(true)} />
    </>
  );
};

export default Image;
