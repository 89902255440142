import {
  OPEN_LOCATION_MODAL,
  CLOSE_LOCATION_MODAL,
  TOGGLE_GEOFENCE_SETTINGS,
  FETCH_GEOLOCATION,
  ADD_LOCATION_OPTION,
  REMOVE_LOCATION_OPTION,
  LOAD_GEOFENCE_SETTINGS,
  SET_INPUT_VALUE,
} from './types';

const initialState = {
  isLocationModalOpen: false,
  isFetching: false,
  hasError: false,
  geofence: {
    enabled: false,
    address: '',
    addressInput: '',
    latitude: null,
    longitude: null,
    radius: null,
    radiusInput: '',
    locations: [],
  },
};

export default (state = initialState, { payload, type } = {}) => {
  switch (type) {
  case LOAD_GEOFENCE_SETTINGS: {
    return {
      ...state,
      geofence: {
        ...state.geofence,
        ...payload,
      },
    };
  }
  case FETCH_GEOLOCATION: {
    const { error, data } = payload || {};
    return {
      ...state,
      isFetching: !error && !data,
      error,
      geofence: {
        ...state.geofence,
        ...data,
      },
    };
  }

  case SET_INPUT_VALUE: {
    const { input } = payload || {};

    return {
      ...state,
      geofence: {
        ...state.geofence,
        ...input,
      },
    };
  }
  case REMOVE_LOCATION_OPTION: {
    const filteredLocations = state.geofence.locations.filter(
      (item) => item !== payload,
    );
    return {
      ...state,
      geofence: {
        ...state.geofence,
        locations: filteredLocations,
      },
    };
  }
  case ADD_LOCATION_OPTION: {
    return {
      ...state,
      geofence: {
        ...state.geofence,
        locations: [...state.geofence.locations, payload],
      },
    };
  }
  case OPEN_LOCATION_MODAL: {
    return {
      ...state,
      isLocationModalOpen: true,
    };
  }
  case CLOSE_LOCATION_MODAL: {
    return {
      ...state,
      isLocationModalOpen: false,
    };
  }
  case TOGGLE_GEOFENCE_SETTINGS: {
    return {
      ...state,
      geofence: {
        ...state.geofence,
        enabled: !state.geofence.enabled,
      },
    };
  }
  default: {
    return state;
  }
  }
};
