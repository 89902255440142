import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import imgPlaceholder from '../../../../assets/images/image-placeholder.svg';
import SelectionButton from '../../../../components/SelectionButton';

import { setSelected } from '../actions';

const SectionBtn = ({
  selected,
  marginTop,
  section: {
    key,
    label,
    data,
    options,
    value,
  },
  setSelectedAction,
}) => {
  const imgUrl = (options && options[value]) ? options[value].imageSrc : data[`${key}Url`];
  const classes = classnames({ 'mt-2': marginTop });
  const imageSrc = imgUrl || imgPlaceholder;

  return (
    <SelectionButton
      label={label}
      selected={selected}
      className={classes}
      imageSrc={imageSrc}
      onClick={setSelectedAction}
    />
  );
};

export default connect(
  ({ graphics: { sections, selectedInd } }, { index }) => ({
    selected: selectedInd === index,
    marginTop: index !== 0,
    section: sections[index],
  }),
  (dispatch, { index }) => ({
    setSelectedAction: () => dispatch(setSelected(index)),
  }),
)(SectionBtn);
