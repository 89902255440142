import React from 'react';
import { connect } from 'react-redux';
import { Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPlusCircle } from '@fortawesome/pro-regular-svg-icons';

import { openLocationModal, deleteLocationOption } from '../actions';

import style from '../style.module.scss';

const LocationOptions = ({
  locationOptions = [],
  openLocationModalAction,
  deleteLocationOptionAction,
}) => (
  <div className={style.tableContainer}>
    <Table size="sm">
      <thead>
        <tr>
          <th>Location</th>
          <th>Allow</th>
          <th>
            <Button variant="link" onClick={openLocationModalAction}>
              <FontAwesomeIcon icon={faPlusCircle} />
            </Button>
          </th>
        </tr>
      </thead>
      <tbody>
        {locationOptions && locationOptions.map((location) => (
          <tr key={`location-${location.location}`}>
            <td>{location.location}</td>
            <td>{location.allow.toString()}</td>
            <td>
              <Button className={style.remove} variant="link" onClick={() => deleteLocationOptionAction(location)}>
                <FontAwesomeIcon icon={faTimesCircle} />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
);

export default connect(({
  events: { template },
  location: { isFetching, geofence: { locations } },
}) => ({
  template,
  locationOptions: locations,
  isFetching,
}), {
  openLocationModalAction: openLocationModal,
  deleteLocationOptionAction: deleteLocationOption,
})(LocationOptions);
