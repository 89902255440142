import React, { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowDown, faCircleArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { Button } from 'react-bootstrap';
import { SocketContext } from 'react-socket-io';
import { isEmpty } from 'lodash';
import camMap from '../../mapLogic';
import { updatePath } from '../../actions';
import { inRange, socketEventNames } from '../../helper';

import style from './style.module.scss';


const { ROVER_MOVE, ROVER_GET_POSITION } = socketEventNames;

const Browse = () => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const connectedCamera = useSelector(({ mapping: { connectedCamera: cam } }) => cam);
  const update = useCallback((...args) => dispatch(updatePath(...args)), []);
  const pan = useSelector(({ mapping: { browse } }) => browse.reference.pan);
  const tilt = useSelector(({ mapping: { browse } }) => browse.reference.tilt);
  const zoom = useSelector(({ mapping: { browse } }) => browse.reference.zoom);
  const section = useSelector(({ mapping: { browse } }) => browse.test.section);
  const row = useSelector(({ mapping: { browse } }) => browse.test.row);
  const seat = useSelector(({ mapping: { browse } }) => browse.test.seat);
  const referenceImage = useSelector(({ mapping: { preview: { referenceImage: img } } }) => img);
  const [refImgVariant, setRefImgVariant] = useState('outline-primary');
  const [refPointVariant, setRefPointVariant] = useState('outline-primary');
  const [requestRefPointVariant, setRequestRefPointVariant] = useState('outline-primary');
  const [requestRefImgVariant, setRequestRefImgVariant] = useState('outline-primary');

  const requestGetPosition = () => {
    dispatch(updatePath('positionPath', 'browse.reference'));
    socket.emit(ROVER_GET_POSITION, 'host', connectedCamera, '#');
  };

  const requestGetReference = () => {
    if (!connectedCamera) {
      console.error('No camera connected');
      return;
    }
    try {
      const camReferencePoint = camMap.getReference();
      update('browse.reference.pan', camReferencePoint.pan);
      update('browse.reference.tilt', camReferencePoint.tilt);
      update('browse.reference.zoom', camReferencePoint.zoom * 1000);
      setRequestRefPointVariant(camReferencePoint ? 'success' : 'danger');
      setTimeout(() => {
        setRequestRefPointVariant('outline-primary');
      }, 1000);
    } catch (error) {
      console.error('Error getting reference point:', error);
    }
  };

  const setReferencePoint = () => {
    if (!connectedCamera) {
      console.error('No camera connected');
      return;
    }
    // if the fields are empty, do not proceed, note that isEmpty return true
    // if the variable is of type number so convert to string before checking
    if (isEmpty(pan.toString()) || isEmpty(tilt.toString())) {
      console.warn('pan or tilt is empty', pan, tilt);
      return;
    }

    // eslint-disable-next-line no-alert, no-restricted-globals
    if (confirm('Are you sure you want to set the reference PTZ?') === true) {
      const result = camMap.updateRefPoint(pan, tilt, zoom / 1000);
      setRefPointVariant(result ? 'success' : 'danger');
      setTimeout(() => {
        setRefPointVariant('outline-primary');
      }, 1000);
    }
  };

  const setReferenceImage = async () => {
    if (!connectedCamera) {
      console.error('No camera connected');
      return;
    }
    // eslint-disable-next-line no-alert, no-restricted-globals
    if (confirm('Are you sure you want to set the reference image?') === true) {
      try {
        const result = await camMap.updateRefImage();
        setRefImgVariant(result ? 'success' : 'danger');
        setTimeout(() => {
          setRefImgVariant('outline-primary');
        }, 1000);
      } catch (error) {
        console.error('Error updating reference image:', error);
      }
    }
  };

  const sendPositionToCamera = () => {
    console.info('BrowseTab: send position to camera');
    const safePan = Number(pan);
    const safeTilt = Number(tilt);
    const safeZoom = Number(zoom / 1000);

    if (connectedCamera && Number.isInteger(safePan)
        && Number.isInteger(safeTilt) && inRange(safeZoom, 0, 1)) {
      console.log('BrowseTab: sending move command to camera', safePan, safeTilt, safeZoom);
      socket.emit(ROVER_MOVE, 'host', connectedCamera, `!${safePan},${safeTilt},${safeZoom}`);
    } else {
      console.error('Error sending move command to camera from BrowseTab', safePan, safeTilt, safeZoom);
    }
  };

  const toggleReferenceImage = async () => {
    if (!connectedCamera) {
      console.error('No camera connected');
      return;
    }

    if (referenceImage) {
      update('preview.referenceImage', null);
      return;
    }

    try {
      const data = await camMap.getRefImage();
      const blob = new Blob([data], { type: 'image/jpeg' });
      const imageUrl = URL.createObjectURL(blob);
      update('preview.referenceImage', imageUrl);
      setRequestRefImgVariant(data ? 'success' : 'danger');
      setTimeout(() => {
        setRequestRefImgVariant('outline-primary');
      }, 1000);
    } catch (error) {
      console.error('Error getting reference image:', error);
    }
  };

  return (
    <div className={style.browseTab}>
      <div className={style.block}>
        <div className={style.top}>
          <span>Reference Position</span>
          <div>
            <button
              className={style.circleButton}
              onClick={requestGetPosition}
              type="button"
              title="Get position from camera"
            >
              <FontAwesomeIcon icon={faCircleArrowDown} />
            </button>
            <button
              className={style.circleButton}
              onClick={sendPositionToCamera}
              type="button"
              title="Send position to camera"
            >
              <FontAwesomeIcon icon={faCircleArrowUp} />
            </button>
          </div>
        </div>
        <div className={style.row}>
          <span>
            Pan
          </span>
          <input
            className={style.input}
            type="number"
            value={pan}
            onChange={(e) => update('browse.reference.pan', e.target.value)}
          />
          <Button
            variant={requestRefPointVariant}
            className="small"
            onClick={requestGetReference}
          >
            Load Ref
          </Button>
        </div>
        <div className={style.row}>
          <span>
            Tilt
          </span>
          <input
            className={style.input}
            type="number"
            value={tilt}
            onChange={(e) => update('browse.reference.tilt', e.target.value)}
          />
          <Button
            variant={requestRefImgVariant}
            className="small"
            onClick={toggleReferenceImage}
          >
            View Image
          </Button>
        </div>
        <div className={style.row}>
          <span>
            Zoom
          </span>
          <input
            className={style.input}
            type="number"
            value={zoom}
            onChange={(e) => update('browse.reference.zoom', e.target.value)}
          />
        </div>
        <div className={style.row}>
          <Button
            variant={refPointVariant}
            onClick={setReferencePoint}
            className="small"
          >
            Set
          </Button>
          <Button
            variant={refImgVariant}
            onClick={setReferenceImage}
            className="small"
          >
            Set Image
          </Button>
        </div>
      </div>

      <div className={style.block} hidden>
        <div className={style.top}>
          <span>Test Position</span>
          <div>
            <button className={style.circleButton} type="button" title="Get position from camera">
              <FontAwesomeIcon icon={faCircleArrowDown} />
            </button>
            <button className={style.circleButton} type="button" title="Send position to camera">
              <FontAwesomeIcon icon={faCircleArrowUp} />
            </button>
          </div>
        </div>
        <div className={style.row}>
          <span>
            Section
          </span>
          <input
            type="text"
            className={style.input}
            value={section}
            onChange={(e) => update('browse.test.section', e.target.value)}
          />
        </div>
        <div className={style.row}>
          <span>
            Row
          </span>
          <input
            type="text"
            className={style.input}
            value={row}
            onChange={(e) => update('browse.test.row', e.target.value)}
          />
        </div>
        <div className={style.row}>
          <span>
            Seat
          </span>
          <input
            type="text"
            className={style.input}
            value={seat}
            onChange={(e) => update('browse.test.seat', e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};


export default Browse;
