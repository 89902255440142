import { UPDATE_OVERLAYS } from '../types/overlays';

export default (state = {}, { type, payload }) => {
  switch (type) {
  case UPDATE_OVERLAYS: {
    const overlays = payload || [];
    const newOverlays = {};

    overlays.forEach((overlay) => {
      newOverlays[overlay.storageKey] = overlay;
    });

    return {
      ...state,
      ...newOverlays,
    };
  }

  default: {
    return state;
  }
  }
};
