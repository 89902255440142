import React from 'react';

const SidenavBlock = (props) => {
  const { className, ...rest } = props;

  return (
    <div className={`sidenav-block ${className || ''}`} {...rest}>
      {this.props.children}
    </div>
  );
}

export default SidenavBlock;
