import {
  FETCH_SUSPICIOUS_NOTIFICATIONS,
  SET_SUSPICIOUS_NOTIFICATION_ADDRESSED,
  FETCH_MORE_SUSPICIOUS_NOTIFICATIONS,
} from './types';
import { PAGE_SIZE } from './constants';

const initialState = {
  isFetching: false,
  isFetchingMore: false,
  hasMore: true,
  items: [],
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
  case FETCH_SUSPICIOUS_NOTIFICATIONS: {
    const { error, data } = payload;

    return {
      ...state,
      isFetching: !error && !data,
      items: data || initialState.items,
      hasMore: data ? data.length === PAGE_SIZE : state.hasMore,
    };
  }

  case FETCH_MORE_SUSPICIOUS_NOTIFICATIONS: {
    const { error, data } = payload;

    return {
      ...state,
      isFetchingMore: !error && !data,
      items: data ? [...state.items, ...data] : state.items,
      hasMore: data ? data.length === PAGE_SIZE : state.hasMore,
    };
  }

  case SET_SUSPICIOUS_NOTIFICATION_ADDRESSED: {
    const { id, addressed } = payload;

    return {
      ...state,
      items: state.items.map((notification) => (
        notification._id === id ? { ...notification, addressed } : notification
      )),
    };
  }

  default: {
    return state;
  }
  }
};
