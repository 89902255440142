import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { generateGoogleMapsImage } from '../../../../helpers/gmaps';
import style from '../style.module.scss';

const Map = ({ geofence }) => {
  const mapRef = useRef();
  const [googleMapsImage, setGoogleMapsImage] = useState(null);

  useEffect(() => {
    const { latitude, longitude, radius } = geofence;
    const ratio = (get(mapRef, 'current.offsetWidth') / get(mapRef, 'current.offsetHeight'));

    if (latitude && longitude && radius && ratio) {
      setGoogleMapsImage(generateGoogleMapsImage(latitude, longitude, radius, ratio));
    }
  }, [geofence]);

  return (
    <div className={style.mapContainer} ref={mapRef}>
      {googleMapsImage && <img src={googleMapsImage} alt="location map" />}
    </div>
  );
};

export default connect(
  ({
    events: { template },
    location: { geofence, error, isFetching },
  }) => ({ template, hideMap: error || isFetching, geofence }),
)(Map);
