import {
  SET_FULLSCREEN_SNAPSHOT,
  UNSET_FULLSCREEN_SNAPSHOT,
  SET_LARGE_IMAGE_URL,
  FETCH_IMAGE_MINING_DATA,
  SET_SELECTED_FACE_IND,
  FETCH_FULLSCREEN_SNAPSHOT,
} from '../type';
import { SET_FAVORITE, SET_BANNED_SNAPSHOTS, SET_BANNED_USER } from '../../../../store/types/snapshots';

const initialState = {
  isFetching: false,
  data: undefined,
  largeImageUrl: null,
  imageMiningData: {
    isFetching: false,
    data: undefined,
    selectedInd: 0,
  },
};

export default (state = initialState, { type, payload = {} } = {}) => {
  switch (type) {
  case SET_FULLSCREEN_SNAPSHOT: {
    return {
      ...state,
      data: payload,
      error: undefined,
      largeImageUrl: payload.rawCroppedURI,
    };
  }

  case UNSET_FULLSCREEN_SNAPSHOT: {
    return {
      ...state,
      data: undefined,
    };
  }

  case FETCH_FULLSCREEN_SNAPSHOT: {
    const { error, data } = payload;

    return {
      ...state,
      isFetching: !error && !data,
      error,
      data,
      largeImageUrl: (data && data.rawCroppedURI) ? data.rawCroppedURI : state.largeImageUrl,
    };
  }

  case SET_FAVORITE: {
    if (!state.data) {
      return state;
    }

    const snaphsotUpdateObjects = payload;
    const { _id: snapshotId } = state.data;
    const updateObj = snaphsotUpdateObjects.find(({ _id }) => _id === snapshotId);

    return {
      ...state,
      data: updateObj ? { ...state.data, favorite: updateObj.favorite } : state.data,
    };
  }

  case SET_BANNED_SNAPSHOTS: {
    if (!state.data) {
      return state;
    }

    const snaphsotUpdateObjects = payload;
    const { _id: snapshotId } = state.data;
    const updateObj = snaphsotUpdateObjects.find(({ _id }) => _id === snapshotId);

    return {
      ...state,
      data: updateObj ? { ...state.data, hidden: updateObj.hidden } : state.data,
    };
  }

  case SET_BANNED_USER: {
    if (!state.data) {
      return state;
    }

    const { userId, hidden } = payload;
    const { data } = state;

    return {
      ...state,
      data: data.userId === userId ? { ...data, hidden } : data,
    };
  }

  case SET_LARGE_IMAGE_URL: {
    return {
      ...state,
      largeImageUrl: payload,
    };
  }

  case FETCH_IMAGE_MINING_DATA: {
    const { error, data } = payload;

    return {
      ...state,
      imageMiningData: {
        ...state.imageMiningData,
        isFetching: !error && !data,
        error,
        data,
      },
    };
  }

  case SET_SELECTED_FACE_IND: {
    return {
      ...state,
      imageMiningData: {
        ...state.imageMiningData,
        selectedInd: payload,
      },
    };
  }

  default: {
    return state;
  }
  }
};
