import React, { forwardRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Navbar,
  Nav,
  Button,
  Dropdown,
} from 'react-bootstrap';

import layoutHelpers from '../helpers';
import { useWindowResize } from '../../../helpers/hooks';
import { logout } from '../../../helpers/api';
import { setUserData } from '../../../store/actions/authentication';
import { METRICS, EVENT_CONTROLS, CAMERA_STATUS } from './permissions';

import EventDetails from './EventDetails';
import CameraStatus from './CameraStatus';
import EventMetrics from './EventMetrics';
import EventControls from './EventControls';
import Authorize from '../../../components/Authorize';

import logo from '../../../assets/images/AMPLIFiCAM_symbol.png';

import './Navbar.scss';

const LayoutNavbar = (props) => {
  const {
    sidenavToggle = true,
    navbarBg,
    setUserDataAction,
    history,
  } = props;

  useWindowResize();

  const toggleSidenav = (e) => {
    e.preventDefault();
    layoutHelpers.toggleCollapsed();
  };

  const doLogout = async () => {
    try {
      await logout();
      setUserDataAction(null);
      history.push('/login');
    } catch (err) {
      console.error('Error trying to logout', err);
    }
  };

  const toggleButton = forwardRef(({ onClick, children }, ref) => (
    <Button ref={ref} variant="default" onClick={onClick} className="toggle-button">
      {children}
    </Button>
  ));

  return (
    <Navbar bg={navbarBg} expand="lg" className="layout-navbar justify-content-between">
      <Nav className="flex-row">
        <div className="app-brand">
          <Link to="/" className="logo-container">
            <img className="app-brand-text" src={logo} alt="logo" />
          </Link>
        </div>

        <EventDetails />
        <Authorize permissions={CAMERA_STATUS}>
          <CameraStatus />
        </Authorize>
        {/* Sidenav toggle */}
        {sidenavToggle && layoutHelpers.isSmallScreen() && (
          <Nav.Item as="a" className="nav-item nav-link px-0 ml-2 ml-lg-0" href="#toggle" onClick={toggleSidenav}>
            <i className="ion ion-md-menu text-large align-middle" />
          </Nav.Item>
        )}
      </Nav>
      <Nav className="flex-row align-items-center" style={{ minHeight: '50px' }}>
        <Authorize permissions={METRICS}>
          <EventMetrics />
        </Authorize>
        <Authorize permissions={EVENT_CONTROLS}>
          <EventControls />
        </Authorize>
        <Dropdown>
          <Dropdown.Toggle as={toggleButton}>
            <i className="fas fa-ellipsis-v fa-lg" />
          </Dropdown.Toggle>
          <Dropdown.Menu alignRight>
            <Dropdown.Item className="action-button" onClick={doLogout}>
              <i className="ion ion-md-log-out text-danger" />
              &nbsp;&nbsp;Log Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav>

    </Navbar>
  );
};

export default connect(
  (store) => ({ navbarBg: store.theme.navbarBg }),
  { setUserDataAction: setUserData },
)(LayoutNavbar);
