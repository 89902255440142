import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { get } from 'lodash';
import FormInput from '../FormInput';
import style from '../style.module.scss';

const CameraConfigRow = ({
  cameras,
  config,
  label,
  configKey,
  register,
  setValue,
  getConfigByKey,
}) => {
  const getDefaultValue = (item, camera) => {
    const currentSettings = camera.cameraSettings;
    const settingsValue = get(currentSettings, item.configKey);
    if (['RADIO', 'MENU'].includes(item.widgetType) && !Object.values(item.choices).includes(settingsValue)) {
      return item.configValue;
    }
    return settingsValue || item.configValue;
  };

  return (
    <tr>
      <td>
        <OverlayTrigger
          placement="bottom"
          overlay={(
            <Tooltip id={`${configKey}-tooltip`}>
              {label}
              <br />
              {configKey}
              {config?.widgetType === 'RANGE' && (
                <>
                  <br />
                  {`step: ${config.step}`}
                  <br />
                  {`min: ${config.bottom}`}
                  <br />
                  {`max: ${config.top}`}
                </>
              )}
            </Tooltip>
          )}
        >
          <span className={style.SideLabel}>{label}</span>
        </OverlayTrigger>
      </td>
      {cameras.map((camera) => {
        const item = getConfigByKey(camera, configKey);
        return (
          <td key={`${camera._id}-${configKey}`}>
            {item ? (
              <FormInput
                form={`cameraConfigs.${camera._id}`}
                item={item}
                defaultValue={getDefaultValue(item, camera)}
                register={register}
                setValue={setValue}
                className={style.FormInput}
              />
            ) : (
              'N/A'
            )}
          </td>
        );
      })}
    </tr>
  );
};

export default CameraConfigRow;
