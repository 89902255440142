import React from 'react';

import FallbackImage from '../../../../assets/images/noDataFallback.svg';

import style from './style.module.scss';

const NoDataFallback = () => (
  <div className={style.FallbackContainer}>
    <img src={FallbackImage} alt="Fallback for no data" />
    <h3>Data not available yet</h3>
    <p>
      The event may have just started and users are still joining, try again in a few minutes.
    </p>
  </div>
);


export default NoDataFallback;
