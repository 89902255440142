import { BLOCKS_LAYOUT } from '../constants';
import {
  SET_MONITOR_LAYOUT,
  TOGGLE_SIDEBAR,
  SET_SELECTED_CAMERA,
} from '../types';

const initialState = {
  selectedCameraName: 'cam1',
  sidebarDisplayed: true,
  layout: BLOCKS_LAYOUT,
};

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
  case SET_MONITOR_LAYOUT: {
    const layout = payload;

    return {
      ...state,
      layout,
    };
  }

  case TOGGLE_SIDEBAR: {
    return {
      ...state,
      sidebarDisplayed: !state.sidebarDisplayed,
    };
  }

  case SET_SELECTED_CAMERA: {
    return {
      ...state,
      selectedCameraName: payload,
    };
  }

  default: {
    return state;
  }
  }
};
