import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Event, SocketContext } from 'react-socket-io';
import { faCamera } from '@fortawesome/pro-regular-svg-icons';
import { socketEventNames } from './helper';

import style from './style.module.scss';
import RoundButton from '../../components/RoundButton';

const { TAKE_SHOT, SEND_SHOT } = socketEventNames;

const TakeSnapshotButton = ({ cam }) => {
  const socket = useContext(SocketContext);

  const takeSnapshot = () => {
    socket.emit(TAKE_SHOT, 'host', cam);
  };

  const snapshotHandler = (data) => {
    const base64String = Buffer.from(data).toString('base64');
    const image = new Image();
    image.src = `data:image/jpeg;base64,${base64String}`;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = image.width;
      canvas.height = image.height;

      // Draw the image onto the canvas
      ctx.drawImage(image, 0, 0);

      // Set the style for the plus sign
      ctx.strokeStyle = 'rgba(255, 255, 255, 1)'; // Red color with 50% opacity
      ctx.lineWidth = 2; // Width of the lines

      // Calculate the center position
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;

      // Draw the horizontal line
      ctx.beginPath();
      ctx.moveTo(centerX - 20, centerY);
      ctx.lineTo(centerX + 20, centerY);
      ctx.stroke();

      // Draw the vertical line
      ctx.beginPath();
      ctx.moveTo(centerX, centerY - 20);
      ctx.lineTo(centerX, centerY + 20);
      ctx.stroke();

      // Convert canvas to image
      const finalImage = canvas.toDataURL('image/jpeg');

      const spacing = 40;
      const height = window.innerHeight - 100;
      const left = spacing;
      const top = spacing;

      const popupWindow = window.open('', '_blank', `width=${height},height=${height},left=${left},top=${top}`);
      popupWindow.document.write(`
        <div style="width: 100%; height: 100%; overflow: auto;">
          <img src="${finalImage}" />
        </div>
      `);
    };
  };

  return (
    <>
      <Event event={SEND_SHOT} handler={snapshotHandler} />
      <RoundButton className={style.takeSnapshot} onClick={takeSnapshot}>
        <FontAwesomeIcon size="lg" icon={faCamera} className="align-middle" />
      </RoundButton>
    </>
  );
};

export default TakeSnapshotButton;
