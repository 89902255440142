import React, { useState } from 'react';
import { Card, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import classnames from 'classnames';

import style from './style.module.scss';

const Section = ({ title, children }) => {
  const [open, setOpen] = useState(false);

  const toggle = (evt) => {
    evt.preventDefault();
    setOpen(!open);
  };

  return (
    <Card className={style.card}>
      <Card.Header>
        <a
          className="text-body d-flex justify-content-between"
          href="#accordion"
          onClick={toggle}
        >
          <h4 className="mb-0">{title}</h4>
          <FontAwesomeIcon className={classnames({ [style.rotated]: open })} icon={faChevronDown} />
        </a>
      </Card.Header>
      <Collapse in={open}>
        <Card.Body className="py-0">
          {children}
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default Section;
