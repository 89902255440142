import { listSnapshots } from '../../../helpers/api';
import { ITEMS_PER_PAGE } from './constants';
import {
  requestSnapshots,
  recieveSnapshots,
  addSnapshot as addToSnapshots,
} from '../../../store/actions/snapshots';
import {
  SET_SHARED,
  SET_DISABLE_UPDATES,
  SET_SHOULD_RECENT_REFRESH,
  SET_SHOULD_SHARED_REFRESH,
  UNSET_SHOULD_REFRESH,
  MONITOR_TOGGLE_OVERLAYS,
} from './types';

export const setShared = (payload) => ({ type: SET_SHARED, payload });
const setShouldRecentRefresh = () => ({ type: SET_SHOULD_RECENT_REFRESH });
const setShouldSharedRefresh = () => ({ type: SET_SHOULD_SHARED_REFRESH });
const unsetShouldRefresh = () => ({ type: UNSET_SHOULD_REFRESH });

export const setUpdatesDisabled = (disabled) => ({
  type: SET_DISABLE_UPDATES,
  payload: disabled,
});


export const fetchSnapshots = () => (dispatch, getState) => {
  const {
    monitor: {
      sidebar: {
        displayingShared,
      },
    },
    events: {
      currentEvent: {
        slugDate: currentEventSlugDate,
      } = {},
    },
  } = getState();

  if (!currentEventSlugDate) {
    return;
  }

  const currentTab = displayingShared ? 'shared' : 'all-with-magicshot';

  dispatch(requestSnapshots());

  listSnapshots([currentEventSlugDate], currentTab, 1, ITEMS_PER_PAGE).then((data) => {
    dispatch(unsetShouldRefresh());
    dispatch(recieveSnapshots(null, data));
  }).catch((err) => {
    console.error(err);
    dispatch(recieveSnapshots(err));
  });
};

export const addSharedSnapshot = (_platform, snapshot) => (dispatch, getState) => {
  const {
    monitor: {
      sidebar: {
        updatesDisabled,
        displayingShared,
      },
    },
  } = getState();
  const shared = snapshot.postObj && snapshot.postObj.length !== 0;

  if (updatesDisabled) {
    dispatch(setShouldSharedRefresh());
  } else if (displayingShared && shared) {
    dispatch(addToSnapshots(snapshot, ITEMS_PER_PAGE));
  }
};

export const addRecentSnapshot = (snapshot) => (dispatch, getState) => {
  const {
    monitor: {
      sidebar: {
        updatesDisabled,
        displayingShared,
      },
    },
  } = getState();
  const shared = snapshot.postObj && snapshot.postObj.length !== 0;

  if (updatesDisabled) {
    dispatch(setShouldRecentRefresh());
  } else if (!displayingShared && !shared) {
    dispatch(addToSnapshots(snapshot, ITEMS_PER_PAGE));
  }
};

export const toggleOverlays = () => ({ type: MONITOR_TOGGLE_OVERLAYS });
