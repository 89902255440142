import React from 'react';
import { connect } from 'react-redux';
import { Event } from 'react-socket-io';

import Toast from './Toast';

import { addToast, removeToast } from './action';
import { socketEventNames } from '../../../helpers/constants';

import style from './style.module.scss';

const { MONITOR_CHECK_DISTINCT } = socketEventNames;

const SuspiciousUserToasts = ({ toasts, addToastAction }) => (
  <>
    <Event event={MONITOR_CHECK_DISTINCT} handler={addToastAction} />
    <div className={style.toastContainer}>
      {toasts.slice(0, 3).map(({
        _id,
        data: {
          userId,
          snapshotsTaken,
          distinctSRS,
          allSRS,
        },
      }) => (
        <Toast
          key={_id}
          id={_id}
          userId={userId}
          snapshotsTaken={snapshotsTaken}
          uniqueSeats={distinctSRS.length}
          allSRS={allSRS}
        />
      ))}
    </div>
  </>
);

export default connect(
  ({ monitor: { toasts: { items: toasts } } }) => ({ toasts }),
  {
    addToastAction: addToast,
    removeToastAction: removeToast,
  },
)(SuspiciousUserToasts);
