import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';

import { setFullscreenSnapshot } from '../../components/Snapshot/LightBox/actions';

import style from './style.module.scss';

const Snapshot = (props) => {
  const {
    history,
    match: {
      params: { snapshotId },
    },
    setFullscreenSnapshotAction,
  } = props;

  useEffect(() => {
    setFullscreenSnapshotAction(snapshotId);
    history.replace('/');
  }, [snapshotId]);

  return (
    <div className={style.wrapper}>
      <Spinner animation="border" variant="primary" />
    </div>
  );
};

export default connect(
  ({ snapshots: { fullscreenSnapshot: snapshot } }) => ({ snapshot }),
  { setFullscreenSnapshotAction: setFullscreenSnapshot },
)(Snapshot);
