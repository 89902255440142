import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Event } from 'react-socket-io';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import {
  OverlayTrigger,
  Popover,
  Spinner,
  Tab,
  Tabs,
} from 'react-bootstrap';
import classnames from 'classnames';

import OverlaidSnapshot from './OverlaidSnapshot';

import { setLightBoxSnapshot, toggleSidebar } from '../actions';
import { socketEventNames } from '../../../helpers/constants';
import { useDelayedRender } from '../../../helpers/hooks';
import { requestSnapshots } from '../../../store/actions/snapshots';
import {
  setShared,
  fetchSnapshots,
  addRecentSnapshot,
  addSharedSnapshot,
  setUpdatesDisabled,
  toggleOverlays,
} from './actions';

import style from './style.module.scss';

const { MONITOR_NEW_SNAPSHOT, MONITOR_SNAPSHOT_SHARED } = socketEventNames;

const Sidebar = (props) => {
  const {
    isFetching,
    snapshots,
    sidebarDisplayed,
    displayingShared,
    slugDate,
    shouldRefresh,
    overlaysDisplayed,
    setSharedAction,
    setLightBoxSnapshotAction,
    fetchSnapshotsAction,
    requestSnapshotsAction,
    setUpdatesDisabledAction,
    addRecentSnapshotAction,
    addSharedSnapshotAction,
    toggleOverlaysAction,
    toggleSidebarAction,
  } = props;
  const sidebarDisplayedRef = useRef(sidebarDisplayed);
  const ref = useRef();

  // we need this variable to display loading indicator in case of the first render,
  // and skip snapshots render, before we set isFetching prop to true
  const canRender = useDelayedRender();

  useEffect(() => {
    if (sidebarDisplayed) {
      requestSnapshotsAction();

      if (!sidebarDisplayedRef.current) {
        // dispatch this action to display the loading indicator, and delay fetch by 300ms
        // so it runs after the sidebar open animation
        setTimeout(() => fetchSnapshotsAction(), 300);
      } else {
        fetchSnapshotsAction();
      }
    }

    sidebarDisplayedRef.current = sidebarDisplayed;
    // we need currentEvent here even though we are not using it.
    // we need the to run this hook if current event is changed
    // this is often happening on page load
  }, [sidebarDisplayed, displayingShared, slugDate]);

  useEffect(() => {
    // setting the initial sidebar width
    ref.current.parentNode.style.width = '240px';
  }, []);

  const mouseEnterHandler = () => {
    setUpdatesDisabledAction(true);
  };

  const mouseLeaveHandler = () => {
    setUpdatesDisabledAction(false);

    const { recent, shared } = shouldRefresh;
    if ((recent && !displayingShared) || (shared && displayingShared)) {
      fetchSnapshotsAction();
    }
  };

  const tabOnSelectHandler = (eventKey) => {
    setSharedAction(eventKey === 'shared');
  };

  return (
    <div className={style.sidebarContainer} ref={ref}>
      <div className={classnames('layout-navbar', style.navContainer)}>
        <button type="button" className="barCollapse" onClick={toggleSidebarAction}>
          <FontAwesomeIcon icon={faArrowRight} size="lg" />
        </button>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="switcher labelFirst">
          <span className="switcher-label">
            <OverlayTrigger
              placement="top"
              overlay={(
                <Popover id="Calendar">
                  <Popover.Content>
                    Toggle to show overlays on the photos
                  </Popover.Content>
                </Popover>
              )}
            >
              <span>Show Overlays</span>
            </OverlayTrigger>
          </span>
          <input
            type="checkbox"
            checked={overlaysDisplayed}
            className="switcher-input"
            onChange={toggleOverlaysAction}
          />
          <span className="switcher-indicator">
            <span className="switcher-yes" />
            <span className="switcher-no" />
          </span>
        </label>
      </div>

      <Tabs
        defaultActiveKey="live"
        id="tabs"
        onSelect={tabOnSelectHandler}
      >
        <Tab
          eventKey="live"
          title={(
            <OverlayTrigger
              placement="top"
              overlay={(
                <Popover id="Calendar">
                  <Popover.Content>
                    Photos are sorted by most recently taken
                  </Popover.Content>
                </Popover>
              )}
            >
              <span>live</span>
            </OverlayTrigger>
          )}
        />
        <Tab
          eventKey="shared"
          title={(
            <OverlayTrigger
              placement="top"
              overlay={(
                <Popover id="Calendar">
                  <Popover.Content>
                    Photos are displayed by most recently shared
                  </Popover.Content>
                </Popover>
              )}
            >
              <span>Shared</span>
            </OverlayTrigger>
          )}
        />
      </Tabs>
      {sidebarDisplayed && (
        <div
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
          className={style.snapshotsContainer}
        >
          <Event event={MONITOR_NEW_SNAPSHOT} handler={addRecentSnapshotAction} />
          <Event event={MONITOR_SNAPSHOT_SHARED} handler={addSharedSnapshotAction} />

          {(isFetching || !canRender) && (
            <div className={style.loaderWrapper}>
              <Spinner animation="border" variant="primary" />
            </div>
          )}

          {!isFetching && canRender && snapshots.map((snapshot) => (
            <OverlaidSnapshot
              key={snapshot._id}
              snapshot={snapshot}
              onClick={setLightBoxSnapshotAction}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default connect(
  ({
    snapshots: {
      isFetching,
      items: snapshots,
    },
    monitor: {
      options: {
        sidebarDisplayed,
        overlaysDisplayed,
      },
      sidebar: {
        displayingShared,
        shouldRefresh,
      },
    },
    events: {
      currentEvent: {
        slugDate,
      } = {},
    },
  }) => ({
    isFetching,
    snapshots,
    sidebarDisplayed,
    displayingShared,
    slugDate,
    shouldRefresh,
    overlaysDisplayed,
  }),
  {
    setSharedAction: setShared,
    setLightBoxSnapshotAction: setLightBoxSnapshot,
    fetchSnapshotsAction: fetchSnapshots,
    requestSnapshotsAction: requestSnapshots,
    setUpdatesDisabledAction: setUpdatesDisabled,
    addRecentSnapshotAction: addRecentSnapshot,
    addSharedSnapshotAction: addSharedSnapshot,
    toggleOverlaysAction: toggleOverlays,
    toggleSidebarAction: toggleSidebar,
  },
)(Sidebar);
