import { FETCH_LIVE_METRICS, OPEN_LIVE_METRICS, CLOSE_LIVE_METRICS } from './types';
import { getMetrics } from './api';

const closeMetrics = () => ({ type: CLOSE_LIVE_METRICS });

const requestMetrics = () => ({ type: FETCH_LIVE_METRICS });

export const setMetrics = (data) => ({
  type: FETCH_LIVE_METRICS,
  payload: { data },
});

const setMetricsError = (error) => ({
  type: FETCH_LIVE_METRICS,
  payload: { error },
});

const openMetrics = () => async (dispatch) => {
  dispatch(requestMetrics());
  dispatch({ type: OPEN_LIVE_METRICS });

  try {
    const defaultVals = {
      peak: 0,
      current: 0,
      snapshots: 0,
      shares: 0,
    };

    // use 0 for everything if we get 204 - this can happen if the event is not live
    // this is defensive case and it should ne be encountered in normal usage
    const metrics = await getMetrics() || defaultVals;

    dispatch(setMetrics(metrics));
  } catch (err) {
    console.error(err);
    dispatch(setMetricsError(err));
  }
};

export const toggleMetrics = () => (dispatch, getState) => {
  const { eventMetrics: { isCollapsed } } = getState();

  if (isCollapsed) {
    dispatch(openMetrics());
  } else {
    dispatch(closeMetrics());
  }
};
