import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';

import { toggleField, toggleEventField } from '../actions';

import style from '../style.module.scss';

const ExportFields = ({
  triviaEnabled,
  geofence,
  overlays,
  toggleFieldAction,
  toggleEventFieldAction,
}) => (
  <div className={style.exportContainer}>
    <Card>
      <Card.Header className="py-3">
        <h4 className="mb-0">Export Settings</h4>
      </Card.Header>
      <Card.Body className="p-0">
        <div className={style.section}>
          <div className={style.exportItem}>
            <h5 className="mb-0">Trivia Questions</h5>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="switcher m-1">
              <input
                type="checkbox"
                checked={triviaEnabled}
                className="switcher-input"
                onChange={() => toggleFieldAction('triviaEnabled')}
              />

              <span className="switcher-indicator">
                <span className="switcher-yes" />
                <span className="switcher-no" />
              </span>
            </label>
          </div>
        </div>
        <div className={style.section}>
          <h5 className="mb-4">Event Properties</h5>
          <div className={classnames(style.exportItem, 'mb-2')}>
            <h6 className="mb-2">Geofence</h6>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="switcher m-1 labelFirst">
              <input
                type="checkbox"
                checked={geofence}
                className="switcher-input"
                onChange={() => toggleEventFieldAction('geofence')}
              />

              <span className="switcher-indicator">
                <span className="switcher-yes" />
                <span className="switcher-no" />
              </span>

              <span className="switcher-label">{geofence ? 'Enabled' : 'Disabled'}</span>
            </label>
          </div>
          <div className={style.exportItem}>
            <h6>Overlays</h6>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="switcher m-1 labelFirst">
              <input
                type="checkbox"
                checked={overlays}
                className="switcher-input"
                onChange={() => toggleEventFieldAction('overlays')}
              />

              <span className="switcher-indicator">
                <span className="switcher-yes" />
                <span className="switcher-no" />
              </span>

              <span className="switcher-label">{overlays ? 'Enabled' : 'Disabled'}</span>
            </label>
          </div>
        </div>
      </Card.Body>
    </Card>
  </div>
);

export default connect(
  ({
    templateExport: {
      triviaEnabled,
      eventFields: {
        geofence,
        overlays,
      },
    },
  }) => ({
    triviaEnabled,
    geofence,
    overlays,
  }),
  {
    toggleFieldAction: toggleField,
    toggleEventFieldAction: toggleEventField,
  },
)(ExportFields);
