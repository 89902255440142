import React from 'react';
import classnames from 'classnames';
import { OverlayTrigger, Tooltip as BootstrapToolTip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

import style from './style.module.scss';

const Tooltip = ({
  content,
  className,
  position = 'top',
  label,
}) => (
  <>
    {label}
    <OverlayTrigger
      placement={position}
      overlay={(
        <BootstrapToolTip className={style.tooltip} id="">{content}</BootstrapToolTip>
      )}
    >
      <FontAwesomeIcon icon={faInfoCircle} className={classnames(style.infoIcon, className, 'hide-on-print')} />
    </OverlayTrigger>
  </>
);

export default Tooltip;
