import React, {
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { connect, batch } from 'react-redux';
import { Spinner, Card } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { isEmpty } from 'lodash';
import LaddaButton, { CONTRACT } from 'react-ladda';
import classnames from 'classnames';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';

import {
  fetchUserList,
  setPageAndSize,
  toggleSelection,
  toggleSelectionAll,
  deleteItems,
  banItems,
  setFilters,
  clearFilters,
  setSort,
  fetchDistinctSharePlatforms,
} from '../actions';
import { useIsLoaded, usePage } from '../../../helpers/hooks';
import { formatDateToTimezone } from '../../../helpers/utils';
import { fetchFilterOptions } from '../../../store/actions/snapshots';
import { EVENT_USERS_WRITE } from '../../../components/Authorize/permissions/resources';

import Loader from '../../../shared/Loader';
import ExportButton from '../ExportButton';
import Authorize from '../../../components/Authorize';
import {
  headerFormatter,
  SelectFilter,
  TextFilter,
  RadioFilter,
  SortOnly,
} from '../../../components/ReactBootstrapTable2/Filter';
import { headerSelection, rowSelection } from '../../../components/ReactBootstrapTable2/selection';

import style from './style.module.scss';

const EventUsersTable = ({
  isFetching,
  isUpdating,
  isDeleting,
  isBanning,
  error,
  userList,
  selectedItems,
  timezone,
  page,
  pageSize,
  totalRows,
  selectedEvents,
  selectedEventsPage,
  filterOptions,
  filters,
  sort,
  sharePlatforms,
  fetchUserListAction,
  setPageAndSizeAction,
  fetchFilterOptionsAction,
  toggleSelectionAction,
  toggleSelectionAllAction,
  deleteItemsAction,
  banItemsAction,
  setFiltersAction,
  clearFiltersAction,
  setSortAction,
  fetchSharePlatformsAction,
}) => {
  const isLoaded = useIsLoaded(isFetching || !timezone);
  const mainPageKey = usePage();
  const headerCheckboxRef = useRef();

  const textFilterRenderer = useCallback((onFilter, { dataField }) => (
    <TextFilter
      onFilter={onFilter}
      onSort={(sortOrder) => {
        setSortAction({ sortField: dataField, sortOrder });
        setPageAndSizeAction(0, pageSize);
      }}
    />
  ), [pageSize]);

  const columns = useMemo(() => {
    const {
      sections: availableSections = [],
      rows: availableRows = [],
      seats: availableSeats = [],
    } = filterOptions;

    return [{
      text: 'User ID',
      dataField: 'userId',
      classes: 'align-middle',
      editable: false,
      filter: customFilter(),
      headerFormatter,
      filterRenderer: textFilterRenderer,
    }, {
      text: 'Section',
      dataField: 'section',
      classes: 'align-middle',
      filter: customFilter(),
      headerFormatter,
      filterRenderer: (onFilter, { dataField }) => (
        <SelectFilter
          options={availableSections}
          onFilter={onFilter}
          onSort={(sortOrder) => {
            setSortAction({ sortField: dataField, sortOrder });
            setPageAndSizeAction(0, pageSize);
          }}
        />
      ),
      editable: false,
    }, {
      text: 'Row',
      dataField: 'row',
      classes: 'align-middle',
      filter: customFilter(),
      headerFormatter,
      filterRenderer: (onFilter, { dataField }) => (
        <SelectFilter
          options={availableRows}
          onFilter={onFilter}
          onSort={(sortOrder) => {
            setSortAction({ sortField: dataField, sortOrder });
            setPageAndSizeAction(0, pageSize);
          }}
        />
      ),
      editable: false,
    }, {
      text: 'Seat',
      dataField: 'seat',
      classes: 'align-middle',
      filter: customFilter(),
      headerFormatter,
      filterRenderer: (onFilter, { dataField }) => (
        <SelectFilter
          options={availableSeats.map((seat) => seat.toString())}
          onFilter={onFilter}
          onSort={(sortOrder) => {
            setSortAction({ sortField: dataField, sortOrder });
            setPageAndSizeAction(0, pageSize);
          }}
        />
      ),
      editable: false,
    }, {
      text: 'Date/Time',
      dataField: 'date',
      classes: 'align-middle',
      editable: false,
      filter: customFilter(),
      headerFormatter,
      filterRenderer: (onFilter, { dataField }) => (
        <SortOnly
          onSort={(sortOrder) => {
            setSortAction({ sortField: dataField, sortOrder });
            setPageAndSizeAction(0, pageSize);
          }}
        />
      ),
      formatter: (_, { date }) => formatDateToTimezone(date, timezone, 'DD/MM/YYYY hh:mm:ss'),
    }, {
      text: 'Shared platforms',
      dataField: 'sharedPlatforms',
      classes: 'align-middle',
      editable: false,
      filter: customFilter(),
      headerFormatter,
      filterRenderer: (onFilter) => (
        <SelectFilter
          canClearAll
          options={sharePlatforms}
          onFilter={onFilter}
          onSort={(sortOrder) => {
            setSortAction({ sortField: 'postObj.0.socialSource', sortOrder });
            setPageAndSizeAction(0, pageSize);
          }}
        />
      ),
      formatter: (_, { sharedPlatforms }) => sharedPlatforms.map((item) => (
        <div key={item}>{item}</div>
      )),
    }, {
      text: 'Contact Info',
      dataField: 'contactInfo',
      classes: 'align-middle',
      editable: false,
      filter: customFilter(),
      headerFormatter,
      filterRenderer: (onFilter, { dataField }) => (
        <TextFilter
          onFilter={onFilter}
          onSort={(sortOrder) => {
            setSortAction({ sortField: dataField, sortOrder });
            setPageAndSizeAction(page, pageSize);
          }}
        />
      ),
      formatter: (_, { contactInfo }) => contactInfo.map((item) => (
        <div key={item}>{item}</div>
      )),
    }, {
      text: 'Link',
      dataField: 'sharedURI',
      sort: false,
      classes: 'align-middle',
      editable: false,
      headerFormatter,
      formatter: (_, { sharedURI }) => (
        <div className="text-center">
          <a href={sharedURI} target="_blank" rel="noopener noreferrer">
            <i className="fas fa-link" />
          </a>
        </div>
      ),
    }, {
      text: 'Banned',
      dataField: 'hidden',
      classes: 'align-middle',
      editable: false,
      filter: customFilter(),
      headerFormatter,
      filterRenderer: (onFilter, { dataField }) => (
        <RadioFilter
          options={['Yes', 'No']}
          onFilter={onFilter}
          onSort={(sortOrder) => {
            setSortAction({ sortField: dataField, sortOrder });
            setPageAndSizeAction(0, pageSize);
          }}
        />
      ),
      formatter: (_, { hidden }) => (hidden ? 'Yes' : 'No'),
    }];
  }, [timezone, filterOptions, textFilterRenderer, sharePlatforms]);

  const banStatus = useMemo(() => {
    const statuses = selectedItems.map((id) => userList.find(({ _id }) => _id === id).hidden);
    if (isEmpty(statuses)) {
      return undefined;
    }
    if (statuses.every((s) => s)) {
      return 'Unban';
    }
    if (statuses.every((s) => !s)) {
      return 'Ban';
    }
    return undefined;
  }, [selectedItems, userList]);

  const selectRow = {
    selected: selectedItems,
    mode: 'checkbox',
    classes: 'table-success',
    onSelect: toggleSelectionAction,
    onSelectAll: toggleSelectionAllAction,
    headerColumnStyle: { verticalAlign: 'middle', textAlign: 'center', padding: '20px' },
    selectColumnStyle: { verticalAlign: 'middle', textAlign: 'center' },
    selectionRenderer: rowSelection,
    selectionHeaderRenderer: headerSelection(headerCheckboxRef),
  };

  const handleTableChange = (type, {
    page: newPage,
    sizePerPage,
    filters: newFilters,
    sortField,
    sortOrder,
  }) => {
    if (type === 'filter') {
      batch(() => {
        setFiltersAction(newFilters);
        setPageAndSizeAction(newPage - 1, sizePerPage);
      });
    } else if (type === 'pagination') {
      setPageAndSizeAction(newPage - 1, sizePerPage);
    } else if (type === 'sort') {
      batch(() => {
        setSortAction({ sortField, sortOrder });
        setPageAndSizeAction(newPage - 1, sizePerPage);
      });
    }
  };

  useEffect(() => {
    if (selectedItems.length !== 0 && selectedItems.length !== userList.length) {
      headerCheckboxRef.current.indeterminate = true;
    }
  }, [selectedItems]);

  useEffect(() => {
    if (selectedEventsPage === mainPageKey && !isEmpty(selectedEvents)) {
      fetchFilterOptionsAction();
      setPageAndSizeAction(0, pageSize);
      fetchSharePlatformsAction();
    }
  }, [selectedEvents, selectedEventsPage]);

  useEffect(() => {
    if (selectedEventsPage === mainPageKey && !isEmpty(selectedEvents)) {
      fetchUserListAction(isLoaded);
    }
  }, [page, pageSize, selectedEvents, selectedEventsPage, filters, sort]);

  // clear filters on unmount
  useEffect(() => {
    clearFiltersAction();
  }, []);

  if (!isLoaded) {
    return (
      <div className="d-flex flex-grow-1 justify-content-center align-items-center">
        <Spinner variant="primary" animation="border" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="d-flex flex-grow-1 justify-content-center align-items-center">
        <p>Error fetching questions.</p>
      </div>
    );
  }

  return (
    <ToolkitProvider
      keyField="_id"
      data={userList}
      columns={columns}
      bootstrap4
      search
      columnToggle
    >
      {(props) => (
        <Card className="flex-grow-1 d-flex flex-column border-0">
          <Card.Header className={classnames(style.cardHeader, 'border-0')}>
            <div className="d-flex justify-content-between align-items-center flex-wrap align-items-start">
              <div className="d-flex align-items-start ml-auto">
                <Authorize permissions={EVENT_USERS_WRITE}>
                  <LaddaButton
                    disabled={isEmpty(selectedItems) || !banStatus}
                    loading={isBanning}
                    data-style={CONTRACT}
                    data-spinner-color="#333"
                    className="btn btn-outline-primary mr-2 small"
                    onClick={() => banItemsAction(banStatus)}
                  >
                    {banStatus || 'Ban'}
                  </LaddaButton>

                  <LaddaButton
                    disabled={isEmpty(selectedItems)}
                    loading={isDeleting}
                    data-style={CONTRACT}
                    className="btn btn-outline-danger mr-2 w-auto small"
                    onClick={deleteItemsAction}
                  >
                    Delete
                  </LaddaButton>
                </Authorize>

                <ExportButton />
              </div>
            </div>
          </Card.Header>

          <Card.Body className={style.cardBody}>
            <BootstrapTable
              {...props.baseProps}
              remote
              wrapperClasses="table-responsive no-round-bottom"
              pagination={paginationFactory({
                page: page + 1,
                sizePerPage: pageSize,
                totalSize: totalRows,
                showTotal: true,
              })}
              filter={filterFactory()}
              selectRow={selectRow}
              cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })}
              noDataIndication={() => isLoaded && <p className="my-3">There are no users.</p>}
              onTableChange={handleTableChange}
            />

            {isUpdating && <Loader />}
          </Card.Body>
        </Card>
      )}
    </ToolkitProvider>
  );
};

export default connect(
  ({
    eventUsers: {
      items: userList,
      selected: selectedItems,
      isFetching,
      isUpdating,
      isDeleting,
      isBanning,
      error,
      page,
      pageSize,
      totalRows,
      filterOptions,
      filters,
      sort,
      sharePlatforms,
    },
    events: {
      timezone,
      selectedEvents,
      selectedEventsPage,
    },
  }) => ({
    userList,
    selectedItems,
    isFetching,
    isUpdating,
    isDeleting,
    isBanning,
    error,
    timezone,
    page,
    pageSize,
    totalRows,
    selectedEvents,
    selectedEventsPage,
    filterOptions,
    filters,
    sort,
    sharePlatforms,
  }),
  {
    fetchUserListAction: fetchUserList,
    setPageAndSizeAction: setPageAndSize,
    fetchFilterOptionsAction: fetchFilterOptions,
    toggleSelectionAction: toggleSelection,
    toggleSelectionAllAction: toggleSelectionAll,
    deleteItemsAction: deleteItems,
    banItemsAction: banItems,
    setFiltersAction: setFilters,
    clearFiltersAction: clearFilters,
    setSortAction: setSort,
    fetchSharePlatformsAction: fetchDistinctSharePlatforms,
  },
)(EventUsersTable);
