import {
  ADD_LANGUAGE,
  DELETE_LANGUAGE,
  SET_LANGUAGES,
  SET_DEFAULT_LANGUAGE,
  SET_AVAILABLE_LANGUAGES,
  OPEN_LANGUAGE_MODAL,
  CLOSE_LANGUAGE_MODAL,
} from './types';

const initialState = {
  languages: [],
  availableLanguages: [],
  isLanguageModalOpen: false,
  defaultLanguage: 'english',
};

export default (state = initialState, { payload, type } = {}) => {
  switch (type) {
  case OPEN_LANGUAGE_MODAL: {
    return {
      ...state,
      isLanguageModalOpen: true,
    };
  }
  case CLOSE_LANGUAGE_MODAL: {
    return {
      ...state,
      isLanguageModalOpen: false,
    };
  }
  case SET_LANGUAGES: {
    return {
      ...state,
      languages: payload,
    };
  }
  case SET_DEFAULT_LANGUAGE: {
    return {
      ...state,
      defaultLanguage: payload,
    };
  }
  case SET_AVAILABLE_LANGUAGES: {
    return {
      ...state,
      availableLanguages: payload,
    };
  }
  case ADD_LANGUAGE: {
    return {
      ...state,
      languages: state.languages.map((item) => ({ ...item, [payload]: '' })),
      availableLanguages: [...state.availableLanguages, payload],
    };
  }
  case DELETE_LANGUAGE: {
    return {
      ...state,
      languages: state.languages.map((item) => {
        const { [payload]: _, ...language } = item;
        return language;
      }),
      availableLanguages: state.availableLanguages.filter((language) => language !== payload),
    };
  }
  default: {
    return state;
  }
  }
};
