import { combineReducers } from 'redux';

import filterForm from '../FilterForm/reducer';
import options from './options';
import stats from './stats';

import email from '../MultiSnapshotControls/EmailModal/reducer';

export default combineReducers({
  options,
  filterForm,
  stats,
  modals: combineReducers({ email }),
});
