import React from 'react';
import { connect } from 'react-redux';

const Overlay = ({ overlay, overlaysDisplayed }) => overlaysDisplayed
  && overlay
  && <img className="overlay" src={overlay} alt="" />;

export default connect(
  (
    { monitor: { sidebar: { overlaysDisplayed } }, overlays },
    { overlayKey },
  ) => ({
    overlaysDisplayed,
    overlay: (overlays[overlayKey] && overlays[overlayKey].urlSmallSize) || null,
  }),
)(Overlay);
