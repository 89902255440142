import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { Event } from 'react-socket-io';
import PromotionButton from '../../../../components/Promotion/Button';

import { setMetrics } from './actions';

import style from './style.module.scss';

const EventMetricsData = (props) => {
  const {
    isFetching,
    error,
    data,
    setMetricsAction,
  } = props;

  if (isFetching) {
    return (
      <div className={style.centered}>
        <Spinner as="span" animation="border" role="status" aria-hidden="true" />
      </div>
    );
  }

  if (error) {
    return (
      <div className={style.centered}>
        <span>Error loading metrics.</span>
      </div>
    );
  }

  const {
    current,
    peak,
    snapshots,
    shares,
  } = data;

  return (
    <>
      <div className={style.row}>
        <h5>{peak}</h5>
        <span>Peak Users</span>
      </div>
      <div className={style.row}>
        <h5>{current}</h5>
        <span>Live Users</span>
      </div>
      <div className={style.row}>
        <h5>{snapshots}</h5>
        <span>Snapshots</span>
      </div>
      <div className={style.row}>
        <h5>{shares}</h5>
        <span>Shares</span>
      </div>
      <PromotionButton />
      <Event event="monitor liveMetricsUsers" handler={setMetricsAction} />
      <Event event="monitor liveMetricsSnapshots" handler={setMetricsAction} />
      <Event event="monitor liveMetricsShares" handler={setMetricsAction} />
    </>
  );
};

export default connect(
  ({ eventMetrics: { isFetching, error, data } }) => ({ isFetching, error, data }),
  { setMetricsAction: setMetrics },
)(EventMetricsData);
