import {
  OPEN_LOCATION_MODAL,
  CLOSE_LOCATION_MODAL,
  TOGGLE_GEOFENCE_SETTINGS,
  FETCH_GEOLOCATION,
  ADD_LOCATION_OPTION,
  REMOVE_LOCATION_OPTION,
  LOAD_GEOFENCE_SETTINGS,
  SET_INPUT_VALUE,
} from './types';

import { fromAddress } from '../../../helpers/gmaps';

export const openLocationModal = () => ({ type: OPEN_LOCATION_MODAL });
export const closeLocationModal = () => ({ type: CLOSE_LOCATION_MODAL });
export const toggleGeofenceSettings = () => ({ type: TOGGLE_GEOFENCE_SETTINGS });

const requestGeolocation = () => ({ type: FETCH_GEOLOCATION });

export const loadGeofenceSettings = (payload) => ({
  type: LOAD_GEOFENCE_SETTINGS,
  payload,
});

const setGeolocationError = (error) => ({
  type: FETCH_GEOLOCATION,
  payload: { error },
});

const setGeolocation = (data) => ({
  type: FETCH_GEOLOCATION,
  payload: { data },
});

export const setGeolocationInput = (input) => ({
  type: SET_INPUT_VALUE,
  payload: { input },
});

export const addLocationOption = (payload) => ({
  type: ADD_LOCATION_OPTION,
  payload,
});

export const deleteLocationOption = (payload) => ({
  type: REMOVE_LOCATION_OPTION,
  payload,
});

export const fetchGeolocation = (payload) => async (dispatch) => {
  dispatch(requestGeolocation());

  try {
    const { results: [result] } = await fromAddress(payload.address);
    const { lat: latitude, lng: longitude } = result.geometry.location;
    const geolocationSettings = { ...payload, latitude, longitude };
    dispatch(setGeolocation(geolocationSettings));
  } catch (err) {
    dispatch(setGeolocationError(err));
  }
};
