import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { deleteHandler } from '../actionHelper';

const Delete = ({ snapshotId, image, show }) => {
  if (!show) {
    return null;
  }

  const deleteBtnHandler = (evt) => {
    evt.stopPropagation();
    deleteHandler(snapshotId, image);
  };

  return (
    <div className="actionButtonContainer">
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>Delete</Tooltip>}
      >
        <Button variant="default" onClick={deleteBtnHandler}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </OverlayTrigger>
    </div>
  );
};
export default Delete;
