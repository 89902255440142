import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as AppRouter,
  Route,
  Switch,
} from 'react-router-dom';

import { removeSplashscreen, showSplashscreen } from '../helpers/browser';

import Login from '../pages/Login';
import PasswordReset from '../pages/PasswordReset';
import DefaultLayout from './layouts/Layout';
import Slideshow from '../pages/Slideshow';

import { checkSession } from '../helpers/api';
import { setUserData } from '../store/actions/authentication';

// ---
// Main route component
const Router = ({ setUserDataAction }) => {
  useEffect(() => {
    // Set app loading class
    showSplashscreen();
    const checkUserAuthentication = async () => {
      try {
        const result = await checkSession();
        setUserDataAction(result);
        removeSplashscreen();
      } catch (err) {
        console.error('Error trying to check session', err);
        setUserDataAction(undefined);
        removeSplashscreen();
      }
    };

    checkUserAuthentication();
  }, [setUserDataAction]);

  return (
    <AppRouter>
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/reset_password/:token?" component={PasswordReset} />
        <Route path="/slideshow" component={Slideshow} />
        <Route path="/" component={DefaultLayout} />
      </Switch>
    </AppRouter>
  );
};

export default connect(
  ({ authentication }) => ({ isUserLogged: authentication.logged }),
  { setUserDataAction: setUserData },
)(Router);
