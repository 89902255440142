import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { setMultiselectValue, deleteImage, setError } from '../../actions';

import style from './style.module.scss';

const ImageSelector = ({
  section: { options, value },
  setMultiselectValueAction,
}) => Object.entries(options).map(([itemVal, { label: itemLabel, imageSrc }]) => (
  // eslint-disable-next-line
  <div className={style.selectItem} onClick={() => setMultiselectValueAction(itemVal)}>
    <span
      className={classnames(
        'selectCircle',
        { active: itemVal === value },
      )}
    />
    <img src={imageSrc} alt="" />
    <strong>{itemLabel}</strong>
  </div>
));

export default connect(
  ({ graphics: { selectedInd, sections } }) => ({ section: sections[selectedInd] }),
  {
    setMultiselectValueAction: setMultiselectValue,
    deleteImageAction: deleteImage,
    setErrorAction: setError,
  },
)(ImageSelector);
