import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { SocketContext } from 'react-socket-io';
import Tabs from './Tabs';
import Slider from '../Slider';
import store from '../../../store';
import { inRange, socketEventNames } from '../helper';

import style from './style.module.scss';


const { ROVER_MOVE } = socketEventNames;

const PositioningForm = () => {
  const socket = useContext(SocketContext);
  const connectedCamera = useSelector(({ mapping: { connectedCamera: cam } }) => cam);
  const minPan = useSelector(({ mapping: { preview: { pan: { min } } } }) => min);
  const maxPan = useSelector(({ mapping: { preview: { pan: { max } } } }) => max);
  const pan = useSelector(({ mapping: { preview: { pan: { value } } } }) => value);

  const minTilt = useSelector(({ mapping: { preview: { tilt: { min } } } }) => min);
  const maxTilt = useSelector(({ mapping: { preview: { tilt: { max } } } }) => max);
  const tilt = useSelector(({ mapping: { preview: { tilt: { value } } } }) => value);

  const minZoom = useSelector(({ mapping: { preview: { zoom: { min } } } }) => min);
  const maxZoom = useSelector(({ mapping: { preview: { zoom: { max } } } }) => max);
  const zoom = useSelector(({ mapping: { preview: { zoom: { value } } } }) => value);

  const minAngle = useSelector(({ mapping: { preview: { angle: { min } } } }) => min);
  const maxAngle = useSelector(({ mapping: { preview: { angle: { max } } } }) => max);
  const angle = useSelector(({ mapping: { preview: { angle: { value } } } }) => value);

  const onChange = () => {
    const { mapping: { preview } } = store.getState();
    const safePan = Number(preview.pan.value);
    const safeTilt = Number(preview.tilt.value);
    const safeZoom = Number(preview.zoom.value / 1000);

    if (Number.isInteger(safePan) && Number.isInteger(safeTilt) && inRange(safeZoom, 0, 1)) {
      console.log('PreviewSlider: sending move command to camera', safePan, safeTilt, safeZoom);
      socket.emit(ROVER_MOVE, 'host', connectedCamera, `!${safePan},${safeTilt},${safeZoom}`);
    } else {
      console.error('Error sending move command to camera from PreviewSlider', safePan, safeTilt, safeZoom);
    }
  };

  return (
    <div className={style.mappingForm}>
      <div className={style.positioning}>
        <div className={style.rangeContainer}>
          <span>Pan</span>
          <Slider
            path="preview.pan.value"
            min={minPan}
            max={maxPan}
            val={pan}
            onReleaseCB={onChange}
            step={(maxPan) / 500}
          />
        </div>
        <div className={style.rangeContainer}>
          <span>Tilt</span>
          <Slider
            path="preview.tilt.value"
            min={minTilt}
            max={maxTilt}
            val={tilt}
            onReleaseCB={onChange}
            step={(maxTilt) / 500}
          />
        </div>
        <div className={style.rangeContainer}>
          <span>Zoom</span>
          <Slider
            path="preview.zoom.value"
            min={minZoom}
            max={maxZoom}
            val={zoom}
            onReleaseCB={onChange}
            step={(maxZoom) / 200}
          />
        </div>
        <div className={style.rangeContainer}>
          <span>Angle</span>
          <Slider
            path="preview.angle.value"
            min={minAngle}
            max={maxAngle}
            val={angle}
            step={0.5}
            basic
          />
        </div>
      </div>
      <Tabs />
    </div>
  );
};


export default PositioningForm;
