import { Permission } from '.';

export const EVENT_WRITE = new Permission('Event:write', 'Allow to edit events');
export const ANALYTICS_READ = new Permission('Analytics:read', 'Allow to view analytics page');
export const MONITOR_READ = new Permission('Monitor:read', 'Allow to view monitor page');
export const MAGICSHOTS_READ = new Permission('Magicshots:read', 'Allow to view magic shots tab in album page');
export const MAGICSHOTS_WRITE = new Permission('Magicshots:write', 'Allow to take magic shots', [MONITOR_READ]);
// TODO PHOTOS_WRITE is always used with DELETE_PHOTO so they are the same and can be unified
export const PHOTOS_WRITE = new Permission('Photos:write', 'Allow to delete photos if checked with album_snapshot_deleteBtn');
export const ROLES_READ = new Permission('Roles:read', 'Allow to view Roles page only, can\'t edit or change roles');
export const ROLES_WRITE = new Permission('Roles:write', 'Allow to create, edit and delete roles', [ROLES_READ]);
export const EVENT_USERS_READ = new Permission('EventUsers:read', 'Allow to view events users page and export data');
export const EVENT_USERS_WRITE = new Permission('EventUsers:write', 'Allow to delete and ban users from event users page', [EVENT_USERS_READ]);

// Pages
export const USERS = new Permission('Users', 'Allow to navigate to admin users page');
export const CONFIG = new Permission('Config', 'Allow to navigate to config page');
export const TEMPLATE = new Permission('Template', 'Allow to navigate to all template pages');
export const TRIVIA = new Permission('Trivia', 'Allow to navigate to trivia page');
export const GRAPHICS = new Permission('Graphics', 'Allow to navigate to graphics page');
export const OVERLAYS = new Permission('Overlays', 'Allow to navigate to overlays page');
export const SLIDESHOW = new Permission('Slideshow', 'Allow to navigate to slide show page');
