
export const scrollTop = (
  to,
  duration,
  element = document.scrollingElement || document.documentElement,
) => {
  const elementToNavigate = element;
  if (element.scrollTop === to) return;
  const start = element.scrollTop;
  const change = to - start;
  const startDate = +new Date();

  if (!duration) {
    elementToNavigate.scrollTop = to;
    return;
  }

  // t = current time; b = start value; c = change in value; d = duration
  const easeInOutQuad = (t, b, c, d) => {
    let currentTime = t;
    currentTime /= d / 2;

    if (t < 1) {
      return (((c / 2) * currentTime) * currentTime) + b;
    }

    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const animateScroll = () => {
    const currentDate = +new Date();
    const currentTime = currentDate - startDate;
    elementToNavigate.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration), 2);

    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      elementToNavigate.scrollTop = to;
    }
  };

  animateScroll();
};


export const removeSplashscreen = () => {
  // Remove splash screen
  const removeLoadingClass = () => {
    document.documentElement.classList.remove('app-loading');
  };

  const splashScreen = document.querySelector('.app-splash-screen');

  if (splashScreen) {
    splashScreen.style.opacity = 0;

    setTimeout(() => {
      if (splashScreen && splashScreen.parentNode.removeChild(splashScreen)) {
        removeLoadingClass();
      }
    }, 300);
  } else {
    removeLoadingClass();
  }
};

export const showSplashscreen = () => {
  document.documentElement.classList.add('app-loading');
};

export const setTitle = (title) => {
  document.title = title;
};

export const resizeObserver = (domEl, callback) => {
  if (!domEl || typeof ResizeObserver === 'undefined') {
    return;
  }

  const observer = new ResizeObserver(() => callback(domEl));
  observer.observe(domEl);

  // eslint-disable-next-line consistent-return
  return () => {
    observer.disconnect();
  };
};
