import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePage, useIsLoaded } from '../../helpers/hooks';
import { fetchEvent } from './api';
import { updateEvent } from '../../store/actions/events';
import { displayErrorModal } from '../../helpers/swal';


// eslint-disable-next-line import/prefer-default-export
export const useUpdateEvent = () => {
  const dispatch = useDispatch();
  const {
    isFetching,
    selectedEventsPage,
    selectedEvents: [event],
  } = useSelector(({ events }) => events);

  const page = usePage();
  const [isEventUpdating, setIsEventUpdating] = useState(true);
  const [updatedEvent, setUpdatedEvent] = useState();
  const updatedSlugRef = useRef(null);
  const eventListLoaded = useIsLoaded(isFetching);
  const { slugDate } = event || {};

  useEffect(() => {
    if (eventListLoaded && updatedEvent) {
      dispatch(updateEvent(updatedEvent));
      setIsEventUpdating(false);
    }
  }, [eventListLoaded, updatedEvent]);

  useEffect(() => {
    if (page === selectedEventsPage && event && updatedSlugRef.current !== slugDate) {
      const update = async () => {
        updatedSlugRef.current = event.slugDate;
        setIsEventUpdating(true);

        try {
          setUpdatedEvent(await fetchEvent(event.slugDate));
        } catch (err) {
          console.log(err);
          displayErrorModal({
            text: 'Failed to update the event. Note that event data might be old. Please try to refresh it.',
          });
        } finally {
          setIsEventUpdating(false);
        }
      };

      update();
    }
  }, [page, event, selectedEventsPage]);

  return isEventUpdating;
};
