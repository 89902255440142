import axios from 'axios';
import moment from 'moment';

import store from '../../store';
import { updatePath } from './actions';
import { buildURL } from '../../helpers/urls';

const adminUser = process.env.BRIZI_ADMIN_USERNAME || 'briziadmin';
const adminPass = process.env.BRIZI_ADMIN_PASSWORD || 'brizionthree';

let camMap;
// eslint-disable-next-line no-unused-vars
let rowDict;

const getCamMapFile = async (camIdx) => {
  try {
    const mappingFileUrl = buildURL(`/config/${process.env.BRIZI_APP_SLUG}`, `${camIdx}.json`);
    const response = await axios.get(mappingFileUrl, {
      auth: {
        username: adminUser,
        password: adminPass,
      },
    });
    camMap = response.data;
  } catch (error) {
    console.error('Error while getting cam map file', error);
  }
};

const getRowDictFile = async () => {
  const rowDictFileUrl = buildURL(`/config/${process.env.BRIZI_APP_SLUG}`, 'row_dict.txt');
  try {
    const response = await axios.get(rowDictFileUrl, {
      auth: {
        username: adminUser,
        password: adminPass,
      },
    });
    rowDict = response.data;
  } catch (error) {
    console.error(`Could not get --> ${rowDictFileUrl}`);
  }
};

const loadMapConfig = async (camIdx) => {
  try {
    await getRowDictFile();
    await getCamMapFile(camIdx);
    store.dispatch(updatePath('loadingStatus', `${camIdx}FileLoaded ${new Date().toISOString()}`));
    console.log('\nSuccessfully parsed mapping files\n');
    return true;
  } catch (error) {
    console.error('Could not retrieve mapping file or row_dict');
    return false;
  }
};

// return reference point from the camera mapping json object
const getReference = () => {
  try {
    // Check if jsonObj is defined and has the "ref_point" property
    if (camMap?.ref_point
      && !Number.isNaN(camMap.ref_point.pan)
      && !Number.isNaN(camMap.ref_point.tilt)
      && !Number.isNaN(camMap.ref_point.zoom)) {
      return camMap.ref_point;
    }
    // Log a warning if ref_point is not found
    console.warn('ref_point not found in the JSON object.');
    return null;
  } catch (error) {
    console.error('Error getting reference point:', error);
    return null;
  }
};

const isValidJSONObject = (obj) => {
  try {
    // Convert the object to a JSON string
    const jsonString = JSON.stringify(obj);
    // Parse the JSON string back to an object
    JSON.parse(jsonString);
    return true; // Both operations succeeded, object is valid JSON data
  } catch (e) {
    return false; // An error occurred, object is not valid JSON data
  }
};

const uploadMappingFile = async () => {
  try {
    if (!isValidJSONObject(camMap)) {
      console.error('Invalid JSON object');
      return false;
    }
    const { mapping } = store.getState();

    const camIdx = mapping.connectedCamera;
    const appSlug = process.env.BRIZI_APP_SLUG;
    const apiUrl = process.env.BRIZI_API_AUTHORITY;
    const eventUrl = apiUrl.replace('api', appSlug);

    // update camera, event_url, section_count, operators, ptu
    camMap.camera = parseInt(camIdx.replace('cam', '').replace('.json', ''), 10);
    camMap.event_url = eventUrl;

    camMap.section_count = camMap.sections.length;

    camMap.operators = [{
      id: 'Mapping Dashboard',
      timestamp: moment().format('YYYY-MM-DD HH:mm:ss Z'),
    }];

    camMap.ptu = {
      model: 'onvif',
    };

    const configJson = JSON.stringify(camMap, null, 2);

    const formData = new FormData();
    formData.append('event_url', eventUrl);
    formData.append('camidx', camIdx);
    formData.append('file', new Blob([configJson], { type: 'application/json' }), `${camIdx}.json`);
    const base64Auth = Buffer.from(`${adminUser}:${adminPass}`).toString('base64');
    const authHeader = `Basic ${base64Auth}`;

    const response = await axios.post(buildURL(`/events/${process.env.BRIZI_APP_SLUG}/configs/JSON`), formData, {
      headers: {
        Authorization: authHeader,
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.status === 200) {
      console.log('uploadMappingFile successful, response.status:', response.status);
      return true;
    }
    console.log('uploadMappingFile failed, response.status:', response.status);
    return false; // Return false if status is not 200
  } catch (error) {
    console.error('Error uploading:', error);
    return false;
  }
};

const updateRefPoint = (pan, tilt, zoom) => {
  try {
    const numericPan = Number(pan);
    const numericTilt = Number(tilt);
    const numericZoom = Number(zoom);

    if (Number.isNaN(numericPan) || Number.isNaN(numericTilt) || Number.isNaN(numericZoom)) {
      console.error('Invalid numeric values for pan or tilt or zoom');
      return false;
    }

    if (!camMap) {
      console.warn('camMap is invalid');
      return false;
    }

    camMap.ref_point = {
      pan: numericPan,
      tilt: numericTilt,
      zoom: numericZoom,
    };

    return uploadMappingFile();
  } catch (error) {
    console.error('Error updating ref point:', error);
    return false;
  }
};

// get reference image from the server
const getRefImage = async () => {
  try {
    const { mapping } = store.getState();
    const camIdx = mapping.connectedCamera;
    console.log('camIdx', camIdx);

    const eventSlug = process.env.BRIZI_APP_SLUG;

    const username = adminUser;
    const password = adminPass;

    // The URL of the image you want to retrieve
    const refImageUrl = buildURL(`/config/${eventSlug}/shots/${camIdx}.jpg`);

    // Encode your credentials in base64 for the Authorization header
    const base64Credentials = Buffer.from(`${username}:${password}`).toString('base64');


    const response = await axios.get(refImageUrl, {
      responseType: 'arraybuffer', // Important for binary data like images
      headers: {
        Authorization: `Basic ${base64Credentials}`,
      },
    });
    const imageData = Buffer.from(response.data);

    // Print the size of the image data
    console.log('getRefImage successful:', (imageData.length / 1024).toFixed(2), 'KB');
    return imageData; // Return the image data directly
  } catch (error) {
    console.error('Error getting ref image:', error);
    return false;
  }
};

const updateRefImage = async () => {
  try {
    const { mapping } = store.getState();
    const camIdx = mapping.connectedCamera;
    console.log('camIdx', camIdx);

    const eventUrl = process.env.BRIZI_APP_SLUG;
    const base64String = document.getElementById('streamPreview')?.src.replace('data:image/jpeg;base64,', '');

    if (!base64String) {
      console.error('Could not get base64 string from image');
      return false;
    }

    // Validate base64 string if needed

    const arrayBuffer = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0)).buffer;
    const imageData = new Blob([arrayBuffer], { type: 'image/jpeg' });

    const formData = new FormData();
    formData.append('event_url', eventUrl);
    formData.append('camidx', camIdx);
    formData.append('file', imageData, `${camIdx}.jpg`);
    const base64Auth = Buffer.from(`${adminUser}:${adminPass}`).toString('base64');
    const authHeader = `Basic ${base64Auth}`;

    const response = await axios.post(buildURL(`/events/${process.env.BRIZI_APP_SLUG}/configs/JPG`), formData, {
      headers: {
        Authorization: authHeader,
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.status === 200) {
      console.log('updateRefImage successful, response.status:', response.status);
      return true;
    }
    console.log('updateRefImage failed, response.status:', response.status);
    return false; // Return false if status is not 200
  } catch (error) {
    console.error('Error uploading ref image:', error);
    return false;
  }
};

const isCornerValid = (corner) => {
  try {
    if (!corner || typeof corner !== 'object') {
      return false;
    }
    return !Number.isNaN(corner.pan) && !Number.isNaN(corner.tilt)
      && !Number.isNaN(corner.row) && !Number.isNaN(corner.seat);
  } catch (error) {
    console.error('Error checking corner validity:', error);
    return false;
  }
};

// parse a camera mapping json object and return a list of formatted
// strings for dropdown options in the check tab
const populateCheckTabDropDownList = () => {
  console.log('populateCheckTabDropDownList called');
  try {
    // Check if camMap is defined and has the "sections" property
    if (!camMap?.sections) {
      console.warn('camMap is invalid');
      return [];
    }
    const parsedList = [];
    let index = 0;
    camMap?.sections.forEach((section) => {
      const sectionName = section.name;
      const type = (section.type).toUpperCase();

      section.regions.forEach((region) => {
        // Find corners based on specific positions
        const topLeftCorner = region.find((corner) => corner.position === 'top_left');
        const bottomRightCorner = region.find((corner) => corner.position === 'bottom_right');

        if (!isCornerValid(topLeftCorner) || !isCornerValid(bottomRightCorner)) {
          console.warn(`Missing corners in section ${sectionName}`);
          return;
        }

        // Extract values from specified corners
        const maxRow = topLeftCorner.row;
        const minSeat = topLeftCorner.seat;
        const minRow = bottomRightCorner.row;
        const maxSeat = bottomRightCorner.seat;

        const formattedString = `${type} ${sectionName}, R ${minRow} to ${maxRow}, S ${minSeat} to ${maxSeat}`;

        // Push an object with label, value, and index properties
        parsedList.push({ label: formattedString, value: formattedString, index });
        index += 1;
      });
    });

    return parsedList;
  } catch (error) {
    console.error('Error populating check tab dropdown list:', error);
    return [];
  }
};

// return the region object that matches the provided criteria
// (rows and seats from top_left and bottom_right corners)
const getRegionByCorners = (
  sectionName,
  rowTopLeft,
  seatTopLeft,
  rowBottomLeft,
  seatBottomRight,
) => {
  try {
  // Check if camMap is defined and has the "sections" property
    if (camMap && camMap.sections) {
      // Convert sectionName to uppercase for comparison
      const sectionNameUpper = sectionName.toUpperCase();

      // Iterate over each section in the camMap
      // eslint-disable-next-line no-restricted-syntax
      for (const section of camMap.sections) {
        // Convert section's name to uppercase for comparison
        const sectionNameInSection = section.name.toUpperCase();

        // Check if the section's name matches the provided sectionName
        if (sectionNameInSection === sectionNameUpper) {
          // Iterate over the regions in the current section
          // eslint-disable-next-line no-restricted-syntax
          for (const region of section.regions) {
            // Find the top_left and bottom_right corners in the region
            const topLeftCorner = region.find((corner) => corner.position === 'top_left');
            const bottomRightCorner = region.find((corner) => corner.position === 'bottom_right');

            // Check if both corners are found and match the provided criteria
            /* eslint-disable max-len */
            if (isCornerValid(topLeftCorner) && isCornerValid(bottomRightCorner)
                  && topLeftCorner.row.toString().toUpperCase() === rowTopLeft.toString().toUpperCase()
                  && topLeftCorner.seat === seatTopLeft
                  && bottomRightCorner.row.toString().toUpperCase() === rowBottomLeft.toString().toUpperCase()
                  && bottomRightCorner.seat === seatBottomRight) {
              // If all conditions are met, return the region
              return region;
              /* eslint-enable max-len */
            }
          }
        }
      }
    }
    // If no matching region is found, or camMap is not in the expected format, return null
    return null;
  } catch (error) {
    console.error('Error getting region by corners:', error);
    return null;
  }
};

const getRegion = (selectedIndex, selectedName, selectedType) => {
  // Check if all arguments are defined
  if (selectedIndex === undefined || selectedName === undefined || selectedType === undefined) {
    console.error('findRegion: Invalid arguments');
    return null;
  }

  try {
    console.log(
      'findRegion called with selectedIndex, selectedName, selectedType:',
      selectedIndex,
      selectedName,
      selectedType,
    );

    let globalRegionIndex = 0;
    const upperSelectedName = selectedName.trim().toUpperCase();
    const upperSelectedType = selectedType.trim().toUpperCase();

    // Iterate over sections
    for (let sectionIndex = 0; sectionIndex < camMap.sections.length; sectionIndex++) {
      const section = camMap.sections[sectionIndex];

      // Check if the section matches the selectedName and selectedType
      if (section.name.trim().toUpperCase() === upperSelectedName
          && section.type.trim().toUpperCase() === upperSelectedType) {
        // Iterate over regions within the matching section
        for (let regionIndex = 0; regionIndex < section.regions.length; regionIndex++) {
          if (globalRegionIndex === selectedIndex) {
            // If the global index matches, return the region
            console.log(`Region found at index ${selectedIndex} in section: ${section.name}`);
            return section.regions[regionIndex];
          }
          globalRegionIndex += 1;
        }
      } else {
        // Increment globalRegionIndex by the number of regions in non-matching sections
        globalRegionIndex += section.regions.length;
      }
    }


    console.warn(`Region with index ${selectedIndex} not found in section ${selectedName}`);
    return null;
  } catch (error) {
    console.error('Error finding region:', error);
    return null;
  }
};


// this function deletes a region from the camera mapping json object
// it uploads the mapping file after the deletion to the server
// it then called loadMapConfig to reload the mapping file
// this can be made faster by not calling loadMapConfig
const deleteRegion = async (selectedIndex, selectedName, selectedType) => {
  // Check if all arguments are defined
  if (selectedIndex === undefined || selectedName === undefined || selectedType === undefined) {
    console.error('deleteRegion: Invalid arguments');
    return false;
  }

  try {
    console.warn(
      'deleteRegion called with selectedIndex, selectedName, selectedType:',
      selectedIndex,
      selectedName,
      selectedType,
    );

    let globalRegionIndex = 0;
    const upperSelectedName = selectedName.trim().toUpperCase();
    const upperSelectedType = selectedType.trim().toUpperCase();

    for (let sectionIndex = 0; sectionIndex < camMap.sections.length; sectionIndex++) {
      const section = camMap.sections[sectionIndex];
      if (section.name.trim().toUpperCase() === upperSelectedName
          && section.type.trim().toUpperCase() === upperSelectedType) {
        for (let regionIndex = 0; regionIndex < section.regions.length; regionIndex++) {
          if (globalRegionIndex === selectedIndex) {
            console.warn(`Deleting region index ${selectedIndex} from section: ${section.name}`);
            section.regions.splice(regionIndex, 1);

            // If there are no more regions in the section, delete the section as well
            if (section.regions.length === 0) {
              console.warn(`Deleting section: ${section.name}`);
              camMap.sections.splice(sectionIndex, 1);
            }

            // Upload the updated mapping file
            // eslint-disable-next-line no-await-in-loop
            await uploadMappingFile();
            const { mapping } = store.getState();
            const camIdx = mapping.connectedCamera;
            // eslint-disable-next-line no-await-in-loop
            const result = await loadMapConfig(camIdx);
            return result;
          }
          globalRegionIndex += 1;
        }
      } else {
        // Increment globalRegionIndex by the number of regions in the current section
        // since these regions are not part of the target section and should be skipped
        globalRegionIndex += section.regions.length;
      }
    }

    console.warn(`Region with index ${selectedIndex} not found in section ${selectedName}`);
    return false;
  } catch (error) {
    console.error('Error deleting region:', error);
    return false;
  }
};

const getSection = (sectionName, type) => {
  try {
    if (!sectionName || !type) {
      console.error('getSection: Invalid arguments');
      return null;
    }
    // eslint-disable-next-line max-len
    const section = camMap.sections.find((sec) => sec.name === sectionName && sec.type === type);

    return section || null;
  } catch (error) {
    console.error('Error getting section:', error);
    return null;
  }
};

// this function will return true if all corners have even seats or odd seats
// it returns false if some corners are even and others are odd
const checkCornersSeatParity = (updatedCorners) => {
  // Check if there are no corners to avoid false positives
  if (updatedCorners.length === 0) {
    return false;
  }

  // Determine the parity of the first corner's seat
  const initialParity = updatedCorners[0].seat % 2;

  // Check if all corners maintain the same parity
  return updatedCorners.every((corner) => corner.seat % 2 === initialParity);
};

// add new region to section, if section does not exist, create a new section
const addRegion = async (
  sectionName,
  sectionType,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  angle,
  zoomFactor,
) => {
  // Check that all corner arguments are defined and objects, and angle and zoom_factor are numbers
  if (!sectionName || !sectionType
    || ![topLeft, topRight, bottomLeft, bottomRight].every((corner) => isCornerValid(corner))
    || typeof angle !== 'number' || typeof zoomFactor !== 'number') {
    console.error('createRegion: Invalid arguments');
    return false;
  }

  try {
    /* note:
    Rove tab: always show raw position without considering ref point
    Browse tab: always show raw position without considering ref point
    preview pos: always show raw position without considering ref point
    Check tab ONLY: always show position with ref point calc
    position to sent to map file: mapFilePos = camPos - refPos
    */
    const refPoint = getReference();
    if (!refPoint) {
      console.error('Reference point not found');
      return false;
    }

    const region = getRegionByCorners(
      sectionName,
      topLeft.row,
      topLeft.seat,
      bottomRight.row,
      bottomRight.seat,
    );

    if (region) {
      console.error('Region already exists!');
      return false;
    }

    // Attempt to get the section; if not found, create a new one
    let section = getSection(sectionName, sectionType);
    if (!section) {
      console.log(`Section ${sectionName} of sectionType ${sectionType} not found. Creating a new section.`);
      section = {
        name: sectionName.trim(),
        type: sectionType,
        regions: [], // Initialize with an empty regions array
      };
      camMap.sections.push(section); // Add the new section to camMap
    }

    // check if even odd is enabled
    const evenOddFlag = process.env.ODD_EVEN_MODE || false;
    console.warn('evenOddFlag', evenOddFlag);
    // update corners with respect to the reference point
    const updatedCorners = [topLeft, topRight, bottomLeft, bottomRight].map((corner) => ({
      ...corner,
      pan: corner.pan - refPoint.pan,
      tilt: corner.tilt - refPoint.tilt,
      seat: (evenOddFlag === true && corner.seat % 2 === 0) ? corner.seat + 100 : corner.seat,
    }));

    if (evenOddFlag === true && !checkCornersSeatParity(updatedCorners)) {
      console.error(`Invalid corner seats, some are even and others are odd ${JSON.stringify(updatedCorners)}`);
      return false;
    }

    if (!updatedCorners.every((corner) => isCornerValid(corner))) {
      console.error('Invalid corner values after updating with respect to reference point');
      return false;
    }

    // if region does not exit, create a new region
    // Construct the new region and add it to the section's regions
    const newRegion = [updatedCorners[0], updatedCorners[1],
      updatedCorners[2], updatedCorners[3],
      { zoom_factor: zoomFactor, angle }];
    section.regions.push(newRegion);

    console.log(`New region ${JSON.stringify(newRegion)} added to section ${sectionName}.`);

    await uploadMappingFile();
    const { mapping } = store.getState();
    const camIdx = mapping.connectedCamera;
    // eslint-disable-next-line no-await-in-loop
    const result = await loadMapConfig(camIdx);
    return result;
  } catch (error) {
    console.error('Error adding region:', error);
    return false;
  }
};

/* note:
  Rove tab: always show raw position without considering ref point
  Browse tab: always show raw position without considering ref point
  preview pos: always show raw position without considering ref point
  Check tab ONLY: always show position with ref point calc
  position to sent to map file: mapFilePos = camPos - refPos

  Given that this function is only called from check tab, the values there are already
  calculated with respect to the reference point, so no need to subtract the reference point
*/
const updateRegion = async (
  selectedIndex,
  selectedName,
  selectedType,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  angle,
  zoomFactor,
) => {
  console.log(
    'updateRegion called:',
    'selectedIndex',
    selectedIndex,
    'selectedName',
    selectedName,
    'selectedType',
    selectedType,
    topLeft,
    topRight,
    bottomLeft,
    bottomRight,
    'angle',
    angle,
    'zoomFactor',
    zoomFactor,
  );

  // Check if all arguments are defined
  if (selectedIndex === undefined || selectedName === undefined || selectedType === undefined
    || ![topLeft, topRight, bottomLeft, bottomRight].every((corner) => isCornerValid(corner))
    || typeof angle !== 'number' || typeof zoomFactor !== 'number') {
    console.error('updateRegion: Invalid arguments');
    return null;
  }

  try {
    let globalRegionIndex = 0;
    const upperSelectedName = selectedName.trim().toUpperCase();
    const upperSelectedType = selectedType.trim().toUpperCase();

    // Iterate over sections
    for (let sectionIndex = 0; sectionIndex < camMap.sections.length; sectionIndex++) {
      const section = camMap.sections[sectionIndex];

      // Check if the section matches the selectedName and selectedType
      if (section.name.trim().toUpperCase() === upperSelectedName
          && section.type.trim().toUpperCase() === upperSelectedType) {
        // Iterate over regions within the matching section
        for (let regionIndex = 0; regionIndex < section.regions.length; regionIndex++) {
          if (globalRegionIndex === selectedIndex) {
            // If the global index matches, update the region
            console.log(`Region found at index ${selectedIndex} in section: ${section.name}`);

            const newRegion = [topLeft, topRight, bottomLeft, bottomRight,
              { zoom_factor: zoomFactor, angle }];

            section.regions[regionIndex] = newRegion;
            console.log(`Region \n${JSON.stringify(section.regions[regionIndex])} \n\nupdated to\n ${JSON.stringify(newRegion)}`);
            // eslint-disable-next-line no-await-in-loop
            await uploadMappingFile();
            const { mapping } = store.getState();
            const camIdx = mapping.connectedCamera;
            // eslint-disable-next-line no-await-in-loop
            const result = await loadMapConfig(camIdx);
            return result;
          }
          globalRegionIndex += 1;
        }
      } else {
        // Increment globalRegionIndex by the number of regions in non-matching sections
        globalRegionIndex += section.regions.length;
      }
    }

    console.warn(`Region with index ${selectedIndex} not found in section ${selectedName}`);
    return null;
  } catch (error) {
    console.error('Error updating region:', error);
    return null;
  }
};

module.exports = {
  loadMapConfig,
  getReference,
  updateRefPoint,
  getRefImage,
  updateRefImage,
  populateCheckTabDropDownList,
  getRegion,
  deleteRegion,
  addRegion,
  updateRegion,
};
