import { buildURL } from '../../../../helpers/urls';
import { post } from '../../../../helpers/fetch';

// eslint-disable-next-line import/prefer-default-export
export const sendEmail = (emails, subject, message, snapshotUrls) => {
  const url = buildURL('email/shareSnapshots');

  return post(url, {
    emailAddress: emails,
    emailSubject: subject,
    emailBodyText: message,
    snapshots: snapshotUrls,
  });
};
