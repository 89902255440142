import React, { useCallback } from 'react';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';

import { setCurrentPage } from '../actions';

import style from './style.module.scss';

const PaginationContainer = (props) => {
  const {
    currentPage,
    itemsPerPage,
    snapshotsLen,
    setCurrentPageAction,
  } = props;

  const onPageChangeHandler = useCallback((page) => {
    setCurrentPageAction(page.selected + 1);
  }, [setCurrentPageAction]);

  const paginationConfig = {
    pages: Math.ceil(snapshotsLen / itemsPerPage),
  };

  if (paginationConfig.pages < 2) {
    return null;
  }

  return (
    <div className={style.AlbumPaginationContainer}>
      <ReactPaginate
        previousLabel={(<i className="ion ion-ios-arrow-back" />)}
        nextLabel={(<i className="ion ion-ios-arrow-forward" />)}
        breakLabel="..."
        breakClassName="break-me"
        pageCount={paginationConfig.pages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={onPageChangeHandler}
        containerClassName="pagination"
        subContainerClassName="pages pagination"
        activeClassName="active"
        forcePage={currentPage - 1}
      />
    </div>
  );
};

export default connect(
  ({
    album: {
      options: {
        currentPage,
        itemsPerPage,
        currentTab,
      },
      stats,
    },
  }) => ({
    currentPage,
    itemsPerPage,
    snapshotsLen: stats[currentTab],
  }),
  { setCurrentPageAction: setCurrentPage },
)(PaginationContainer);
