import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter as faFilterSolid } from '@fortawesome/pro-solid-svg-icons';
import { isEmpty } from 'lodash';
import {
  Row,
  Button,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';

import EventSelectorButton from '../../../components/EventSelector/Button';
import Stats from './Stats';
import { openModal as openEventSelectorModal } from '../../../components/EventSelector/Modal/actions';
import { toggleFilterBar, toggleDisplayOverlay, toggleAutoUpdate } from '../actions';

import style from './style.module.scss';

const AlbumController = ({
  selectedEvents,
  filterBarDisplayed,
  overlaysDisplayed,
  autoUpdateEnabled,
  toggleFilterBarAction,
  toggleDisplayOverlayAction,
  toggleAutoUpdateAction,
  filterData,
}) => (
  <div className={classNames('layout-navbar', style.AlbumNavContainer)}>
    <Row className="justify-content-between">
      <div className="d-flex align-items-center">
        <h2>Album</h2>
        <EventSelectorButton />
      </div>
      <div className="d-flex">
        <Button variant="link pr-0">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="switcher mr-0 labelFirst">
            <input type="checkbox" checked={overlaysDisplayed} className="switcher-input" onChange={toggleDisplayOverlayAction} />
            <span className="switcher-indicator">
              <span className="switcher-yes" />
              <span className="switcher-no" />
            </span>
            <span className="switcher-label">Show Overlays</span>
          </label>
        </Button>
        <Button variant="link pr-0">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="switcher mr-0 labelFirst">
            <input type="checkbox" checked={autoUpdateEnabled} className="switcher-input" onChange={toggleAutoUpdateAction} />
            <span className="switcher-indicator">
              <span className="switcher-yes" />
              <span className="switcher-no" />
            </span>
            <OverlayTrigger
              placement="top"
              overlay={(
                <Popover id="autoRefresh">
                  <Popover.Content>
                    Auto Refresh will update the photos in the album automatically
                    as they are taken.
                  </Popover.Content>
                </Popover>
              )}
            >
              <span className="switcher-label">
                Auto Refresh
              </span>
            </OverlayTrigger>
          </label>
        </Button>
      </div>
    </Row>
    <Row>
      <div className={style.AlbumNav}><Stats /></div>
      <div className={style.AlbumNavControllers}>
        <Button
          variant="link"
          disabled={selectedEvents.length === 0}
          className={style.FilterTextContainer}
          onClick={toggleFilterBarAction}
        >
          {Object.values(filterData).every(isEmpty)
            ? <FontAwesomeIcon icon={faFilter} />
            : <FontAwesomeIcon icon={faFilterSolid} />}
          <span>Filters</span>
          {!filterBarDisplayed && <i className="ion ion-ios-arrow-down" />}
          {filterBarDisplayed && <i className="ion ion-ios-arrow-up" />}
        </Button>
      </div>
    </Row>
  </div>
);

export default connect(
  ({
    events: {
      selectedEvents,
    },
    album: {
      options: {
        filterBarDisplayed,
        overlaysDisplayed,
        autoUpdateEnabled,
      },
      filterForm: {
        data: filterData,
      },
    },
  }) => ({
    selectedEvents,
    filterBarDisplayed,
    overlaysDisplayed,
    autoUpdateEnabled,
    filterData,
  }),
  {
    openEventSelectorModalAction: openEventSelectorModal,
    toggleFilterBarAction: toggleFilterBar,
    toggleDisplayOverlayAction: toggleDisplayOverlay,
    toggleAutoUpdateAction: toggleAutoUpdate,
  },
)(AlbumController);
