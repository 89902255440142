import {
  SET_COLORS,
  SET_THEME_COLOR,
  SET_ICON_COLOR,
  SET_EMAIL_COLOR,
  SET_SLIDESHOW_COLOR,
} from './types';

export const setColors = (colors) => ({
  type: SET_COLORS,
  payload: colors,
});

export const setThemeColor = (value, key) => ({
  type: SET_THEME_COLOR,
  payload: { [key]: value },
});

export const setIconColor = (value, key) => ({
  type: SET_ICON_COLOR,
  payload: { [key]: value },
});

export const setEmailColor = (value, key) => ({
  type: SET_EMAIL_COLOR,
  payload: { [key]: value },
});

export const setSlideshowColor = (slideshowColor) => ({
  type: SET_SLIDESHOW_COLOR,
  payload: { slideshowColor },
});
