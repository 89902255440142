import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-regular-svg-icons';
import style from './style.module.scss';

const FallbackContainer = () => (
  <div className={style.OverlayWrapper}>
    <div className={style.OverlayFallback}>
      <div className={style.FallbackIcon}>
        <FontAwesomeIcon icon={faImage} />
      </div>
      <span>Overlay is not available</span>
    </div>
  </div>
);

export default FallbackContainer;
