import React, { useEffect, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { SocketContext, Event } from 'react-socket-io';
import { Button } from 'react-bootstrap';
import classnames from 'classnames';

import { socketEventNames } from '../../../../helpers/constants';
import { displayErrorModal } from '../../../../helpers/swal';
import { useTakingSnapshotHook } from '../hooks';

import style from './style.module.scss';

const { MONITOR_TAKE_SNAPSHOT, MONITOR_NEW_SNAPSHOT } = socketEventNames;

const InfoBar = (props) => {
  const {
    cameraName,
    firstSrs,
    queue = [],
    fps,
  } = props;
  const socket = useContext(SocketContext);
  const [liveTime, setLiveTime] = useState();
  const [linkCopied, setLinkCopied] = useState();
  const [
    takingSnapshot,
    onTakeSnapshot,
    onNewSnapshot,
  ] = useTakingSnapshotHook(cameraName);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    setLiveTime(undefined);

    if (firstSrs) {
      const timeout = setTimeout(() => socket.emit('getLiveTime', firstSrs.id, setLiveTime), 1000);
      return () => clearTimeout(timeout);
    }
  }, [firstSrs]);

  useEffect(() => {
    if (queue.length > 0) {
      socket.emit('getLiveTime', queue[0], setLiveTime);
    }
  }, [cameraName]);

  useEffect(() => {
    if (takingSnapshot) {
      setLiveTime(undefined);
    }
  }, [takingSnapshot]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (liveTime > 1 && queue.length > 0) {
      const timeout = setTimeout(() => setLiveTime(liveTime - 1), 1000);
      // eslint-disable-next-line consistent-return
      return () => clearTimeout(timeout);
    }
  }, [liveTime]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (linkCopied) {
      const timeout = setTimeout(() => setLinkCopied(false), 1500);
      return () => clearTimeout(timeout);
    }
  }, [linkCopied]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (linkCopied) {
      const timeout = setTimeout(() => setLinkCopied(false), 1500);
      return () => clearTimeout(timeout);
    }
  }, [linkCopied]);

  const copyToClipboardBtnHandler = async () => {
    try {
      await navigator.clipboard.writeText(firstSrs.id);
      setLinkCopied(true);
    } catch (err) {
      console.error(err);
      displayErrorModal();
    }
  };

  return (
    <div className={style.topBar}>
      <Event event={MONITOR_TAKE_SNAPSHOT} handler={onTakeSnapshot} />
      <Event event={MONITOR_NEW_SNAPSHOT} handler={onNewSnapshot} />
      <span className="text-left text-capitalize">{`${cameraName} | ${fps.toFixed(1)} FPS`}</span>
      <span className={classnames(style.dropdown, 'text-center', { [style.hide]: queue.length === 0 })}>
        {queue.length === 0 && <span key="no-user">No current user</span>}
        {queue.length > 0 && !liveTime && <span key="loading">Loading...</span>}
        {queue.length > 0 && liveTime && (
          <span key="time-left">
            <strong className={style.liveTime}>
              {liveTime}
              s
            </strong>
            {' '}
            left for user
          </span>
        )}
        {' '}
        <i className="fa fa-chevron-down" />

        {firstSrs && (
          <div className={style.dropdownBlock}>
            <div>
              <strong>ID:</strong>
              <span>{firstSrs.id}</span>
              <span className={style.copyBtn}>
                <Button variant="icon-btn" onClick={copyToClipboardBtnHandler} disabled={linkCopied}>
                  <i className={classnames({ 'far fa-copy': !linkCopied, 'fas fa-check': linkCopied })} />
                </Button>
              </span>
            </div>
            <div>
              <strong>SRS:</strong>
              <span>
                {firstSrs.section}
                {', '}
                {firstSrs.row}
                {', '}
                {firstSrs.seat}
              </span>
            </div>
          </div>
        )}
      </span>
      <span className={classnames(style.dropdown, 'text-right')}>
        <strong className={style.userCount}>{queue.length > 0 ? queue.length - 1 : 0}</strong>
        {' '}
        in line
        {' '}
        <i className="fa fa-chevron-down" />

        {queue.length > 1 && (
          <div className={classnames(style.dropdownBlock, style.alignRight)}>
            {queue.slice(1).map((userId) => (
              <div key={userId}>
                <strong>ID:</strong>
                <span>{userId}</span>
              </div>
            ))}
          </div>
        )}
      </span>
    </div>
  );
};

export default connect(
  ({
    cameras: {
      queue,
      firstSrs,
    },
  }, {
    cameraName,
  }) => ({
    queue: queue[cameraName],
    firstSrs: firstSrs[cameraName],
  }),
)(InfoBar);
