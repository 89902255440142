import { post, patch } from '../../../helpers/fetch';

import { buildURL, urls } from '../../../helpers/urls';

export const updateColors = (event) => {
  const url = buildURL(urls.events.root, 'colors');
  return patch(url, event);
};

export const generatePreviewScreenshots = ({
  hash,
  screenshots,
  slugDate,
  theme,
  iconColor,
  emailSettings,
}) => {
  const url = buildURL('events/screenshots/preview');
  return post(url, {
    hash,
    screenshots,
    slugDate,
    theme,
    iconColor,
    emailSettings,
  });
};

export const generateScreenshots = ({ hash, screenshots, eventIds }) => {
  const url = buildURL('events/screenshots');
  return post(url, { hash, screenshots, eventIds });
};
