import React from 'react';
import { useSelector } from 'react-redux';

import GAChartWrapper from '../../ChartComponents/GAChartWrapper';
import Info from '../../../../components/Info';

import { capitalize } from '../../utils';
import { GA_CHARTS_RESULTS } from '../consts';

const AgeChart = () => {
  const data = useSelector(({ analytics }) => analytics.ga.data.gaUserAge || []);
  const total = data.reduce((prev, curr) => (prev + curr.y), 0);

  const chartData = data.slice(0, GA_CHARTS_RESULTS).map((item) => ({
    name: capitalize(item.x),
    percentage: (item.y / total) * 100,
  }));

  return (
    <GAChartWrapper
      chartType="bar"
      barKey="percentage"
      cardTitle={(
        <Info
          label="Age"
          content="Users by age based on Google Analytics data"
          className="ml-2"
        />
      )}
      fallbackTitle="age"
      chartData={chartData}
    />
  );
};

export default AgeChart;
