import React, { useState, useEffect } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { connect } from 'react-redux';
import { Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';

import { closeModal } from './actions';
import { setEventPromotion } from '../../../shared/layouts/LayoutNavbar/EventControls/api';
import { formatDateToTimezone } from '../../../helpers/utils';

const emptyPromotion = { _id: '', type: '', start: '' };

const SUCCESS = 'success';
const ERROR = 'error';

const promotionTypeOptions = [
  { key: '', label: '' },
  { key: 'NA', label: 'N/A' },
  { key: 'live read', label: 'live announcement read' },
  { key: 'pre recorded read', label: 'pre-recorded announcement read' },
  { key: 'URL banner with live fans', label: 'URL banner with live fans' },
  { key: 'promo video', label: 'promotion video on video board' },
  { key: 'static image', label: 'static image on video board' },
  { key: 'LED', label: 'LED boards' },
];

const NEW = 'New';
// eslint-disable-next-line consistent-return
const formatDate = (date, timezone) => {
  if (date && timezone) {
    return formatDateToTimezone(date, timezone, 'YYYY-MM-DD HH:mm');
  }
};

const PromotionModal = ({ isOpen, closeModalAction, currentEvent }) => {
  const [currentPromotion, setCurrentPromotion] = useState(emptyPromotion);
  const [isSubmitting, setIsSubmitting] = useState();
  const [submitStatus, setSubmitStatus] = useState();
  const [isDeleting, setIsDeleting] = useState();
  const [deleteStatus, setDeleteStatus] = useState();
  const [invalidDate, setInvalidDate] = useState(false);
  const { slugDate, promo: promotions, timezone } = currentEvent || {};
  const promotionOptions = [];

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (submitStatus) {
      const timeout = setTimeout(() => setSubmitStatus(undefined), 2000);
      return () => clearTimeout(timeout);
    }
  }, [submitStatus]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (deleteStatus) {
      const timeout = setTimeout(() => setDeleteStatus(undefined), 2000);
      return () => clearTimeout(timeout);
    }
  }, [deleteStatus]);

  if (promotions) {
    promotions.forEach(({ _id, start }) => {
      if (_id && start) {
        promotionOptions.push(<option key={_id} value={_id}>{formatDate(start, timezone)}</option>);
      }
    });
  }

  const promotionChangeHandler = ({ currentTarget: { value } }) => {
    const selected = promotions.find(({ _id }) => _id === value);

    if (selected) {
      setCurrentPromotion({
        ...selected,
        start: formatDate(selected.start, timezone),
      });
    } else if (value === NEW) {
      setCurrentPromotion({
        ...emptyPromotion,
        _id: NEW,
        start: formatDate(moment(), timezone),
      });
    } else {
      setCurrentPromotion(emptyPromotion);
    }
  };

  const promotionTypeChangeHandler = ({ currentTarget: { value } }) => {
    setCurrentPromotion({ ...currentPromotion, type: value });
  };

  const timeChangeHandler = (value) => {
    setCurrentPromotion({
      type: currentPromotion.type,
      start: value,
      _id: currentPromotion._id === '' ? NEW : currentPromotion._id,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const current = { ...currentPromotion };

    // eslint-disable-next-line no-restricted-globals
    if (current.start && !moment(current.start).isValid()) {
      setInvalidDate(true);
      return;
    }

    current.start = formatDate(current.start, 'UTC');

    let promotionList = [...promotions];
    if (current._id !== NEW) {
      promotionList = promotionList.map((promo) => {
        if (promo._id === current._id) {
          return current;
        }

        return promo;
      });
    } else {
      delete current._id;
      promotionList.push(current);
    }

    promotionList.sort((a, b) => new Date(a) > new Date(b));

    try {
      setIsSubmitting(true);
      await setEventPromotion(slugDate, promotionList);
      setSubmitStatus(SUCCESS);
      setCurrentPromotion(emptyPromotion);
    } catch (err) {
      console.error(err);
      setSubmitStatus(ERROR);
    } finally {
      setIsSubmitting(false);
      setInvalidDate(false);
    }
  };

  const deleteHandler = async () => {
    try {
      setIsDeleting(true);
      await setEventPromotion(
        slugDate,
        promotions.filter(({ _id }) => _id !== currentPromotion._id),
      );
      setCurrentPromotion(emptyPromotion);
      setDeleteStatus(SUCCESS);
    } catch (err) {
      console.error(err);
      setDeleteStatus(ERROR);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Modal show={isOpen} onHide={closeModalAction} centered>
      <Form onSubmit={submitHandler}>
        <Modal.Header closeButton />
        <Modal.Body>
          <Form.Group className="col-sm-12 d-inline-block">
            <Form.Label>Promotion</Form.Label>
            <Form.Control
              custom
              as="select"
              onChange={promotionChangeHandler}
              value={currentPromotion._id}
              required
            >
              <option key="" value="">&nbsp;</option>
              <option key={NEW} value={NEW}>{NEW}</option>
              {promotionOptions}
            </Form.Control>
          </Form.Group>
          <Form.Group className="col-sm-12 d-inline-block">
            <Form.Label>Promotion Type</Form.Label>
            <Form.Control
              custom
              as="select"
              value={currentPromotion.type}
              onChange={promotionTypeChangeHandler}
              required
            >
              {promotionTypeOptions.map(
                ({ key, label }) => <option key={key} value={key}>{label}</option>,
              )}
            </Form.Control>
          </Form.Group>
          <Form.Group className="col-sm-12 d-inline-block mb-0">
            <Form.Label>Promotion Time</Form.Label>
            <Form.Control
              type="text"
              name="start"
              placeholder="yyyy-mm-dd hh:mm"
              value={currentPromotion.start || ''}
              onChange={({ currentTarget: { value } }) => timeChangeHandler(value)}
              required
              isInvalid={invalidDate}
            />
            <Form.Control.Feedback type="invalid">
              Time is Not Valid
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <LaddaButton
            data-size="s"
            className={classnames('btn', 'small', {
              'btn-outline-danger': !deleteStatus,
              'btn-success': deleteStatus === SUCCESS,
              'btn-danger': deleteStatus === ERROR,
            })}
            type="button"
            onClick={deleteHandler}
            disabled={!currentPromotion._id || currentPromotion._id === NEW || !!deleteStatus}
            loading={isDeleting}
            data-style={ZOOM_IN}
            data-spinner-color="#d9534f"
            title={(deleteStatus === SUCCESS && 'Promo deleted successfuly')
              || (deleteStatus === ERROR && 'Promo delete failed')
              || undefined}
          >
            {deleteStatus === SUCCESS && <FontAwesomeIcon icon={faCheck} />}
            {deleteStatus === ERROR && <FontAwesomeIcon icon={faTimes} />}
            {!deleteStatus && 'Delete'}
          </LaddaButton>

          <LaddaButton
            data-size="s"
            className={classnames('btn', 'small', {
              'btn-primary': !submitStatus,
              'btn-success': submitStatus === SUCCESS,
              'btn-danger': submitStatus === ERROR,
            })}
            type="submit"
            loading={isSubmitting}
            data-style={ZOOM_IN}
            disabled={!!submitStatus}
            title={(submitStatus === SUCCESS && 'Promo submitted successfuly')
              || (submitStatus === ERROR && 'Promo submit failed')
              || undefined}
          >
            {submitStatus === SUCCESS && <FontAwesomeIcon icon={faCheck} />}
            {submitStatus === ERROR && <FontAwesomeIcon icon={faTimes} />}
            {!submitStatus && 'Submit'}
          </LaddaButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default connect(
  ({
    modals: { promotion: { isOpen } },
    events: { currentEvent },
  }) => ({
    isOpen,
    currentEvent,
  }),
  { closeModalAction: closeModal },
)(PromotionModal);
