import {
  CLOSE_BLOCKED_SEATS_MODAL,
  OPEN_BLOCKED_SEATS_MODAL,
  SET_BLOCKED_SEATS,
  ADD_BLOCKED_SEAT,
  REMOVE_BLOCKED_SEAT,
} from './types';

const initialState = {
  isBlockedSeatModalOpen: false,
  blockedSeats: [],
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
  case REMOVE_BLOCKED_SEAT: {
    const {
      seatType,
      section,
      row,
      seat,
    } = payload;
    const SRS = `${seatType}-${section}-${row}-${seat}`;

    return {
      ...state,
      blockedSeats: state.blockedSeats.filter((item) => (`${item.seattype}-${item.section}-${item.row}-${item.seat}` === SRS)),
    };
  }
  case ADD_BLOCKED_SEAT: {
    return {
      ...state,
      blockedSeats: [...state.blockedSeats, payload],
    };
  }
  case SET_BLOCKED_SEATS: {
    return {
      ...state,
      blockedSeats: payload,
    };
  }
  case CLOSE_BLOCKED_SEATS_MODAL: {
    return {
      ...state,
      isBlockedSeatModalOpen: false,
    };
  }
  case OPEN_BLOCKED_SEATS_MODAL: {
    return {
      ...state,
      isBlockedSeatModalOpen: true,
    };
  }
  default: {
    return state;
  }
  }
};
