import React from 'react';
import HostInfo from './HostInfo';
import style from '../style.module.scss';

const HostInfoRow = ({ cameras }) => (
  <>
    <tr>
      <td colSpan={cameras.length + 1} className={style.ThemeYellowBackground}>
        Device
      </td>
    </tr>
    <tr className={style.TopLabel}>
      <td className={style.SideLabel}>Host Info</td>
      {cameras.map((camera) => (
        <td key={camera._id}>
          <HostInfo camKey={camera.cameraKey} />
        </td>
      ))}
    </tr>
  </>
);

export default HostInfoRow;
