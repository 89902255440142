import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Button } from 'react-bootstrap';
import useForm from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';

import { closeNewQuestionModal, addNewQuestion } from '../actions';

const defaultValues = {
  question: '',
  answer1: '',
  answer2: '',
  correctAnswer: undefined,
};

const InputField = memo(({
  register,
  setValue,
  errors,
  name,
}) => (
  <>
    <RHFInput
      name={name}
      as={(
        <Form.Control as="input" type="text" isInvalid={errors[name]} />
      )}
      register={register({ required: true })}
      setValue={setValue}
    />
    {errors[name] && (
      <Form.Control.Feedback type="invalid">
        {errors[name].type === 'required' && 'This field is required'}
      </Form.Control.Feedback>
    )}
  </>
));

const AddLanguageModal = ({ isNewQuestionModalOpen, closeModalAction, addNewQuestionAction }) => {
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    reset,
  } = useForm({ defaultValues });

  const closeHandler = () => {
    closeModalAction();
    reset(defaultValues);
  };

  const submitHandler = (question) => {
    addNewQuestionAction(question);
    closeHandler();
  };

  const inputProps = { register, setValue, errors };

  return (
    <Modal
      show={isNewQuestionModalOpen}
      onHide={closeHandler}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title as="h3" className="text-center w-100">Add New Question</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(submitHandler)}>
          <Form.Group md="2" controlId="question">
            <Form.Label>Question</Form.Label>
            <RHFInput
              name="question"
              as={(
                <Form.Control as="textarea" rows="3" isInvalid={errors.question} />
              )}
              register={register({ required: true })}
              setValue={setValue}
            />
            {errors.question && (
              <Form.Control.Feedback type="invalid">
                {errors.question.type === 'required' && 'This field is required'}
              </Form.Control.Feedback>
            )}

            <Form.Label className="mt-2">Answer 1</Form.Label>
            <InputField name="answer1" {...inputProps} />
            <Form.Label className="mt-2">Answer 2</Form.Label>
            <InputField name="answer2" {...inputProps} />

            <Form.Label className="mt-2">Correct Answer</Form.Label>
            <RHFInput
              name="correctAnswer"
              as={(
                <Form.Control as="select" custom isInvalid={errors.correctAnswer}>
                  <option value="">Select...</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </Form.Control>
              )}
              register={register({ required: true })}
              setValue={setValue}
            />
            {errors.correctAnswer && (
              <Form.Control.Feedback type="invalid">
                {errors.correctAnswer.type === 'required' && 'This field is required'}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="small" variant="outline-primary" onClick={closeHandler}>
          Cancel
        </Button>
        <Button className="small" variant="primary" type="submit" onClick={handleSubmit(submitHandler)}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default connect(
  ({ trivia: { questions: { isNewQuestionModalOpen } } }) => ({ isNewQuestionModalOpen }),
  {
    closeModalAction: closeNewQuestionModal,
    addNewQuestionAction: addNewQuestion,
  },
)(AddLanguageModal);
