import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Event, SocketContext } from 'react-socket-io';
import { socketEventNames } from '../../../../../helpers/constants';

const { MONITOR_LIVESTREAM } = socketEventNames;

const HostInfo = ({ camKey, slug }) => {
  const [hostInfo, setHostInfo] = useState();
  const socket = useContext(SocketContext);

  useEffect(() => {
    socket.emit(
      'join monitor livestream',
      slug,
      camKey,
      (st) => console.log(`joined livestream for camera ${camKey} ${st}`),
    );

    return () => socket.emit(
      'leave livestream',
      camKey,
      (camera) => console.log(`left livestream for camera ${camera}`),
    );
  }, []);

  const handleLivestream = (camera, binary, info) => {
    if (camera === camKey) {
      setHostInfo(info);
    }
  };

  return (
    <div className="form-group">
      <Event event={MONITOR_LIVESTREAM} handler={handleLivestream} />
      <div className="form-row">
        <pre className="m-0 d-flex flex-column justify-content-around text-right">
          {hostInfo || 'No info available'}
        </pre>
      </div>
    </div>
  );
};

export default connect(({ events: { slug } }) => ({ slug }))(HostInfo);
