import { get } from '../../../../helpers/fetch';
import { buildURL } from '../../../../helpers/urls';

export const getImageMiningFacesData = async (snapshotId) => {
  const url = buildURL(`/snapshots/${snapshotId}/facesData`);
  return get(url);
};

export const getUserData = async (userId) => {
  const url = buildURL(`/users/${userId}/stats`);
  return get(url);
};
