import { useRef, useCallback, useState } from 'react';
import { checkCamAndExec } from './helpers';

// eslint-disable-next-line import/prefer-default-export
export const useTakingSnapshotHook = (cameraName, numOfSnapshots = 3) => {
  const snapsCount = useRef();
  const [takingSnapshot, setTakingSnapshot] = useState(false);

  const onTakeSnapshot = useCallback(checkCamAndExec({ cameraName }, () => {
    snapsCount.current = 0;
    setTakingSnapshot(true);
  }), []);

  const onNewSnapshot = useCallback(checkCamAndExec({ cameraName }, ({ userId }) => {
    snapsCount.current += 1;
    if (snapsCount.current === numOfSnapshots || userId.startsWith('MAGIC')) {
      setTakingSnapshot(false);
      snapsCount.current = 0;
    }
  }), []);

  return [takingSnapshot, onTakeSnapshot, onNewSnapshot];
};
