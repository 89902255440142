import { isUndefined } from 'lodash';

import {
  UPDATE_CONFIG,
  CONFIG_UPDATED,
  ADD_FILES,
  REMOVE_FILE,
  UPLOAD_ALL_FILES,
  UPLOAD_ALL_FILES_DONE,
  UPLOAD_FILE,
} from './types';

const initialState = {
  config: {
    isFetching: false,
  },
  files: {
    isUploading: false,
    items: [
      // isUploading (boolean)
      // isUploaded (boolean)
      // error (boolean)
      // file (File)
    ],
  },
};

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
  case UPDATE_CONFIG: {
    return {
      ...state,
      config: {
        ...state.config,
        isFetching: true,
      },
    };
  }

  case CONFIG_UPDATED: {
    return {
      ...state,
      config: {
        ...state.config,
        isFetching: false,
      },
    };
  }

  case ADD_FILES: {
    const { files, isUploaded } = payload;

    return {
      ...state,
      files: {
        ...state.files,
        items: [
          ...state.files.items,
          ...files.filter((file) => !state.files.items.some(({ file: existingFile }) => (
            file.name === existingFile.name
            && file.size === existingFile.size
            && file.lastModified === existingFile.lastModified
            && file.stamp === existingFile.stamp
          ))).map((file) => ({ file, isUploaded })),
        ],
      },
    };
  }

  case REMOVE_FILE: {
    const { index } = payload;

    return {
      ...state,
      files: {
        ...state.files,
        items: [
          ...state.files.items.slice(0, index),
          ...state.files.items.slice(index + 1),
        ],
      },
    };
  }

  case UPLOAD_ALL_FILES: {
    return {
      ...state,
      files: {
        ...state.files,
        isUploading: true,
      },
    };
  }

  case UPLOAD_ALL_FILES_DONE: {
    const updates = payload;

    return {
      ...state,
      files: {
        ...state.files,
        items: state.files.items.map((item) => {
          const updated = updates.find(({ name }) => name === item.file.name);

          if (!isUndefined(updated)) {
            return {
              ...item,
              isUploaded: updated.success === true,
              error: updated.success === false,
            };
          }
          return item;
        }),
        isUploading: false,
      },
    };
  }

  case UPLOAD_FILE: {
    const { success, file } = payload;

    return {
      ...state,
      files: {
        ...state.files,
        items: state.files.items
          .filter((item) => !(item.file.name === file.name && item.isUploaded))
          .map((item) => {
            if (item.file === file) {
              return {
                isUploading: isUndefined(success),
                isUploaded: success === true,
                error: success === false,
                file: item.file,
              };
            }
            return item;
          }),
      },
    };
  }

  default: {
    return state;
  }
  }
};
