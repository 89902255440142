import { combineReducers } from 'redux';

import options from './options';
import sidebar from '../Sidebar/reducer';
import notifications from '../MonitorNav/Notifications/reducer';
import toasts from '../SuspiciousUserToasts/reducer';

export default combineReducers({
  options,
  sidebar,
  notifications,
  toasts,
});
