import React from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons';

const SaveOption = ({
  label,
  name,
  onToggle,
  checked,
  disabled,
}) => {
  if (disabled) {
    return null;
  }

  return (
    <Form.Group>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="switcher labelFirst">
        <input
          checked={checked}
          onChange={onToggle}
          name={name}
          type="checkbox"
          className="switcher-input"
        />
        <span className="switcher-indicator">
          <span className="switcher-yes" />
          <span className="switcher-no" />
        </span>
        <span className="switcher-label">
          <FontAwesomeIcon icon={faLayerGroup} />
          <span>{label}</span>
        </span>
      </label>
    </Form.Group>
  );
};

export default SaveOption;
