import { batch } from 'react-redux';

import {
  SET_ALL_GRAPHICS,
  SET_GRAPHICS_SECTION,
  SET_GRAPHICS_SELECTED_IND,
  SET_MULTISELECT_VALUE,
  DELETE_GRAPHICS_ITEM,
  ADD_GRAPHICS_ITEM,
  SET_GRAPHICS_ERROR,
  UPLOAD_GRAPHICS_FILE,
  UPLOAD_GRAPHICS_FILE_DONE,
} from './types';

import { updateEventImages } from './api';
import { uploadImage as uploadFileApi } from '../../../helpers/api';
import { displaySuccessModal, displayErrorModal } from '../../../helpers/swal';
import Alert from '../../../components/Alert';

export const setImages = (payload) => ({
  type: SET_ALL_GRAPHICS,
  payload,
});

export const setSection = (payload) => ({
  type: SET_GRAPHICS_SECTION,
  payload,
});

export const setSelected = (selectedInd) => ({
  type: SET_GRAPHICS_SELECTED_IND,
  payload: { selectedInd },
});

export const setMultiselectValue = (value) => ({
  type: SET_MULTISELECT_VALUE,
  payload: { value },
});

export const addFile = ({ file, data }) => ({
  type: ADD_GRAPHICS_ITEM,
  payload: { file, data },
});

export const setError = (error) => ({
  type: SET_GRAPHICS_ERROR,
  payload: { error },
});

const deleteImg = () => ({ type: DELETE_GRAPHICS_ITEM });

export const deleteImage = () => async (dispatch) => {
  const { value: confirmed } = await Alert.fire({
    title: 'Do you want to delete the image?',
    type: 'question',
    html: 'Note that the changes are saved only on "Update event"',
    showCancelButton: true,
    showLoaderOnConfirm: true,
    allowOutsideClick: false,
  });

  if (confirmed) {
    dispatch(deleteImg());
  }
};

export const save = (eventIds) => async (dispatch, getState) => {
  const { graphics: { sections } } = getState();
  const images = {};

  sections.forEach(({ data, value, key }) => {
    if (data) {
      Object.assign(images, data);
    } else {
      Object.assign(images, { [key]: value });
    }
  });

  try {
    await updateEventImages({ eventIds, images });
    displaySuccessModal({ text: 'Event successfully updated' });
  } catch (err) {
    console.error(err);
    displayErrorModal({
      title: 'Failed to update overlays',
      text: (err.body && err.body.error.message) || err.message,
    });
  }
};

const uploadFileStart = () => ({
  type: UPLOAD_GRAPHICS_FILE,
});
const uploadFileDone = () => ({
  type: UPLOAD_GRAPHICS_FILE_DONE,
});

export const uploadFile = (file) => async (dispatch, getState) => {
  // this should be removed when we change server side API to just upload image
  const {
    events: { selectedEvents: [{ slugDate }] },
    graphics: { sections, selectedInd },
  } = getState();
  const { key } = sections[selectedInd];

  dispatch(uploadFileStart());

  try {
    const data = await uploadFileApi(key, slugDate, file);
    batch(() => {
      dispatch(uploadFileDone());
      dispatch(addFile({ data }));
    });
  } catch (err) {
    console.error(err);
    batch(() => {
      dispatch(setError(err.body.error));
      dispatch(deleteImg);
    });
  }
};
