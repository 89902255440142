import React from 'react';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { faInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { formatDateToTimezone } from '../../../../../../helpers/utils';

import style from './style.module.scss';

const Info = ({
  children,
  className,
  addressed,
  allSRS,
  placement = 'left',
  timezone,
}) => (
  <OverlayTrigger
    rootClose
    placement={placement}
    trigger="click"
    overlay={(
      <Tooltip>
        {allSRS.map(({
          _id,
          row,
          seat,
          section,
          time,
        }) => (
          <p key={_id}>
            {formatDateToTimezone(time, timezone, '(DD-MM/ hh:mm)')}
            &nbsp;
            {section}
            -
            {row}
            -
            {seat}
          </p>
        ))}
      </Tooltip>
    )}
  >
    <div className={className}>
      <div className={classnames(style.icon, { [style.addressed]: addressed })}>
        <FontAwesomeIcon size="xs" icon={faInfo} />
      </div>
      {children}
    </div>
  </OverlayTrigger>
);

export default connect(
  ({ events: { timezone } }) => ({ timezone }),
)(Info);
