import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toggleSelection } from '../../../store/actions/snapshots';

const Select = ({ snapshotId, toggleSelectedAction, show }) => {
  if (!show) {
    return null;
  }

  const selectBtnHandler = (evt) => {
    evt.stopPropagation();
    toggleSelectedAction(snapshotId);
  };

  return (
    // eslint-disable-next-line
    <div className="actionButtonContainer" onClick={selectBtnHandler}>
      <Button variant="default">
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Select</Tooltip>}
        >
          <FontAwesomeIcon icon={faCheckCircle} />
        </OverlayTrigger>
      </Button>
    </div>
  );
};

export default connect(null, { toggleSelectedAction: toggleSelection })(Select);
