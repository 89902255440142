export const SET_FULLSCREEN_SNAPSHOT = 'SET_FULLSCREEN_SNAPSHOT';
export const UNSET_FULLSCREEN_SNAPSHOT = 'UNSET_FULLSCREEN_SNAPSHOT';
export const FETCH_FULLSCREEN_SNAPSHOT = 'FETCH_FULLSCREEN_SNAPSHOT';
export const SET_SNAPSHOT_LIST_ITEMS = 'SET_SNAPSHOT_LIST_ITEMS';
export const FETCH_LIST_ITEMS = 'FETCH_LIST_ITEMS';
export const SET_LIST_ITEMS_FILTER_DATA = 'SET_LIST_ITEMS_FILTER_DATA';
export const SET_LIST_ITEMS_PAGE_LEFT = 'SET_LIST_ITEMS_PAGE_LEFT';
export const SET_LIST_ITEMS_PAGE_RIGHT = 'SET_LIST_ITEMS_PAGE_RIGHT';
export const SET_LARGE_IMAGE_URL = 'SET_LARGE_IMAGE_URL';
export const TOGGLE_INFO_SECTION = 'TOGGLE_INFO_SECTION';
export const FETCH_IMAGE_MINING_DATA = 'FETCH_IMAGE_MINING_DATA';
export const SET_SELECTED_FACE_IND = 'SET_SELECTED_FACE_IND';
export const SET_USER_PHOTOS_ID = 'SET_USER_PHOTOS_ID';
export const TOGGLE_USER_DATA_SECTION = 'TOGGLE_USER_DATA_SECTION';
export const FETCH_USER_DATA = 'FETCH_USER_DATA';
