import { isEmpty } from 'lodash';

import {
  FETCH_TRIVIA_QUESTIONS,
  SET_TRIVIA_LANGUAGE,
  UPDATE_TRIVIA_QUESTION,
  DELETE_TRIVIA_QUESTIONS,
  ADD_NEW_TRIVIA_QUESTION,
  TOGGLE_TRIVIA_QUESTION,
  SAVE_TRIVIA_QUESTIONS,
  OPEN_NEW_QUESTION_MODAL,
  CLOSE_NEW_QUESTION_MODAL,
  SET_TRIVIA_QUESTIONS,
} from './types';
import {
  getTriviaQuestions,
  updateTriviaQuestions,
  deleteTriviaQuestion,
  updateTrivia,
} from './api';
import { displayErrorModal, ReactSwal } from '../../../helpers/swal';

const requestTriviaQuestions = () => ({ type: FETCH_TRIVIA_QUESTIONS });

const receiveTriviaQuestions = (error, data) => ({
  type: FETCH_TRIVIA_QUESTIONS,
  payload: { error, data },
});

export const fetchTriviaQuestions = (language, eventId) => (dispatch) => {
  dispatch(requestTriviaQuestions());

  getTriviaQuestions(language, eventId)
    .then((data) => dispatch(receiveTriviaQuestions(undefined, data)))
    .catch((err) => {
      console.error(err);
      displayErrorModal();
      dispatch(receiveTriviaQuestions(err.message));
    });
};

export const setLanguage = (language) => ({
  type: SET_TRIVIA_LANGUAGE,
  payload: { language },
});

export const updateQuestion = ({ rowId, dataField, newValue }) => ({
  type: UPDATE_TRIVIA_QUESTION,
  payload: { rowId, dataField, newValue },
});

const requestDeleteQuestions = () => ({ type: DELETE_TRIVIA_QUESTIONS });
const receiveDeleteQuestions = (error, questionIds) => ({
  type: DELETE_TRIVIA_QUESTIONS,
  payload: { error, questionIds },
});

export const deleteQuestions = () => (dispatch, getState) => {
  const { trivia: { questions: { selected } } } = getState();

  ReactSwal.fire({
    title: 'Are you sure you want to delete questions?',
    type: 'warning',
    showCancelButton: true,
    showConfirmButton: true,
    customClass: {
      confirmButton: 'btn btn-danger btn-lg',
      cancelButton: 'btn btn-default btn-lg',
      actions: 'text-center',
    },
  }).then(async (result) => {
    if (result && result.value) {
      try {
        dispatch(requestDeleteQuestions());

        const filteredQuestionIds = selected.filter((qId) => !!qId);
        if (!isEmpty(filteredQuestionIds)) {
          await deleteTriviaQuestion(selected);
        }

        dispatch(receiveDeleteQuestions(undefined, selected));
      } catch (err) {
        console.error(err);
        displayErrorModal();
        dispatch(receiveDeleteQuestions(err.message));
      }
    }
  }).catch((err) => {
    console.error(err);
    displayErrorModal();
  });
};

export const addNewQuestion = (question) => ({
  type: ADD_NEW_TRIVIA_QUESTION,
  payload: { question },
});

export const toggleSelection = ({ _id: questionId }, isSelect) => ({
  type: TOGGLE_TRIVIA_QUESTION,
  payload: { questionId, isSelect },
});

export const toggleSelectionAll = (isSelect) => ({
  type: TOGGLE_TRIVIA_QUESTION,
  payload: { all: true, isSelect },
});

const requestSave = () => ({ type: SAVE_TRIVIA_QUESTIONS });
const receiveSaved = (error, updated) => ({
  type: SAVE_TRIVIA_QUESTIONS,
  payload: { error, updated },
});

export const save = (eventIdsToUpdate, triviaEnabled) => async (dispatch, getState) => {
  const { trivia: { questions: { items: questions, selectedLanguage } } } = getState();
  const language = selectedLanguage;

  dispatch(requestSave());

  try {
    await Promise.all([
      updateTriviaQuestions(language, eventIdsToUpdate, questions),
      updateTrivia(triviaEnabled, eventIdsToUpdate),
    ]);
    dispatch(receiveSaved(undefined, true));
  } catch (err) {
    console.error(err);
    const { body, message } = err;
    dispatch(receiveSaved({ body, message }));
    throw err;
  }
};

export const openNewQuestionModal = () => ({ type: OPEN_NEW_QUESTION_MODAL });
export const closeNewQuestionModal = () => ({ type: CLOSE_NEW_QUESTION_MODAL });

export const setQuestions = (questions) => ({
  type: SET_TRIVIA_QUESTIONS,
  payload: questions,
});
