import { getCameraStatus } from '../../helpers/api';
import { SET_CAMERA_STATUS, SET_PAN_ZOOM, SET_DEMO_MODE } from '../types/cameras';

export const setCameraStatus = (payload) => ({
  type: SET_CAMERA_STATUS,
  payload,
});

export const updateCameraStatus = () => async (dispatch) => {
  try {
    const payload = await getCameraStatus();
    dispatch(setCameraStatus(payload));
  } catch (e) {
    console.error('Failed to fetch current event', e);
  }
};

export const setPanAndZoom = (camera, panAndZoom) => ({
  type: SET_PAN_ZOOM,
  payload: { [camera]: panAndZoom },
});

export const setDemoMode = (payload) => ({
  type: SET_DEMO_MODE,
  payload,
});
