import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import StreamPreview from './Stream';

import PositioningForm from './positioningForm';
import StepSpeedControls from './StepSpeedControls';
import ContinuousSpeedControls from './ContinuousSpeedControls';
import CameraConnect from './CameraConnect';
import MoveControls from './MoveControls';
import MoveToHomeButton from './MoveToHomeButton';
import camMap from './mapLogic';

import style from './style.module.scss';

const Mapping = () => {
  const connectedCamera = useSelector(({ mapping: { connectedCamera: cam } }) => cam);
  console.warn('Mapping render', { connectedCamera });

  useEffect(() => {
    if (connectedCamera) {
      camMap.loadMapConfig(connectedCamera);
    }
  }, [connectedCamera]);

  return (
    <div className={style.mappingContainer}>
      <div className={style.content}>
        <div className={style.mappingPreview}>
          <div className="pageHeader">
            <CameraConnect />
          </div>
          <div className={style.cameraContainer}>
            <StreamPreview />
          </div>
          <div className={style.cameraControlsContainer}>
            <ContinuousSpeedControls />
            <StepSpeedControls />
            <MoveControls />
            <MoveToHomeButton />
          </div>
        </div>
        <PositioningForm />
      </div>
    </div>
  );
};

export default memo(Mapping);
