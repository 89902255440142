import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CustomToggle from './CustomToggle';
import Authorize from '../../../../components/Authorize';

import { eventStatus } from '../../../../helpers/constants';
import { setSelectedEvents } from '../../../../store/actions/events';
import { EVENT_WRITE, ANALYTICS_READ } from '../../../../components/Authorize/permissions/resources';
import { EDIT_EVENT, DELETE_EVENT } from '../../permissions';

import style from './style.module.scss';

const { PRE, LIVE, POST } = eventStatus;

const EventContainer = ({
  event,
  title,
  history,
  deleteEventHandler,
  editEventHandler,
  setSelectedEventsAction,
}) => {
  const editButtonHandler = (e) => {
    e.preventDefault();
    editEventHandler(event);
  };

  const navigateTo = (location) => {
    setSelectedEventsAction([event], location.slice(1));
    history.push(location);
  };

  const menu = (
    <div className={style.ActionMenu}>
      <Authorize
        permissions={(can) => (
          (
            event.eventStatus !== POST
            && can([EDIT_EVENT, EVENT_WRITE], true)
            && can(EVENT_WRITE)
          )
          || event.eventStatus !== PRE
          || (event.eventStatus !== LIVE && can([DELETE_EVENT, EVENT_WRITE], true))
        )}
      >
        <Dropdown drop="right" flip="true" className={style.DropdownMenu}>
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu>
            <Authorize or permissions={[EDIT_EVENT, EVENT_WRITE]}>
              <Dropdown.Item
                className={classNames('action-button', { hidden: event.eventStatus === POST })}
                onClick={editButtonHandler}
              >
                <i className="ion ion-md-create" />
                <span className="ml-2">Edit event</span>
              </Dropdown.Item>
            </Authorize>

            <Authorize permissions={EVENT_WRITE}>
              <Dropdown.Item
                className={classNames('action-button', { hidden: event.eventStatus === POST })}
                onClick={() => navigateTo('/template/colors')}
              >
                <i className="far fa-clone" />
                <span className="ml-2">Template</span>
              </Dropdown.Item>
            </Authorize>

            <Dropdown.Item
              className={classNames('action-button', { hidden: event.eventStatus === PRE })}
              onClick={() => navigateTo('/album')}
            >
              <i className="far fa-images" />
              <span className="ml-2">Album</span>
            </Dropdown.Item>

            <Authorize permissions={ANALYTICS_READ}>
              <Dropdown.Item
                className={classNames('action-button', { hidden: event.eventStatus === PRE })}
                onClick={() => navigateTo('/analytics')}
              >
                <i className="fas fa-chart-line" />
                <span className="ml-2">Analytics</span>
              </Dropdown.Item>
            </Authorize>

            <Authorize or permissions={[DELETE_EVENT, EVENT_WRITE]}>
              <Dropdown.Item
                className={classNames('action-button', { hidden: event.eventStatus === LIVE })}
                onClick={(e) => deleteEventHandler(e, event)}
              >
                <i className="far fa-trash-alt" />
                <span className="ml-2">Delete</span>
              </Dropdown.Item>
            </Authorize>
          </Dropdown.Menu>
        </Dropdown>
      </Authorize>
    </div>
  );

  const eventTitle = (
    <div className={style.EventTitle}>
      <span className={style.icon}>
        <FontAwesomeIcon
          icon={faCircle}
          className={classNames({
            [style.live]: event.eventStatus === LIVE,
            [style.pre]: event.eventStatus === PRE,
            [style.post]: event.eventStatus === POST,
          })}
        />
      </span>
      <span className="text-truncate pl-1">{title}</span>
    </div>
  );

  const eventTime = (
    <div className={classNames(style.EventTime, 'text-truncate w-100')}>
      <span className={style.icon}><FontAwesomeIcon icon={faClock} /></span>
      <span className="pl-1">
        {event.startTime}
        {' - '}
        {event.endTime}
      </span>
    </div>
  );

  return (
    <div className={style.EventCard}>
      {menu}
      {eventTitle}
      {eventTime}
    </div>
  );
};

export default compose(
  withRouter,
  connect(
    null,
    { setSelectedEventsAction: setSelectedEvents },
  ),
)(EventContainer);
