import {
  SET_CURRENT_ALBUM_PAGE,
  TOGGLE_FILTER_BAR,
  TOGGLE_DISPLAY_OVERLAY,
  FETCH_SNAPSHOT_STATS,
  SET_CURRENT_ALBUM_TAB,
  RESET_SNAPSHOT_STATS,
  CHANGE_FAVORITE_ON_STATS,
  TOGGLE_AUTO_UPDATE,
} from './types';

import { listSnapshots, getAvailableSnapshots } from '../../helpers/api';
import {
  requestSnapshots,
  recieveSnapshots,
  setFavorite as setFavoriteOnSnapshots,
} from '../../store/actions/snapshots';

export const fetchSnapshots = () => (dispatch, getState) => {
  const {
    album: {
      options: {
        currentPage,
        currentTab,
        itemsPerPage,
      },
      filterForm: {
        data: filterData,
      },
    },
    events: {
      selectedEvents,
    },
  } = getState();

  const slugDates = selectedEvents.map(({ slugDate }) => slugDate);

  if (slugDates.length === 0) {
    return;
  }

  dispatch(requestSnapshots());

  listSnapshots(slugDates, currentTab, currentPage, itemsPerPage, filterData).then((data) => {
    dispatch(recieveSnapshots(null, data));
  }).catch((err) => {
    console.error(err);
    dispatch(recieveSnapshots(err));
  });
};

const requestSnapshotStats = () => ({ type: FETCH_SNAPSHOT_STATS });

const recieveSnapshotStats = (error, data) => ({
  type: FETCH_SNAPSHOT_STATS,
  payload: { error, data },
});

const resetSnapshotStats = () => ({ type: RESET_SNAPSHOT_STATS });

export const fetchSnapshotStats = () => (dispatch, getState) => {
  const {
    events: {
      selectedEvents,
    },
    album: {
      filterForm: {
        data: filterData,
      },
    },
  } = getState();

  const slugDates = selectedEvents.map(({ slugDate }) => slugDate);

  if (slugDates.length === 0) {
    dispatch(resetSnapshotStats());
    return;
  }

  dispatch(requestSnapshotStats());

  getAvailableSnapshots(slugDates, filterData).then((data) => {
    dispatch(recieveSnapshotStats(null, data));
  }).catch((err) => {
    console.error(err);
    dispatch(recieveSnapshotStats(err));
  });
};

const setPage = (page) => ({
  type: SET_CURRENT_ALBUM_PAGE,
  payload: page,
});

export const setCurrentPage = (page) => (dispatch) => {
  dispatch(setPage(page));
  dispatch(fetchSnapshots());
};

export const resetCurrentPage = () => (dispatch) => {
  dispatch(setPage(1));
};

const setTab = (tab) => ({
  type: SET_CURRENT_ALBUM_TAB,
  payload: tab,
});

export const setCurrentTab = (tab) => (dispatch) => {
  dispatch(setTab(tab));
  dispatch(setCurrentPage(1));
};

export const resetCurrentTab = () => (dispatch) => {
  dispatch(setTab('all'));
};

export const toggleFilterBar = () => ({ type: TOGGLE_FILTER_BAR });

export const toggleDisplayOverlay = () => ({ type: TOGGLE_DISPLAY_OVERLAY });

export const setFavoriteOnStats = (favorite) => ({
  type: CHANGE_FAVORITE_ON_STATS,
  payload: favorite,
});

export const setFavorite = (snapshotUpdateObjects) => (dispatch, getState) => {
  const { snapshots: { items: snapshots } } = getState();
  let favoriteDelta = 0;

  snapshotUpdateObjects.forEach(({ _id, favorite }) => {
    const snapshot = snapshots.find(({ _id: snapshotId }) => snapshotId === _id);

    if (snapshot) {
      if (snapshot.favorite && !favorite) {
        favoriteDelta += -1;
      }

      if (!snapshot.favorite && favorite) {
        favoriteDelta += 1;
      }
    }
  });

  dispatch(setFavoriteOnSnapshots(snapshotUpdateObjects));
  dispatch(setFavoriteOnStats(favoriteDelta));
};

const toggleAutoUpdateOnly = () => ({ type: TOGGLE_AUTO_UPDATE });

export const toggleAutoUpdate = () => (dispatch, getState) => {
  const { album: { options: { autoUpdateEnabled } } } = getState();

  if (!autoUpdateEnabled) {
    // using setTimeout to defer this action and let the toggle animation finish
    setTimeout(() => {
      dispatch(fetchSnapshots());
      dispatch(fetchSnapshotStats());
    });
  }

  dispatch(toggleAutoUpdateOnly());
};
