import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Button } from 'react-bootstrap';

import { closeNewRoleModal, addRole } from '../actions';

const NewRoleModal = ({ isOpen, closeModalAction, addRoleAction }) => {
  const [roleName, setRoleName] = useState('');

  useEffect(() => {
    if (!isOpen) {
      setRoleName('');
    }
  }, [isOpen]);

  const submitHandler = (evt) => {
    evt.preventDefault();
    addRoleAction(roleName);
  };

  return (
    <Modal show={isOpen} onHide={closeModalAction} size="sm" centered>
      <Form onSubmit={submitHandler}>
        <Modal.Header className="border-0 justify-content-center" closeButton>
          <h3>Add New Role</h3>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="col-sm-12 d-inline-block mb-0">
            <Form.Control
              type="text"
              name="start"
              placeholder="Role name"
              value={roleName}
              onChange={({ currentTarget: { value } }) => setRoleName(value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              Time is Not Valid
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button className="small" onClick={closeModalAction} variant="outline-primary">
            Cancel
          </Button>
          <Button className="small" type="submit">Add</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default connect(
  ({ roles: { deleteModalOpen } }) => ({ isOpen: deleteModalOpen }),
  {
    closeModalAction: closeNewRoleModal,
    addRoleAction: addRole,
  },
)(NewRoleModal);
