import React from 'react';
import * as Recharts from 'recharts';
import ChartTooptip from '../ChartTooltip';
import ChartLabel from '../ChartLabel';

const HorizontalChart = ({
  data,
  yAxisDatakey,
  xAxisDatakey,
  barColor,
}) => (
  <Recharts.ResponsiveContainer height={350} width="100%">
    <Recharts.ComposedChart data={data} margin={{ top: 0, left: 0, right: 50 }} layout="vertical">
      <Recharts.XAxis
        type="number"
        padding={{ left: 250 }}
        hide
      />
      <Recharts.YAxis
        axisLine={false}
        dataKey={yAxisDatakey}
        mirror
        tick={{
          fontSize: '12px',
          fill: '#B7B8BA',
          fontWeight: 500,
          fontFamily: 'Roboto',
        }}
        width={225}
        type="category"
        padding={{ left: 0, right: 250 }}
        tickLine={false}
      />
      <Recharts.Tooltip
        content={<ChartTooptip isPercentage />}
        labelStyle={{ color: '#283238', fontSize: '14px', lineHeight: '27px' }}
        cursor={null}
      />
      <Recharts.Bar
        dataKey={xAxisDatakey}
        fill={barColor}
        layout="vertical"
        radius={[10, 10, 10, 10]}
        barSize={25}
      >
        <Recharts.LabelList dataKey="percentage" position="right" content={ChartLabel} />
      </Recharts.Bar>
    </Recharts.ComposedChart>
  </Recharts.ResponsiveContainer>
);


export default HorizontalChart;
