import React from 'react';
import {
  ResponsiveContainer,
  BarChart as RechartsBarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  LabelList,
} from 'recharts';

import ChartTooltip from '../ChartTooltip';

const renderCustomizedLabel = ({
  x,
  y,
  width,
  value,
  isPercentage,
}) => (
  <text
    x={x + width / 2}
    y={y - 10}
    fill="var(--theme-gray)"
    textAnchor="middle"
    dominantBaseline="middle"
  >
    {isPercentage ? `${value.toFixed(2)}%` : value}
  </text>
);

const BarChart = ({
  xAxisKey,
  barKey,
  labelKey,
  data,
  barColor,
  hideYAxis,
  renderLabel,
  isPercentage,
}) => (
  <ResponsiveContainer height={350} width="100%">
    <RechartsBarChart
      data={data}
      margin={{ top: 50 }}
      isAnimationActive={false}
    >
      <XAxis
        dataKey={xAxisKey}
        tickLine={false}
        dy={10}
        tick={{
          fontSize: '12px',
          color: '#B7B8BA',
          marginRight: '10px',
          lineHeight: '27px',
          fontWeigth: '500',
        }}
      />
      <YAxis
        hide={hideYAxis}
        dy={-5}
        dx={-5}
        tick={{
          fontSize: '12px',
          color: '#B7B8BA',
          marginRight: '10px',
        }}
      />
      <Tooltip
        content={<ChartTooltip isPercentage={isPercentage} />}
        labelStyle={{ color: '#283238', fontSize: '14px', lineHeight: '27px' }}
        cursor={{ fill: 'white' }}
      />
      <Bar dataKey={barKey} fill={barColor} radius={[10, 10, 0, 0]} barSize={50}>
        {renderLabel && <LabelList dataKey={labelKey} position="top" content={renderCustomizedLabel} isPercentage={isPercentage} />}
      </Bar>
    </RechartsBarChart>
  </ResponsiveContainer>
);

export default BarChart;
