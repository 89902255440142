import React, { memo } from 'react';

import Snapshot from '../../../components/Snapshot';
import Overlay from './Overlay';

const OverlaidSnapshot = memo(({ snapshot, onClick }) => (
  <Snapshot snapshot={snapshot} onClick={onClick} sidebar>
    <Overlay overlayKey={snapshot.overlay} />
  </Snapshot>
));

export default OverlaidSnapshot;
