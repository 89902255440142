import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import {
  Modal,
  Button,
  Form,
  Alert,
} from 'react-bootstrap';

import { closeEmailModal } from '../actions';
import { sendEmail } from './api';
import { displaySuccessModal } from '../../../../helpers/swal';

const handleInputChange = (stateUpdateFn) => (evt) => stateUpdateFn(evt.currentTarget.value);

const EmailModal = ({ isOpen, selectedSnapshots, closeModalAction }) => {
  const [validated, setValidated] = useState();
  const [errorSendingEmail, setErrorSendingEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const formRef = useRef();

  const onSaveHandler = async () => {
    try {
      setErrorSendingEmail(false);
      setIsLoading(true);

      if (formRef.current.checkValidity() === true) {
        const snapshotUrls = selectedSnapshots.map(({ sharedURI }) => sharedURI);
        await sendEmail(email, subject, message, snapshotUrls);
        closeModalAction();
        displaySuccessModal({ text: 'Email is sent.', timer: 1500 });
      }
    } catch (err) {
      console.error(err);
      setErrorSendingEmail(true);
    } finally {
      setValidated(true);
      setIsLoading(false);
    }
  };

  return (
    <Modal show={isOpen} onHide={closeModalAction} centered>
      <Modal.Header closeButton>
        <h3 className="text-center w-100">Share Snapshots</h3>
      </Modal.Header>
      <Modal.Body>
        {errorSendingEmail && <Alert variant="danger">Error sending email. Please try again.</Alert>}

        <Form ref={formRef} noValidate validated={validated}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              required
              type="email"
              value={email}
              onChange={handleInputChange(setEmail)}
            />
            <Form.Control.Feedback type="invalid">
              {formRef.current && formRef.current[0].validationMessage}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formBasicSubject">
            <Form.Label>Subject</Form.Label>
            <Form.Control
              required
              type="text"
              value={subject}
              onChange={handleInputChange(setSubject)}
            />
            <Form.Control.Feedback type="invalid">
              {formRef.current && formRef.current[1].validationMessage}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-0">
            <Form.Label>Message</Form.Label>
            <Form.Control
              required
              as="textarea"
              rows="3"
              value={message}
              onChange={handleInputChange(setMessage)}
            />
            <Form.Control.Feedback type="invalid">
              {formRef.current && formRef.current[2].validationMessage}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="small" variant="outline-primary" onClick={closeModalAction}>
          Close
        </Button>
        <LaddaButton
          loading={isLoading}
          data-style={ZOOM_OUT}
          onClick={onSaveHandler}
          className="btn btn-primary small"
        >
          Send
        </LaddaButton>
      </Modal.Footer>
    </Modal>
  );
};

export default connect(
  ({
    album: { modals: { email: { isOpen } } },
    snapshots: { items: snapshots },
  }) => ({
    isOpen,
    selectedSnapshots: snapshots.filter((snap) => snap.selected),
  }),
  { closeModalAction: closeEmailModal },
)(EmailModal);
