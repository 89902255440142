import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Select from 'react-select';
import { Button } from 'react-bootstrap';
import { faCircleChevronLeft, faCircleChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { isUndefined } from 'lodash';

import { customSelectStylesOptions, seatOptions, delay } from '../../helper';
import { restoreClonedValues, updatePath, updatePaths } from '../../actions';
import camMap from '../../mapLogic';
import CornerPosition from '../CornerControls';

import style from './style.module.scss';

const CrossCheckCorners = () => {
  const [disable, setDisable] = useState(false);
  const waitTime = 1000;
  const checkCoreners = async () => {
    setDisable(true);
    document.getElementById('topLeft-goToPosition').click();
    await delay(waitTime);
    document.getElementById('bottomRight-goToPosition').click();
    await delay(waitTime);
    document.getElementById('topRight-goToPosition').click();
    await delay(waitTime);
    document.getElementById('bottomLeft-goToPosition').click();
    await delay(waitTime);
    document.getElementById('topLeft-goToPosition').click();
    setDisable(false);
  };

  return (
    <Button
      variant="outline-primary"
      className="small"
      disabled={disable}
      onClick={checkCoreners}
      title="Cross check all corners"
    >
      Cross
    </Button>
  );
};

const CheckTab = () => {
  const dispatch = useDispatch();
  const update = useCallback((...args) => dispatch(updatePath(...args)), []);
  const type = useSelector(({ mapping: { check } }) => check.type);
  const isSelectDisabled = useSelector(({ mapping: { check: { disableSelect: v } } }) => v);
  const loadingStatus = useSelector(({ mapping: { loadingStatus: status } }) => status);
  const [regionIndex, setRegionIndex] = useState();
  const [deleteBtnVariant, setDeleteBtnVariant] = useState('outline-danger');
  const regionList = useMemo(() => camMap.populateCheckTabDropDownList(), [loadingStatus]);

  useEffect(() => {
    const cornerLabels = ['topLeft', 'topRight', 'bottomLeft', 'bottomRight'];

    // if the regionList is empty, set the regionIndex to undefined
    // note that the value for the select component is set to null
    // when the regionList is empty
    if (regionList.length === 0) {
      console.warn('the regionList is empty');
      setRegionIndex();
      return;
    }
    // if the regionIndex is greater than the length of the regionList
    // set the regionIndex to the last item in the new list
    if (regionIndex >= regionList.length) {
      console.error('*** new regionIndex', regionList.length - 1);
      setRegionIndex(regionList.length - 1);
      return;
    }

    if (!isUndefined(regionIndex) && regionIndex >= 0) {
      const [selectedType, ...rest] = regionList[regionIndex].value.split(',')[0].split(' ');
      const selectedName = rest.join(' '); // names might have spaces, therefore we combine index 1 to n
      const region = camMap.getRegion(regionIndex, selectedName, selectedType);
      update('check.selectedRegion', { index: regionIndex, value: regionList[regionIndex].value });

      const updateObject = { 'check.type': selectedType.toLowerCase() };
      updateObject['check.sectionName'] = selectedName.trim();

      for (let i = 0; i < region.length && i < cornerLabels.length; i++) {
        const corner = region[i];
        const label = cornerLabels[i];
        updateObject[`check.${label}.pan`] = corner.pan;
        updateObject[`check.${label}.tilt`] = corner.tilt;
        updateObject[`check.${label}.row`] = corner.row;
        updateObject[`check.${label}.seat`] = corner.seat;
      }
      updateObject['preview.zoom.value'] = (region[4].zoom_factor * 1000);
      updateObject['preview.angle.value'] = region[4].angle;
      updateObject['check.clonedValues'] = { ...updateObject };
      dispatch(updatePaths(updateObject));
    } else {
      // clear the corner field if the regionList is empty
      const updateObject = {};
      console.warn('region list is empty or regionIndex is undefined', regionList, regionIndex);
      for (let i = 0; i < cornerLabels.length; i++) {
        const label = cornerLabels[i];
        updateObject[`check.${label}.pan`] = '';
        updateObject[`check.${label}.tilt`] = '';
        updateObject[`check.${label}.row`] = '';
        updateObject[`check.${label}.seat`] = '';
      }
      dispatch(updatePaths(updateObject));
    }
  }, [regionIndex, regionList.length]);

  const deleteBtnHandler = async () => {
    if (isUndefined(regionIndex) || regionIndex < 0
        || isUndefined(regionList) || regionList.length === 0) {
      console.warn('deleteBtnHandler regionIndex is undefined or regionList is empty', regionIndex, regionList);
      return;
    }

    // note that the deleteRegion would also call:
    // - uploadMappingFile() to update the map file on the server
    // - loadMapConfig() to pull the new map file from the server
    // loadMapConfig() would automatically call populateCheckTabDropDownList
    // then the logic is handled by the useEffects for regionList.length, regionIndex
    const [selectedType, ...rest] = regionList[regionIndex].value.split(',')[0].split(' ');
    const selectedName = rest.join(' '); // names might have spaces, therefore we combine index 1 to n
    const result = await camMap.deleteRegion(regionIndex, selectedName, selectedType);

    setDeleteBtnVariant(result ? 'success' : 'danger');
    setTimeout(() => {
      setDeleteBtnVariant('outline-danger');
    }, 1000);
  };

  return (
    <div className={style.checkTab}>
      <div className={style.listSelectorContainer}>
        <button
          type="button"
          disabled={isUndefined(regionIndex) || regionIndex === 0 || isSelectDisabled}
          onClick={() => {
            setRegionIndex((prev) => prev - 1);
          }}
        >
          <FontAwesomeIcon icon={faCircleChevronLeft} />
        </button>
        <Select
          placeholder="Section"
          options={regionList}
          isDisabled={isSelectDisabled}
          value={regionList.length ? regionList[regionIndex] : null}
          styles={customSelectStylesOptions}
          onChange={({ index }) => {
            setRegionIndex(index);
          }}
        />
        <button
          type="button"
          disabled={
            isUndefined(regionIndex) || regionIndex === (regionList.length - 1) || isSelectDisabled
          }
          onClick={() => {
            setRegionIndex((prev) => prev + 1);
          }}
        >
          <FontAwesomeIcon icon={faCircleChevronRight} />
        </button>
      </div>
      <div className={style.selectRow}>
        <span>
          Type
        </span>
        <Select
          placeholder="Section"
          isDisabled
          value={seatOptions.find(({ value }) => value === type)}
          options={seatOptions}
          styles={customSelectStylesOptions}
          onChange={({ value }) => update('check.type', value)}
        />
      </div>
      <div className={style.deleteControls}>
        <CrossCheckCorners />
        <Button
          variant="outline-primary"
          className="small"
          onClick={() => dispatch(restoreClonedValues())}
        >
          Discard
        </Button>
        <Button
          variant={deleteBtnVariant}
          onClick={deleteBtnHandler}
          className="small"
        >
          Delete
        </Button>
      </div>
      <div className={style.cornersWrapper}>
        <CornerPosition name="Top Left" path="check.topLeft" />
        <CornerPosition name="Top Right" path="check.topRight" />
      </div>
      <div className={style.cornersWrapper}>
        <CornerPosition name="Bottom Left" path="check.bottomLeft" />
        <CornerPosition name="Bottom Right" path="check.bottomRight" />
      </div>
    </div>
  );
};

export default CheckTab;
