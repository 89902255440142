import { buildURL } from '../../../helpers/urls';
import {
  post,
  get,
  handleResponse,
  callDelete,
} from '../../../helpers/fetch';

export const reloadConfig = () => {
  const url = buildURL(`events/${process.env.BRIZI_APP_SLUG}/configs/reload`);
  return post(url);
};

export const uploadFile = async (file) => {
  const url = buildURL('events/configs/camAndRowDict');
  const body = new FormData();

  body.append('file', file);

  const response = await fetch(url, {
    body,
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    credentials: 'include',
  });

  return handleResponse(response);
};

export const fetchConfigFilesList = () => {
  const url = buildURL('events/configs/camAndRowDict');
  return get(url);
};

export const deleteConfigFiles = (name) => {
  const url = buildURL('events/configs/camAndRowDict');
  return callDelete(url, { name });
};
