import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown } from '@fortawesome/pro-regular-svg-icons';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import HoverDropdown from '../../../vendor/libs/hover-dropdown';
import { removeOrigin } from '../../../helpers/urls';

const Download = ({ overlayed, raw, show }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="actionButtonContainer">
      <HoverDropdown
        onClick={(evt) => evt.stopPropagation()}
        as={DropdownButton}
        variant="default"
        title={<FontAwesomeIcon icon={faArrowCircleDown} />}
        className="dropdown-toggle-hide-arrow"
      >
        <Dropdown.Item href={removeOrigin(raw)} download>Raw</Dropdown.Item>
        <Dropdown.Divider />
        {overlayed && (
          <Dropdown.Item href={removeOrigin(overlayed)} download>
            Overlayed
          </Dropdown.Item>
        )}
      </HoverDropdown>
    </div>
  );
};

export default Download;
