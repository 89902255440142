import { SET_FILTER_FILED, RESET_FILTER_FORM, SET_ALBUM_FILTER } from './types';

export const setFilterField = (field) => (value) => ({
  type: SET_FILTER_FILED,
  payload: { field, value },
});

export const resetFilterForm = () => ({ type: RESET_FILTER_FORM });

export const setFilter = () => ({ type: SET_ALBUM_FILTER });
