import React from 'react';
import { useSelector } from 'react-redux';

import GAChartWrapper from '../../ChartComponents/GAChartWrapper';
import Info from '../../../../components/Info';

import { GA_CHARTS_RESULTS } from '../consts';
import { capitalize } from '../../utils';

const InterestChart = () => {
  const data = useSelector(({ analytics }) => analytics.ga.data.gaUserInterest || []);
  const total = data.reduce((prev, curr) => (prev + curr.y), 0);

  const chartData = data.slice(0, GA_CHARTS_RESULTS).map((item) => {
    const percentage = (item.y / total) * 100;
    return {
      name: capitalize(item.x),
      total: percentage,
      percentage,
    };
  });

  return (
    <GAChartWrapper
      chartType="horizontal"
      cardTitle={(
        <Info
          label="Interests"
          content="Users by interests based on Google Analytics data"
          className="ml-2"
        />
      )}
      chartData={chartData}
      fallbackTitle="Interests"
    />
  );
};

export default InterestChart;
