import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Form } from 'react-bootstrap';

import { setFilterField } from './actions';
import { strToReactSelectItem } from './helpers';
import { dividerMargin } from './constants';

import Divider from '../../../components/Divider';
import style from './style.module.scss';

const SRSBlock = (props) => {
  const {
    seatTypes,
    availableSections,
    availableRows,
    avalilableSeats,
    availableBoxes,
    availableSuites,
    seatType,
    sections,
    rows,
    seats,
    boxes,
    suites,
    setFilterSeatTypeAction,
    setFilterSectionsAction,
    setFilterRowsAction,
    setFilterSeatsAction,
    setFilterBoxesAction,
    setFilterSuitesAction,
  } = props;

  const getFields = () => {
    if (seatType.value === 'box') {
      return (
        <div className={style.customMultiValueInput}>
          <Form.Label className={style.FormLabel}>Box</Form.Label>
          <Select
            isMulti
            key="boxes"
            value={boxes}
            options={availableBoxes.map(strToReactSelectItem)}
            classNamePrefix="react-select"
            onChange={setFilterBoxesAction}
          />
        </div>
      );
    }

    if (seatType.value === 'suite') {
      return (
        <div className={style.customMultiValueInput}>
          <Form.Label className={style.FormLabel}>Suite</Form.Label>
          <Select
            isMulti
            key="suites"
            value={suites}
            options={availableSuites.map(strToReactSelectItem)}
            classNamePrefix="react-select"
            onChange={setFilterSuitesAction}
          />
        </div>
      );
    }

    // both section and custom_stand
    return (
      <div className={style.customMultiValueInput}>
        <Form.Label className={style.FormLabel}>Section</Form.Label>
        <Select
          isMulti
          key="sections"
          value={sections}
          options={availableSections.map(strToReactSelectItem)}
          classNamePrefix="react-select"
          onChange={setFilterSectionsAction}
        />

        <Divider className={style.divider} margin={dividerMargin} />

        <Form.Label className={style.FormLabel}>Row</Form.Label>
        <Select
          isMulti
          key="rows"
          value={rows}
          options={availableRows.map(strToReactSelectItem)}
          classNamePrefix="react-select"
          onChange={setFilterRowsAction}
        />

        <Divider className={style.divider} margin={dividerMargin} />

        <Form.Label className={style.FormLabel}>Seat</Form.Label>
        <Select
          isMulti
          key="seat"
          value={seats}
          options={avalilableSeats.map(strToReactSelectItem)}
          classNamePrefix="react-select"
          onChange={setFilterSeatsAction}
        />
      </div>
    );
  };

  return (
    <div className={style.customSingleValueInput}>
      <Form.Label className={style.FormLabel}>Seat Type</Form.Label>
      <Select
        value={seatType}
        options={seatTypes.map(strToReactSelectItem)}
        classNamePrefix="react-select"
        onChange={setFilterSeatTypeAction}
      />

      <Divider className={style.divider} margin={dividerMargin} />

      {getFields()}
    </div>
  );
};

export default connect(
  ({
    album: {
      filterForm: {
        formOptions: {
          seatTypes,
          sections: availableSections,
          rows: availableRows,
          seats: avalilableSeats,
          boxes: availableBoxes,
          suites: availableSuites,
        },
        tempData: {
          seatType,
          sections,
          rows,
          seats,
          boxes,
          suites,
        },
      },
    },
  }) => ({
    seatTypes,
    availableSections,
    availableRows,
    avalilableSeats,
    availableBoxes,
    availableSuites,
    seatType,
    sections,
    rows,
    seats,
    boxes,
    suites,
  }),
  {
    setFilterSeatTypeAction: setFilterField('seatType'),
    setFilterSectionsAction: setFilterField('sections'),
    setFilterRowsAction: setFilterField('rows'),
    setFilterSeatsAction: setFilterField('seats'),
    setFilterBoxesAction: setFilterField('boxes'),
    setFilterSuitesAction: setFilterField('suites'),
  },
)(SRSBlock);
