export const FETCH_TRIVIA_QUESTIONS = 'FETCH_TRIVIA_QUESTIONS';
export const SET_TRIVIA_LANGUAGE = 'SET_TRIVIA_LANGUAGE';
export const UPDATE_TRIVIA_QUESTION = 'UPDATE_TRIVIA_QUESTION';
export const DELETE_TRIVIA_QUESTIONS = 'DELETE_TRIVIA_QUESTIONS';
export const ADD_NEW_TRIVIA_QUESTION = 'ADD_NEW_TRIVIA_QUESTION';
export const TOGGLE_TRIVIA_QUESTION = 'TOGGLE_TRIVIA_QUESTION';
export const SAVE_TRIVIA_QUESTIONS = 'SAVE_TRIVIA_QUESTIONS';
export const OPEN_NEW_QUESTION_MODAL = 'OPEN_NEW_QUESTION_MODAL';
export const CLOSE_NEW_QUESTION_MODAL = 'CLOSE_NEW_QUESTION_MODAL';
export const SET_TRIVIA_QUESTIONS = 'SET_TRIVIA_QUESTIONS';
