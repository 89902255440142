import React from 'react';
import { connect } from 'react-redux';

import useForm from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import {
  Modal,
  Button,
  Form,
} from 'react-bootstrap';

import { addLanguage, closeAddLanguageModal } from '../actions';

const defaultValues = {
  language: '',
};

const AddLanguageModal = ({
  availableLanguages,
  isLanguageModalOpen,
  addLanguageAction,
  closeAddLanguageModalAction,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    setError,
    clearError,
    getValues,
    reset,
  } = useForm({ defaultValues });

  const handleClose = () => {
    closeAddLanguageModalAction();
    reset(defaultValues);
  };

  const handleSubmitFn = ({ language }) => {
    addLanguageAction(language.trim());
    handleClose();
  };

  const verifyDuplicated = (evt) => {
    const { value } = evt.target;
    const isDuplicated = availableLanguages.some((language) => language === value.trim());

    if (!isDuplicated) {
      return clearError('language');
    }

    return setError('language', 'duplicated', 'This value already exists, please, try a new one');
  };

  const canSubmit = !errors.language && getValues().language;

  return (
    <Modal
      show={isLanguageModalOpen}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Language</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(handleSubmitFn)}>
          <Form.Group md="2" controlId="language">
            <Form.Label>Language</Form.Label>
            <RHFInput
              name="language"
              as={(
                <Form.Control
                  as="input"
                  type="text"
                  isInvalid={errors.language}
                />
              )}
              register={register({ required: true })}
              setValue={setValue}
              onChange={(evt) => verifyDuplicated(evt)}
            />
            {errors.language && <Form.Control.Feedback type="invalid">{errors.language.message}</Form.Control.Feedback>}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="small" variant="outline-primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="small" variant="primary" type="submit" onClick={handleSubmit(handleSubmitFn)} disabled={!canSubmit}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default connect(({
  languages: { availableLanguages, isLanguageModalOpen },
}) => ({
  availableLanguages,
  isLanguageModalOpen,
}), {
  closeAddLanguageModalAction: closeAddLanguageModal,
  addLanguageAction: addLanguage,
})(AddLanguageModal);
