import { SET_SHIFT_KEY_STATUS } from './type';

const initialState = {
  shiftKey: false,
};

export default (state = initialState, { type, payload = {} } = {}) => {
  switch (type) {
  case SET_SHIFT_KEY_STATUS: {
    return {
      ...state,
      shiftKey: payload,
    };
  }

  default: {
    return state;
  }
  }
};
