import React, { useState, useEffect } from 'react';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';

import { passwordReset } from '../api';
import { displayErrorModal } from '../../../helpers/swal';
import style from '../../Login/style.module.scss';

const ChangePassword = ({ token }) => {
  const [isSuccess, setisSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (error) {
      displayErrorModal({ text: error });
      setError(false);
    }
  }, [error]);

  const resetPassword = async () => {
    try {
      setLoading(true);
      const response = await passwordReset({ token });
      if (response.error) {
        setError(response.error);
      } else {
        setisSuccess(true);
      }
    } catch (e) {
      setError('Something went wrong, please try again');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  if (isSuccess) {
    return (
      <div>
        <div className={style.topText}>
          <h4><b>New password has been sent</b></h4>
          <p>please check your email</p>
        </div>
        <a href="/login">
          <LaddaButton className="btn btn-primary">
            Back to Login
          </LaddaButton>
        </a>
      </div>
    );
  }

  return (
    <div>
      <div className={style.topText}>
        <h4><b>Confirm password reset</b></h4>
        <p className="mb-0">An email with your new credentials will be sent to you</p>
      </div>
      <LaddaButton
        loading={loading}
        onClick={resetPassword}
        className="btn btn-primary"
        data-style={ZOOM_OUT}
      >
        Reset
      </LaddaButton>
    </div>
  );
};

export default ChangePassword;
