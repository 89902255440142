import {
  ADD_LANGUAGE,
  DELETE_LANGUAGE,
  SET_LANGUAGES,
  SET_DEFAULT_LANGUAGE,
  SET_AVAILABLE_LANGUAGES,
  OPEN_LANGUAGE_MODAL,
  CLOSE_LANGUAGE_MODAL,
} from './types';

export const openAddLanguageModal = () => ({ type: OPEN_LANGUAGE_MODAL });
export const closeAddLanguageModal = () => ({ type: CLOSE_LANGUAGE_MODAL });

export const addLanguage = (payload) => ({
  type: ADD_LANGUAGE,
  payload,
});

export const deleteLanguage = (payload) => ({
  type: DELETE_LANGUAGE,
  payload,
});

export const setLanguages = (payload) => ({
  type: SET_LANGUAGES,
  payload,
});

export const setDefaultLanguage = (payload) => ({
  type: SET_DEFAULT_LANGUAGE,
  payload,
});

export const setAvailableLanguages = (payload) => ({
  type: SET_AVAILABLE_LANGUAGES,
  payload,
});
