import {
  FETCH_ROLES,
  ADD_ROLE,
  SET_SELECTED_ROLE,
  SAVE_ROLE,
  SAVE_ROLE_DONE,
  ADD_PERMISSION,
  REMOVE_PERMISSION,
  DELETE_ROLE,
  DELETE_ROLE_DONE,
  OPEN_NEW_ROLE_MODAL,
  CLOSE_NEW_ROLE_MODAL,
} from './types';
import { getRoles, saveRole as saveRoleApi, deleteRole as deleteRoleApi } from './api';
import { displayErrorModal, ReactSwal } from '../../../helpers/swal';

export const requestRoles = () => ({ type: FETCH_ROLES });

export const receiveRoles = (error, data) => ({
  type: FETCH_ROLES,
  payload: { error, data },
});

export const fetchRoles = () => (dispatch) => {
  dispatch(requestRoles());

  getRoles().then((roles) => {
    dispatch(receiveRoles(undefined, roles));
  }).catch((err) => {
    console.error(err);
    dispatch(receiveRoles(err));
  });
};

export const openNewRoleModal = () => ({ type: OPEN_NEW_ROLE_MODAL });
export const closeNewRoleModal = () => ({ type: CLOSE_NEW_ROLE_MODAL });

const addRoleNoValidation = (name) => ({
  type: ADD_ROLE,
  payload: { name },
});

export const addRole = (name) => (dispatch, getState) => {
  const { roles: { roles } } = getState();
  const exists = roles.some((role) => role.name === name);

  if (exists) {
    displayErrorModal({
      title: 'Role already exists',
      text: 'Role name must be unique. A role with this name already exists',
    });
  } else {
    dispatch(addRoleNoValidation(name));
    dispatch(closeNewRoleModal());
  }
};

export const selectRole = (role) => ({
  type: SET_SELECTED_ROLE,
  payload: { role },
});

const fetchSaveRole = () => ({ type: SAVE_ROLE });
const roleSaved = (role) => ({
  type: SAVE_ROLE_DONE,
  payload: { role },
});

export const saveRole = (role) => (dispatch) => {
  const { name, permissions } = role;

  dispatch(fetchSaveRole());

  saveRoleApi(name, permissions)
    .then(() => dispatch(roleSaved(role)))
    .catch((err) => {
      console.error(err);
      displayErrorModal();
      dispatch(roleSaved());
    });
};

export const addPermission = (permissionName) => ({
  type: ADD_PERMISSION,
  payload: { permissionName },
});

export const removePermission = (permissionName) => ({
  type: REMOVE_PERMISSION,
  payload: { permissionName },
});

const fetchDeleteRole = () => ({ type: DELETE_ROLE });
const roleDeleted = (role) => ({
  type: DELETE_ROLE_DONE,
  payload: { role },
});

export const deleteRole = (role) => async (dispatch) => {
  const { name } = role;

  const { value } = await ReactSwal.fire({
    showCancelButton: true,
    showConfirmButton: true,
    confirmButtonText: 'Confirm',
    type: 'warning',
    title: 'Delete role',
    text: 'Are you sure you would like to delete this role?',
    customClass: {
      container: 'sweetAlertCustomContainer deleteRoleConfirm',
      confirmButton: 'btn btn-danger btn-lg mx-2',
      cancelButton: 'btn btn-default btn-lg mx-2',
      actions: 'text-center',
    },
  });

  if (value) {
    dispatch(fetchDeleteRole());

    try {
      await deleteRoleApi(name);
      dispatch(roleDeleted(role));
    } catch (err) {
      console.error(err);
      displayErrorModal();
      dispatch(roleDeleted());
    }
  }
};
