import React from 'react';
import { connect } from 'react-redux';
import Nouislider from 'nouislider-react';
import { Form } from 'react-bootstrap';

import '../../../vendor/libs/nouislider-react/nouislider-react.scss';

import style from './style.module.scss';

import { setFilterField } from './actions';

const GroupSizeField = (props) => {
  const {
    groupSizeMin = 0,
    groupSizeMax = 40,
    setGroupSizeMinAction,
    setGroupSizeMaxAction,
  } = props;

  const onChange = (...args) => {
    const [min, max] = args[2];
    setGroupSizeMinAction(Math.round(min));
    setGroupSizeMaxAction(Math.round(max));
  };

  const min = 0;
  const max = 40;

  return (
    <>
      <Form.Label className={style.FormLabel}>Group Size</Form.Label>
      <Nouislider
        connect
        step={1}
        range={{ min, max }}
        start={[groupSizeMin, groupSizeMax]}
        className={style.sliderInput}
        onChange={onChange}
      />
      <div className={style.rangeInputs}>
        <input
          type="number"
          min={min}
          max={max}
          value={groupSizeMin}
          onChange={({ target }) => {
            setGroupSizeMinAction(target.value);
            setGroupSizeMaxAction(groupSizeMax);
          }}
        />
        <span> - </span>
        <input
          type="number"
          min={min}
          max={max}
          value={groupSizeMax}
          onChange={({ target }) => {
            setGroupSizeMaxAction(target.value);
            setGroupSizeMinAction(groupSizeMin);
          }}
        />
      </div>
    </>
  );
};

export default connect(
  ({
    album: {
      filterForm: {
        tempData: {
          groupSizeMin,
          groupSizeMax,
        },
      },
    },
  }) => ({
    groupSizeMin,
    groupSizeMax,
  }),
  {
    setGroupSizeMinAction: setFilterField('groupSizeMin'),
    setGroupSizeMaxAction: setFilterField('groupSizeMax'),
  },
)(GroupSizeField);
