import React, {
  forwardRef,
  useState,
  useEffect,
  useContext,
} from 'react';
import { connect } from 'react-redux';
import { SocketContext } from 'react-socket-io';
import {
  Dropdown,
  ToggleButton,
  ToggleButtonGroup,
  Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faCircle } from '@fortawesome/pro-solid-svg-icons';
import { faCalendarDay, faClock, faCamera } from '@fortawesome/pro-regular-svg-icons';

import RoundButton from '../../../../components/RoundButton';
import CamRow from './CamRow';
import DropdownItem from './DropdownItem';

import { convertGameDate, convertTime } from '../../../../helpers/events';
import { eventStatus as eventStatuses } from '../../../../helpers/constants';
import { displayErrorModal } from '../../../../helpers/swal';
import { setEventStatus } from './actions';
import camOffConfirmation from './camOffConfirmation';

import style from './style.module.scss';

const EventControls = (props) => {
  const {
    title,
    timezone,
    eventStatus,
    camerasStatus,
    gameDate: gameDateUtc,
    startTime: startTimeUtc,
    endTime: endTimeUtc,
    demoMode,
    setEventStatusAction,
  } = props;

  const [allCamLoading, setAllCamLoading] = useState();
  const socketContext = useContext(SocketContext);
  const allCamsOn = Object.values(camerasStatus).every((status) => status === 'on');
  const cameraKeys = Object.keys(camerasStatus);

  useEffect(() => {
    if (allCamLoading === true) {
      setAllCamLoading(false);
    }
  }, [allCamsOn]);

  useEffect(() => {
    if (!allCamLoading) {
      return undefined;
    }

    const timeout = setTimeout(() => {
      displayErrorModal({
        timer: null,
        title: `Error turning ${allCamsOn ? 'off' : 'on'} cameras`,
        text: 'We did not recieve camera status change updates from the server.'
          + ' Please try again, or try powering the cameras manually.',
      });
      setAllCamLoading(false);
    }, 120 * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [allCamLoading]);

  if (!title || !timezone || !gameDateUtc) {
    return null;
  }

  const gameDate = convertGameDate(gameDateUtc, timezone);
  const startTime = convertTime(startTimeUtc, timezone, 'hh:mma');
  const endTime = convertTime(endTimeUtc, timezone, 'hh:mma');

  const toggleButton = forwardRef(({ onClick }, ref) => (
    <RoundButton ref={ref} onClick={onClick} className={style.onOffBtn}>
      <FontAwesomeIcon icon={faPowerOff} />
    </RoundButton>
  ));

  const toggleCameras = () => {
    const update = (power) => {
      socketContext.emit('switch power campool', power);
      setAllCamLoading(true);
    };

    if (!allCamsOn) {
      update(true);
    } else if (eventStatus === eventStatuses.LIVE) {
      camOffConfirmation(() => update(false));
    } else {
      update(false);
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle variant="default" title="On/Off" as={toggleButton} />
      <Dropdown.Menu alignRight className={style.dropdownContainer}>
        <Dropdown.Item as={DropdownItem}>
          <span><FontAwesomeIcon icon={faCircle} className={style[eventStatus]} /></span>
          <span>{title.toUpperCase()}</span>
        </Dropdown.Item>
        <Dropdown.Item as={DropdownItem}>
          <span><FontAwesomeIcon icon={faClock} className={style[eventStatus]} /></span>
          <span>{`${startTime} - ${endTime}`}</span>
        </Dropdown.Item>
        <Dropdown.Item as={DropdownItem}>
          <span><FontAwesomeIcon icon={faCalendarDay} className={style[eventStatus]} /></span>
          <span>{gameDate}</span>
        </Dropdown.Item>
        <Dropdown.Item as={DropdownItem} className="text-center">
          <div className="text-center">
            <div className={style.eventControl}>
              <ToggleButtonGroup type="radio" name="eventStatus" value={eventStatus} onChange={setEventStatusAction}>
                <ToggleButton className="pre" size="sm" value="pre">Pre</ToggleButton>
                <ToggleButton className="live" size="sm" value="live">Live</ToggleButton>
                <ToggleButton className="post" size="sm" value="post">Post</ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as={DropdownItem}>
          <div className={style.cameraItem}>
            <div>
              {allCamLoading && <Spinner as="span" animation="border" variant="primary" size="sm" />}
              {!allCamLoading && <FontAwesomeIcon icon={faCamera} />}
              <span>All cameras</span>
            </div>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="switcher">
              <input
                type="checkbox"
                checked={allCamsOn}
                className="switcher-input"
                onChange={toggleCameras}
                disabled={allCamLoading || demoMode === 'on'}
              />
              <span className="switcher-indicator">
                <span className="switcher-yes" />
                <span className="switcher-no" />
              </span>
            </label>
          </div>
        </Dropdown.Item>
        <Dropdown.Divider />
        {cameraKeys.map((camName) => (
          <CamRow
            key={camName}
            camName={camName}
            status={camerasStatus[camName]}
            disabled={allCamLoading || demoMode === 'on'}
            isLiveEvent={eventStatus === eventStatuses.LIVE}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default connect(
  ({
    events: {
      currentEvent: {
        _id,
        title,
        eventStatus,
        timezone,
        gameDate,
        startTime,
        endTime,
      } = {},
    },
    cameras: {
      status: camerasStatus,
      demoMode,
    },
  }) => ({
    eventId: _id,
    title,
    eventStatus,
    gameDate,
    timezone,
    startTime,
    endTime,
    camerasStatus,
    demoMode,
  }),
  { setEventStatusAction: setEventStatus },
)(EventControls);
