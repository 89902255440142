import { ADD_SUSPICIOUS_TOAST, REMOVE_SUSPICIOUS_TOAST } from './types';

export const addToast = (data) => ({
  type: ADD_SUSPICIOUS_TOAST,
  payload: { data },
});

export const removeToast = (id) => ({
  type: REMOVE_SUSPICIOUS_TOAST,
  payload: { id },
});
