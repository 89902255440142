import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import classnames from 'classnames';
import { range } from 'lodash';

import EventSelector from '../../../components/EventSelector/Modal';
import EventSelectorButton from '../../../components/EventSelector/Button';
import UpdateButton from '../UpdateButton';
import SectionBtn from './SectionBtn';
import ImageEditor from './ImageEditor';

import { setImages, save } from './actions';
import { useUpdateEvent } from '../hooks';

import style from './style.module.scss';

const Graphics = ({
  event,
  sectionsNum,
  setImagesAction,
  saveAction,
}) => {
  const isEventUdating = useUpdateEvent();

  useEffect(() => {
    if (event && !isEventUdating) {
      const { venueType, images: eventImages } = event;
      setImagesAction({ ...eventImages, venueType });
    }
  }, [event, isEventUdating]);

  return (
    <div className={style.container}>
      <div className="pageHeader">
        <h4>Graphics</h4>
        <EventSelectorButton showFutureEvents />
        <UpdateButton label="Update Event" updateHandler={saveAction} />
      </div>

      {isEventUdating && (
        <div className="flex-grow-1 d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!isEventUdating && (
        <div className={classnames('d-flex', 'flex-grow-1', style.mainContainer)}>
          <div className={classnames('d-flex', 'flex-column', style.sidebar)}>
            {range(0, sectionsNum).map((index) => <SectionBtn key={index} index={index} />)}
          </div>

          <ImageEditor />
        </div>
      )}

      <EventSelector singleSelection showTemplate showFutureEvents />
    </div>
  );
};

export default connect(
  ({
    events: { selectedEvents },
    graphics: { sections },
  }) => ({
    sectionsNum: sections.length,
    event: selectedEvents[0],
  }),
  {
    setImagesAction: setImages,
    saveAction: save,
  },
)(Graphics);
