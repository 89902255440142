import { sortBy } from 'lodash';
import { FETCH_CONFIGS, FETCH_CAMERA } from './types';

const initialState = {
  isFetching: false,
  cameras: [],
  error: null,
};

export default (state = initialState, { type, payload = {} } = {}) => {
  switch (type) {
  case FETCH_CAMERA: {
    const { error, camera } = payload;
    let newCameras = null;

    if (camera) {
      const { cameraKey: newCameraKey } = camera;
      const filteredCameras = state.cameras.filter(({ cameraKey }) => cameraKey !== newCameraKey);
      newCameras = sortBy([...filteredCameras, camera], 'cameraKey');
    }

    return {
      ...state,
      isFetching: !error && !camera,
      cameras: newCameras || state.cameras,
      error,
    };
  }
  case FETCH_CONFIGS: {
    const { error, cameras } = payload;

    return {
      ...state,
      isFetching: !error && !cameras,
      cameras: cameras || state.cameras,
      error,
    };
  }
  default: {
    return state;
  }
  }
};
