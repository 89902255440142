import React from 'react';
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import Info from '../../../components/Info';

import { setFilterField } from './actions';

import style from './style.module.scss';

const UserIDsField = ({ userIDs, setUserIDsAction }) => (
  <div className={classNames(style.hideOptions, style.customMultiValueInput)}>
    <Form.Label className={style.FormLabel}>
      User ID
      <Info content="Each user has a unique User ID that can be found on their photo. Enter User IDs here separated by a comma to isolate their photos in the album" className="ml-2" />
    </Form.Label>
    <CreatableSelect
      isMulti
      value={userIDs}
      classNamePrefix="react-select"
      onChange={setUserIDsAction}
    />
  </div>
);

export default connect(
  ({ album: { filterForm: { tempData: { userIDs } } } }) => ({ userIDs }),
  { setUserIDsAction: setFilterField('userIDs') },
)(UserIDsField);
