// we will need this later to map strings to permissions when we fetch them from server
const allPermissions = new Map();

export class Permission {
  constructor(name, description, parents = []) {
    this.name = name;
    this.parents = parents;
    this.description = description;
    this.children = [];

    if (allPermissions.has(this.name)) {
      throw new Error('Permission with the same name already exists');
    }

    parents.forEach((parent) => {
      parent.addChild(this);
    });

    allPermissions.set(this.name, this);
  }

  addChild(child) {
    this.children.push(child);
  }
}

export const getPermissionKeys = () => Array.from(allPermissions.keys());

export const getPermissionByKey = (key) => allPermissions.get(key);

export const getTopLevelPermissions = () => Array.from(allPermissions.values())
  .filter((item) => item.parents.length === 0);
