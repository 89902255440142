import classNames from 'classnames';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/pro-regular-svg-icons';
import { faHeart as solidHeart } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { connect } from 'react-redux';
import { toggleFavorite } from '../actions';

const Favorite = ({
  snapshotId,
  favorite,
  toggleFavoriteAction,
  show,
}) => {
  if (!show) {
    return null;
  }
  const favoriteBtnHandler = (evt) => {
    evt.stopPropagation();
    toggleFavoriteAction({ _id: snapshotId, favorite });
  };

  return (
    <div className={classNames('actionButtonContainer', { 'd-flex': favorite })}>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>Favorite</Tooltip>}
      >
        <Button variant="default" onClick={favoriteBtnHandler}>
          <FontAwesomeIcon icon={favorite ? solidHeart : faHeart} />
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default connect(null, { toggleFavoriteAction: toggleFavorite })(Favorite);
