import React from 'react';
import { useSelector } from 'react-redux';

import style from './style.module.scss';

import Slider from './Slider';

const StepSpeedControls = () => {
  const minPan = useSelector(({ mapping: { preview: { stepSpeed: { pan: { min } } } } }) => min);
  const maxPan = useSelector(({ mapping: { preview: { stepSpeed: { pan: { max } } } } }) => max);
  const pan = useSelector(({ mapping: { preview: { stepSpeed: { pan: { value } } } } }) => value);

  const minTilt = useSelector(({ mapping: { preview: { stepSpeed: { tilt: { min } } } } }) => min);
  const maxTilt = useSelector(({ mapping: { preview: { stepSpeed: { tilt: { max } } } } }) => max);
  const tilt = useSelector(({ mapping: { preview: { stepSpeed: { tilt: { value } } } } }) => value);

  return (
    <div className={style.speedContainer}>
      <span>Step Size</span>
      <div className={style.wrapper}>
        <div>
          <span>Pan</span>
          <div className={style.rangeWrapper}>
            <Slider
              path="preview.stepSpeed.pan.value"
              min={minPan}
              max={maxPan}
              val={pan}
            />
          </div>
        </div>
        <div>
          <span>Tilt</span>
          <div className={style.rangeWrapper}>
            <Slider
              path="preview.stepSpeed.tilt.value"
              min={minTilt}
              max={maxTilt}
              val={tilt}
            />
          </div>
        </div>
      </div>
    </div>
  );
};


export default StepSpeedControls;
