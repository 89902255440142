import React from 'react';
import { connect } from 'react-redux';

import Bar from './Bar';
import EmailModal from './EmailModal';

const MultiSnapshotControls = ({ snapshotsSelected }) => {
  if (!snapshotsSelected) {
    return null;
  }

  return (
    <>
      <Bar />
      <EmailModal />
    </>
  );
};

export default connect(
  ({ snapshots: { items: snapshots } }) => ({
    snapshotsSelected: snapshots.some(({ selected }) => selected),
  }),
)(MultiSnapshotControls);
