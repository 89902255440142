import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import classnames from 'classnames';

import { dismissNotification } from '../../actions';

import style from './style.module.scss';

const CloseBtn = ({
  className,
  disabled,
  onClick,
  dismissNotificationAction,
}) => {
  const clickHandler = () => {
    dismissNotificationAction();
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      type="button"
      disabled={disabled}
      className={classnames(style.btn, className)}
      onClick={clickHandler}
    >
      <FontAwesomeIcon icon={faTimes} />
    </button>
  );
};

export default connect(
  null,
  (dispatch, { notificationId }) => ({
    dismissNotificationAction: () => dispatch(dismissNotification(notificationId)),
  }),
)(CloseBtn);
