import { isEmpty } from 'lodash';

import {
  FETCH_BASIC_DATA,
  FETCH_GA_DATA,
  FETCH_BRIZI_VISION_DATA,
  FETCH_AVERAGE_DATA,
} from './types';

const initialState = {
  basic: {
    isFetching: false,
    data: [],
  },
  briziVision: {
    isFetching: false,
    data: [],
  },
  ga: {
    isFetching: false,
    data: [],
  },
  average: {
    isFetching: false,
    data: {},
  },
};

const checkForValues = (data) => !isEmpty(data)
  && !Object.values(data).every((item) => isEmpty(item));

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
  case FETCH_BASIC_DATA: {
    const { error, data } = payload;

    const hasValues = checkForValues(data);

    return {
      ...state,
      basic: {
        data: data || initialState.basic.data,
        isFetching: !error && !data,
        error,
        hasValues,
      },
    };
  }
  case FETCH_GA_DATA: {
    const { error, data } = payload;

    const hasValues = checkForValues(data);

    return {
      ...state,
      ga: {
        data: data || initialState.ga.data,
        isFetching: !error && !data,
        error,
        hasValues,
      },
    };
  }
  case FETCH_BRIZI_VISION_DATA: {
    const { error, data } = payload;

    const hasValues = checkForValues(data);

    return {
      ...state,
      briziVision: {
        data: data || initialState.briziVision.data,
        isFetching: !error && !data,
        error,
        hasValues,
      },
    };
  }
  case FETCH_AVERAGE_DATA: {
    const { error, data } = payload;

    return {
      ...state,
      average: {
        data: data || initialState.briziVision.data,
        isFetching: !error && !data,
        error,
      },
    };
  }
  default: {
    return state;
  }
  }
};
