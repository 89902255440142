import { buildURL } from '../../helpers/urls';
import { get } from '../../helpers/fetch';

// eslint-disable-next-line import/prefer-default-export
export const getDistinctSharePlatforms = (slugDates) => {
  const searchParams = new URLSearchParams();
  slugDates.forEach((slugDate) => searchParams.append('slugDate[]', slugDate));
  const url = buildURL('/snapshots/sharedPlatforms', `?${searchParams}`);
  return get(url);
};
