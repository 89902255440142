import { ADD_SUSPICIOUS_TOAST, REMOVE_SUSPICIOUS_TOAST } from './types';

const initialState = {
  items: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case ADD_SUSPICIOUS_TOAST: {
    const { data } = payload;
    const { _id } = data;

    if (state.items.some((item) => item._id === _id)) {
      return state;
    }

    return {
      ...state,
      items: [data, ...state.items],
    };
  }

  case REMOVE_SUSPICIOUS_TOAST: {
    const { id } = payload;
    return {
      ...state,
      items: state.items.filter(({ _id }) => _id !== id),
    };
  }

  default: {
    return state;
  }
  }
};
