import React from 'react';
import classNames from 'classnames';

import style from './style.module.scss';

const shareIcons = Object.freeze({
  print: 'fas fa-print',
  email: 'far fa-envelope',
  share: 'fas fa-share-alt',
  download: 'fas fa-download',
});

const ShareNotification = ({ snapshot, platform, user = {} }) => {
  const generateMessageIcon = () => {
    const platformLowerCase = platform.toLowerCase();

    return (<i className={shareIcons[platformLowerCase]} />);
  };

  const generateMessageBody = () => {
    let userInfo = snapshot.userId;

    if (platform.toLowerCase() === 'email') {
      userInfo = user.userEmail;
    }

    return (
      <span>
        {`${userInfo} shared using ${platform}`}
      </span>
    );
  };

  return (
    <div className={style.ShareNotificationContainer}>
      <div className={classNames(style.IconContainer, style[platform.toLowerCase()])}>
        {generateMessageIcon()}
      </div>
      <div className={style.MessageContainer}>
        {generateMessageBody()}
      </div>
    </div>
  );
};

export default ShareNotification;
