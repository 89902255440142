import { TOGGLE_EXPORT_FIELD, TOGGLE_EXPORT_EVENT_FIELD } from './types';

export const toggleField = (field) => ({
  type: TOGGLE_EXPORT_FIELD,
  payload: { field },
});

export const toggleEventField = (field) => ({
  type: TOGGLE_EXPORT_EVENT_FIELD,
  payload: { field },
});
