import { batch } from 'react-redux';

import {
  FETCH_USER_LIST,
  SET_USER_LIST_PAGINATION_DATA,
  TOGGLE_EVENT_USER_ROW,
  DELETE_EVENT_USER_ROW,
  BAN_USER_LIST_ITEMS,
  SET_EVENT_USER_FILTER,
  CLEAR_EVENT_USER_FILTER,
  SET_EVENT_USER_SORT,
  SET_EVENT_USER_SHARE_PLATFORMS,
} from './types';
import {
  listSnapshots,
  getAvailableSnapshots,
  deleteSnapshots,
  banSnapshots,
} from '../../helpers/api';
import { getDistinctSharePlatforms } from './api';
import { displayErrorModal, ReactSwal } from '../../helpers/swal';

const requestUserList = (isUpdate) => ({
  type: FETCH_USER_LIST,
  payload: { isUpdate },
});

const recieveUserList = (error, data, totalRows) => ({
  type: FETCH_USER_LIST,
  payload: { error, data, totalRows },
});

export const fetchUserList = (isUpdate) => async (dispatch, getState) => {
  const {
    events: { selectedEvents },
    eventUsers: {
      page,
      pageSize,
      filters,
      sort,
    },
  } = getState();
  const slugDates = selectedEvents.map(({ slugDate }) => slugDate);

  dispatch(requestUserList(isUpdate));

  try {
    const [list, { all: total }] = await Promise.all([
      listSnapshots(slugDates, undefined, page, pageSize, filters, sort),
      getAvailableSnapshots(slugDates, filters),
    ]);

    const mappedList = list.map((row) => {
      const sharePlatforms = new Set();
      const contactInfo = new Set();

      row.postObj.forEach(({ socialSource, userEmail }) => {
        sharePlatforms.add(socialSource);
        if (userEmail) {
          contactInfo.add(userEmail);
        }
      });

      return {
        ...row,
        postObj: undefined,
        sharedPlatforms: [...sharePlatforms],
        contactInfo: [...contactInfo],
      };
    });

    dispatch(recieveUserList(undefined, mappedList, total));
  } catch (err) {
    console.error(err);
    displayErrorModal();
    dispatch(recieveUserList(err));
  }
};

export const setPageAndSize = (page, pageSize) => ({
  type: SET_USER_LIST_PAGINATION_DATA,
  payload: { page, pageSize },
});

export const toggleSelection = ({ _id: snapshotId }, isSelect) => ({
  type: TOGGLE_EVENT_USER_ROW,
  payload: { snapshotId, isSelect },
});

export const toggleSelectionAll = (isSelect) => ({
  type: TOGGLE_EVENT_USER_ROW,
  payload: { all: true, isSelect },
});

const requestDelete = () => ({ type: DELETE_EVENT_USER_ROW });
const receiveDelete = (error, snapshotIds) => ({
  type: DELETE_EVENT_USER_ROW,
  payload: { error, snapshotIds },
});

export const deleteItems = () => async (dispatch, getState) => {
  const { eventUsers: { selected } } = getState();

  try {
    const result = await ReactSwal.fire({
      title: `Are you sure you want to delete ${selected.length > 1 ? 'these photos' : 'this photo'}?`,
      text: 'You will not be able to recover this snapshot!',
      type: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      customClass: {
        confirmButton: 'btn btn-danger btn-lg',
        cancelButton: 'btn btn-default btn-lg',
        actions: 'text-center',
      },
    });

    if (result && result.value) {
      dispatch(requestDelete());
      await deleteSnapshots(selected);
      batch(() => {
        dispatch(receiveDelete(undefined, selected));
        dispatch(fetchUserList(true));
      });
    }
  } catch (err) {
    console.error(err);
    displayErrorModal();
    dispatch(receiveDelete(err));
  }
};

const requestBan = () => ({ type: BAN_USER_LIST_ITEMS });
const receiveBan = (error, snapshotIds) => ({
  type: BAN_USER_LIST_ITEMS,
  payload: { error, snapshotIds },
});

export const banItems = (banStatus) => async (dispatch, getState) => {
  const { eventUsers: { selected } } = getState();

  try {
    const result = await ReactSwal.fire({
      title: 'Are you sure?',
      text: 'Banning a photo will change the URL of that photo and remove it from social media',
      type: 'warning',
      confirmButtonText: banStatus,
      showConfirmButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-danger btn-lg',
        cancelButton: 'btn btn-default btn-lg',
        actions: 'text-center',
      },
    });

    if (result && result.value) {
      dispatch(requestBan());
      await banSnapshots(selected);
      dispatch(receiveBan(undefined, selected));
    }
  } catch (err) {
    console.error(err);
    displayErrorModal();
    dispatch(receiveBan(err));
  }
};

export const setFilters = ({
  section,
  row,
  seat,
  hidden,
  contactInfo,
  sharedPlatforms,
  userId,
  date,
}) => {
  const filters = {};

  // note that the field is singular, but our listSnapshots in helpers/api.js expects plural
  if (section) {
    filters.sections = section.filterVal.map((value) => ({ value }));
  }
  if (row) {
    filters.rows = row.filterVal.map((value) => ({ value }));
  }
  if (seat) {
    filters.seats = seat.filterVal.map((value) => ({ value }));
  }
  if (hidden && hidden.filterVal !== undefined) {
    filters.hidden = hidden.filterVal === 'Yes';
  }
  if (contactInfo) {
    filters.contactInfo = contactInfo.filterVal;
  }
  if (sharedPlatforms) {
    filters.sharedPlatforms = sharedPlatforms.filterVal;
  }
  if (userId) {
    filters.userIdPart = userId.filterVal;
  }
  if (date) {
    filters.date = date.filterVal;
  }

  return {
    type: SET_EVENT_USER_FILTER,
    payload: { filters },
  };
};

export const setSort = ({ sortField, sortOrder }) => ({
  type: SET_EVENT_USER_SORT,
  payload: { sortField, sortOrder },
});

export const clearFilters = () => ({ type: CLEAR_EVENT_USER_FILTER });

const setSharePlatforms = (sharePlatforms) => ({
  type: SET_EVENT_USER_SHARE_PLATFORMS,
  payload: { sharePlatforms },
});

export const fetchDistinctSharePlatforms = () => async (dispatch, getState) => {
  const { events: { selectedEvents } } = getState();
  const slugDates = selectedEvents.map(({ slugDate }) => slugDate);

  try {
    const sharePlatforms = await getDistinctSharePlatforms(slugDates);
    dispatch(setSharePlatforms(sharePlatforms));
  } catch (err) {
    displayErrorModal({ text: 'Error fetching share platforms' });
  }
};
