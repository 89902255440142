import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';

import EventSelector from '../../../components/EventSelector/Modal';
import EventSelectorButton from '../../../components/EventSelector/Button';
import AddLanguageModal from './AddLanguageModal';
import LanguagesTable from './LanguagesTable';
import UpdateButton from '../UpdateButton';
import {
  setLanguages,
  deleteLanguage,
  setAvailableLanguages,
  setDefaultLanguage,
} from './actions';
import { updateLanguage } from './apis';
import { useUpdateEvent } from '../hooks';

const Languages = ({
  event,
  languages,
  availableLanguages,
  defaultLanguage,
  setLanguagesAction,
  setDefaultLanguageAction,
  setAvailableLanguagesAction,
}) => {
  const isEventUpdating = useUpdateEvent();

  const updateEventHandler = (eventIds) => {
    const data = {
      eventIds,
      languages,
      availableLanguages,
      defaultLanguage,
    };

    return updateLanguage(data);
  };

  useEffect(() => {
    if (event && !isEventUpdating) {
      const {
        languages: eventLanguages,
        availableLanguages: eventAvailableLanguages,
        defaultLanguage: eventDefaultLanguage,
      } = event;

      if (eventLanguages && eventAvailableLanguages && eventDefaultLanguage) {
        setAvailableLanguagesAction(eventAvailableLanguages);
        setDefaultLanguageAction(eventDefaultLanguage);
        setLanguagesAction(eventLanguages);
      }
    }
  }, [event, isEventUpdating]);


  return (
    <div className="d-flex flex-column flex-grow-1">
      <div className="pageHeader">
        <h4>Languages</h4>
        <EventSelectorButton showFutureEvents />
        <UpdateButton serverErrorMessage label="Update Event" updateHandler={updateEventHandler} />
      </div>

      {isEventUpdating && (
        <div className="flex-grow-1 d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!isEventUpdating && <LanguagesTable />}

      <EventSelector singleSelection showTemplate showFutureEvents />
      <AddLanguageModal />
    </div>
  );
};

export default connect(
  ({
    events: { selectedEvents },
    languages: { languages, availableLanguages, defaultLanguage },
  }) => ({
    event: selectedEvents[0],
    languages,
    availableLanguages,
    defaultLanguage,
  }),
  {
    setLanguagesAction: setLanguages,
    setDefaultLanguageAction: setDefaultLanguage,
    setAvailableLanguagesAction: setAvailableLanguages,
    deleteLanguageAction: deleteLanguage,
  },
)(Languages);
