import React from 'react';
import classnames from 'classnames';
import { deleteSnapshots } from '../../../../helpers/api';
import { ReactSwal, displayErrorModal, displaySuccessModal } from '../../../../helpers/swal';

// eslint-disable-next-line import/prefer-default-export
export const openDeleteConfirmationAlert = async (snapshots) => {
  const deleteAction = async () => {
    try {
      await deleteSnapshots(snapshots.map(({ _id }) => _id));
      displaySuccessModal({ text: 'Snapshot is deleted', timer: 1500 });
    } catch (err) {
      console.error(err);
      displayErrorModal();
    }
  };

  try {
    const html = (
      <div>
        <h3>Delete Photo</h3>
        <p>
          Are you sure you would like to delete selected photos?
          you wil not be able to undo this change.
        </p>
        <div className={classnames('imageContainer', { multiple: snapshots.length > 1 })}>
          <img src={snapshots[0].thumbURI} alt="" />
          {snapshots[1] && <img src={snapshots[1].thumbURI} alt="" />}
          {snapshots[2] && <img src={snapshots[2].thumbURI} alt="" />}
        </div>
      </div>
    );

    const result = await ReactSwal.fire({
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Confirm',
      html,
      customClass: {
        container: 'deletePhotoConfirmation',
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-default',
      },
    });

    if (result && result.value) {
      deleteAction();
    }
  } catch (err) {
    console.error(err);
  }
};
