import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';

import { removeOrigin } from '../../../../helpers/urls';
import * as exiftool from './exiftool';

import style from './style.module.scss';

const displayMessage = (text) => <tr key="error" className="text-center"><td>{text}</td></tr>;

const Exif = ({ url, isPanelOpen }) => {
  const [fetchedUrl, setFetchedUrl] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [imageSize, setImageSize] = useState();
  const [list, setList] = useState([]);

  const getImageData = async () => {
    try {
      setLoading(true);
      setFetchedUrl(url);
      const response = await fetch(removeOrigin(url));
      const buffer = Buffer.from(await response.arrayBuffer());
      exiftool.getExifFromNodeBuffer(buffer, (_err, exifInfo) => {
        setData({
          FileSize: `${Math.fround(Buffer.byteLength(buffer) / (1000)).toString().slice(0, 6)} kB`,
          ImageSize: imageSize,
          ...exifInfo,
        });
      });
    } catch (e) {
      console.error(e);
      setList([displayMessage('Sorry Something Went Wrong')]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isPanelOpen && fetchedUrl !== url) {
      getImageData();
    }
  }, [url, isPanelOpen]);

  useEffect(() => {
    const img = document.getElementById('lightboxImage');
    const onLoad = () => setImageSize(`${img.naturalWidth} X ${img.naturalHeight}`);

    if (img) {
      if (img.complete && isPanelOpen) {
        onLoad();
      } else if (isPanelOpen) {
        img.addEventListener('load', onLoad);
      } else {
        img.removeEventListener('load', onLoad);
      }
    }

    return () => {
      if (img) {
        img.removeEventListener('load', onLoad);
      }
    };
  }, [fetchedUrl, isPanelOpen]);

  useEffect(() => {
    if (data) {
      const tmp = Object.entries({ ...data, ImageSize: imageSize }).map(([key, value]) => (
        <tr key={key}>
          <td>{key}</td>
          <td>{key === 'ExposureTime' ? `1/${1 / value} sec` : value}</td>
        </tr>
      ));
      setList(tmp.length ? tmp : [displayMessage('No data to display')]);
      setLoading(false);
    }
  }, [data, imageSize]);

  if (loading) {
    return (
      <div className="text-center">
        <Spinner as="span" animation="border" role="status" aria-hidden="true" />
      </div>
    );
  }

  return (
    <table className={style.exif}>
      <tbody>
        {list}
      </tbody>
    </table>
  );
};

export default connect(
  ({
    lightbox: {
      info: {
        exifData: {
          open,
        },
      },
      snapshot: {
        data: { rawCroppedURI },
      },
    },
  }) => ({
    isPanelOpen: open,
    url: rawCroppedURI,
  }),
)(Exif);
