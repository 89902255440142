import React from 'react';
import { Card } from 'react-bootstrap';

import style from './style.module.scss';

const ChartCard = ({
  children,
  title,
  header = null,
}) => (
  <Card className={style.ChartCard}>
    <Card.Body>
      <div className={style.HeaderContainer}>
        <span className={style.ChartTitle}>
          {title}
        </span>
        {header}
      </div>
      {children}
    </Card.Body>
  </Card>
);

export default ChartCard;
