import React from 'react';
import { connect } from 'react-redux';
import Nouislider from 'nouislider-react';
import { Form } from 'react-bootstrap';

import '../../../vendor/libs/nouislider-react/nouislider-react.scss';
import style from './style.module.scss';

import { setFilterField } from './actions';

const AgeRangeField = (props) => {
  const {
    ageRangeMin = 1,
    ageRangeMax = 90,
    setAgeRangeMinAction,
    setAgeRangeMaxAction,
  } = props;

  const onChange = (...args) => {
    const [min, max] = args[2];
    setAgeRangeMinAction(Math.round(min));
    setAgeRangeMaxAction(Math.round(max));
  };

  const min = 1;
  const max = 90;

  return (
    <>
      <Form.Label className={style.FormLabel}>Age Range</Form.Label>
      <Nouislider
        connect
        step={1}
        range={{ min, max }}
        start={[ageRangeMin, ageRangeMax]}
        className={style.sliderInput}
        onChange={onChange}
      />
      <div className={style.rangeInputs}>
        <input
          type="number"
          min={min}
          max={max}
          value={ageRangeMin}
          onChange={({ target }) => setAgeRangeMinAction(target.value)}
        />
        <span> - </span>
        <input
          type="number"
          min={min}
          max={max}
          value={ageRangeMax}
          onChange={({ target }) => setAgeRangeMaxAction(target.value)}
        />
      </div>
    </>
  );
};

export default connect(
  ({
    album: {
      filterForm: {
        tempData: {
          ageRangeMin,
          ageRangeMax,
        },
      },
    },
  }) => ({
    ageRangeMin,
    ageRangeMax,
  }),
  {
    setAgeRangeMinAction: setFilterField('ageRangeMin'),
    setAgeRangeMaxAction: setFilterField('ageRangeMax'),
  },
)(AgeRangeField);
