import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Spinner } from 'react-bootstrap';
import classnames from 'classnames';

import SeatOption from './SeatOption';
import EventSelector from '../../../components/EventSelector/Modal';
import EventSelectorButton from '../../../components/EventSelector/Button';
import UpdateButton from '../UpdateButton';
import BlockedSeat from './BlockedSeat';

import { updateSeatType } from './apis';
import { setSeatOptions, changeOrder } from './actions';
import { useUpdateEvent } from '../hooks';

import style from './style.module.scss';

const SeatOptions = ({
  event = { seatOptions: {} },
  seatOptions,
  blockedSeats,
  setSeatOptionsAction,
  changeOrderAction,
}) => {
  const isEventUpdating = useUpdateEvent();

  useEffect(() => {
    if (event.seatOptions && !isEventUpdating) {
      setSeatOptionsAction(event.seatOptions);
    }
  }, [event.seatOptions, isEventUpdating]);

  const updateEventHandler = (eventIds) => {
    const data = { eventIds, seatType: seatOptions, blockedSeats };
    return updateSeatType(data);
  };

  const onDragEnd = ({ source, destination }) => {
    if (destination) {
      changeOrderAction(source.index, destination.index);
    }
  };

  return (
    <div className={classnames('d-flex', 'flex-column', 'flex-grow-1', style.SeatOptionsPage)}>
      <div className="pageHeader">
        <h4>Seat Options</h4>
        <EventSelectorButton showFutureEvents />
        <UpdateButton label="Update Event" updateHandler={updateEventHandler} />
        <EventSelector singleSelection showTemplate showFutureEvents />
      </div>

      {isEventUpdating && (
        <div className="flex-grow-1 d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!isEventUpdating && (
        <div>
          <div className={style.SeatOptionsWrapper}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="simple-horizontal-droppable" direction="horizontal">
                {(droppableProvided) => (
                  <div ref={droppableProvided.innerRef} className="d-flex w-100">
                    {Object.entries(seatOptions).map(([key, seat], index) => (
                      <Draggable key={key} draggableId={key} index={index}>
                        {(draggableProvided) => (
                          <div
                            className={style.seatOptionContainer}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                          >
                            <SeatOption type={key} seat={seat} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <BlockedSeat />
        </div>
      )}
    </div>
  );
};

export default connect(
  ({
    events: { selectedEvents },
    blockedSeats: { blockedSeats },
    seatOptions,
  }) => ({
    event: selectedEvents[0],
    blockedSeats,
    seatOptions,
  }),
  {
    changeOrderAction: changeOrder,
    setSeatOptionsAction: setSeatOptions,
  },
)(SeatOptions);
