import { FETCH_LIVE_METRICS, OPEN_LIVE_METRICS, CLOSE_LIVE_METRICS } from './types';

const initialState = {
  isCollapsed: true,
  isFetching: false,
  data: {},
};

export default (state = initialState, { type, payload = {} } = {}) => {
  switch (type) {
  case OPEN_LIVE_METRICS: {
    return {
      ...state,
      isCollapsed: false,
    };
  }

  case CLOSE_LIVE_METRICS: {
    return {
      ...state,
      isCollapsed: true,
    };
  }

  case FETCH_LIVE_METRICS: {
    const { data, error } = payload;

    return {
      ...state,
      isFetching: !error && !data,
      error,
      data: {
        ...state.data,
        ...(data || {}),
      },
    };
  }

  default: {
    return state;
  }
  }
};
