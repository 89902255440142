import {
  FETCH_EVENTS,
  SET_SELECTED_EVENTS,
  SET_CURRENT_EVENT,
  SET_CURRENT_EVENT_STATUS,
  RESET_SELECTED_EVENTS,
  UPDATE_EVENT,
} from '../types/events';

const initialState = {
  allEvents: [],
  currentEvent: undefined,
  selectedEvents: [],
  selectedEventsPage: null,
  timezone: process.env.EVENT_TIMEZONE,
  slug: process.env.BRIZI_APP_SLUG,
  eventForm: {
    selectedSlots: [],
    fromDate: null,
    toDate: null,
    eventTitle: '',
    startTime: null,
    endTime: null,
    slugDate: null,
    formMode: 'create',
    isNextDay: false,
  },
  showEventModal: false,
  template: {},
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
  case FETCH_EVENTS: {
    const { error, data } = payload || {};

    return {
      ...state,
      isFetching: !error && !data,
      error,
      allEvents: data || [],
    };
  }
  case SET_CURRENT_EVENT: {
    const { data } = payload;
    const allEvents = state.allEvents.map((event) => {
      if (event._id === data._id) {
        const { eventStatus } = data;
        return { ...event, eventStatus };
      }

      return event;
    });

    return {
      ...state,
      allEvents,
      currentEvent: data,
    };
  }
  case SET_CURRENT_EVENT_STATUS: {
    const { eventStatus } = payload;

    return {
      ...state,
      currentEvent: {
        ...state.currentEvent,
        eventStatus,
      },
    };
  }
  case 'CLEAR_EVENT_FORM': {
    return {
      ...state,
      eventForm: initialState.eventForm,
      showEventModal: false,
    };
  }
  case 'SET_EVENT_FORM': {
    return {
      ...state,
      eventForm: { ...initialState.eventForm, ...payload },
      showEventModal: true,
    };
  }
  case 'ON_INPUT_CHANGE': {
    return {
      ...state,
      eventForm: { ...state.eventForm, ...payload },
    };
  }
  case 'SET_TIMEZONE': {
    return {
      ...state,
      timezone: payload,
    };
  }
  case 'SET_SLUG': {
    return {
      ...state,
      slug: payload,
    };
  }
  case 'SET_EVENT_TEMPLATE': {
    return {
      ...state,
      template: {
        ...state.template,
        ...payload,
      },
      timezone: payload.timezone || initialState.timezone,
    };
  }

  case SET_SELECTED_EVENTS: {
    const { eventsIds, selectedEventsPage } = payload;

    if (eventsIds.length === 1 && eventsIds[0] === state.template._id) {
      return {
        ...state,
        selectedEvents: [state.template],
        selectedEventsPage,
      };
    }

    return {
      ...state,
      selectedEvents: state.allEvents.filter(({ _id }) => eventsIds.includes(_id)),
      selectedEventsPage,
    };
  }

  case RESET_SELECTED_EVENTS: {
    const { selectedEventsPage } = payload;
    const currentEvent = state.currentEvent && state.allEvents
      .find(({ _id }) => _id === state.currentEvent._id);

    return {
      ...state,
      selectedEvents: currentEvent ? [currentEvent] : initialState.selectedEvents,
      selectedEventsPage,
    };
  }

  case UPDATE_EVENT: {
    const { event } = payload;
    const mapFn = (evt) => {
      if (evt.slugDate === event.slugDate) {
        return event;
      }
      return evt;
    };

    return {
      ...state,
      selectedEvents: state.selectedEvents.map(mapFn),
      allEvents: state.allEvents.map(mapFn),
    };
  }

  default: {
    return state;
  }
  }
}
