import { getPermissionKeys, getPermissionByKey } from '../../../components/Authorize/permissions';
import {
  FETCH_ROLES,
  ADD_ROLE,
  SET_SELECTED_ROLE,
  SAVE_ROLE,
  SAVE_ROLE_DONE,
  ADD_PERMISSION,
  REMOVE_PERMISSION,
  DELETE_ROLE,
  DELETE_ROLE_DONE,
  OPEN_NEW_ROLE_MODAL,
  CLOSE_NEW_ROLE_MODAL,
} from './types';

const initialState = {
  isFetching: false,
  isSaving: false,
  isDeleting: false,
  error: undefined,
  selectedRoleInd: 0,
  deleteModalOpen: false,
  roles: [{
    name: 'admin',
    readonly: true,
    saved: true,
    permissions: getPermissionKeys(),
  }],
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
  case FETCH_ROLES: {
    const { data: roles, error } = payload;

    return {
      ...state,
      selectedRoleInd: 0,
      isFetching: !roles && !error,
      error,
      roles: roles ? [
        ...initialState.roles,
        ...roles.map((role) => ({ ...role, saved: true })),
      ] : state.roles,
    };
  }

  case ADD_ROLE: {
    const { name } = payload;

    return {
      ...state,
      selectedRoleInd: state.roles.length,
      roles: [
        ...state.roles,
        { name, permissions: [] },
      ],
    };
  }

  case SET_SELECTED_ROLE: {
    const { role } = payload;

    return {
      ...state,
      selectedRoleInd: state.roles.indexOf(role),
    };
  }

  case SAVE_ROLE: {
    return {
      ...state,
      isSaving: true,
    };
  }

  case SAVE_ROLE_DONE: {
    const { role: savedRole } = payload;

    return {
      ...state,
      roles: state.roles.map((role) => (role === savedRole ? { ...role, saved: true } : role)),
      isSaving: false,
    };
  }

  case ADD_PERMISSION: {
    const { permissionName } = payload;
    const allPermissions = [permissionName];

    getPermissionByKey(permissionName).parents.forEach(({ name }) => {
      allPermissions.push(name);
    });

    return {
      ...state,
      roles: state.roles.map((role, ind) => {
        if (ind === state.selectedRoleInd) {
          return {
            ...role,
            saved: false,
            permissions: [
              ...role.permissions,
              ...allPermissions,
            ],
          };
        }

        return role;
      }),
    };
  }

  case REMOVE_PERMISSION: {
    const { permissionName } = payload;
    const allPermissions = [permissionName];

    getPermissionByKey(permissionName).children.forEach(({ name }) => {
      allPermissions.push(name);
    });

    return {
      ...state,
      roles: state.roles.map((role, ind) => {
        if (ind === state.selectedRoleInd) {
          return {
            ...role,
            saved: false,
            permissions: role.permissions.filter((p) => !allPermissions.includes(p)),
          };
        }

        return role;
      }),
    };
  }

  case DELETE_ROLE: {
    return {
      ...state,
      isDeleting: true,
    };
  }

  case DELETE_ROLE_DONE: {
    const { role: deletedRole } = payload;

    return {
      ...state,
      selectedRoleInd: 0,
      isDeleting: false,
      roles: state.roles.filter((role) => role !== deletedRole),
    };
  }

  case OPEN_NEW_ROLE_MODAL: {
    return { ...state, deleteModalOpen: true };
  }

  case CLOSE_NEW_ROLE_MODAL: {
    return { ...state, deleteModalOpen: false };
  }

  default: {
    return state;
  }
  }
};
