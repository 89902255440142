import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { resizeObserver } from '../../../helpers/browser';

const StreamImage = ({ src, panAndZoom: { scale = 1, posX = 0, posY = 0 } = {} }) => {
  const imgRef = useRef();
  const [{ tX = 0, tY = 0 } = {}, setTranslateXY] = useState();

  const update = () => {
    if (imgRef.current) {
      const imgSize = imgRef.current.clientWidth * (scale - 1);
      setTranslateXY({
        tX: -(posX - 0.5) * (imgSize / scale),
        tY: -(posY - 0.5) * (imgSize / scale),
      });
    }
  };

  useEffect(update, [scale, posX, posY]);
  useEffect(() => resizeObserver(imgRef.current, update), [imgRef.current, scale, posX, posY]);

  return src && (
    <img
      ref={imgRef}
      src={src}
      style={{ transform: `scale3d(${scale}, ${scale}, 1) translate3d(${tX}px, ${tY}px, 0)` }}
      alt="stream"
    />
  );
};

export default connect(
  ({ cameras: { panAndZoom } }, { camKey }) => ({ panAndZoom: panAndZoom[camKey] }),
)(StreamImage);
