import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { setLargeImageUrl } from '../actions';

import style from './style.module.scss';

const SharedList = ({ setLargeImageUrlAction, postObj }) => {
  if (isEmpty(postObj)) {
    return null;
  }

  const mapper = (item) => {
    const { socialSource, imgURL, date } = item;

    const shareItem = (icon, tooltip) => (
      <button type="button" disabled={!imgURL} onClick={() => setLargeImageUrlAction(imgURL)} key={date}>
        <OverlayTrigger placement="bottom" overlay={<Tooltip>{tooltip}</Tooltip>}>
          <i className={icon} />
        </OverlayTrigger>
      </button>
    );

    switch (socialSource) {
    case 'Download':
      return shareItem('fas fa-download', socialSource);
    case 'Email':
      return shareItem('fas fa-envelope', socialSource);
    case 'print':
      return shareItem('fas fa-print', 'Print');
    case 'share':
      return shareItem('fas fa-share-alt', 'Share');
    case 'share2':
      return shareItem('fas fa-share-alt', 'Share2');
    default:
      return null;
    }
  };

  return (
    <div className={style.sharedContainer}>
      { postObj.map(mapper) }
    </div>
  );
};

export default connect(null, {
  setLargeImageUrlAction: setLargeImageUrl,
})(SharedList);
