import React, {
  forwardRef,
  useState,
  useEffect,
  useReducer,
  useCallback,
} from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { Event } from 'react-socket-io';
import { useSelector } from 'react-redux';

import RoundButton from '../../../../../components/RoundButton';
import Notifications from '..';

import { displayErrorModal } from '../../../../../helpers/swal';
import { getNotificationsCount } from '../apis';
import { socketEventNames } from '../../../../../helpers/constants';

import style from './style.module.scss';

const { MONITOR_NOTIFICATION_ADDED, MONITOR_NOTIFICATION_DISMISSED } = socketEventNames;

const notificationCounterReducer = (state, { type, payload }) => {
  if (type === 'set') {
    return { count: payload };
  }
  if (type === 'increase') {
    return { count: (state.count || 0) + 1 };
  }
  if (type === 'decrease') {
    return { count: state.count - 1 };
  }
  throw new Error('Unknown action.');
};

const NotificationsToggleBtn = forwardRef(({ onClick }, ref) => {
  const [{ count }, dispatch] = useReducer(notificationCounterReducer, {});
  const { slugDate } = useSelector(({ events: { currentEvent } }) => currentEvent);

  useEffect(() => {
    const getCounter = async () => {
      try {
        const { total } = await getNotificationsCount({ slugDate });
        dispatch({ type: 'set', payload: total });
      } catch (err) {
        console.error(err);
        displayErrorModal();
      }
    };

    getCounter();
  }, []);

  const increaseCount = useCallback(({ type }) => {
    if (type === 'suspiciousUser') {
      dispatch({ type: 'increase' });
    }
  }, []);

  const decreaseCount = useCallback(({ type }) => {
    if (type === 'suspiciousUser') {
      dispatch({ type: 'decrease' });
    }
  }, []);

  return (
    <>
      <Event event={MONITOR_NOTIFICATION_ADDED} handler={increaseCount} />
      <Event event={MONITOR_NOTIFICATION_DISMISSED} handler={decreaseCount} />
      <RoundButton ref={ref} className={style.toggleBtn} onClick={onClick}>
        <FontAwesomeIcon size="lg" icon={faBell} className="align-middle" />
        {(count || null) && (
          <span className={style.counterBadge}>
            {count}
          </span>
        )}
      </RoundButton>
    </>
  );
});

const NotificationsButton = () => {
  const [show, setShow] = useState();

  const onToggle = (shouldShow) => {
    setShow(shouldShow);
  };

  return (
    <Dropdown show={show} onToggle={onToggle}>
      <Dropdown.Toggle as={NotificationsToggleBtn} />
      <Dropdown.Menu alignRight style={{ paddingRight: '1px', width: 'fit-content' }}>
        {show && <Notifications />}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationsButton;
