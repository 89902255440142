import { SET_CURRENT_EVENT_STATUS } from '../../../../store/types/events';
import { setEventStatus as setEventStatusAPI } from './api';
import { displayErrorModal } from '../../../../helpers/swal';

const setStatus = (eventStatus) => ({
  type: SET_CURRENT_EVENT_STATUS,
  payload: { eventStatus },
});

// eslint-disable-next-line import/prefer-default-export
export const setEventStatus = (status) => (dispatch, getState) => {
  const {
    events: {
      currentEvent: {
        slugDate,
        eventStatus: oldStatus,
      },
    },
  } = getState();

  if (status === oldStatus) {
    return;
  }

  dispatch(setStatus(status));

  setEventStatusAPI(slugDate, status).catch((err) => {
    console.error(err);
    dispatch(setStatus(oldStatus));
    displayErrorModal();
  });
};
