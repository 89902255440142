import { buildURL, urls } from '../../helpers/urls';
import { post } from '../../helpers/fetch';

export const resetPasswordEmail = (body) => {
  const url = buildURL(urls.users, urls.passwordReset.email);
  return post(url, body);
};

export const passwordReset = (body) => {
  const url = buildURL(urls.users, urls.passwordReset.reset);
  return post(url, body);
};
