import React, { useMemo } from 'react';
import classnames from 'classnames';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import { randomPassword } from '../../../helpers/utils';
import { buildURL } from '../../../helpers/urls';

import style from './style.module.scss';
import { confirmationModal } from '../../../helpers/swal';

const ExportButton = ({
  all,
  selectedEvents,
  currentEventStatus,
  selectedIds,
}) => {
  const password = useMemo(randomPassword, [selectedEvents]);

  const exportAllParams = useMemo(() => {
    const params = new URLSearchParams({ password });
    if (all) {
      selectedEvents.forEach(({ slugDate }) => {
        params.append('slugDate[]', slugDate);
      });
    } else {
      selectedIds.forEach((id) => {
        params.append('id[]', id);
      });
    }
    return params;
  }, [selectedEvents, selectedIds]);

  const onBtnClick = () => {
    confirmationModal(
      'info',
      'File password',
      null,
      <code>{password}</code>,
    );
  };

  return (
    <Button
      target="__blank"
      variant={all ? 'primary' : 'outline-primary'}
      className={classnames('small', { [style.morePadding]: all, 'ml-auto': all })}
      disabled={all ? currentEventStatus === 'live' : selectedIds.length === 0}
      href={buildURL('snapshots/export', `?${exportAllParams}`)}
      onClick={onBtnClick}
    >
      {all ? 'Export All' : 'Export'}
    </Button>
  );
};

export default connect(
  ({
    events: {
      selectedEvents,
      currentEvent: { eventStatus: currentEventStatus } = {},
    },
    eventUsers: {
      selected: selectedIds,
    },
  }) => ({
    selectedEvents,
    currentEventStatus,
    selectedIds,
  }),
)(ExportButton);
