import React from 'react';

import './style.scss';

const HandleBar = () => (
  <div className="innerBar">
    <div className="iconResize">
      <i className="fas fa-grip-lines-vertical" />
    </div>
  </div>
);

export default HandleBar;
