import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers';
import middlewares from './middlewares';

const store = createStore(rootReducer, applyMiddleware(...middlewares));

if (module.hot) {
  module.hot.accept('./reducers', () => {
    // eslint-disable-next-line global-require
    const { default: nextRootReducer } = require('./reducers');
    store.replaceReducer(nextRootReducer);
  });
}

export default store;
