import React from 'react';

import FallbackImage from '../../../../assets/images/upgradeImage.svg';

import style from './style.module.scss';

const PremiumFallback = () => (
  <div className={style.VisionFallbackContainer}>
    <div className={style.InfoContainer}>
      <h6>Upgrade to Premium</h6>
      <h2>Get Brizi&apos;s Vision</h2>
      <p>
        You&apos;ll get access to Brizi&apos;s top of the line analysis and understand
        your customers emotions and sentiments throughout an event.
      </p>
      <div className={style.ButtonsWrapper}>
        <a
          className="btn btn-primary "
          href="mailto:support@brizicam.com?subject=Requesting a demo"
          target="_blank"
          rel="noopener noreferrer"
        >
          Request a demo
        </a>
      </div>
    </div>
    <div className={style.ImageContainer}>
      <img src={FallbackImage} alt="Upgrade to premium" />
    </div>
  </div>
);


export default PremiumFallback;
