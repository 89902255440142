import React from 'react';
import { connect } from 'react-redux';
import Nouislider from 'nouislider-react';
import { Form } from 'react-bootstrap';

import '../../../vendor/libs/nouislider-react/nouislider-react.scss';
import style from './style.module.scss';

import { setFilterField } from './actions';
import { formatDateToUTC, getStartOfEpoch, getEndOfEpoch } from '../../../helpers/utils';

const startMs = getStartOfEpoch().getTime();
const endMs = getEndOfEpoch().getTime();

const TimeRangeField = (props) => {
  const {
    timeSince,
    timeUntil,
    settimeSinceAction,
    settimeUntilAction,
  } = props;

  const onChange = (...args) => {
    const [min, max] = args[2];
    settimeSinceAction(Math.round(min));
    settimeUntilAction(Math.round(max));
  };

  const getInputFieldChangeHandler = (actionFn) => (evt) => {
    const [hours, minutes] = evt.target.value.split(':');
    const date = getStartOfEpoch();

    date.setUTCHours(hours, minutes);
    actionFn(date.getTime());
  };

  // 1 minute step
  const sliderStep = 60;
  const sliderStepMs = sliderStep * 1000;

  return (
    <>
      <Form.Label className={style.FormLabel}>Time Range</Form.Label>
      <Nouislider
        connect
        step={sliderStepMs}
        range={{ min: startMs, max: endMs }}
        start={[timeSince, timeUntil]}
        className={style.sliderInput}
        onChange={onChange}
      />

      <form noValidate>
        <div className={style.rangeInputs}>
          <input
            type="time"
            step={sliderStep}
            value={formatDateToUTC(timeSince, 'HH:mm')}
            onChange={getInputFieldChangeHandler(settimeSinceAction)}
          />

          <span> - </span>

          <input
            type="time"
            step={sliderStep}
            value={formatDateToUTC(timeUntil, 'HH:mm')}
            onChange={getInputFieldChangeHandler(settimeUntilAction)}
          />
        </div>
      </form>
    </>
  );
};

export default connect(
  ({
    album: {
      filterForm: {
        tempData: {
          timeSince,
          timeUntil,
        },
      },
    },
  }) => ({
    timeSince: timeSince || startMs,
    timeUntil: timeUntil || endMs,
  }),
  {
    settimeSinceAction: setFilterField('timeSince'),
    settimeUntilAction: setFilterField('timeUntil'),
  },
)(TimeRangeField);
