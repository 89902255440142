import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { RHFInput } from 'react-hook-form-input';

const inputTypes = ['RADIO', 'MENU', 'RANGE', 'TEXT'];

const isFloat = (n) => Number(n) === n && n % 1 !== 0;

const getInputStep = (min, max, step) => (
  ((isFloat(min) || isFloat(max)) && !isFloat(step)) ? 0.1 : step
);

const FormInput = ({
  form,
  item,
  defaultValue,
  setValue,
  register,
}) => {
  useEffect(() => {
    setValue(`${form}.${item.configKey}`, defaultValue);
  }, [setValue, form, item.configKey, defaultValue]);

  if (!inputTypes.includes(item.widgetType)) {
    return null;
  }

  let input;
  if (item.widgetType === 'TEXT') {
    input = (
      <Form.Control
        as="input"
        type="text"
        maxLength={item.max}
        minLength={item.min}
        value={defaultValue}
        name={`${form}.${item.configKey}`}
      />
    );
  }

  if (['RADIO', 'MENU'].includes(item.widgetType)) {
    input = (
      <Form.Control
        custom
        value={defaultValue}
        as="select"
        name={`${form}.${item.configKey}`}
      >
        {/* eslint-disable-next-line react/no-array-index-key */}
        {Object.values(item.choices).map((option, index) => <option key={`${item.configKey}-${index}`}>{option}</option>)}
      </Form.Control>
    );
  }

  if (item.widgetType === 'RANGE') {
    input = (
      <Form.Control
        as="input"
        type="number"
        min={item.bottom}
        max={item.top}
        step={getInputStep(item.bottom, item.top, item.step)}
        placeholder="0"
        name={`${form}.${item.configKey}`}
      />
    );
  }

  return (
    <RHFInput
      name={`${form}.${item.configKey}`}
      as={input}
      defaultValue={defaultValue}
      register={register({ required: true })}
      setValue={(name, value) => setValue(name, item.widgetType === 'RANGE' ? Number(value) : value)}
    />
  );
};

export default FormInput;
