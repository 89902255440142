import React from 'react';
import { Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Map from './Map';
import Check from './Check';
import Browse from './Browse';
import { selectTab } from '../../actions';

import style from './style.module.scss';


const MappingTabs = () => {
  const dispatch = useDispatch();
  const selected = useSelector(({ mapping: { selectedTab } }) => selectedTab);

  return (
    <div className={style.mappingTabsContainer}>
      <Nav
        variant="tabs tabs-alt"
        className={style.tabs}
        defaultActiveKey={selected}
        onSelect={(tab) => {
          dispatch(selectTab(tab));
        }}
      >
        <Nav.Item>
          <Nav.Link eventKey="map">Map</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="check">Check</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="browse">Browse</Nav.Link>
        </Nav.Item>
      </Nav>
      {selected === 'map' && <Map />}
      {selected === 'check' && <Check />}
      {selected === 'browse' && <Browse />}
    </div>
  );
};

export default MappingTabs;
