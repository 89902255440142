import React from 'react';
import { connect } from 'react-redux';
import { Table, Button } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import { removeBlockedSeat } from '../actions';

import style from '../style.module.scss';

const BlockedSeatTable = ({ blockedSeats, removeBlockedSeatAction }) => (
  <div className={style.blockedSeatsTable}>
    <Table size="sm">
      <thead>
        <tr>
          <th>Seat Type</th>
          <th>Section</th>
          <th>Row</th>
          <th>Seat</th>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          {!isEmpty(blockedSeats) && <th className={style.deleteColumn} />}
        </tr>
      </thead>
      <tbody>
        {isEmpty(blockedSeats) && (
          <tr>
            <td colSpan="5">
              No seat registered
            </td>
          </tr>
        )}
        {blockedSeats?.map((location) => (
          <tr key={`${location.seatType}-${location.section}-${location.row}-${location.seat}`}>
            <td>{location.seatType}</td>
            <td>{location.section}</td>
            <td>{location.row}</td>
            <td>{location.seat}</td>
            <td>
              <Button variant="outline" size="sm" onClick={() => removeBlockedSeatAction(location)}>
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
);

export default connect(
  ({ blockedSeats: { blockedSeats } }) => ({ blockedSeats }),
  { removeBlockedSeatAction: removeBlockedSeat },
)(BlockedSeatTable);
