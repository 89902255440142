import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import EventMetricsData from './EventMetricsData';

import { toggleMetrics } from './actions';
import { eventStatus } from '../../../../helpers/constants';

import style from './style.module.scss';

const EventMetrics = ({ isCollapsed, isEventLive, toggleMetricsAction }) => {
  if (!isEventLive) {
    return null;
  }

  return (
    <>
      <div className={classnames(style.wrapper, { [style.isCollapsed]: isCollapsed })}>
        <div className={style.btnWrapper}>
          <Button
            variant="light"
            className={style.liveMetricsBtn}
            onClick={toggleMetricsAction}
          >
            {isCollapsed && <FontAwesomeIcon icon={faChevronLeft} />}
            <span>Live Metrics</span>
            {!isCollapsed && <FontAwesomeIcon icon={faChevronRight} />}
          </Button>
        </div>

        <div className={style.data}>
          {!isCollapsed && <EventMetricsData />}
        </div>
      </div>

      <strong className="separator">|</strong>
    </>
  );
};

export default connect(
  ({
    eventMetrics: { isCollapsed },
    events: { currentEvent },
  }) => ({
    isCollapsed,
    isEventLive: currentEvent && currentEvent.eventStatus === eventStatus.LIVE,
  }),
  { toggleMetricsAction: toggleMetrics },
)(EventMetrics);
