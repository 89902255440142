import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { Spinner, Button } from 'react-bootstrap';

import imgPlaceholder from '../../../../../assets/images/image-placeholder.svg';


import { uploadFile, setError } from '../../actions';

import style from './style.module.scss';

const OVERLAY_MIN_WIDTH = 1080;
const OVERLAY_MAX_WIDTH = 2160;
const FRONT_IMAGE_MIN_WIDTH = 720;
const FRONT_IMAGE_MIN_HEIGHT = 1280;
const BANNER_MIN_WIDTH = 600;
const BANNER_MIN_HEIGHT = 300;
const SLIDESHOW_BANNER_WIDTH = 1920;
const SLIDESHOW_BANNER_HEIGHT = 180;

const ImageUploader = ({
  section: {
    key,
    data,
    file,
    label,
    validationGuideline,
    isUploading,
  },
  dropZoneProps,
  className,
  setErrorAction,
  uploadFileAction,
}) => {
  const isEmpty = Object.values(data).every((val) => !val);
  const imageRef = useRef(null);

  // eslint-disable-next-line consistent-return
  const validateImage = () => {
    if (imageRef.current) {
      const { naturalWidth, naturalHeight } = imageRef.current;

      if (key === 'overlay' && (naturalWidth < OVERLAY_MIN_WIDTH || naturalWidth > OVERLAY_MAX_WIDTH)) {
        return setErrorAction(`Image should be between ${OVERLAY_MIN_WIDTH}px and ${OVERLAY_MAX_WIDTH}px`);
      }

      if (key === 'watermark' && naturalWidth !== OVERLAY_MIN_WIDTH) {
        return setErrorAction(`Image should have ${OVERLAY_MIN_WIDTH}px`);
      }

      if (key === 'watermark' && naturalWidth !== naturalHeight) {
        return setErrorAction('Image should be squared');
      }

      if (key === 'frontPage' && (naturalWidth < FRONT_IMAGE_MIN_WIDTH || naturalHeight < FRONT_IMAGE_MIN_HEIGHT)) {
        return setErrorAction(`Image should be least ${FRONT_IMAGE_MIN_WIDTH}x${FRONT_IMAGE_MIN_HEIGHT}px`);
      }

      if (key === 'logo' && (naturalWidth < BANNER_MIN_WIDTH || naturalHeight < BANNER_MIN_HEIGHT)) {
        return setErrorAction(`Image should be least ${BANNER_MIN_WIDTH}x${BANNER_MIN_HEIGHT}px`);
      }

      if (key === 'slideshowBanner' && (naturalWidth !== SLIDESHOW_BANNER_WIDTH || naturalHeight !== SLIDESHOW_BANNER_HEIGHT)) {
        return setErrorAction(`Image should be ${SLIDESHOW_BANNER_WIDTH}x${SLIDESHOW_BANNER_HEIGHT}px`);
      }

      uploadFileAction(file.inputFile);
    }
  };

  return (
    <>
      {(isEmpty || isUploading) && (
        // eslint-disable-next-line
        <div {...dropZoneProps} className={className}>
          {isUploading && (
            <div className={style.placeholderSpinner}>
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          <img className={style.placeholder} src={imgPlaceholder} alt="Placeholder" />
          <h4 className="mt-4">Drag and drop your file or upload your image.</h4>
          <p className={style.guideline}>{validationGuideline}</p>
          <Button className="small" variant="outline-primary">Upload</Button>
        </div>
      )}

      {!isEmpty && !file && <img src={data[`${key}Url`]} alt={label} />}

      {file && (
        <img
          ref={imageRef}
          onLoad={validateImage}
          className={style.preview}
          src={file.preview}
          alt={label}
        />
      )}
    </>
  );
};

export default connect(
  ({ graphics: { selectedInd, sections } }) => ({ section: sections[selectedInd] }),
  {
    setErrorAction: setError,
    uploadFileAction: uploadFile,
  },
)(ImageUploader);
