import React, { useState } from 'react';

import FallbackContainer from '../FallbackContainer';

import style from './style.module.scss';

const OverlayContainer = ({ overlay, index, percentage }) => {
  const [showImageFallback, setShowImageFallback] = useState(false);

  if (percentage && !overlay && !showImageFallback) {
    setShowImageFallback(true);
  }

  return (
    <div className={style.OverlayWrapper}>
      <div className={style.OverlayContainer}>
        {!showImageFallback && (
          <img src={overlay && overlay.urlMediumSize} alt="overlay" onError={() => setShowImageFallback(true)} />
        )}
        {showImageFallback && (
          <FallbackContainer />
        )}
      </div>
      <div className={style.OverlayDataContainer}>
        <span className={style.overlayTitle}>
          Overlay
          {' '}
          {index + 1}
        </span>
        <span className={style.overlayData}>{`${percentage.toFixed(2)}%`}</span>
      </div>
    </div>
  );
};

export default OverlayContainer;
