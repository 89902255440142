import { get } from '../../helpers/fetch';

import { buildURL } from '../../helpers/urls';

export const getBriziBasicData = (slugDate) => {
  const url = buildURL('analytics', slugDate, 'basic');
  return get(url);
};

export const getGAData = (slugDate) => {
  const url = buildURL('analytics', slugDate, 'google');
  return get(url);
};

export const getBriziVisionData = (slugDate) => {
  const url = buildURL('analytics', slugDate, 'briziVision');
  return get(url);
};

export const getAverageData = (slugDate) => {
  const url = buildURL('analytics', slugDate, 'pastAverageData');
  return get(url);
};
