import React, { useState, useMemo, useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';

import { randomPassword } from '../../../../helpers/utils';
import { validateUsername } from '../../../../helpers/api';

import style from './style.module.scss';

const UserModal = ({
  userData,
  formMode,
  roles,
  isOpen,
  isLoading,
  onHide,
  setFormLoading,
  onValueChangeHandler,
  onSubmitHandler,
  onCloseHandler,
}) => {
  const [isUsernameValid, setIsUsernameValid] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const rolesOptions = useMemo(
    () => roles.map(({ name }) => ({ value: name, label: name })),
    [roles],
  );

  useEffect(() => {
    if (formMode === 'create') {
      onValueChangeHandler({ name: 'password', value: randomPassword() });
    }
  }, [formMode]);

  const cancelHandler = () => {
    setFormSubmitted(false);
    setIsUsernameValid(null);
    onCloseHandler();
  };

  const validateCreateForm = async () => {
    setFormSubmitted(true);
    setIsUsernameValid(null);

    if (!userData.username.length) {
      return;
    }

    try {
      setFormLoading(true);
      const { exists } = await validateUsername(userData.username);

      if (exists) {
        setIsUsernameValid(false);
        return;
      }

      if (userData.password.length) {
        await onSubmitHandler();
      }
    } catch (err) {
      setFormLoading(false);
      console.error('Error trying to check username', err);
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (formMode === 'update') {
      onSubmitHandler();
      return;
    }
    validateCreateForm();
  };

  return (
    <Modal className={style.container} show={isOpen} size="md" onHide={onHide} backdrop="static" centered>
      <Form noValidate onSubmit={onSubmit}>
        <Modal.Header className="border-0 justify-content-center" closeButton>
          <Modal.Title as="h3">
            {formMode === 'create' ? 'Create new' : 'Update'}
            &nbsp;user
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="username">
            <Form.Label>User</Form.Label>
            <Form.Control
              placeholder="Brizi"
              value={userData.username}
              name="username"
              onChange={(e) => onValueChangeHandler(e.target)}
              required
              isInvalid={(!userData.username.length || isUsernameValid === false) && formSubmitted}
              type="text"
              disabled={formMode !== 'create'}
            />
            <Form.Control.Feedback type="invalid">
              {!userData.username.length && 'User name is required and must contains at least 6 characters'}
              {isUsernameValid === false && 'This user name is already taken, please, try another one'}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <div className="d-flex">
              <Form.Control
                placeholder={formMode === 'create' ? 'Password' : 'Click the button to change user password'}
                value={userData.password}
                name="password"
                onChange={(e) => onValueChangeHandler(e.target)}
                type="text"
                isInvalid={formMode === 'create' && !userData.password.length && formSubmitted}
                required
                readOnly
              />
              <Button
                variant="outline-primary"
                name="randomPassword"
                className="small ml-2"
                onClick={() => onValueChangeHandler({ name: 'password', value: randomPassword() })}
              >
                Random
              </Button>
            </div>
            <Form.Control.Feedback type="invalid">
              Password is required and must contains at least 6 characters
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-0">
            <Form.Label>Role Group</Form.Label>
            <Select
              isMulti
              value={userData.roles}
              className="react-select"
              classNamePrefix="react-select"
              options={rolesOptions}
              onChange={(roleGroup) => onValueChangeHandler({ name: 'roles', value: roleGroup })}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button className="small" variant="outline-primary" onClick={cancelHandler}>Close</Button>
          <LaddaButton
            className="btn btn-primary small"
            type="submit"
            loading={isLoading}
            data-style={ZOOM_OUT}
          >
            Save
          </LaddaButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};


export default connect(
  ({ roles: { roles } }) => ({ roles }),
)(UserModal);
