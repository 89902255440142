import moment from 'moment-timezone';

export const convertGameDate = (gameDate, timezone, format = 'DD/MM/YYYY') => {
  if (!timezone || !gameDate) {
    throw new Error('Missing parameter');
  }

  return moment(gameDate).tz(timezone).format(format);
};

export const convertTime = (time, timezone, timeFormat = 'h:mmA') => {
  if (!time) {
    return null;
  }

  if (!timezone || !timeFormat) {
    throw new Error('Missing parameter');
  }

  return moment(time).tz(timezone).format(timeFormat);
};
