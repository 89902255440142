import {
  SET_SNAPSHOT_LIST_ITEMS,
  FETCH_LIST_ITEMS,
  SET_LIST_ITEMS_FILTER_DATA,
  SET_LIST_ITEMS_PAGE_LEFT,
  SET_LIST_ITEMS_PAGE_RIGHT,
  SET_USER_PHOTOS_ID,
} from '../type';
import { SET_BANNED_SNAPSHOTS, SET_BANNED_USER, SET_FAVORITE } from '../../../../store/types/snapshots';
import snapshotsReducer from '../../../../store/reducers/snapshots';

const initialState = {
  isFetching: false,
  allLoadedRight: false,
  items: [],
  filter: {},
  // we are ignoring filter if userId is set and loading only photos for that user
  userId: null,
  currentTab: 'all',
  currentPageLeft: 1,
  currentPageRight: 1,
  itemsPerPage: 100,
};

export default (state = initialState, { type, payload = {} } = {}) => {
  switch (type) {
  case SET_SNAPSHOT_LIST_ITEMS: {
    const items = payload;
    return {
      ...state,
      isFetching: false,
      items,
      allLoadedRight: payload.length < state.itemsPerPage,
    };
  }

  case FETCH_LIST_ITEMS: {
    const { error, data, loadLeft } = payload;
    const allLoadedRight = data && data.length < state.itemsPerPage;
    let { items } = state;

    if (data) {
      const existingIds = items.map(({ _id }) => _id);
      const dedupNewItems = data.filter(({ _id }) => !existingIds.includes(_id));
      items = loadLeft
        ? [...dedupNewItems, ...state.items]
        : [...state.items, ...dedupNewItems];
    }

    return {
      ...state,
      isFetching: !error && !data,
      error,
      items,
      allLoadedRight,
      currentPageRight: allLoadedRight ? state.currentPageRight - 1 : state.currentPageRight,
    };
  }

  case SET_LIST_ITEMS_FILTER_DATA: {
    const {
      filter,
      currentTab,
      currentPage,
      itemsPerPage,
    } = payload;

    return {
      ...state,
      filter,
      currentTab,
      itemsPerPage,
      currentPageLeft: currentPage,
      currentPageRight: currentPage,
      allLoadedRight: false,
    };
  }

  case SET_LIST_ITEMS_PAGE_LEFT: {
    return {
      ...state,
      currentPageLeft: payload,
    };
  }

  case SET_LIST_ITEMS_PAGE_RIGHT: {
    return {
      ...state,
      currentPageRight: payload,
    };
  }

  case SET_FAVORITE:
  case SET_BANNED_SNAPSHOTS:
  case SET_BANNED_USER: {
    return snapshotsReducer(state, { type, payload });
  }

  case SET_USER_PHOTOS_ID: {
    return {
      ...initialState,
      // this number is initially set to 1 because we already have the page loaded
      // and it's being increased after load
      currentPageRight: 0,
      isFetching: true,
      userId: payload,
      filter: payload ? { userIDs: [{ value: payload }] } : state.initialState,
    };
  }

  default: {
    return state;
  }
  }
};
