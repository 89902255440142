import React, { useContext, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleArrowDown,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleArrowUp,
} from '@fortawesome/pro-solid-svg-icons';
import { SocketContext } from 'react-socket-io';
import { get } from 'lodash';
import { socketEventNames } from './helper';
import store from '../../store';

import style from './style.module.scss';

const { ROVER_MOVE, ROVER_GET_POSITION } = socketEventNames;

const continuousMoveHelper = (cmd, key) => {
  if (cmd !== 'move' && cmd !== 'stop' && key !== 'w' && key !== 'a' && key !== 's' && key !== 'd') {
    return null;
  }
  const { mapping } = store.getState();
  const connectedCamera = get(mapping, 'connectedCamera');

  if (cmd === 'move') {
    const panSpeed = get(mapping, 'preview.continuousSpeed.pan.value');
    const tiltSpeed = get(mapping, 'preview.continuousSpeed.tilt.value');
    const panSpeedMax = get(mapping, 'preview.continuousSpeed.pan.max');
    const tiltSpeedMax = get(mapping, 'preview.continuousSpeed.tilt.max');

    let ROVER_MOVE_CMD = '';

    switch (key) {
    case 'w':
      ROVER_MOVE_CMD = `c0,${Number(tiltSpeed) / Number(tiltSpeedMax)}`;
      return { connectedCamera, ROVER_MOVE_CMD };
    case 'a':
      ROVER_MOVE_CMD = `c${Number(-1 * panSpeed) / Number(panSpeedMax)},0`;
      return { connectedCamera, ROVER_MOVE_CMD };
    case 's':
      ROVER_MOVE_CMD = `c0,${Number(-1 * tiltSpeed) / Number(tiltSpeedMax)}`;
      return { connectedCamera, ROVER_MOVE_CMD };
    case 'd':
      ROVER_MOVE_CMD = `c${Number(panSpeed) / Number(panSpeedMax)},0`;
      return { connectedCamera, ROVER_MOVE_CMD };
    default:
      return null;
    }
  } else if (cmd === 'stop') {
    const ROVER_MOVE_CMD = 's';
    const updatePositionOnMove = get(mapping, 'preview.continuousSpeed.updatePosition');
    return { connectedCamera, ROVER_MOVE_CMD, updatePositionOnMove };
  }

  return null;
};

const KeyboardControls = ({ socket, connectedCamera }) => {
  const keyStates = useRef({
    w: false,
    a: false,
    s: false,
    d: false,
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (/^(input|select|textarea)$/.exec(document.activeElement.tagName.toLowerCase()) || keyStates.current[event.key]) {
        return;
      }
      let cmd = '';
      switch (event.key) {
      case 'w':
        console.log('Sending socket message for moving up');
        cmd = continuousMoveHelper('move', event.key);
        socket.emit(ROVER_MOVE, 'host', cmd.connectedCamera, cmd.ROVER_MOVE_CMD);
        keyStates.current = { ...keyStates.current, w: true };
        break;
      case 'a':
        console.log('Sending socket message for moving left');
        cmd = continuousMoveHelper('move', event.key);
        socket.emit(ROVER_MOVE, 'host', cmd.connectedCamera, cmd.ROVER_MOVE_CMD);
        keyStates.current = { ...keyStates.current, a: true };
        break;
      case 's':
        console.log('Sending socket message for moving down');
        cmd = continuousMoveHelper('move', event.key);
        socket.emit(ROVER_MOVE, 'host', cmd.connectedCamera, cmd.ROVER_MOVE_CMD);
        keyStates.current = { ...keyStates.current, s: true };
        break;
      case 'd':
        console.log('Sending socket message for moving right');
        cmd = continuousMoveHelper('move', event.key);
        socket.emit(ROVER_MOVE, 'host', cmd.connectedCamera, cmd.ROVER_MOVE_CMD);
        keyStates.current = { ...keyStates.current, d: true };
        break;
      default:
        break;
      }
    };

    const handleKeyUp = (event) => {
      const cmd = continuousMoveHelper('stop', event.key);

      if (cmd) {
        socket.emit(ROVER_MOVE, 'host', cmd.connectedCamera, cmd.ROVER_MOVE_CMD);
        keyStates.current = { ...keyStates.current, w: false };
        keyStates.current = { ...keyStates.current, a: false };
        keyStates.current = { ...keyStates.current, s: false };
        keyStates.current = { ...keyStates.current, d: false };

        if (cmd.updatePositionOnMove) {
          setTimeout(() => socket.emit(ROVER_GET_POSITION, 'host', connectedCamera, '#'), 750);
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [connectedCamera]);

  return null;
};

const MoveControls = () => {
  const socket = useContext(SocketContext);
  const connectedCamera = useSelector(({ mapping: { connectedCamera: cam } }) => cam);
  const panSpeed = useSelector(({ mapping }) => get(mapping, 'preview.stepSpeed.pan.value'));
  const tiltSpeed = useSelector(({ mapping }) => get(mapping, 'preview.stepSpeed.tilt.value'));

  return (
    <div className={style.arrowControls}>
      <KeyboardControls socket={socket} connectedCamera={connectedCamera} />
      <div>
        <button
          type="button"
          onClick={() => {
            console.warn('relative move up');
            // e.g {,2,10000,false --> relative move tilt by 10000
            socket.emit(ROVER_MOVE, 'host', connectedCamera, `{,2,${-1 * tiltSpeed}`);
          }}
        >
          <FontAwesomeIcon icon={faCircleArrowUp} />
        </button>
      </div>
      <div>
        <button
          type="button"
          onClick={() => {
            console.warn('relative move left');
            // e.g [,1,10000,false --> relative move pan by 10000
            socket.emit(ROVER_MOVE, 'host', connectedCamera, `[,1,${-1 * panSpeed}`);
          }}
        >
          <FontAwesomeIcon icon={faCircleArrowLeft} />
        </button>
        <button
          type="button"
          onClick={() => {
            console.warn('relative move down');
            // e.g [,1,10000,false --> relative move pan by 10000
            socket.emit(ROVER_MOVE, 'host', connectedCamera, `},2,${tiltSpeed}`);
          }}
        >
          <FontAwesomeIcon icon={faCircleArrowDown} />
        </button>
        <button
          type="button"
          onClick={() => {
            console.warn('relative move right');
            // e.g [,1,10000,false --> relative move pan by 10000
            socket.emit(ROVER_MOVE, 'host', connectedCamera, `],1,${panSpeed}`);
          }}
        >
          <FontAwesomeIcon icon={faCircleArrowRight} />
        </button>
      </div>
    </div>
  );
};

export default MoveControls;
