/* eslint-disable max-len */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { faCheckSquare, faSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updatePath } from './actions';

import style from './style.module.scss';

import Slider from './Slider';

const ContinuousSpeedControls = () => {
  const dispatch = useDispatch();
  const minPan = useSelector(({ mapping: { preview: { continuousSpeed: { pan: { min } } } } }) => min);
  const maxPan = useSelector(({ mapping: { preview: { continuousSpeed: { pan: { max } } } } }) => max);
  const pan = useSelector(({ mapping: { preview: { continuousSpeed: { pan: { value } } } } }) => value);

  const minTilt = useSelector(({ mapping: { preview: { continuousSpeed: { tilt: { min } } } } }) => min);
  const maxTilt = useSelector(({ mapping: { preview: { continuousSpeed: { tilt: { max } } } } }) => max);
  const tilt = useSelector(({ mapping: { preview: { continuousSpeed: { tilt: { value } } } } }) => value);
  const updatePosition = useSelector(({ mapping: { preview: { continuousSpeed: { updatePosition: val } } } }) => val);

  return (
    <div className={style.speedContainer}>
      <span>Speed</span>
      <div className={style.wrapper}>
        <div>
          <span>Pan</span>
          <div className={style.rangeWrapper}>
            <Slider
              path="preview.continuousSpeed.pan.value"
              min={minPan}
              max={maxPan}
              val={pan}
            />
          </div>
        </div>
        <div>
          <span>Tilt</span>
          <div className={style.rangeWrapper}>
            <Slider
              path="preview.continuousSpeed.tilt.value"
              min={minTilt}
              max={maxTilt}
              val={tilt}
            />
          </div>
        </div>
        <button
          type="button"
          onClick={() => {
            dispatch(updatePath('preview.continuousSpeed.updatePosition', !updatePosition));
          }}
        >
          <span>Track on Move</span>
          <FontAwesomeIcon icon={updatePosition ? faCheckSquare : faSquare} />
        </button>
      </div>
    </div>
  );
};


export default ContinuousSpeedControls;
