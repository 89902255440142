import React, { useEffect } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import useForm from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';

import { fetchGeolocation, setGeolocationInput } from '../actions';

import style from '../style.module.scss';

const GeofenceAddressForm = ({ geofence, fetchGeolocationAction, setGeolocationInputAction }) => {
  const {
    register,
    handleSubmit,
    errors,
    setValue,
  } = useForm();

  useEffect(() => {
    setValue('address', geofence.address);
    setValue('radius', geofence.radius);
  }, [geofence.address, geofence.radius]);

  const handleSubmitFn = (data) => {
    fetchGeolocationAction(data);
  };

  const changeHandler = ({ target: { id, value } }) => {
    setGeolocationInputAction({ [`${id}Input`]: value });
  };

  return (
    <Card className={style.innerForm}>
      <Card.Header>
        <b>Geofence Reference</b>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Group controlId="address">
            <Form.Label>Venue Address</Form.Label>
            <RHFInput
              name="address"
              placeholder="Add venue adress..."
              onChange={changeHandler}
              as={(
                <Form.Control
                  as="input"
                  type="text"
                  isInvalid={errors.address}
                />
              )}
              register={register({ required: true })}
              setValue={setValue}
            />
            <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="radius">
            <Form.Label>Geofence radius in KMs</Form.Label>
            <RHFInput
              name="radius"
              onChange={changeHandler}
              as={(
                <Form.Control
                  as="input"
                  type="number"
                  step={1}
                  min={0}
                  isInvalid={errors.radius}
                />
              )}
              register={register({ required: true })}
              setValue={setValue}
            />
            <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button size="md" variant="primary" onClick={handleSubmit(handleSubmitFn)} className="small">
              Search
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default connect(
  ({ location: { geofence } }) => ({ geofence }),
  {
    fetchGeolocationAction: fetchGeolocation,
    setGeolocationInputAction: setGeolocationInput,
  },
)(GeofenceAddressForm);
