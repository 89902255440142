import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import style from './style.module.scss';
import Camera from '../Camera';
import { resizeObserver } from '../../../helpers/browser';


const getAllLayouts = (len) => {
  const sides = [];
  for (let side1 = 1; side1 <= len / side1; side1++) {
    sides.push([side1, Math.ceil(len / side1)]);
  }
  return sides;
};

const CameraBlocks = ({ cameras }) => {
  const ref = useRef();
  const camerasStatus = Object.keys(cameras);

  const calcAndSetCamSize = (parentDomEl) => {
    if (!parentDomEl) {
      return;
    }

    const margin = 4;
    const containerHeight = parentDomEl.clientHeight - margin;
    const containerWidth = parentDomEl.clientWidth - margin;

    const [area, bestLayout] = getAllLayouts(camerasStatus.length)
      .reduce(([largestArea, layout], [side1, side2]) => {
        const cs1 = Math.min(containerHeight, containerWidth);
        const cs2 = Math.max(containerHeight, containerWidth);
        const k1 = cs1 / side1;
        const k2 = cs2 / side2;

        if (cs2 >= k1 * side2) {
          const curArea = Math.floor(side1 * side2 * k1 ** 2);

          if (curArea > largestArea) {
            return [curArea, [side1, side2]];
          }
        }

        if (cs1 >= k2 * side1) {
          const curArea = Math.floor(side1 * side2 * k2 ** 2);

          if (curArea > largestArea) {
            return [curArea, [side1, side2]];
          }
        }

        return [largestArea, layout];
      }, [0, null]);

    const [rows, columns] = bestLayout;
    const camSize = Math.sqrt(area / (rows * columns)) - margin;

    parentDomEl.style.setProperty('--camSize', `${camSize}px`);
    parentDomEl.style.setProperty('--controllsFontSize', `${Math.min(camSize / 40, 12)}px`);

    const width = (camSize + margin) * Math.max(rows, columns);
    if (containerWidth > width) {
      // eslint-disable-next-line no-param-reassign
      parentDomEl.firstChild.style.width = `${width}px`;
    } else {
      // eslint-disable-next-line no-param-reassign
      parentDomEl.firstChild.style.width = 'auto';
    }
  };

  useEffect(() => {
    requestAnimationFrame(() => calcAndSetCamSize(ref.current));
  }, []);

  useEffect(() => resizeObserver(ref.current, calcAndSetCamSize), [ref.current]);

  return (
    <div ref={ref} className={style.camContainer}>
      <div className={style.inner}>
        {camerasStatus.map((camName) => (
          <Camera key={camName} name={camName} />
        ))}
      </div>
    </div>
  );
};

export default connect(
  ({
    cameras: {
      status: camerasStatus,
    },
  }) => ({
    cameras: camerasStatus,
  }),
)(CameraBlocks);
