import React from 'react';
import { Button } from 'react-bootstrap';
import style from '../style.module.scss';

const CameraControls = ({
  cameras,
  restartACSHandler,
  handleSubmit,
  onSubmit,
}) => (
  <tr>
    <td />
    {cameras.map((camera) => (
      <td key={camera._id}>
        <div className={style.ButtonWrapper}>
          <Button
            className={style.Button}
            variant="outline-primary"
            size="sm"
            onClick={() => restartACSHandler(camera.cameraKey)}
          >
            Restart ACS
          </Button>
          <Button
            className={style.Button}
            size="sm"
            variant="outline-primary"
            onClick={handleSubmit((data) => onSubmit(data, camera))}
          >
            Submit
          </Button>
        </div>
      </td>
    ))}
  </tr>
);

export default CameraControls;
