import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';

import { resetPasswordEmail } from '../api';
import { displayErrorModal } from '../../../helpers/swal';
import style from '../../Login/style.module.scss';

const EmailVerification = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);

  const changeHandler = (e) => {
    setEmail(e.target.value);
  };

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      setLoading(true);
      const response = await resetPasswordEmail({ email });
      if (response.error) {
        setError(response.error);
      } else {
        setError(null);
        setEmailSent(true);
      }
    } catch (e) {
      displayErrorModal({ text: 'Something went wrong, please try again' });
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  if (emailSent) {
    return (
      <div className={style.topText}>
        <p>An email with your new login credentials will be sent to you</p>
      </div>
    );
  }

  return (
    <Form onSubmit={formSubmitHandler}>
      <div className={style.topText}>
        <p className="mb-3">Please enter your email</p>
      </div>
      <Form.Group>
        <Form.Control
          type="email"
          name="email"
          value={email}
          onChange={changeHandler}
          placeholder="email@example.com"
          isInvalid={error}
          required
        />
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Form.Group>
      <LaddaButton
        loading={loading}
        type="submit"
        className="btn btn-primary"
        data-style={ZOOM_OUT}
      >
        Reset Password
      </LaddaButton>
    </Form>
  );
};
export default EmailVerification;
