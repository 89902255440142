import { buildURL } from '../../../../helpers/urls';
import { get, patch } from '../../../../helpers/fetch';
import { PAGE_SIZE } from './constants';

export const getNotifications = ({ slugDate, offset } = {}) => {
  const searchParams = new URLSearchParams({ slugDate, offset, limit: PAGE_SIZE });
  const url = buildURL('/notifications/suspiciousUser', `?${searchParams}`);
  return get(url);
};

export const dismissNotification = (id) => {
  const url = buildURL(`/notifications/${id}/dismiss`);
  return patch(url);
};

export const getNotificationsCount = ({ slugDate } = {}) => {
  const searchParams = new URLSearchParams({ slugDate });
  const url = buildURL('/notifications/suspiciousUser/count', `?${searchParams}`);
  return get(url);
};
