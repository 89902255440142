import React from 'react';
import Router from './shared/Router';

import './vendor/styles/bootstrap.scss';
import './vendor/styles/appwork.scss';
import './vendor/styles/theme-corporate.scss';
import './vendor/styles/colors.scss';
import './vendor/styles/uikit.scss';
import './vendor/libs/react-ladda/react-ladda.scss';
import './vendor/libs/react-toastify/react-toastify.scss';
import './vendor/libs/react-bootstrap-table2/react-bootstrap-table2.scss';
import './shared/layouts/print.scss';
import './shared/layouts/global.scss';

const App = () => <Router />;

export default App;
