import {
  SET_COLORS,
  SET_THEME_COLOR,
  SET_ICON_COLOR,
  SET_EMAIL_COLOR,
  SET_SLIDESHOW_COLOR,
} from './types';

export default (state = null, { type, payload = {} }) => {
  switch (type) {
  case SET_COLORS: {
    return { ...payload };
  }

  case SET_THEME_COLOR: {
    return {
      ...state,
      theme: {
        ...state.theme,
        ...payload,
      },
    };
  }

  case SET_ICON_COLOR: {
    return {
      ...state,
      iconColor: {
        ...state.iconColor,
        ...payload,
      },
    };
  }

  case SET_EMAIL_COLOR: {
    return {
      ...state,
      emailSettings: {
        ...state.emailSettings,
        ...payload,
      },
    };
  }

  case SET_SLIDESHOW_COLOR: {
    const { slideshowColor } = payload;
    return { ...state, slideshowColor };
  }

  default: {
    return state;
  }
  }
};
