import React, { useContext, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Event, SocketContext } from 'react-socket-io';
import { ToastContainer, toast, cssTransition } from 'react-toastify';

import MonitorNav from './MonitorNav';
import RightBar from '../../components/RightBar';
import CameraBlocks from './CameraBlocks';
import CameraBlocksBig from './CameraBlocksBig';
import ShareNotification from './ShareNotification';
import Sidebar from './Sidebar';
import SuspiciousUserToasts from './SuspiciousUserToasts';

import { BLOCKS_LAYOUT, BIG_BLOCK_LAYOUT } from './constants';
import { setCamQueue, setLightBoxSnapshot } from './actions';
import { setPanAndZoom, updateCameraStatus } from '../../store/actions/cameras';
import { socketEventNames } from '../../helpers/constants';

import style from './style.module.scss';

const { MONITOR_QUEUE_ARRAY, MONITOR_PAN_ZOOM } = socketEventNames;

const toastTransition = cssTransition({
  enter: style.zoomIn,
  exit: style.zoomOut,
});

const Monitor = (props) => {
  const {
    slug,
    layout,
    sidebarDisplayed,
    camerasStatus,
    setCamQueueAction,
    setLightBoxSnapshotAction,
    setPanAndZoomAction,
    updateCameraStatusAction,
  } = props;

  const socket = useContext(SocketContext);
  const camKeys = Object.keys(camerasStatus);

  useEffect(() => {
    updateCameraStatusAction();

    socket.emit('getInitTotalQueue', '', slug, (queues) => {
      queues.forEach((queue, camInd) => {
        setCamQueueAction(`cam${camInd + 1}`, queue);
      });
    });
  }, []);

  useEffect(() => {
    camKeys.forEach((camName) => {
      socket.emit(
        'join monitor livestream',
        slug,
        camName,
        (st) => console.log(`joined livestream  for camera ${camName} ${st}`),
      );
    });

    return () => camKeys.forEach((camName) => {
      socket.emit(
        'leave livestream',
        camName,
        (camera) => console.log(`left livestream for camera ${camera}`),
      );
    });
  }, [camerasStatus]);

  const handleShareNotification = useCallback((snapshot, platform, userData) => {
    const toastMessage = (
      <ShareNotification platform={platform} snapshot={snapshot} user={userData} />
    );
    toast(toastMessage, {
      onClick: () => {
        setLightBoxSnapshotAction(snapshot._id);
      },
      containerId: 'share',
      transition: toastTransition,
    });
  }, []);

  if (camKeys.length === 0) {
    return (
      <div className={style.noCameraWrapper}>
        <h5>There are no available cameras</h5>
      </div>
    );
  }

  return (
    <div className={style.monitor}>
      <Event event="monitor snapshotShared" handler={handleShareNotification} />
      <Event event={MONITOR_PAN_ZOOM} handler={setPanAndZoomAction} />
      <Event event={MONITOR_QUEUE_ARRAY} handler={setCamQueueAction} />

      <ToastContainer className={style.ShareToast} enableMultiContainer containerId="share" position="bottom-right" autoClose={5000} />
      <ToastContainer enableMultiContainer containerId="magicshot" position="bottom-right" autoClose={3000} />

      <div className={style.mainContent}>
        <MonitorNav />
        {layout === BLOCKS_LAYOUT && <CameraBlocks />}
        {layout === BIG_BLOCK_LAYOUT && <CameraBlocksBig />}
      </div>

      <RightBar resizable showBar={sidebarDisplayed} minWidth="200px">
        <Sidebar />
      </RightBar>

      <SuspiciousUserToasts />
    </div>
  );
};

export default connect(
  ({
    // TODO: this should be refactored on the server, and slug should be removed
    events: {
      slug,
    },
    monitor: {
      options: {
        sidebarDisplayed,
        layout,
      },
    },
    cameras: {
      status: camerasStatus,
    },
  }) => ({
    slug,
    sidebarDisplayed,
    layout,
    camerasStatus,
  }),
  {
    setCamQueueAction: setCamQueue,
    setLightBoxSnapshotAction: setLightBoxSnapshot,
    setPanAndZoomAction: setPanAndZoom,
    updateCameraStatusAction: updateCameraStatus,
  },
)(Monitor);
