import React, { useCallback } from 'react';
import classnames from 'classnames';

import Table from '../../../../components/Table';

import { formatDate } from '../../../../helpers/utils';

import '../../../../vendor/libs/react-select/react-select.scss';

const UsersTable = ({ data = [], selectedRow, onRowSelect }) => {
  const onRowClick = useCallback((evt, row) => onRowSelect(row), [onRowSelect]);

  if (!data.length) {
    return null;
  }

  const columns = [{
    text: 'User Name',
    dataField: 'username',
    sort: true,
    classes: 'align-middle',
  }, {
    text: 'Created at',
    dataField: 'createdAt',
    sort: true,
    classes: 'align-middle',
    formatter: (_, row) => formatDate(row.createdAt, 'DD/MM/YYYY'),
  }, {
    text: 'Last login',
    dataField: 'lastLogin',
    sort: true,
    classes: 'align-middle',
    formatter: (_, row) => formatDate(row.lastLogin, 'DD/MM/YYYY'),
  }, {
    text: 'User ',
    dataField: 'roles',
    sort: true,
    classes: 'align-middle',
    formatter: (_, row) => row.roles.map(({ label }) => label).join(', '),
  }];

  const selectRow = {
    selected: selectedRow && [selectedRow.username],
    mode: 'radio',
    style: { borderRight: 0 },
    selectionRenderer: ({ checked }) => (
      <div className="d-flex">
        <span
          className={classnames(
            'selectCircle',
            'align-self-center',
            'mr-2',
            { active: checked },
          )}
        />
      </div>
    ),
    onSelect: onRowSelect,
  };

  return (
    <Table
      rowKey="username"
      data={data}
      columns={columns}
      selectRow={selectRow}
      onRowClick={onRowClick}
      pagination={false}
    />
  );
};

export default UsersTable;
