import React from 'react';
import { connect, batch } from 'react-redux';
import classnames from 'classnames';
import { useDropzone } from 'react-dropzone';

import { setError, addFile } from '../actions';
import { displayErrorModal } from '../../../../helpers/swal';

import Info from '../../../../components/Info';
import ActionButtons from './ActionButtons';
import ImageUploader from './ImageUploader';
import ImageSelector from './ImageSelector';

import style from './style.module.scss';

const ImageEditor = ({
  section: {
    label,
    error,
    fileFormat,
    isUploading,
    validationGuideline,
  },
  selectedSectionType,
  setErrorAction,
  setFileAction,
}) => {
  const onDrop = (acceptedFiles) => {
    const [inputFile] = acceptedFiles;

    if (!inputFile.type.startsWith('image')) {
      setErrorAction('Only images are supported');
    } else {
      batch(() => {
        setErrorAction(null);
        setFileAction({ file: { inputFile, preview: URL.createObjectURL(inputFile) } });
      });
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
  } = useDropzone({ onDrop });

  const dropZoneProps = getRootProps();
  const { onClick: selectFile } = dropZoneProps;

  const dropZoneClassName = classnames(
    'w-100 text-center',
    style.dropzone,
    {
      'border-primary': isDragActive || isDragAccept,
      [style.disabled]: isUploading,
    },
  );

  if (error) {
    displayErrorModal({ text: error });
    setErrorAction(null);
  }

  return (
    <div className={style.container}>
      <div className={classnames('d-flex', 'justify-content-between flex-row', style.header)}>
        <h3 className="mb-0">
          {label}
          {validationGuideline && <Info content={validationGuideline} className="ml-2" position="right" />}
        </h3>

        <input {...getInputProps()} multiple={false} accept={fileFormat} />

        <div className="d-flex">
          {selectedSectionType === 'upload' && <ActionButtons selectFile={selectFile} />}
        </div>
      </div>

      <div className={classnames('d-flex', 'pt-3', 'justify-content-center', style.main)}>
        {selectedSectionType === 'upload' && (
          <ImageUploader className={dropZoneClassName} dropZoneProps={dropZoneProps} />
        )}
        {selectedSectionType === 'select' && <ImageSelector />}
      </div>
    </div>
  );
};

export default connect(
  ({ graphics: { selectedInd, sections } }) => ({
    selectedSectionType: sections[selectedInd].options ? 'select' : 'upload',
    section: sections[selectedInd],
  }),
  {
    setErrorAction: setError,
    setFileAction: addFile,
  },
)(ImageEditor);
