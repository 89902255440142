import { combineReducers } from 'redux';

import snapshot from './snapshot';
import snapshotList from '../Carousel/reducer';
import info from '../InfoSection/reducer';

export default combineReducers({
  snapshot,
  snapshotList,
  info,
});
