import { TOGGLE_INFO_SECTION, FETCH_IMAGE_MINING_DATA, FETCH_USER_DATA } from '../type';
import { getImageMiningFacesData, getUserData } from './api';

export const toggleInfoSection = (section) => ({
  type: TOGGLE_INFO_SECTION,
  payload: section,
});

const requestImageMiningData = () => ({ type: FETCH_IMAGE_MINING_DATA });

const recieveImageMiningData = (error, data, snapshotId) => ({
  type: FETCH_IMAGE_MINING_DATA,
  payload: { error, data, snapshotId },
});

export const fetchImageMining = (snapshotId) => (dispatch) => {
  dispatch(requestImageMiningData());

  getImageMiningFacesData(snapshotId).then((facesData) => {
    dispatch(recieveImageMiningData(null, facesData, snapshotId));
  }).catch((err) => {
    console.error(err);
    dispatch(recieveImageMiningData(err));
  });
};

const requestUserData = () => ({ type: FETCH_USER_DATA });

const receiveUserData = (error, data, userId) => ({
  type: FETCH_USER_DATA,
  payload: { error, data, userId },
});

export const fetchUserData = (userId) => (dispatch) => {
  dispatch(requestUserData());

  getUserData(userId).then((userData) => {
    dispatch(receiveUserData(null, userData, userId));
  }).catch((err) => {
    console.error(err);
    dispatch(receiveUserData(err));
  });
};
