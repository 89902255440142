import React from 'react';
import { Card } from 'react-bootstrap';

import { useLogPageViews } from '../../helpers/hooks';

import LoginForm from './LoginForm';
import backgroundImage from '../../assets/images/login.png';
import logo from '../../assets/images/AMPLIFiCAM_logo_symbol.png';

import style from './style.module.scss';

const Login = () => {
  useLogPageViews();

  return (
    <div className={style.loginContainer} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <img className={style.logo} src={logo} alt="background-arena" />
      <Card className={style.formContainer}>
        <div>
          <LoginForm />
        </div>
      </Card>
    </div>
  );
};

export default Login;
