import React, { useEffect } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Event } from 'react-socket-io';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

import { socketEventNames, eventStatus as eventStatuses } from '../../../../helpers/constants';
import { fetchCurrentEvent } from '../../../../store/actions/events';
import { convertGameDate, convertTime } from '../../../../helpers/events';

import style from './style.module.scss';

const { MONITOR_EVENT_UPDATED } = socketEventNames;
const { LIVE, PRE } = eventStatuses;

const EventDetails = ({ currentEvent, fetchCurrentEventAction }) => {
  useEffect(() => {
    fetchCurrentEventAction();
  }, []);

  if (!currentEvent || !currentEvent.gameDate) {
    return null;
  }

  const {
    gameDate,
    startTime,
    endTime,
    timezone,
    eventStatus,
    title,
  } = currentEvent;
  const date = convertGameDate(gameDate, timezone);
  const start = convertTime(startTime, timezone);
  const end = convertTime(endTime, timezone);

  const eventName = <span className={style.eventName}>{title}</span>;

  const tooltip = start && end && (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip>{`${start} - ${end}`}</Tooltip>}
    >
      {eventName}
    </OverlayTrigger>
  );

  return (
    <div className={style.eventDetailsContainer}>
      <Event event={MONITOR_EVENT_UPDATED} handler={fetchCurrentEventAction} />
      <span>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip><span className="text-capitalize">{eventStatus}</span></Tooltip>}
        >
          <FontAwesomeIcon
            icon={faCircle}
            className={classnames({
              [style.active]: eventStatus === LIVE,
              [style.semiActive]: eventStatus === PRE,
            })}
          />
        </OverlayTrigger>
      </span>
      <div className="d-inline-block">
        <div className={style.date}>{date}</div>
        {tooltip || eventName}
      </div>
    </div>
  );
};

export default connect(
  ({ slug, events: { currentEvent } }) => ({ slug, currentEvent }),
  { fetchCurrentEventAction: fetchCurrentEvent },
)(EventDetails);
