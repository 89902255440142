import {
  FETCH_SNAPSHOTS,
  SET_FAVORITE,
  REMOVE_SNAPSHOTS,
  ADD_SNAPSHOT,
  SET_BANNED_USER,
  SET_BANNED_SNAPSHOTS,
  SET_SHARED,
  TOGGLE_SELECTED_SNAPSHOTS,
  DESELECT_ALL_SNAPSHOTS,
  SELECT_ALL_SNAPSHOTS,
  SELECT_INTERVAL,
} from '../types/snapshots';

const initialState = {
  isFetching: false,
  items: [],
  lastSelected: null,
};

export default (state = initialState, { type, payload = {} } = {}) => {
  switch (type) {
  case FETCH_SNAPSHOTS: {
    const { error, data } = payload;

    return {
      ...state,
      isFetching: !error && !data,
      error,
      items: data || [],
    };
  }

  case ADD_SNAPSHOT: {
    const { snapshot, itemsPerPage } = payload;
    const { _id: snapshotId } = snapshot;
    const exists = !!state.items.find(({ _id }) => _id === snapshotId);

    if (exists) {
      return state;
    }

    return {
      ...state,
      items: [snapshot, ...state.items].slice(0, itemsPerPage),
    };
  }

  case REMOVE_SNAPSHOTS: {
    const snapshotIds = payload;

    return {
      ...state,
      items: state.items.filter(({ _id }) => !snapshotIds.includes(_id)),
    };
  }

  case SET_FAVORITE: {
    const snaphsotUpdateObjects = payload;

    return {
      ...state,
      items: state.items.map((snapshot) => {
        const { _id: curSnapId } = snapshot;
        const updateObject = snaphsotUpdateObjects
          .find(({ _id: snapshotId }) => snapshotId === curSnapId);

        if (!updateObject) {
          return snapshot;
        }

        return { ...snapshot, favorite: updateObject.favorite };
      }),
    };
  }

  case SET_BANNED_SNAPSHOTS: {
    const snaphsotUpdateObjects = payload;

    return {
      ...state,
      items: state.items.map((snapshot) => {
        const { _id: curSnapId } = snapshot;
        const updateObject = snaphsotUpdateObjects
          .find(({ _id: snapshotId }) => snapshotId === curSnapId);

        if (!updateObject) {
          return snapshot;
        }

        return { ...snapshot, hidden: updateObject.hidden };
      }),
    };
  }

  case SET_BANNED_USER: {
    const { userId, hidden } = payload;

    return {
      ...state,
      items: [
        ...state.items.map((snapshot) => {
          if (snapshot.userId === userId) {
            return { ...snapshot, hidden };
          }

          return snapshot;
        }),
      ],
    };
  }

  case SET_SHARED: {
    const snapshot = payload;
    const { _id: snapshotId } = snapshot;
    const ind = state.items.findIndex(({ _id }) => _id === snapshotId);

    // return if snapshot is not in the state
    if (ind === -1) {
      return state;
    }

    return {
      ...state,
      items: [
        ...state.items.slice(0, ind),
        snapshot,
        ...state.items.slice(ind + 1),
      ],
    };
  }

  case TOGGLE_SELECTED_SNAPSHOTS: {
    const snapshotId = payload;
    const ind = state.items.findIndex(({ _id }) => _id === snapshotId);
    const snapshot = state.items[ind];
    const selected = !snapshot.selected;

    return {
      ...state,
      items: [
        ...state.items.slice(0, ind),
        { ...snapshot, selected },
        ...state.items.slice(ind + 1),
      ],
      // because of the new snapshots, indexes will not be accurate so IDs will be used instead
      lastSelected: selected ? snapshotId : null,
    };
  }

  case SELECT_ALL_SNAPSHOTS: {
    return {
      ...state,
      items: state.items.map((snapshot) => (
        snapshot.selected ? snapshot : { ...snapshot, selected: true }
      )),
    };
  }

  case DESELECT_ALL_SNAPSHOTS: {
    return {
      ...state,
      items: state.items.map((snapshot) => (
        snapshot.selected ? { ...snapshot, selected: false } : snapshot
      )),
    };
  }

  case SELECT_INTERVAL: {
    const { start, end } = payload;

    return {
      ...state,
      items: state.items.map((snapshot, idx) => {
        if (idx >= start && idx <= end) {
          return { ...snapshot, selected: true };
        }

        return snapshot;
      }),
      lastSelected: null,
    };
  }

  default: {
    return state;
  }
  }
};
