import { pick } from 'lodash';

import {
  FETCH_USER_LIST,
  BAN_USER_LIST_ITEMS,
  SET_USER_LIST_PAGINATION_DATA,
  TOGGLE_EVENT_USER_ROW,
  DELETE_EVENT_USER_ROW,
  SET_EVENT_USER_FILTER,
  CLEAR_EVENT_USER_FILTER,
  SET_EVENT_USER_SORT,
  SET_EVENT_USER_SHARE_PLATFORMS,
} from './types';
import { FETCH_SNAPSHOTS_FILTER_OPTIONS } from '../../store/types/snapshots';

const initialState = {
  isFetching: false,
  isUpdating: false,
  isDeleting: false,
  isBanning: false,
  error: undefined,
  page: 0,
  pageSize: 50,
  totalRows: 0,
  items: [],
  selected: [],
  filterOptions: {},
  filters: {},
  sort: {},
  sharePlatforms: undefined,
};

export default (state = initialState, { type, payload = {} } = {}) => {
  switch (type) {
  case FETCH_USER_LIST: {
    const {
      data: items,
      error,
      isUpdate,
      totalRows,
    } = payload;

    return {
      ...state,
      isFetching: !items && !error && !isUpdate,
      isUpdating: !items && !error && isUpdate,
      error,
      items: items || state.items,
      selected: initialState.selected,
      totalRows: totalRows !== undefined ? totalRows : state.totalRows,
    };
  }

  case BAN_USER_LIST_ITEMS: {
    const { error, snapshotIds } = payload;

    return {
      ...state,
      isBanning: !error && !snapshotIds,
      items: !snapshotIds ? state.items : state.items.map((item) => (
        snapshotIds.includes(item._id)
          ? { ...item, hidden: !item.hidden }
          : item
      )),
    };
  }

  case SET_USER_LIST_PAGINATION_DATA: {
    const { page, pageSize } = payload;

    return {
      ...state,
      page: state.pageSize === pageSize ? page : 0,
      pageSize,
    };
  }

  case FETCH_SNAPSHOTS_FILTER_OPTIONS: {
    const { error, data } = payload;

    return {
      ...state,
      isFetching: !error && !data,
      filterOptions: data ? pick(data, ['sections', 'rows', 'seats']) : state.filterOptions,
    };
  }

  case TOGGLE_EVENT_USER_ROW: {
    const { isSelect, all, snapshotId } = payload;
    let selected;

    if (all) {
      selected = isSelect
        ? state.items.map(({ _id }) => _id)
        : initialState.selected;
    } else {
      selected = isSelect
        ? [...state.selected, snapshotId]
        : state.selected.filter((qId) => qId !== snapshotId);
    }

    return {
      ...state,
      selected,
    };
  }

  case DELETE_EVENT_USER_ROW: {
    const { error, snapshotIds } = payload;

    return {
      ...state,
      isDeleting: !error && !snapshotIds,
    };
  }

  case SET_EVENT_USER_FILTER: {
    const { filters } = payload;
    return { ...state, filters };
  }

  case CLEAR_EVENT_USER_FILTER: {
    return { ...state, filters: initialState.filters };
  }

  case SET_EVENT_USER_SORT: {
    const { sortField, sortOrder } = payload;

    return {
      ...state,
      sort: {
        field: sortField,
        order: sortOrder,
      },
    };
  }

  case SET_EVENT_USER_SHARE_PLATFORMS: {
    const { sharePlatforms } = payload;
    return { ...state, sharePlatforms };
  }

  default: {
    return state;
  }
  }
};
