import moment from 'moment-timezone';

export const randomPassword = () => Math.random().toString(36).substr(2, 10);

// TODO: remove - complexity of calling formatDate(value, patter)
// is the same as calling moment(value).format(pattern)
export const formatDate = (value, pattern) => moment(value).format(pattern);

export const formatDateToUTC = (value, pattern) => moment.utc(value).format(pattern);

export const formatDateToTimezone = (value, timezone, format) => (
  moment(value).tz(timezone).format(format)
);

export const streamToBase64 = (streamData) => {
  const uint8Arr = new Uint8Array(streamData);
  let binary = '';
  for (let i = 0; i < uint8Arr.length; i++) {
    binary += String.fromCharCode(uint8Arr[i]);
  }
  return `data:image/jpeg;base64,${btoa(binary)}`;
};

export const isDev = () => process.env.NODE_ENV === 'development';

export const getStartOfEpoch = () => {
  const date = new Date('1970-01-01');
  return date;
};

export const getEndOfEpoch = () => {
  const date = new Date('1970-01-02');
  const returnDate = new Date(date.getTime() - 1);
  return returnDate;
};

export const humanFileSize = (bytes, si) => {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  let u = -1;
  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  do {
    // eslint-disable-next-line no-param-reassign
    bytes /= thresh;
    u += 1;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);

  return `${bytes.toFixed(1)} ${units[u]}`;
};
