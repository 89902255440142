export const FETCH_SNAPSHOTS = 'FETCH_SNAPSHOTS';
export const ADD_SNAPSHOT = 'ADD_SNAPSHOT';
export const REMOVE_SNAPSHOTS = 'REMOVE_SNAPSHOTS';
export const SET_FAVORITE = 'SET_FAVORITE';
export const SET_BANNED_SNAPSHOTS = 'SET_BANNED_SNAPSHOTS';
export const SET_BANNED_USER = 'SET_BANNED_USER';
export const SET_SHARED = 'SET_SHARED';
export const TOGGLE_SELECTED_SNAPSHOTS = 'TOGGLE_SELECTED_SNAPSHOTS';
export const DESELECT_ALL_SNAPSHOTS = 'DESELECT_ALL_SNAPSHOTS';
export const SELECT_ALL_SNAPSHOTS = 'SELECT_ALL_SNAPSHOTS';
export const SELECT_INTERVAL = 'SELECT_INTERVAL';
export const FETCH_SNAPSHOTS_FILTER_OPTIONS = 'FETCH_SNAPSHOTS_FILTER_OPTIONS';
