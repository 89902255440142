import React from 'react';
import { Button } from 'react-bootstrap';

import { ReactSwal } from '../../../../helpers/swal';

const KickUser = ({ onKick }) => {
  const onKickClick = () => {
    onKick();
    ReactSwal.close();
  };

  return (
    <div className="swal2-actions text-center">
      <p>
        If you decide to proceed, the user will be kicked out from his/her session temporarily.
        The user is still able to use the camera by re-entering the queue.
        This action can maybe help in case of a frozen camera queue.
      </p>
      <br />
      <Button
        className="swal2-confirm btn btn-danger btn-lg"
        variant="danger"
        onClick={onKickClick}
      >
        Kick the user
      </Button>
      <Button
        className="swal2-confirm btn btn-danger btn-lg"
        variant="default"
        onClick={ReactSwal.close}
        autoFocus
      >
        Cancel
      </Button>
    </div>
  );
};

const BanUser = ({ onBan }) => {
  const onBanClick = () => {
    onBan();
    ReactSwal.close();
  };

  return (
    <div className="swal2-actions text-center">
      <p>
        By banning the user, the user will not be able to use the camera by re-entering the page.
        All the photos taken by this user will be moved, and no longer available to the user.
      </p>
      <br />
      <Button
        className="swal2-confirm btn btn-danger btn-lg"
        variant="danger"
        onClick={onBanClick}
      >
        Ban the user
      </Button>
      <Button
        className="swal2-confirm btn btn-danger btn-lg"
        variant="default"
        onClick={ReactSwal.close}
        autoFocus
      >
        Cancel
      </Button>
    </div>
  );
};

export const openKickDialog = (onKick) => ReactSwal.fire({
  title: 'Are you sure you want to Kick the user?',
  type: 'warning',
  html: <KickUser onKick={onKick} />,
});

export const openBanDialog = (onBan) => ReactSwal.fire({
  title: 'Are you sure you want to Ban the user?',
  type: 'warning',
  html: <BanUser onBan={onBan} />,
});
