import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import filterFactory from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt,
  faArrowDown,
  faArrowUp,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { faPauseCircle, faCheckCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';

import { useDropzone } from 'react-dropzone';
import { isEmpty } from 'lodash';
import { Button } from 'react-bootstrap';

import { humanFileSize } from '../../../../helpers/utils';
import { fetchConfigFilesList } from '../api';
import {
  setFiles,
  removeFile,
  uploadAllFiles,
  uploadFile,
} from '../actions';

import style from './style.module.scss';
import { displayErrorModal } from '../../../../helpers/swal';

import { buildURL, urls } from '../../../../helpers/urls';

const { mapping: { root: mapping, download } } = urls;

const generateStatus = (item) => {
  let icon;
  let text;
  let color;

  if (item.error) {
    text = 'Error';
    icon = faTimesCircle;
    color = '#EB5757';
  } else if (item.isUploaded) {
    text = 'Uploaded';
    icon = faCheckCircle;
    color = '#3373E4';
  } else if (!item.isUploaded) {
    icon = faPauseCircle;
    text = 'Pending';
    color = '#F2C94C';
  }

  return (
    <div className={style.statusContainer}>
      <FontAwesomeIcon icon={icon} style={{ color }} />
      <span>{text}</span>
    </div>
  );
};

const FileUpload = ({
  isUploading,
  files,
  setFilesAction,
  removeFileAction,
  uploadFileAction,
  uploadAllFilesAction,
}) => {
  useEffect(() => {
    fetchConfigFilesList()
      .then(({ files: list }) => {
        setFilesAction(list, true);
      })
      .catch((error) => {
        console.error(error);
        displayErrorModal({ text: 'We couldn\'t load config files, Please try again' });
      });
  }, []);

  const onDropHandler = (list) => {
    setFilesAction(list);
  };

  const mapToTable = () => files.map((item, idx) => {
    const actions = (
      <div className={style.actionButtonsContainer}>
        <Button
          disabled={isUploading || item.isUploaded || item.isUploading}
          onClick={() => uploadFileAction(item.file)}
          variant="outline-primary"
        >
          <FontAwesomeIcon icon={faArrowUp} />
        </Button>
        <Button onClick={() => removeFileAction(item, idx)} variant="outline-danger">
          <FontAwesomeIcon icon={faTimes} />
        </Button>
        <Button
          href={buildURL(mapping, download, item.file.name)}
          target="_blank"
          rel="noopener noreferrer"
          variant="outline-primary"
          disabled={!item.isUploaded}
        >
          <FontAwesomeIcon icon={faArrowDown} />
        </Button>
      </div>
    );

    const size = humanFileSize(item.file.size, true);

    const status = generateStatus(item);
    const key = `${item.file.name}-${item.file.size || 'noStamp'}-${idx}`;
    return {
      ...item,
      actions,
      size,
      status,
      key,
    };
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop: onDropHandler });

  const className = classnames(style.dropzone, {
    'border-primary': isDragActive || isDragAccept,
    'border-danger': isDragReject,
    'border-light': !(isDragActive || isDragAccept) && !isDragReject,
  });

  return (
    <div className={style.container}>
      <p className="m-3">
        Drag &#39;n&#39; drop camera config or row dictionary files.Or click to select files.
      </p>

      <div {...getRootProps()} className={className}>
        <input {...getInputProps()} />
        {isEmpty(files) && (
          <div className={style.emptyFilesContainer}>
            <div>
              <FontAwesomeIcon icon={faFileAlt} />
              <p>Choose Files</p>
            </div>
          </div>
        )}

        {!isEmpty(files) && (
          <div className={style.table}>
            {/* eslint-disable-next-line */}
            <div
              className={classnames('card', 'card-condenced')}
              onClick={(evt) => evt.stopPropagation()}
            >
              <ToolkitProvider
                keyField="key"
                data={mapToTable()}
                columns={[
                  { dataField: 'file.name', text: 'File Name', sort: true },
                  { dataField: 'size', text: 'File Size', sort: true },
                  { dataField: 'status', text: 'Status', sort: true },
                  { dataField: 'actions', text: 'Action' },
                ]}
                bootstrap4
              >
                {(props) => (
                  <BootstrapTable
                    {...props.baseProps}
                    wrapperClasses="table-responsive react-bs-table-card"
                    classes="card-table border-top"
                    filter={filterFactory()}
                    striped
                  />
                )}
              </ToolkitProvider>
            </div>
          </div>
        )}
        <div className={style.bottomButtons}>
          <Button variant="default" className="small">Choose File</Button>
          <Button
            variant="default"
            className="small"
            onClick={(e) => {
              uploadAllFilesAction();
              e.stopPropagation();
            }}
          >
            Upload All
          </Button>
        </div>
      </div>
    </div>
  );
};

export default connect(
  ({
    mappingInfo: {
      files: {
        isUploading,
        items: files,
      },
    },
  }) => ({
    isUploading,
    files,
  }),
  {
    setFilesAction: setFiles,
    removeFileAction: removeFile,
    uploadAllFilesAction: uploadAllFiles,
    uploadFileAction: uploadFile,
  },
)(FileUpload);
