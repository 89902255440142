import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { toggleGeofenceSettings } from '../actions';

const GeolocationToggle = ({ eventKey, geofenceEnabled, toggleGeofenceSettingsAction }) => {
  const decoratedOnClick = useAccordionToggle(eventKey, toggleGeofenceSettingsAction);

  return (
    <Button variant="link">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="switcher labelFirst">
        <span className="switcher-label">{geofenceEnabled ? 'Enabled' : 'Disabled'}</span>
        <input type="checkbox" checked={geofenceEnabled} className="switcher-input" onChange={decoratedOnClick} />
        <span className="switcher-indicator">
          <span className="switcher-yes" />
          <span className="switcher-no" />
        </span>
      </label>
    </Button>
  );
};

export default connect(
  ({ location: { geofence } }) => ({ geofenceEnabled: geofence.enabled }),
  { toggleGeofenceSettingsAction: toggleGeofenceSettings },
)(GeolocationToggle);
