import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import { SocketContext } from 'react-socket-io';
import classnames from 'classnames';

import { dismissNotification } from '../../actions';
import { displayErrorModal } from '../../../../../../helpers/swal';

import style from './style.module.scss';

const SnoozeBtn = ({
  children,
  className,
  title,
  userId,
  slugDate,
  onSuccess,
  dismissNotificationAction,
}) => {
  const [isSnoozing, setIsSnoozing] = useState();
  const socket = useContext(SocketContext);

  const onSnooze = () => {
    setIsSnoozing(true);
    socket.emit('snooze user', userId, slugDate, (success) => {
      setIsSnoozing(false);
      if (success) {
        dismissNotificationAction();
        if (onSuccess) {
          onSuccess();
        }
      } else {
        displayErrorModal();
      }
    });
  };

  return (
    <LaddaButton
      className={classnames(style.btn, className)}
      data-style={ZOOM_OUT}
      onClick={onSnooze}
      loading={isSnoozing}
      data-spinner-size={24}
      data-spinner-color="#F2994A"
      title={title}
    >
      {children}
    </LaddaButton>
  );
};

export default connect(
  ({
    events: {
      currentEvent: {
        slugDate,
      },
    },
  }) => ({
    slugDate,
  }),
  (dispatch, { notificationId }) => ({
    dismissNotificationAction: () => dispatch(dismissNotification(notificationId)),
  }),
)(SnoozeBtn);
