import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classname from 'classnames';
import { Event } from 'react-socket-io';
import {
  DropdownButton,
  Dropdown,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { socketEventNames } from '../../../../helpers/constants';
import { updateCameraStatus } from '../../../../store/actions/cameras';
import style from './style.module.scss';

const { MONITOR_SOCKET_DISCONNECTED, MONITOR_SOCKET_CONNECTED } = socketEventNames;
const ON = 'on';

const CameraStatus = ({ updateCameraStatusAction, cameraStatus = {} }) => {
  const keys = Object.keys(cameraStatus);
  const camNumber = keys.length;
  const activeCount = keys.filter((k) => cameraStatus[k] === ON).length;
  const semiActive = activeCount < camNumber && activeCount !== 0;

  useEffect(() => {
    updateCameraStatusAction();
  }, []);

  const dot = (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip>{semiActive ? `${camNumber - activeCount} Down` : `All ${activeCount > 0 ? 'On' : 'Off'}`}</Tooltip>}
    >
      <FontAwesomeIcon
        icon={faCircle}
        className={classname({
          [style.active]: activeCount > 0,
          [style.semiActive]: semiActive,
        })}
      />
    </OverlayTrigger>
  );

  const list = (
    keys.map((cam) => {
      const on = cameraStatus[cam] === ON;
      return (
        <Dropdown.Item key={cam}>
          <FontAwesomeIcon
            icon={faCircle}
            className={classname('mr-1 align-baseline', { [style.active]: on })}
          />
          <span>{cam.replace('cam', 'Camera ')}</span>
        </Dropdown.Item>
      );
    })
  );

  return (
    <div className={style.cameraStatusContainer}>
      <Event event={MONITOR_SOCKET_CONNECTED} handler={updateCameraStatusAction} />
      <Event event={MONITOR_SOCKET_DISCONNECTED} handler={updateCameraStatusAction} />
      <div className={style.dotWrapper}>
        {dot}
      </div>
      <div className="d-inline-block">
        <div>
          <DropdownButton variant="default" title="Cameras">
            {list}
          </DropdownButton>
        </div>
        <span className={style.ratio}>{`${activeCount}/${camNumber}`}</span>
      </div>
    </div>
  );
};

export default connect(
  ({ cameras: { status } }) => ({ cameraStatus: status }),
  { updateCameraStatusAction: updateCameraStatus },
)(CameraStatus);
