import React from 'react';

import style from './style.module.scss';

const ChartTooltip = ({
  active,
  payload,
  label,
  isPercentage,
}) => {
  if (active) {
    return (
      <div className={style.ChartTooltip}>
        <span className={style.tooltipTitle}>{`${label}`}</span>
        {payload.map((item) => (
          <div className={style.tooltipValueContainer} key={`tooltip-${item.dataKey}-${item.value}-${item.stroke}`}>
            <span
              className={style.dot}
              style={{ backgroundColor: item.stroke || item.color || item.fill }}
            />
            <span
              className={style.tooltipValue}
              style={{ color: item.stroke || item.color || item.fill }}
            >
              {isPercentage ? `${item.value.toFixed(2)}%` : `${item.value} ${item.dataKey}`}
            </span>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

export default ChartTooltip;
