import {
  SELECT_TAB,
  UPDATE_CORNER_PROPERTY,
  UPDATE_PATH,
  UPDATE_MULTIPLE_PATHS,
  UPDATE_REGION_TYPE,
  RESTORE_CLONED_VALUES,
  UPDATE_CLONED_VALUES_AFTER_SAVE,
  UPDATE_POSITION,
} from './types';

export const updatePath = (path, value) => ({
  type: UPDATE_PATH,
  payload: { path, value },
});

export const updatePaths = (pathsObj) => ({
  type: UPDATE_MULTIPLE_PATHS,
  payload: { pathsObj },
});

export const selectTab = (tabName) => ({
  type: SELECT_TAB,
  payload: tabName,
});

export const updateCornerValue = (corner, property, value) => ({
  type: UPDATE_CORNER_PROPERTY,
  payload: { corner, property, value },
});

export const updateRegionType = (regionType) => ({
  type: UPDATE_REGION_TYPE,
  payload: { regionType },
});

export const restoreClonedValues = (regionType) => ({
  type: RESTORE_CLONED_VALUES,
  payload: { regionType },
});

export const updateClonedValuesAfterSave = () => ({ type: UPDATE_CLONED_VALUES_AFTER_SAVE });

export const updatePosition = (pan, tilt, zoom, reference) => ({
  type: UPDATE_POSITION,
  payload: {
    pan,
    tilt,
    zoom,
    reference,
  },
});

