import {
  FETCH_TRIVIA_QUESTIONS,
  SET_TRIVIA_LANGUAGE,
  UPDATE_TRIVIA_QUESTION,
  DELETE_TRIVIA_QUESTIONS,
  ADD_NEW_TRIVIA_QUESTION,
  TOGGLE_TRIVIA_QUESTION,
  SAVE_TRIVIA_QUESTIONS,
  OPEN_NEW_QUESTION_MODAL,
  CLOSE_NEW_QUESTION_MODAL,
  SET_TRIVIA_QUESTIONS,
} from '../types';

const initialState = {
  isFetching: false,
  isDeleting: false,
  isSaving: false,
  selectedLanguage: undefined,
  isNewQuestionModalOpen: false,
  validationError: undefined,
  selected: [],
  items: [],
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
  case FETCH_TRIVIA_QUESTIONS: {
    const { error, data: items } = payload;

    return {
      ...state,
      isFetching: !error && !items,
      items: items || initialState.items,
      error,
    };
  }

  case SET_TRIVIA_LANGUAGE: {
    const { language: selectedLanguage } = payload;

    return {
      ...state,
      selectedLanguage,
    };
  }

  case UPDATE_TRIVIA_QUESTION: {
    const { rowId, dataField, newValue } = payload;

    const items = state.items.map((question) => {
      if (question._id === rowId) {
        return {
          ...question,
          [dataField]: newValue,
        };
      }

      return question;
    });

    return { ...state, items };
  }

  case DELETE_TRIVIA_QUESTIONS: {
    const { error, questionIds } = payload;

    return {
      ...state,
      isDeleting: !error && !questionIds,
      selected: questionIds ? initialState.selected : state.selected,
      items: questionIds
        ? state.items.filter(({ _id }) => !questionIds.includes(_id))
        : state.items,
    };
  }

  case ADD_NEW_TRIVIA_QUESTION: {
    const { question } = payload;
    const modifiedQuestion = { ...question, _id: question.question };

    return {
      ...state,
      items: [
        modifiedQuestion,
        ...state.items,
      ],
    };
  }

  case TOGGLE_TRIVIA_QUESTION: {
    const { isSelect, all, questionId } = payload;
    let selected;

    if (all) {
      selected = isSelect
        ? state.items.map(({ _id }) => _id)
        : initialState.selected;
    } else {
      selected = isSelect
        ? [...state.selected, questionId]
        : state.selected.filter((qId) => qId !== questionId);
    }

    return {
      ...state,
      selected,
    };
  }

  case SAVE_TRIVIA_QUESTIONS: {
    const { error, updated } = payload;

    return {
      ...state,
      isSaving: !error && !updated,
      validationError: error && error.body && error.body.error,
    };
  }

  case OPEN_NEW_QUESTION_MODAL: {
    return {
      ...state,
      isNewQuestionModalOpen: true,
    };
  }

  case CLOSE_NEW_QUESTION_MODAL: {
    return {
      ...state,
      isNewQuestionModalOpen: false,
    };
  }

  case SET_TRIVIA_QUESTIONS: {
    const importedQuestions = payload.map((question) => ({
      _id: question.question,
      ...question,
    }));

    return {
      ...state,
      items: [...state.items, ...importedQuestions],
    };
  }

  default: {
    return state;
  }
  }
};
