import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import ChartCard from '../../ChartComponents/ChartCard';
import { getPercentage, capitalize } from '../../utils';
import BarChart from '../../ChartComponents/BarChart';

import Loader from '../../../../shared/Loader';
import Info from '../../../../components/Info';

const ShareChart = ({
  shareInsights,
  error,
  isFetching,
}) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (!isEmpty(shareInsights)) {
      const totalAmmount = shareInsights.reduce((prev, curr) => (prev + curr.count), 0);

      setChartData(shareInsights.map((item) => ({
        name: capitalize(item.socialSource),
        total: item.count,
        percentage: getPercentage(item.count, totalAmmount),
      })));
    }
  }, [shareInsights]);

  if (isFetching) {
    return <Loader />;
  }

  if (error) {
    return (
      <div>
        Error querying Basic Data for the selected event
      </div>
    );
  }

  if (!isEmpty(chartData)) {
    return (
      <ChartCard title={(
        <Info
          label="Share"
          content="The percentage of each share option based on usage"
          className="ml-2"
        />
      )}
      >
        <BarChart
          barColor="var(--theme-yellow-dark)"
          data={chartData}
          xAxisKey="name"
          barKey="total"
          labelKey="percentage"
          hideYAxis
          renderLabel
        />
      </ChartCard>
    );
  }

  return null;
};

export default connect((
  { analytics: { basic: { isFetching, data: { shareInsights }, error } } },
) => (
  { isFetching, shareInsights, error }
))(ShareChart);
