import React from 'react';
import style from './style.module.scss';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    type="button"
    ref={ref}
    className={style.actionMenuIcon}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <i className="ion ion-md-more" />
  </button>
));

export default CustomToggle;
