import { buildURL } from '../../../helpers/urls';
import {
  get,
  callDelete,
  post,
  patch,
} from '../../../helpers/fetch';

const resourcePath = '/trivia/questions';

export const getTriviaQuestions = (language, eventId) => {
  const url = buildURL('/events', eventId, resourcePath, language);
  return get(url);
};

export const deleteTriviaQuestion = (questionIds) => {
  const url = buildURL('/trivia/questions');
  return callDelete(url, { questionIds });
};

export const updateTriviaQuestions = (language, eventIds, questions) => {
  const url = buildURL(resourcePath, language);
  return post(url, { language, eventIds, questions });
};

export const updateTrivia = (triviaEnabled, eventIds) => {
  const url = buildURL('/events/trivia');
  return patch(url, { triviaEnabled, eventIds });
};
