import React, {
  useCallback,
  useEffect,
  memo,
  useContext,
  useState,
} from 'react';
import Select from 'react-select';
import { Event, SocketContext } from 'react-socket-io';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';

import OverlayDropdown from './OverlayDropdown';
import { customSelectStylesOptions, socketEventNames } from './helper';
import {
  updateClonedValuesAfterSave,
  updatePath,
  updatePaths,
  updatePosition,
} from './actions';
import { getCameraStatus } from '../../helpers/api';
import camMap from './mapLogic';

import style from './style.module.scss';

const formatCorner = (corner, position) => ({
  position, // Assuming position is always a valid string input
  row: corner.row, // Already a string, so just pass it through
  seat: parseInt(corner.seat, 10), // Convert seat to an integer
  pan: parseInt(corner.pan, 10), // Convert pan to an integer
  tilt: parseInt(corner.tilt, 10), // Convert tilt to an integer
});
const cornerLabels = ['top_left', 'top_right', 'bottom_left', 'bottom_right'];

const { ROVER_JOIN_LIVESTREAM, ROVER_LEAVE_LIVESTREAM, ROVER_GET_POSITION } = socketEventNames;

const CameraConnect = () => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const update = useCallback((...args) => dispatch(updatePath(...args)), []);
  const availableCameras = useSelector(({ mapping: { availableCameras: cameras } }) => cameras);
  const selectedCamera = useSelector(({ mapping: { preview } }) => preview.camera);
  const connectedCamera = useSelector(({ mapping: { connectedCamera: cam } }) => cam);
  const [saveBtnVariant, setSaveBtnVariant] = useState('outline-primary');
  const [isSaveBtnLoading, setIsSaveBtnLoading] = useState(false);
  const currentTab = useSelector(({ mapping: { selectedTab } }) => selectedTab);
  const zoom = useSelector(({ mapping }) => (mapping.preview.zoom.value));
  const angle = useSelector(({ mapping }) => (mapping.preview.angle.value));

  const mapSectionName = useSelector(({ mapping }) => (mapping.map.name));
  const mapType = useSelector(({ mapping }) => (mapping.map.type));
  const mapTopLeft = useSelector(({ mapping }) => (mapping.map.topLeft));
  const mapTopRight = useSelector(({ mapping }) => (mapping.map.topRight));
  const mapBottomLeft = useSelector(({ mapping }) => (mapping.map.bottomLeft));
  const mapBottomRight = useSelector(({ mapping }) => (mapping.map.bottomRight));

  const checkSelectedIndex = useSelector(({ mapping }) => (mapping.check.selectedRegion.index));
  const checkSectionName = useSelector(({ mapping }) => (mapping.check.sectionName));
  const checkType = useSelector(({ mapping }) => (mapping.check.type));
  const checkTopLeft = useSelector(({ mapping }) => (mapping.check.topLeft));
  const checkTopRight = useSelector(({ mapping }) => (mapping.check.topRight));
  const checkBottomLeft = useSelector(({ mapping }) => (mapping.check.bottomLeft));
  const checkBottomRight = useSelector(({ mapping }) => (mapping.check.bottomRight));

  const clearMapCorners = () => {
    const emptyCorner = {
      pan: '', tilt: '', row: '', seat: '',
    };
    dispatch(updatePaths({
      'map.topLeft': { ...emptyCorner },
      'map.topRight': { ...emptyCorner },
      'map.bottomLeft': { ...emptyCorner },
      'map.bottomRight': { ...emptyCorner },
      'map.regionType': 'default',
      'map.type': 'section',
      'map.name': '',
      'preview.angle.value': 0,
    }));
  };

  const saveBtnHandler = useCallback(async () => {
    setIsSaveBtnLoading(true);
    let result = false;
    let topLeftCorner;
    let topRightCorner;
    let bottomLeftCorner;
    let bottomRightCorner;
    const rotationAngle = parseInt(angle, 10);
    const zoomFactor = parseInt(zoom, 10) / 1000;

    if (currentTab === 'map' && connectedCamera) {
      console.log('Saving the Map tab');
      topLeftCorner = formatCorner(mapTopLeft, cornerLabels[0]);
      topRightCorner = formatCorner(mapTopRight, cornerLabels[1]);
      bottomLeftCorner = formatCorner(mapBottomLeft, cornerLabels[2]);
      bottomRightCorner = formatCorner(mapBottomRight, cornerLabels[3]);

      console.log('sectionName', mapSectionName);
      console.log('type', mapType);
      console.log('topLeft', topLeftCorner);
      console.log('topRight', topRightCorner);
      console.log('bottomLeft', bottomLeftCorner);
      console.log('bottomRight', bottomRightCorner);

      result = await camMap.addRegion(
        mapSectionName?.trim(),
        mapType?.trim().toLowerCase(),
        topLeftCorner,
        topRightCorner,
        bottomLeftCorner,
        bottomRightCorner,
        rotationAngle,
        zoomFactor,
      );
      console.log('Result', result);
      if (result) {
        clearMapCorners();
      }
    } else if (currentTab === 'check' && connectedCamera) {
      console.log('Saving the Check tab');
      topLeftCorner = formatCorner(checkTopLeft, cornerLabels[0]);
      topRightCorner = formatCorner(checkTopRight, cornerLabels[1]);
      bottomLeftCorner = formatCorner(checkBottomLeft, cornerLabels[2]);
      bottomRightCorner = formatCorner(checkBottomRight, cornerLabels[3]);

      console.log('sectionName', checkSectionName);
      console.log('type', checkType);
      console.log('topLeft', topLeftCorner);
      console.log('topRight', topRightCorner);
      console.log('bottomLeft', bottomLeftCorner);
      console.log('bottomRight', bottomRightCorner);

      result = await camMap.updateRegion(
        checkSelectedIndex,
        checkSectionName.trim(),
        checkType.trim().toLowerCase(),
        topLeftCorner,
        topRightCorner,
        bottomLeftCorner,
        bottomRightCorner,
        rotationAngle,
        zoomFactor,
      );

      if (result) {
        dispatch(updateClonedValuesAfterSave());
      }
    }

    setSaveBtnVariant(result ? 'success' : 'danger');
    setTimeout(() => {
      setSaveBtnVariant('outline-primary');
      setIsSaveBtnLoading(false);
    }, 1000);
  });

  useEffect(() => {
    if (availableCameras.length === 0) {
      getCameraStatus().then((data) => {
        update('availableCameras', Object.keys(data).map((item) => ({ label: item, value: item })));
      }).catch((e) => console.error('Failed to fetch available cameras', e));
    }
  }, [availableCameras]);

  const disconnectHandler = useCallback(() => {
    if (connectedCamera) {
      socket.emit(ROVER_LEAVE_LIVESTREAM, 'map', connectedCamera, (disconnected) => {
        if (disconnected) {
          update('connectedCamera', null);
        }
      });
    }
  }, [connectedCamera, socket]);

  const connectHandler = useCallback(() => {
    if (!selectedCamera?.value) {
      return;
    }

    // This is a temp fix to change the namespace as all rover listeners and emitters are running on
    // main namespace '/' unlike monitor that is running on '/admin', this will change the namespace
    // once the connect button is pressed with a selected camera, if a new socket connection is
    // established we will receive the frames on both sockets, therefore it's best to change current
    socket.nsp = '/';
    socket.emit(ROVER_JOIN_LIVESTREAM, 'map', selectedCamera.value, (connected) => {
      if (connected) {
        update('connectedCamera', selectedCamera.value);
        socket.emit(ROVER_GET_POSITION, 'host', selectedCamera.value, '#');
      }
    });
  }, [selectedCamera, socket]);

  const positionHandler = ({ data, error }) => {
    if (error) {
      console.error('Error while getting position on connect:', error);
    }
    if (data) {
      const [p, t, z] = data.split(',');
      const reference = camMap.getReference();
      dispatch(updatePosition(p, t, z * 1000, reference));
    }
  };

  return (
    <div className={style.cameraConnect}>
      <Event event="position" handler={positionHandler} />
      <div>
        <Select
          placeholder="Select Camera"
          value={selectedCamera}
          options={availableCameras}
          styles={customSelectStylesOptions}
          onChange={(selected) => update('preview.camera', selected)}
        />
        <Button
          variant={connectedCamera ? 'outline-danger' : 'outline-primary'}
          className="small"
          onClick={connectedCamera ? disconnectHandler : connectHandler}
        >
          {connectedCamera ? 'Disconnect' : 'Connect'}
        </Button>
      </div>
      <OverlayDropdown />
      <Button
        variant={saveBtnVariant}
        onClick={saveBtnHandler}
        className="small"
        disabled={isSaveBtnLoading}
      >
        Save
      </Button>
    </div>
  );
};

export default memo(CameraConnect);
