import React, { memo } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/pro-regular-svg-icons';
import HoverDropdown from '../../../vendor/libs/hover-dropdown';
import { banHandler } from '../actionHelper';

const Ban = ({
  banned,
  snapshotId,
  userId,
  show,
}) => {
  if (!show) {
    return null;
  }

  const banBtnHandler = (userBan) => (evt) => {
    evt.stopPropagation();
    if (userBan) {
      banHandler(!banned, snapshotId, userId);
    } else {
      banHandler(!banned, snapshotId);
    }
  };

  return (
    <div className="actionButtonContainer">
      <HoverDropdown
        key={banned}
        onClick={(evt) => evt.stopPropagation()}
        as={DropdownButton}
        variant="default"
        title={<FontAwesomeIcon icon={faBan} />}
        className="dropdown-toggle-hide-arrow"
      >
        <Dropdown.Item onClick={banBtnHandler(false)}>
          {`${banned ? 'Unban' : 'Ban'} Photo`}
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={banBtnHandler(true)}>
          {`${banned ? 'Unban' : 'Ban'} User`}
        </Dropdown.Item>
      </HoverDropdown>
    </div>
  );
};

export default memo(Ban);
