export const FETCH_SNAPSHOTS = 'FETCH_SNAPSHOTS';
export const SET_CURRENT_ALBUM_PAGE = 'SET_CURRENT_ALBUM_PAGE';
export const SET_CURRENT_ALBUM_TAB = 'SET_CURRENT_ALBUM_TAB';
export const TOGGLE_FILTER_BAR = 'TOGGLE_FILTER_BAR';
export const TOGGLE_DISPLAY_OVERLAY = 'TOGGLE_DISPLAY_OVERLAY';

export const FETCH_SNAPSHOT_STATS = 'FETCH_SNAPSHOT_STATS';
export const RESET_SNAPSHOT_STATS = 'RESET_SNAPSHOT_STATS';
export const ADD_SNAPSHOT_TO_STATS = 'ADD_SNAPSHOT_TO_STATS';
export const REMOVE_SNAPSHOTS_FROM_STATS = 'REMOVE_SNAPSHOTS_FROM_STATS';
export const CHANGE_FAVORITE_ON_STATS = 'CHANGE_FAVORITE_ON_STATS';
export const INCREMENT_SHARED = 'INCREMENT_SHARED';
export const TOGGLE_AUTO_UPDATE = 'TOGGLE_AUTO_UPDATE';
