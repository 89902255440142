import React from 'react';

import FallbackImage from '../../../../assets/images/noDataGA.svg';

import style from './style.module.scss';

const GANoDataFallback = ({ title }) => (
  <div className={style.FallbackContainer}>
    <img src={FallbackImage} alt="Fallback for no data" />
    <h3>Data not available yet</h3>
    <p>
      {`Please come back within 48 hours after your event to view ${title} data.`}
    </p>
  </div>
);

export default GANoDataFallback;
