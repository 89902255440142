import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  setCurrentPage,
  fetchSnapshotStats,
  resetCurrentPage,
  resetCurrentTab,
} from './actions';
import { usePage } from '../../helpers/hooks';

import AlbumController from './AlbumController';
import AlbumPhotoContainer from './AlbumPhotoContainer';
import RightBar from '../../components/RightBar';
import FilterForm from './FilterForm';
import EventSelector from '../../components/EventSelector/Modal';
import MultiSnapshotControls from './MultiSnapshotControls';

import style from './style.module.scss';

const Album = (props) => {
  const {
    filterBarDisplayed,
    selectedEvents,
    selectedEventsPage,
    setCurrentPageAction,
    fetchSnapshotStatsAction,
    resetCurrentPageAction,
    resetCurrentTabAction,
  } = props;

  const page = usePage();

  useEffect(() => {
    if (selectedEventsPage === page) {
      fetchSnapshotStatsAction();
      setCurrentPageAction(1);
    }
  }, [selectedEvents, selectedEventsPage]);

  useEffect(() => () => {
    resetCurrentPageAction();
    resetCurrentTabAction();
  }, []);

  return (
    <div className={style.AlbumPage}>
      <MultiSnapshotControls />
      <AlbumController />
      <div className={style.MainContent}>
        <div className={style.Snapshots}>
          <AlbumPhotoContainer />
        </div>
        <RightBar showBar={filterBarDisplayed} minWidth="250px">
          <div className={style.FilterContainer}>
            {filterBarDisplayed && <FilterForm />}
          </div>
        </RightBar>
      </div>
      <EventSelector />
    </div>
  );
};

export default connect(
  ({
    album: {
      options: {
        filterBarDisplayed,
      },
    },
    events: {
      selectedEvents,
      selectedEventsPage,
    },
  }) => ({
    filterBarDisplayed,
    selectedEvents,
    selectedEventsPage,
  }),
  {
    setCurrentPageAction: setCurrentPage,
    fetchSnapshotStatsAction: fetchSnapshotStats,
    resetCurrentPageAction: resetCurrentPage,
    resetCurrentTabAction: resetCurrentTab,
  },
)(Album);
