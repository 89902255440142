import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { isEmpty } from 'lodash';

import {
  faGlasses,
  faGlassesAlt,
  faSmile,
  faSmileBeam,
  faLaughBeam,
} from '@fortawesome/pro-regular-svg-icons';

import BeardIcon from '../../../../assets/images/beard.svg';
import MoustacheIcon from '../../../../assets/images/moustache.svg';

import ChartCard from '../../ChartComponents/ChartCard';
import Info from '../../../../components/Info';
import TraitItem from './TraitItem';

const TraitsChart = ({
  beard,
  eyeglasses,
  eyesOpen,
  moustache,
  mouthOpen,
  smile,
  sunglasses,
}) => {
  const isDataEmpty = (
    isEmpty(beard)
    && isEmpty(eyeglasses)
    && isEmpty(eyesOpen)
    && isEmpty(moustache)
    && isEmpty(mouthOpen)
    && isEmpty(smile)
    && isEmpty(sunglasses)
  );

  if (isDataEmpty) {
    return null;
  }

  return (
    <ChartCard
      title={(
        <Info
          label="Faces and Acessories"
          content="The number of users in photos with different types of expressions and accessories"
          className="ml-2"
        />
      )}
    >
      <Row className="mt-3">
        <Col>
          <TraitItem title="Smiling" value={smile.yes} icon={faSmile} type="icon" />
        </Col>
        <Col>
          <TraitItem title="Eyes Closed" value={eyesOpen.no} icon={faSmileBeam} type="icon" />
        </Col>
        <Col>
          <TraitItem title="Mouth Open" value={mouthOpen.yes} icon={faLaughBeam} type="icon" />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <TraitItem title="Glasses" value={eyeglasses.yes} icon={faGlasses} type="icon" />
        </Col>
        <Col>
          <TraitItem title="Sunglasses" value={eyeglasses.yes} icon={faGlassesAlt} type="icon" />
        </Col>
        <Col>
          <TraitItem title="Beards" value={beard.yes} icon={BeardIcon} type="image" />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <TraitItem title="Moustaches" value={moustache.yes} icon={MoustacheIcon} type="image" />
        </Col>
      </Row>
    </ChartCard>
  );
};

export default connect(
  ({
    analytics: {
      briziVision: {
        data: {
          beard,
          eyeglasses,
          eyesOpen,
          moustache,
          mouthOpen,
          smile,
          sunglasses,
        },
      },
    },
  }) => ({
    beard,
    eyeglasses,
    eyesOpen,
    moustache,
    mouthOpen,
    smile,
    sunglasses,
  }),
)(TraitsChart);
