import React from 'react';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import style from './style.module.scss';

import { setFilterField } from './actions';

const availableEmotions = ['angry', 'surprised', 'fear', 'confused', 'sad', 'happy', 'calm'];

const EmotionsField = ({ emotions, setEmotionsAction }) => {
  const toggleAll = () => (emotions.length !== availableEmotions.length
    ? setEmotionsAction(availableEmotions)
    : setEmotionsAction([]));

  const toggleEmotion = (emotion) => {
    const ind = emotions.indexOf(emotion);

    if (ind === -1) {
      setEmotionsAction([...emotions, emotion]);
    } else {
      setEmotionsAction([...emotions.slice(0, ind), ...emotions.slice(ind + 1)]);
    }
  };

  const buildToggle = (emotion) => (
    <Button
      size="sm"
      variant="primary"
      key={emotion}
      value={emotion}
      active={emotions.includes(emotion)}
      onClick={() => toggleEmotion(emotion)}
    >
      {emotion[0].toUpperCase() + emotion.slice(1)}
    </Button>
  );

  return (
    <>
      <Form.Label className={style.FormLabel}>Emotions</Form.Label>
      <div className={style.EmotionButtons}>
        <Button active={emotions.length === availableEmotions.length} value="all" size="sm" onClick={toggleAll}>All</Button>
        {availableEmotions.map(buildToggle)}
      </div>
    </>
  );
};

export default connect(
  ({ album: { filterForm: { tempData: { emotions } } } }) => ({ emotions }),
  { setEmotionsAction: setFilterField('emotions') },
)(EmotionsField);
