import {
  faArrowToBottom,
  faEnvelope,
  faPrint,
  faShare,
  faShareSquare,
} from '@fortawesome/pro-regular-svg-icons';

const socialMediaIcons = {
  print: {
    icon: faPrint,
  },
  share: {
    icon: faShare,
  },
  'share level 2': {
    icon: faShareSquare,
  },
  download: {
    icon: faArrowToBottom,
  },
  email: {
    icon: faEnvelope,
  },
};

// eslint-disable-next-line import/prefer-default-export
export const getPlatformIcon = (platform) => socialMediaIcons[platform.toLowerCase()].icon;
