import React from 'react';

import CalendarContainer from './CalendarContainer';
import EventFormModal from './EventFormModal';

import '../../vendor/libs/react-datepicker/react-datepicker.scss';
import '../../vendor/libs/sweetalert2/sweetalert2.scss';

const CalendarPage = () => (
  <div>
    <CalendarContainer />
    <EventFormModal />
  </div>
);

export default CalendarPage;
