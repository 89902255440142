import React from 'react';
import style from '../style.module.scss';

const CameraHeader = ({ cameras }) => (
  <tr className={style.TopLabel}>
    <th className={style.TopLabel} />
    {cameras.map((camera) => (
      <th key={camera._id}>{camera.cameraKey}</th>
    ))}
  </tr>
);

export default CameraHeader;
