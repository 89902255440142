import ReactGA from 'react-ga';

import { isNil } from 'lodash';
import { isDev } from './utils';

export const gaOptions = {
  siteSpeedSampleRate: 100,
};

export const initializeGA = () => {
  ReactGA.initialize(process.env.DASHBOARD_GA_APPID, {
    debug: isDev(),
    testMode: isDev(),
    gaOptions,
  });
};

export const logGAPageView = (path) => {
  ReactGA.pageview(path);
};

export const logGAEvent = (category, action, label = null, value = null) => {
  if ([category, action].some(isNil)) {
    throw new Error('Missing required parameters');
  }

  ReactGA.event({
    category,
    action,
    label,
    value,
  });
};

export const startGASession = (userId) => {
  if (isNil(userId)) {
    throw new Error('Missing required parameters');
  }

  ReactGA.set(
    {
      userId,
      anonymizeIp: true,
      forceSSL: true,
      appName: process.env.BRIZI_APP_SLUG,
    },
  );
};
