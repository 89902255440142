import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faChair } from '@fortawesome/pro-regular-svg-icons';

import { convertTime } from '../../helpers/events';

const Description = ({
  show,
  section,
  row,
  seat,
  date,
  timezone,
}) => {
  if (!show) {
    return null;
  }

  return (
    <div className="descriptionBar">
      <div>
        <FontAwesomeIcon icon={faClock} className="mr-2" />
        {timezone && convertTime(date, timezone, 'DD/MM/YYYY hh:mm:ss')}
      </div>
      <div>
        <FontAwesomeIcon icon={faChair} className="mr-2" />
        {section}
        &nbsp;
        &middot;
        &nbsp;
        {row}
        &nbsp;
        &middot;
        &nbsp;
        {seat}
      </div>
    </div>
  );
};

export default connect(({ events: { timezone } }) => ({ timezone }))(Description);
