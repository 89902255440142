import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import { Button, Form, Modal } from 'react-bootstrap';
import { get } from 'lodash';

import { displayErrorModal, displaySuccessModal } from '../../../../helpers/swal';
import { eventStatus as eventStatuses } from '../../../../helpers/constants';

import SaveOption from '../saveOption';

import style from './style.module.scss';

const initialData = {
  master: false,
  current: true,
  future: false,
};

const UpdateModal = ({
  show,
  onHide,
  selectedEvent,
  allEvents,
  template,
  serverErrorMessage,
  updateHandler,
}) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialData);

  useEffect(() => {
    setFormData(initialData);
  }, [show]);

  const toggleHandler = ({ currentTarget: { name } }) => {
    setFormData({ ...formData, [name]: !formData[name] });
  };

  const submitHandler = async (evt) => {
    evt.preventDefault();
    const IDs = [];

    if (formData.master) {
      IDs.push(template._id);
    }

    if (formData.current) {
      IDs.push(selectedEvent._id);
    }

    if (formData.future) {
      allEvents.forEach((event) => {
        if ((selectedEvent.template && event.eventStatus === eventStatuses.PRE)
          || (!selectedEvent.template && event.gameDate > selectedEvent.gameDate)) {
          IDs.push(event._id);
        }
      });
    }

    try {
      setLoading(true);
      await updateHandler(IDs);
      displaySuccessModal({ text: 'Event successfully updated', timer: 1500 });
    } catch (err) {
      console.error('Error updating event template:', err);
      if (serverErrorMessage) {
        displayErrorModal({ text: err.body.error });
      } else {
        displayErrorModal({ text: 'Error trying to update the event, try again' });
      }
    } finally {
      setLoading(false);
      onHide();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="sm"
      className={style.updateEventModalContainer}
    >
      <Form onSubmit={submitHandler}>
        <Modal.Header closeButton>
          <Modal.Title as="h3" className="w-100 text-center">Save your changes?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            Please save your changes to the desired event
            in order to update your share settings
          </p>
          <div className={style.optionsContainer}>
            <SaveOption
              label="Current Event"
              name="current"
              onToggle={toggleHandler}
              checked={formData.current}
              disabled={get(selectedEvent, 'template')}
            />
            <SaveOption
              label="Future Events"
              name="future"
              onToggle={toggleHandler}
              checked={formData.future}
            />
            <SaveOption
              label="Master Template"
              name="master"
              onToggle={toggleHandler}
              checked={formData.master}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="rounded-pill btn btn-outline-primary" onClick={onHide}>
            Cancel
          </Button>
          <LaddaButton
            disabled={!Object.values(formData).find((val) => val)}
            loading={loading}
            type="submit"
            variant="outline-primary"
            className="btn btn-primary"
            data-style={ZOOM_OUT}
          >
            Save
          </LaddaButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};


export default connect(
  ({
    events: {
      selectedEvents,
      allEvents,
      template,
    },
  }) => ({
    selectedEvent: selectedEvents[0],
    allEvents,
    template,
  }),
)(UpdateModal);
