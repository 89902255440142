import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { Event, SocketContext } from 'react-socket-io';

import { setCameraStatus, setDemoMode } from '../../store/actions/cameras';
import { setFullscreenSnapshot, setUserId } from '../../components/Snapshot/LightBox/actions';
import { setFirstSrs } from '../../pages/Monitor/actions';
import { socketEventNames } from '../../helpers/constants';

const {
  MONITOR_POWER_CAMPOOL,
  MONITOR_FIRST_SRS,
  MONITOR_DEMO_MODE_CHANGED,
  MONITOR_GET_DEMO_MODE,
} = socketEventNames;

const mapCamStatus = (action) => (cam, status) => action({ [cam]: (status ? 'on' : 'off') });

const GlobalSocketEvents = ({ setCameraStatusAction, setFirstSrsAction, setDemoModeAction }) => {
  const socket = useContext(SocketContext);

  useEffect(() => {
    socket.emit(MONITOR_GET_DEMO_MODE, (demoMode) => setDemoModeAction(demoMode));
  }, []);

  return (
    <>
      <Event event={MONITOR_POWER_CAMPOOL} handler={mapCamStatus(setCameraStatusAction)} />
      <Event event={MONITOR_FIRST_SRS} handler={setFirstSrsAction} />
      <Event event={MONITOR_DEMO_MODE_CHANGED} handler={setDemoModeAction} />
    </>
  );
};

export default connect(
  null,
  {
    setCameraStatusAction: setCameraStatus,
    setFullscreenSnapshotAction: setFullscreenSnapshot,
    setUserIdAction: setUserId,
    setFirstSrsAction: setFirstSrs,
    setDemoModeAction: setDemoMode,
  },
)(GlobalSocketEvents);
