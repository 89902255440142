import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Form } from 'react-bootstrap';

import { setFilterField } from './actions';

import style from './style.module.scss';

const mapOverlaysFn = (overlay, ind) => ({ label: `Overlay ${ind + 1}`, value: overlay });

const OverlaysField = ({ overlays, availableOverlays, setOverlaysAction }) => (
  <div className={style.customMultiValueInput}>
    <Form.Label className={style.FormLabel}>Overlay</Form.Label>
    <Select
      isMulti
      value={overlays}
      options={availableOverlays.map(mapOverlaysFn)}
      classNamePrefix="react-select"
      onChange={setOverlaysAction}
    />
  </div>
);

export default connect(
  ({
    album: {
      filterForm: {
        formOptions: { overlays: availableOverlays },
        tempData: { overlays },
      },
    },
  }) => ({
    availableOverlays,
    overlays,
  }),
  { setOverlaysAction: setFilterField('overlays') },
)(OverlaysField);
