import React, {
  memo,
  useRef,
  useEffect,
  useState,
  useContext,
} from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import { SocketContext } from 'react-socket-io';
import classNames from 'classnames';

import DropdownItem from './DropdownItem';

import { displayErrorModal } from '../../../../helpers/swal';
import camOffConfirmation from './camOffConfirmation';

import style from './style.module.scss';

const CamRow = ({
  camName,
  status,
  disabled,
  isLiveEvent,
}) => {
  const prevStatus = useRef();
  const [loading, setLoading] = useState(false);
  const socket = useContext(SocketContext);

  useEffect(() => {
    if (!loading) {
      return undefined;
    }

    const timeout = setTimeout(() => {
      displayErrorModal({
        timer: null,
        title: 'Error turning on/off camera',
        text: 'We did not recieve camera status change updates from the server.'
          + ' Please try again, or try powering the cameras manually.',
      });

      setLoading(false);
    }, 120 * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [loading]);

  useEffect(() => {
    if (prevStatus.current !== status) {
      setLoading(false);
    }
  }, [status]);

  const toggleCam = () => {
    prevStatus.current = status;

    const update = (power) => {
      socket.emit('switch power campool each', power, camName);
      setLoading(true);
    };

    if (status !== 'on') {
      update(true);
    } else if (isLiveEvent) {
      camOffConfirmation(() => update(false));
    } else {
      update(false);
    }
  };

  return (
    <Dropdown.Item as={DropdownItem}>
      <div className={style.cameraItem}>
        <div>
          {loading && <Spinner as="span" animation="border" variant="primary" size="sm" />}
          {!loading && <i className={classNames('fas fa-circle', status)} />}
          <span>{camName}</span>
        </div>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="switcher">
          <input
            type="checkbox"
            checked={status === 'on'}
            className="switcher-input"
            onChange={toggleCam}
            disabled={loading || disabled}
          />
          <span className="switcher-indicator">
            <span className="switcher-yes" />
            <span className="switcher-no" />
          </span>
        </label>
      </div>
    </Dropdown.Item>
  );
};

export default memo(CamRow);
