import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';
import { isUndefined } from 'lodash';

import { setSelectedEvents, fetchEvents } from '../../../store/actions/events';
import { openModal as openEventSelectorModal } from '../Modal/actions';
import { usePage } from '../../../helpers/hooks';

import style from './style.module.scss';

const EventSelectorButton = ({
  allEvents,
  selectedEvents,
  setSelectedEventsAction,
  openEventSelectorModalAction,
  showFutureEvents,
  fetchEventsAction,
}) => {
  const page = usePage();

  useEffect(() => {
    fetchEventsAction();
  }, []);

  let nextBtnClickHandler;
  let prevBtnClickHandler;
  let isPrevEventInFuture = false;
  let isNextEventInFuture = false;

  let eventsBtnContent = (
    <div>
      <Spinner as="span" animation="border" role="status" aria-hidden="true" />
    </div>
  );

  if (selectedEvents.length === 1) {
    if (selectedEvents[0].template) {
      eventsBtnContent = 'Master Template';
    } else {
      eventsBtnContent = selectedEvents[0].gameDateStr;
      const eventIndex = allEvents.findIndex(({ _id }) => _id === selectedEvents[0]._id);
      const nextIndex = eventIndex - 1;
      const prevIndex = eventIndex + 1;

      nextBtnClickHandler = allEvents[nextIndex]
        && (() => setSelectedEventsAction([allEvents[nextIndex]], page));

      prevBtnClickHandler = allEvents[prevIndex]
        && (() => setSelectedEventsAction([allEvents[prevIndex]], page));

      isPrevEventInFuture = allEvents[prevIndex] && allEvents[prevIndex].isFutureEvent;
      isNextEventInFuture = allEvents[nextIndex] && allEvents[nextIndex].isFutureEvent;
    }
  } else if (selectedEvents.length > 1) {
    eventsBtnContent = `Selected: ${selectedEvents.length}`;
  }

  return (
    <div className={style.EventSelectorButtonContainer}>
      <button
        type="button"
        onClick={prevBtnClickHandler}
        disabled={isUndefined(prevBtnClickHandler) || (!showFutureEvents && isPrevEventInFuture)}
      >
        <i className="d-block ion ion-ios-arrow-back" />
      </button>
      <Button size="sm" variant="outline-primary" onClick={openEventSelectorModalAction}>
        {eventsBtnContent}
      </Button>

      <button
        type="button"
        onClick={nextBtnClickHandler}
        disabled={isUndefined(nextBtnClickHandler) || (!showFutureEvents && isNextEventInFuture)}
      >
        <i className="d-block ion ion-ios-arrow-forward" />
      </button>
    </div>
  );
};

export default connect(
  ({ events: { allEvents, selectedEvents } }) => ({ allEvents, selectedEvents }),
  {
    setSelectedEventsAction: setSelectedEvents,
    openEventSelectorModalAction: openEventSelectorModal,
    fetchEventsAction: fetchEvents,
  },
)(EventSelectorButton);
