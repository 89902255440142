import React, { Component } from 'react';
import styles from './Loader.module.scss';

class Loader extends Component {
  async componentWillUnmount() {
    await new Promise((resolve) => setTimeout(() => resolve(), 5000));
  }

  render() {
    return (
      <div className={styles.Container}>
        <div className={styles.Spinner}>
          <span className="spinner-border">
            <span className="sr-only">Loading...</span>
          </span>
        </div>
      </div>
    );
  }
}

export default Loader;
