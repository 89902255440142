import {
  SET_CAMERA_STATUS,
  SET_CAMERA_QUEUE,
  SET_FIRST_SRS,
  SET_PAN_ZOOM,
  SET_DEMO_MODE,
} from '../types/cameras';

const initialState = {
  status: {},
  queue: {},
  firstSrs: {},
  panAndZoom: {},
  demoMode: 'OFF',
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
  case SET_DEMO_MODE: {
    return {
      ...state,
      ...payload,
    };
  }
  case SET_CAMERA_STATUS: {
    const diff = Object.entries(payload).some(([key, val]) => state.status[key] !== val);

    if (diff) {
      return {
        ...state,
        status: { ...state.status, ...payload },
      };
    }

    return state;
  }

  case SET_CAMERA_QUEUE: {
    const firstSrs = { ...state.firstSrs };
    const panAndZoom = { ...state.panAndZoom };

    Object.entries(payload).forEach(([cam, queue]) => {
      if (queue.length === 0) {
        delete firstSrs[cam];
        delete panAndZoom[cam];
      }
    });

    return {
      ...state,
      firstSrs,
      panAndZoom,
      queue: { ...state.queue, ...payload },
    };
  }

  case SET_FIRST_SRS: {
    return {
      ...state,
      firstSrs: { ...state.firstSrs, ...payload },
    };
  }

  case SET_PAN_ZOOM: {
    return {
      ...state,
      panAndZoom: { ...state.panAndZoom, ...payload },
    };
  }

  default: {
    return state;
  }
  }
}
