import { connect } from 'react-redux';
import { useEffect } from 'react';
import { setShiftKeyPress } from './actions';

const KeyListener = ({ setShiftKeyPressAction }) => {
  useEffect(() => {
    const keyDown = (evt) => {
      if (evt.shiftKey) {
        setShiftKeyPressAction(true);
      }

      // metaKey is the command for Mac
      if ((evt.ctrlKey || evt.metaKey) && evt.key === 'p') {
        window.print();
        evt.preventDefault();
      }
    };

    const keyUp = (evt) => {
      if (evt.key === 'Shift') {
        setShiftKeyPressAction(false);
      }
    };

    document.addEventListener('keydown', keyDown);
    document.addEventListener('keyup', keyUp);

    return () => {
      document.removeEventListener('keydown', keyDown);
      document.removeEventListener('keyup', keyUp);
    };
  }, []);

  return null;
};

export default connect(
  null,
  { setShiftKeyPressAction: setShiftKeyPress },
)(KeyListener);
