import { SET_LIST_ITEMS_PAGE_LEFT, SET_LIST_ITEMS_PAGE_RIGHT, FETCH_LIST_ITEMS } from '../type';
import { listSnapshots } from '../../../../helpers/api';
import { setFsSnapshot } from '../actions';
import { fetchImageMining } from '../InfoSection/actions';

export const setPageLeft = (page) => ({
  type: SET_LIST_ITEMS_PAGE_LEFT,
  payload: page,
});

export const setPageRight = (page) => ({
  type: SET_LIST_ITEMS_PAGE_RIGHT,
  payload: page,
});

const requestListItems = () => ({ type: FETCH_LIST_ITEMS });
const recieveListItems = (error, data, loadLeft) => ({
  type: FETCH_LIST_ITEMS,
  payload: { error, data, loadLeft },
});

export const fetchListItems = (loadLeft) => (dispatch, getState) => {
  const {
    lightbox: {
      snapshotList: {
        filter,
        userId,
        currentTab,
        itemsPerPage,
        currentPageLeft,
        currentPageRight,
      },
    },
    events: {
      selectedEvents,
    },
  } = getState();

  // select all events for user photos
  const slugDates = userId ? [] : selectedEvents.map(({ slugDate }) => slugDate);
  const newPage = loadLeft ? currentPageLeft - 1 : currentPageRight + 1;

  dispatch(requestListItems());

  listSnapshots(slugDates, currentTab, newPage, itemsPerPage, filter).then((data) => {
    dispatch(recieveListItems(null, data, loadLeft));
    const changePageFn = loadLeft ? setPageLeft : setPageRight;
    dispatch(changePageFn(newPage));
  }).catch((err) => {
    console.error(err);
    dispatch(recieveListItems(err));
  });
};

export const setFullscreenSnapshot = (snapshotId) => (dispatch, getState) => {
  const {
    lightbox: {
      snapshotList: {
        items: snapshots,
      },
      info: {
        imageMiningData: {
          open: imageMiningTabOpen,
        },
      },
    },
  } = getState();

  const snapshot = snapshots.find(({ _id }) => _id === snapshotId);
  dispatch(setFsSnapshot(snapshot));

  if (imageMiningTabOpen) {
    dispatch(fetchImageMining(snapshot._id));
  }
};
