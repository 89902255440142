import { isUndefined, omit, omitBy } from 'lodash';
import {
  get,
  post,
  callDelete,
  put,
  patch,
  handleResponse,
} from './fetch';

import { buildURL, urls } from './urls';

export const checkSession = () => {
  const url = buildURL(urls.auth.checkSession);
  return get(url);
};

export const login = (credentials) => {
  const url = buildURL(urls.auth.login);
  return post(url, credentials);
};

export const logout = () => {
  const url = buildURL(urls.auth.logout);
  return post(url);
};

export const createUser = (user) => {
  const url = buildURL(urls.users);
  return post(url, user);
};

export const getUserList = () => {
  const url = buildURL(urls.users);
  return get(url);
};

export const deleteUser = (userId) => {
  const url = buildURL(urls.users, userId);
  return callDelete(url);
};

export const updateUser = (data) => {
  const url = buildURL(urls.users, data._id);
  return post(url, data);
};

export const validateUsername = (username) => {
  const url = buildURL(urls.users, username);
  return get(url);
};

export const listEvents = () => {
  const fields = [
    '_id',
    'template',
    'slug',
    'slugDate',
    'eventStatus',
    'startTime',
    'endTime',
    'gameDate',
    'title',
    'overlays',
    'timezone',
    'availableLanguages',
  ];
  const searchParams = new URLSearchParams();
  fields.forEach((field) => searchParams.append('fields[]', field));
  const url = buildURL(urls.events.root, `?${searchParams}`);
  return get(url);
};

export const getCurrentEvent = (fields = []) => {
  const fieldsParams = fields.map((field) => `fields[]=${field}`).join('&');
  const url = buildURL(urls.events.root, process.env.BRIZI_APP_SLUG, `?${fieldsParams}`);
  return get(url);
};

export const createEvent = (event) => {
  const url = buildURL(urls.events.root);
  return post(url, event);
};

export const deleteEvent = (slugDate) => {
  const url = buildURL(urls.events.root, slugDate, urls.events.delete, 'false');
  return callDelete(url);
};

export const updateEvent = (event) => {
  const url = buildURL(urls.events.root);
  return put(url, event);
};

export const updateEventShareOptions = (event) => {
  const url = buildURL(urls.events.root, urls.events.share);
  return patch(url, event);
};

export const updateEventGeofence = (event) => {
  const url = buildURL(urls.events.root, urls.events.geofence);
  return patch(url, event);
};

export const ban = (banData) => {
  const url = buildURL(urls.snapshots.ban);
  return put(url, banData);
};

export const banSnapshots = (snapshotIds) => {
  const url = buildURL(urls.snapshots.ban);
  return post(url, snapshotIds);
};

export const favoriteSnapshots = (snapshotIds) => {
  const url = buildURL(urls.snapshots.favorite);
  return post(url, snapshotIds);
};

export const deleteSnapshots = (snapshotIds) => {
  const url = buildURL(urls.snapshots.delete);
  return post(url, snapshotIds);
};

const buildSnapshotParams = (slugDates, params, filterData) => {
  const reactSelectFields = ['userIDs', 'cameras', 'boxes', 'suites', 'sections', 'rows', 'seats', 'overlays'];
  const arrayFields = ['emotions', 'sharedPlatforms'];
  const primitiveValues = omit(filterData, [...reactSelectFields, ...arrayFields, 'seatType', 'date']);

  const searchParams = new URLSearchParams({
    ...params,
    ...omitBy(primitiveValues, isUndefined),
  });

  slugDates.forEach((slugDate) => searchParams.append('slugDate[]', slugDate));
  // loop through { [key]: [{ value: [value], label: [label] }] }
  // key.slice(0, -1) to remove "s" at the end
  reactSelectFields
    .filter((key) => filterData[key] !== undefined)
    .forEach((key) => (filterData[key] || []).forEach(({ value }) => searchParams.append(`${key.slice(0, -1)}[]`, value)));
  arrayFields
    .filter((key) => filterData[key] !== undefined)
    .forEach((key) => (filterData[key] || []).forEach((value) => searchParams.append(`${key}[]`, value)));

  const { date } = filterData;
  if (date?.date && date?.comparator) {
    searchParams.append('dateVal', date.date.toISOString());
    searchParams.append('dateComparator', date.comparator);
  }

  return searchParams;
};

export const getAvailableSnapshots = (slugDates, filterData = {}) => {
  const searchParams = buildSnapshotParams(slugDates, {}, filterData);
  const url = buildURL(urls.events.root, urls.snapshots.stats, `?${searchParams}`);
  return get(url);
};

export const listSnapshots = (slugDates, type = 'all', page = 0, pageSize = 100, filterData = {}, sort) => {
  const searchParams = buildSnapshotParams(slugDates, { type, page, pageSize }, filterData);

  if (sort?.field && sort?.order) {
    searchParams.append('sortField', sort.field);
    searchParams.append('sortOrder', sort.order);
  }

  const url = buildURL(urls.snapshots.list, `?${searchParams}`);

  return get(url);
};

export const fetchSnapshot = (snapshotId) => {
  const searchParams = new URLSearchParams();
  [
    'favorite',
    'rawCroppedURI',
    'sharedURI',
    'hidden',
    'userId',
    'section',
    'row',
    'seat',
    'postObj',
    'camera',
    'date',
  ].forEach((field) => searchParams.append('fields[]', field));

  const url = buildURL(`/snapshots/${snapshotId}?${searchParams}`);
  return get(url);
};

export const getFilterOptions = (slugDates) => {
  const searchParams = new URLSearchParams();
  slugDates.forEach((slugDate) => searchParams.append('slugDate[]', slugDate));
  const url = buildURL(urls.events.root, 'snapshots/filterOptions', `?${searchParams}`);
  return get(url);
};

export const getEventTemplate = () => {
  const url = buildURL(urls.events.event, process.env.BRIZI_APP_SLUG);
  return get(url);
};

export const getCameraStatus = () => {
  const url = buildURL(urls.cameras.root, urls.cameras.status);
  return get(url);
};

// This is not in use because we might remove an image file that is saved on other events
export const deleteImage = async (imageType, slugDate, imageName) => {
  const url = buildURL(`events/${slugDate}/images/${imageType}/${imageName}`);
  const body = new FormData();
  const response = await fetch(url, {
    body,
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
    },
    credentials: 'include',
  });

  return handleResponse(response);
};

export const uploadImage = async (imageCategory, slugDate, file) => {
  const url = buildURL(urls.events.root, slugDate, urls.events.images, imageCategory);
  const body = new FormData();

  body.append('image', file);

  const response = await fetch(url, {
    body,
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    credentials: 'include',
  });

  return handleResponse(response);
};
