import React from 'react';
import classnames from 'classnames';

import style from './style.module.scss';

const CheckIcon = ({ onClick, checked, inverted }) => (
  <div className={style.checkIcon}>
    <button
      type="button"
      onClick={onClick}
      className={classnames({
        'fas fa-check-circle unselected': !checked && !inverted,
        'fas fa-check-circle unselected inverted': !checked && inverted,
        'fas fa-check-circle selected': checked && !inverted,
        'fas fa-check-circle': checked && inverted,
      })}
    />
  </div>
);

export default CheckIcon;
