import { displayErrorModal } from '../../../../helpers/swal';
import { getNotifications, dismissNotification as dismissNotificationApi } from './apis';
import {
  FETCH_SUSPICIOUS_NOTIFICATIONS,
  SET_SUSPICIOUS_NOTIFICATION_ADDRESSED,
  FETCH_MORE_SUSPICIOUS_NOTIFICATIONS,
} from './types';

const requestNotifications = () => ({ type: FETCH_SUSPICIOUS_NOTIFICATIONS });
const receiveNotifications = (error, data) => ({
  type: FETCH_SUSPICIOUS_NOTIFICATIONS,
  payload: { error, data },
});

export const fetchNotifications = () => async (dispatch, getState) => {
  const { events: { currentEvent: { slugDate } } } = getState();
  dispatch(requestNotifications());

  try {
    const data = await getNotifications({ slugDate });
    dispatch(receiveNotifications(undefined, data));
  } catch (err) {
    console.error(err);
    displayErrorModal();
    dispatch(receiveNotifications(err));
  }
};

const requestMoreNotifications = () => ({ type: FETCH_MORE_SUSPICIOUS_NOTIFICATIONS });
const receiveMoreNotifications = (error, data) => ({
  type: FETCH_MORE_SUSPICIOUS_NOTIFICATIONS,
  payload: { error, data },
});

export const fetchMoreNotifications = (offset) => async (dispatch, getState) => {
  const { events: { currentEvent: { slugDate } } } = getState();
  dispatch(requestMoreNotifications());

  try {
    const data = await getNotifications({ slugDate, offset });
    dispatch(receiveMoreNotifications(undefined, data));
  } catch (err) {
    console.error(err);
    displayErrorModal();
    dispatch(receiveMoreNotifications(err));
  }
};

export const setAddressed = (id, addressed = true) => ({
  type: SET_SUSPICIOUS_NOTIFICATION_ADDRESSED,
  payload: { id, addressed },
});

export const dismissNotification = (id) => async (dispatch) => {
  dispatch(setAddressed(id));

  try {
    await dismissNotificationApi(id);
    dispatch(setAddressed(id));
  } catch (err) {
    console.error(err);
    displayErrorModal();
    dispatch(setAddressed(id, false));
  }
};
