import React, { useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import { Spinner, Button } from 'react-bootstrap';

import {
  fetchRoles,
  saveRole,
  deleteRole,
  selectRole,
  openNewRoleModal,
} from './actions';
import { getTopLevelPermissions } from '../../../components/Authorize/permissions';
import { ROLES_WRITE } from '../../../components/Authorize/permissions/resources';
import { useIsLoaded } from '../../../helpers/hooks';

import Permissions from './Permissions';
import Authorize from '../../../components/Authorize';
import SelectionButton from '../../../components/SelectionButton';

import style from './style.module.scss';
import NewRoleModal from './NewRoleModal';

const Roles = ({
  isFetching,
  isSaving,
  isDeleting,
  roles,
  selectedRoleInd,
  fetchRolesAction,
  saveRoleAction,
  deleteRoleAction,
  selectRoleAction,
  openNewRoleModalAction,
}) => {
  const selectedRole = roles[selectedRoleInd];
  const permissions = useMemo(getTopLevelPermissions, []);
  const isLoaded = useIsLoaded(isFetching);

  useEffect(() => {
    fetchRolesAction();
  }, []);

  return (
    <div className={style.container}>
      <div className="pageHeader">
        <h4>Role Editor</h4>

        <Authorize permissions={ROLES_WRITE}>
          <div className={style.buttonsContainer}>
            <LaddaButton
              className="btn btn-outline-danger small"
              data-style={ZOOM_OUT}
              disabled={!isLoaded || selectedRole.name === 'admin'}
              onClick={() => deleteRoleAction(selectedRole)}
              loading={isDeleting}
            >
              Delete role
            </LaddaButton>

            <Button
              variant="outline-primary"
              className="ml-2 small"
              disabled={!isLoaded}
              onClick={openNewRoleModalAction}
            >
              Add new role
            </Button>

            <LaddaButton
              className="ml-2 btn btn-primary small"
              data-style={ZOOM_OUT}
              onClick={() => saveRoleAction(selectedRole)}
              disabled={!isLoaded || selectedRole.saved}
              loading={isSaving}
            >
              Save
            </LaddaButton>
          </div>
        </Authorize>
      </div>

      <div className={classnames('d-flex', 'flex-column', 'flex-grow-1', style.content)}>
        {!isLoaded && (
          <div className="d-flex flex-grow-1 justify-content-center align-items-center">
            <Spinner variant="primary" animation="border" />
          </div>
        )}

        {isLoaded && (
          <div className="d-flex border">
            <div>
              <div className="px-3 py-3 border-bottom">
                <h3 className="mb-0">Roles</h3>
              </div>
              <div className="px-3 py-3">
                {roles.map((role, ind) => (
                  <SelectionButton
                    key={role.name}
                    label={role.name}
                    selected={ind === selectedRoleInd}
                    className={classnames({ 'mt-2': ind > 0 })}
                    onClick={() => selectRoleAction(role)}
                  />
                ))}
              </div>
            </div>
            <div className="flex-grow-1">
              <div className="px-3 py-3 border-left border-bottom">
                <h3 className="mb-0">Permissions</h3>
              </div>
              <div className="px-3 py-3 border-left">
                <Permissions permissions={permissions} />
              </div>
            </div>
          </div>
        )}
      </div>

      <NewRoleModal />
    </div>
  );
};

export default connect(
  ({
    roles: {
      isFetching,
      isSaving,
      isDeleting,
      roles,
      selectedRoleInd,
    },
  }) => ({
    isFetching,
    isSaving,
    isDeleting,
    roles,
    selectedRoleInd,
  }),
  {
    fetchRolesAction: fetchRoles,
    saveRoleAction: saveRole,
    deleteRoleAction: deleteRole,
    selectRoleAction: selectRole,
    openNewRoleModalAction: openNewRoleModal,
  },
)(Roles);
