import { setFavorite, setBannedSnapshots } from '../../../../store/actions/snapshots';
import { favoriteSnapshots, banSnapshots } from '../../../../helpers/api';
import { displayErrorModal } from '../../../../helpers/swal';
import { setFavoriteOnStats } from '../../actions';

export const toggleFavorite = (snapshots) => (dispatch) => {
  const updateObjects = [];
  const snapshotIds = [];
  let favoriteDelta = 0;

  snapshots.forEach(({ _id, favorite }) => {
    snapshotIds.push(_id);
    updateObjects.push({ _id, favorite: !favorite });
    favoriteDelta += favorite ? -1 : 1;
  });

  dispatch(setFavorite(updateObjects));
  dispatch(setFavoriteOnStats(favoriteDelta));

  favoriteSnapshots(snapshotIds).catch((err) => {
    console.error(err);
    // revert the action in case of failed request
    dispatch(setFavorite(snapshots.map(({ _id, favorite }) => ({ _id, favorite }))));
    dispatch(setFavoriteOnStats(-favoriteDelta));

    displayErrorModal();
  });
};

export const toggleBanned = (snapshots) => (dispatch) => {
  const updateObjects = snapshots.map(({ _id, hidden }) => ({ _id, hidden: !hidden }));
  const snapshotIds = snapshots.map(({ _id }) => _id);

  dispatch(setBannedSnapshots(updateObjects));

  banSnapshots(snapshotIds).catch((err) => {
    console.error(err);
    // revert the action in case of failed request
    dispatch(setFavorite(snapshots.map(({ _id, hidden }) => ({ _id, hidden }))));
    displayErrorModal();
  });
};
