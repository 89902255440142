import {
  CLOSE_BLOCKED_SEATS_MODAL,
  OPEN_BLOCKED_SEATS_MODAL,
  ADD_BLOCKED_SEAT,
  REMOVE_BLOCKED_SEAT,
  SET_BLOCKED_SEATS,
} from './types';

export const openBlockedSeatModal = () => ({ type: OPEN_BLOCKED_SEATS_MODAL });
export const closeBlockedSeatModal = () => ({ type: CLOSE_BLOCKED_SEATS_MODAL });
export const setBlockedSeats = (payload) => ({ type: SET_BLOCKED_SEATS, payload });

export const addBlockedSeat = (payload) => ({ type: ADD_BLOCKED_SEAT, payload });
export const removeBlockedSeat = (payload) => ({ type: REMOVE_BLOCKED_SEAT, payload });
