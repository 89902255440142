import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import SnoozeBtn from '../../MonitorNav/Notifications/Notification/SnoozeBtn';
import PhotoBtn from '../../MonitorNav/Notifications/Notification/PhotoBtn';
import BanBtn from '../../MonitorNav/Notifications/Notification/BanBtn';
import CloseBtn from '../../MonitorNav/Notifications/Notification/CloseBtn';

import { removeToast } from '../action';

import style from './style.module.scss';
import Info from '../../MonitorNav/Notifications/Notification/Info';

const Toast = ({
  id: notificationId,
  userId,
  allSRS,
  uniqueSeats,
  snapshotsTaken,
  removeToastAction,
}) => {
  const [removing, setRemoving] = useState();
  const timeoutRef = useRef();

  const closeHandler = () => {
    setRemoving(true);
    setTimeout(removeToastAction, 400);
  };

  const clearCloseTimouet = () => {
    clearTimeout(timeoutRef.current);
  };

  const startCloseTimeout = () => {
    timeoutRef.current = setTimeout(closeHandler, 5000);
  };

  useEffect(startCloseTimeout, []);

  return (
    <div
      onMouseEnter={clearCloseTimouet}
      onMouseLeave={startCloseTimeout}
      className={classnames('p-3', style.toast, { [style.tostOut]: removing })}
    >
      <CloseBtn
        className={style.closeBtn}
        onClick={closeHandler}
        notificationId={notificationId}
      />

      <div className="p-3">
        <Info placement="top" className="d-flex justify-content-center" allSRS={allSRS}>
          &nbsp;
          <h4 className="m-0">User Warning</h4>
        </Info>
      </div>

      <div className={style.data}>
        <p>
          <strong>UserID:</strong>
          &nbsp;
          <span>{userId}</span>
          &nbsp;&nbsp;&nbsp;
          <strong>Unique Seats:</strong>
          &nbsp;
          <span>{uniqueSeats}</span>
          &nbsp;&nbsp;&nbsp;
          <strong>Photos:</strong>
          &nbsp;
          <span>{snapshotsTaken}</span>
        </p>
      </div>

      <div className="d-flex justify-content-between">
        <SnoozeBtn
          title="Snooze"
          className={style.actionBtn}
          onSuccess={closeHandler}
          notificationId={notificationId}
          userId={userId}
        >
          Snooze
        </SnoozeBtn>

        <PhotoBtn title="Open User Photos" className={style.actionBtn} userId={userId}>
          View Photos
        </PhotoBtn>

        <BanBtn
          title="Ban User"
          className={style.actionBtn}
          onBan={closeHandler}
          notificationId={notificationId}
          userId={userId}
        >
          Ban
        </BanBtn>
      </div>
    </div>
  );
};

export default connect(
  null,
  (dispatch, { id: notificationId }) => ({
    removeToastAction: () => dispatch(removeToast(notificationId)),
  }),
)(Toast);
