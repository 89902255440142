import React, { useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';

import UpdateModal from './UpdateModal';

const UpdateButton = ({
  label,
  serverErrorMessage,
  updateHandler,
  validation = () => true,
}) => {
  const [showModal, setShowModal] = useState(false);
  const onHide = useCallback(() => setShowModal(false), [setShowModal]);

  const clickHandler = () => {
    if (validation()) {
      setShowModal(true);
    }
  };

  return (
    <div>
      <Button onClick={clickHandler} className="small">
        {label}
      </Button>

      <UpdateModal
        show={showModal}
        onHide={onHide}
        updateHandler={updateHandler}
        serverErrorMessage={serverErrorMessage}
      />
    </div>
  );
};

export default UpdateButton;
