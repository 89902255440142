/* eslint-disable consistent-return */
import { useState, useEffect } from 'react';

const attachObserver = (domEl, callback) => {
  const observer = new IntersectionObserver(([{ isIntersecting }]) => {
    callback(isIntersecting);
  });

  observer.observe(domEl);
  return observer;
};

// eslint-disable-next-line import/prefer-default-export
export const useLoadingObserver = (
  leftElRef,
  rightElRef,
  containerRef,
  snapshots,
  itemsPerPage,
  currentPageLeft,
) => {
  const [isLeftLoading, setIsLeftLoading] = useState();
  const [isRightLoading, setIsRightLoading] = useState();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const scrollHandler = () => {
      setTimeout(() => setScrolled(true), 300);
      containerRef.current.removeEventListener('scroll', scrollHandler);
    };

    if (!scrolled) {
      // we need to delay it because of the scroll on lightbox open
      // if we remove the timeout, openning the last photo
      // will cause the next page to load which btw is not a big problem
      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.addEventListener('scroll', scrollHandler);
        }
      }, 200);
    }

    return () => {
      containerRef.current.removeEventListener('scroll', scrollHandler);
    };
  }, [containerRef, scrolled]);

  useEffect(() => {
    if (leftElRef.current && snapshots.length > 0) {
      const observer = attachObserver(leftElRef.current, (isInViewport) => {
        if (isInViewport && scrolled) {
          setIsLeftLoading(true);
          setTimeout(() => {
            setScrolled(false);
          }, 300);
        }
      });

      return () => observer.disconnect();
    }
  }, [leftElRef, scrolled]);

  useEffect(() => {
    if (rightElRef.current && snapshots.length > 0) {
      const observer = attachObserver(rightElRef.current, (isInViewport) => {
        if (isInViewport && scrolled) {
          setIsRightLoading(true);
          setTimeout(() => {
            setScrolled(false);
          }, 300);
        }
      });

      return () => observer.disconnect();
    }
  }, [rightElRef, scrolled]);

  useEffect(() => {
    if (isRightLoading) {
      setTimeout(() => {
        rightElRef.current.scrollIntoView({ inline: 'center', behavior: 'smooth' });
      }, 50);
    }
  }, [isRightLoading]);

  useEffect(() => {
    if (snapshots && snapshots.length < itemsPerPage && currentPageLeft > 1) {
      // we need to delay it to the next event cycle
      // because we are calling unsetBoth on component init
      setTimeout(() => setIsLeftLoading(true));
    }
  }, [snapshots, currentPageLeft]);

  const unsetBoth = () => {
    setIsLeftLoading(false);
    setIsRightLoading(false);
  };

  return [isLeftLoading, isRightLoading, unsetBoth];
};
