import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { SocketContext } from 'react-socket-io';
import { useSelector } from 'react-redux';
import { socketEventNames } from './helper';

const { ROVER_MOVE } = socketEventNames;

const MoveToHomeButton = () => {
  const socket = useContext(SocketContext);
  const connectedCamera = useSelector(({ mapping: { connectedCamera: cam } }) => cam);

  return (
    <div>
      <Button
        onClick={() => {
          console.warn('move to home position');
          socket.emit(ROVER_MOVE, 'host', connectedCamera, '!0,0,0');
        }}
        variant="outline-primary"
        className="small"
      >
        Home
      </Button>
    </div>
  );
};

export default MoveToHomeButton;
