import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

import ChartCard from '../../ChartComponents/ChartCard';
import BarChart from '../../ChartComponents/BarChart';
import Info from '../../../../components/Info';

import style from './style.module.scss';

const HistogramChart = ({ histogramData }) => {
  const [chartData, setChartData] = useState([]);
  const [dataType, setDataType] = useState('mixed');

  useEffect(() => {
    if (!isEmpty(histogramData)) {
      const newChartData = [];
      histogramData[dataType].forEach((item) => {
        newChartData.push({
          name: item.label,
          total: item.value,
        });
      });
      setChartData(newChartData);
    }
  }, [histogramData, dataType]);

  const buttonsContainer = (
    <div className={style.ButtonsContainer}>
      <div className="text-center">
        <div className={style.eventControl}>
          <ToggleButtonGroup type="radio" name="eventStatus" value={dataType} onChange={setDataType}>
            <ToggleButton size="sm" value="mixed">Row + Section</ToggleButton>
            <ToggleButton size="sm" value="row">Row</ToggleButton>
            <ToggleButton size="sm" value="section">Section</ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </div>
  );

  if (!isEmpty(chartData)) {
    return (
      <ChartCard
        title={(
          <Info
            label="Top Row/Section (for Photos Taken)"
            content="The number of photos that are produced by the top 10 rows/sections. Aggregated by row, row and section, or section"
            className="ml-2"
          />
        )}
        header={buttonsContainer}
      >
        <BarChart
          data={chartData}
          dataKey="name"
          labelKey="percentage"
          xAxisKey="name"
          barKey="total"
          barColor="var(--theme-yellow-dark)"
          renderLabel
        />
      </ChartCard>
    );
  }

  return null;
};

export default connect(
  ({
    analytics: {
      basic: {
        data: {
          histogramSectionRow,
          histogramSection,
          histogramRow,
        },
      },
    },
  }) => ({
    histogramData: {
      mixed: histogramSectionRow,
      section: histogramSection,
      row: histogramRow,
    },
  }),
)(HistogramChart);
