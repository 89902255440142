import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';

const Share = ({ count, show }) => {
  if (!show || !count) {
    return null;
  }

  return (
    <div className="actionButtonContainer shareIcons">
      <OverlayTrigger
        placement="bottom"
        overlay={(
          <Tooltip>
            {`Shared ${count} time${count > 1 ? 's' : ''}`}
          </Tooltip>
        )}
      >
        <span>
          <FontAwesomeIcon icon={faShareAlt} />
        </span>
      </OverlayTrigger>
    </div>
  );
};

export default Share;
