import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';

import style from './style.module.scss';
import { setFilterField } from './actions';

const GenderField = ({ gender, setGenderAction }) => (
  <>
    <div className={style.GenderInputRow}>
      <Form.Check
        inline
        custom
        type="radio"
        id="All"
        label="All"
        checked={!gender}
        onChange={() => setGenderAction(undefined)}
      />
      <Form.Check
        inline
        custom
        type="radio"
        id="Female"
        label="Female"
        checked={gender === 'female'}
        onChange={() => setGenderAction('female')}
      />
    </div>
    <div className={style.GenderInputRow}>
      <Form.Check
        inline
        custom
        type="radio"
        id="Both"
        label="Both"
        checked={gender === 'both'}
        onChange={() => setGenderAction('both')}
      />
      <Form.Check
        inline
        custom
        type="radio"
        id="Male"
        label="Male"
        checked={gender === 'male'}
        onChange={() => setGenderAction('male')}
      />
    </div>
  </>
);

export default connect(
  ({ album: { filterForm: { tempData: { gender } } } }) => ({ gender }),
  { setGenderAction: setFilterField('gender') },
)(GenderField);
