import React from 'react';
import { ban, banSnapshots, deleteSnapshots } from '../../helpers/api';
import { ReactSwal, displayErrorModal, displaySuccessModal } from '../../helpers/swal';

export const banHandler = (banStatus, snapshotId, userId) => {
  const banAction = async () => {
    try {
      if (userId) {
        await ban({ userId, banStatus });
      } else {
        await banSnapshots([snapshotId]);
      }
      displaySuccessModal({ text: 'Your work has been saved', timer: 1500 });
    } catch (err) {
      console.error(err);
      displayErrorModal();
    }
  };

  return ReactSwal.fire({
    title: 'Are you sure?',
    text: userId
      ? 'Banning a user will ban all their photos'
      : 'Banning a photo will change the URL of that photo and remove it from social media',
    type: 'warning',
    confirmButtonText: banStatus ? 'Ban' : 'Unban',
    showConfirmButton: true,
    showCancelButton: true,
    customClass: {
      confirmButton: 'btn btn-danger btn-lg',
      cancelButton: 'btn btn-default btn-lg',
      actions: 'text-center',
    },
  }).then(({ value }) => {
    if (value) {
      banAction();
    }
    return value;
  });
};

export const deleteHandler = async (snapshotId, image) => {
  const deleteAction = async () => {
    try {
      await deleteSnapshots([snapshotId]);
      displaySuccessModal({ text: 'Snapshot is deleted', timer: 1500 });
    } catch (err) {
      console.error(err);
      displayErrorModal();
    }
  };

  try {
    const html = (
      <div>
        <h3>Delete Photo</h3>
        <p>
          Are you sure you would like to delete the photo?
          you wil not be able to recover this snapshot.
        </p>
        <div className="imageContainer">
          <img src={image} alt="" />
        </div>
      </div>
    );

    const result = await ReactSwal.fire({
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Confirm',
      html,
      customClass: {
        container: 'deletePhotoConfirmation',
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-default',
      },
    });

    if (result && result.value) {
      deleteAction();
    }

    return result && result.value;
  } catch (err) {
    console.error(err);
    displayErrorModal();
    return undefined;
  }
};
