import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faCheck } from '@fortawesome/pro-regular-svg-icons';

import { displayErrorModal } from '../../../../helpers/swal';

const CopyLink = ({ link }) => {
  const [linkCopied, setLinkCopied] = useState();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (linkCopied) {
      const timeout = setTimeout(() => {
        setLinkCopied(false);
      }, 1500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [linkCopied]);

  const copyToClipboardBtnHandler = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setLinkCopied(true);
    } catch (err) {
      displayErrorModal();
    }
  };

  return (
    <OverlayTrigger placement="right" overlay={<Tooltip>{linkCopied ? 'Copied!' : 'Copy'}</Tooltip>}>
      <button
        type="button"
        className="btn"
        onClick={copyToClipboardBtnHandler}
      >
        <FontAwesomeIcon icon={linkCopied ? faCheck : faLink} />
      </button>
    </OverlayTrigger>
  );
};

export default CopyLink;
