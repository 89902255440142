import { buildURL } from '../../../helpers/urls';
import { get, callDelete, post } from '../../../helpers/fetch';

export const getRoles = () => {
  const url = buildURL('/roles');
  return get(url);
};

export const deleteRole = (roleName) => {
  const url = buildURL(`/roles/${roleName}`);
  return callDelete(url);
};

export const saveRole = (roleName, permissions) => {
  const url = buildURL(`/roles/${roleName}`);
  return post(url, { permissions });
};
