import { buildURL } from '../../../../helpers/urls';

// eslint-disable-next-line import/prefer-default-export
export const getDownloadUrl = (selectedSnapshots, type = 'rawCroppedURI') => {
  const searchParams = new URLSearchParams({ type });

  selectedSnapshots.forEach(({ _id }) => {
    searchParams.append('snapshots[]', _id);
  });

  return buildURL('downloadArchive', `?${searchParams}`);
};
