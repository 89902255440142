export const customSelectStylesOptions = {
  control: (provided) => ({
    ...provided,
    background: '#fff',
    borderColor: '#9e9e9e',
    minHeight: '30px',
    height: '30px',
    minWidth: '150px',
    boxShadow: null,
  }),

  valueContainer: (provided) => ({
    ...provided,
    height: '30px',
    padding: '0 6px',
  }),

  // input: (provided) => ({
  //   ...provided,
  //   margin: '0px',
  // }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '30px',
  }),
};

export const seatOptions = [
  { label: 'Section', value: 'section' },
  { label: 'Box', value: 'box' },
  { label: 'Suite', value: 'suite' },
  { label: 'Custom type 1', value: 'custom_type_1' },
  { label: 'Custom type 2', value: 'custom_type_2' },
  { label: 'Custom type 3', value: 'custom_type_3' },
];


export const socketEventNames = Object.freeze({
  ROVER_JOIN_LIVESTREAM: 'join rover livestream',
  ROVER_LEAVE_LIVESTREAM: 'leave rover livestream',
  ROVER_GET_POSITION: 'get_position',
  ROVER_MOVE: 'move',
  TAKE_SHOT: 'take_shot',
  SEND_SHOT: 'send_shot',
});

export const inRange = (value, min, max) => typeof value === 'number' && value >= min && value <= max;

export const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
