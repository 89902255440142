import { TOGGLE_EXPORT_FIELD, TOGGLE_EXPORT_EVENT_FIELD } from './types';

const initialState = {
  isFetching: false,
  triviaEnabled: true,
  eventFields: {
    geofence: true,
    overlays: true,
  },
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
  case TOGGLE_EXPORT_FIELD: {
    const { field } = payload;
    return {
      ...state,
      [field]: !state[field],
    };
  }

  case TOGGLE_EXPORT_EVENT_FIELD: {
    const { field } = payload;
    return {
      ...state,
      eventFields: {
        ...state.eventFields,
        [field]: !state.eventFields[field],
      },
    };
  }

  default: {
    return state;
  }
  }
};
