import React from 'react';
import { FormCheck } from 'react-bootstrap';

export const rowSelection = ({ checked }) => (
  <FormCheck
    className="bootstrap-table-checkbox row-checkbox"
    inline
    label=""
    custom
    readOnly
    type="checkbox"
    checked={checked}
  />
);
export const headerSelection = (headerRef) => ({ checked }) => (
  <FormCheck
    className="bootstrap-table-checkbox header-checkbox"
    ref={headerRef}
    inline
    label=""
    custom
    readOnly
    type="checkbox"
    checked={checked}
  />
);
