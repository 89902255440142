import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Card,
  Collapse,
  Spinner,
} from 'react-bootstrap';

import { toggleInfoSection, fetchUserData } from '../InfoSection/actions';
import { convertTime } from '../../../../helpers/events';

import style from './style.module.scss';

const UserDataSection = (props) => {
  const {
    snapshot,
    event = {},
    isSectionOpen,
    userInfo,
    isFetching,
    toggleInfoSectionAction,
    fetchUserDataAction,
  } = props;

  const { timezone } = event;
  const { userId, magicshot } = snapshot;

  useEffect(() => {
    const userDataFetched = userInfo && userInfo.userId === userId;
    if (isSectionOpen && !userDataFetched) {
      fetchUserDataAction(userId);
    }
  }, [isSectionOpen, snapshot]);

  if (magicshot) {
    return null;
  }

  return (
    <Card>
      <Card.Header>
        <button
          type="button"
          onClick={() => toggleInfoSectionAction('userInfo')}
          aria-expanded={isSectionOpen}
        >
          User Information
        </button>
      </Card.Header>
      <Collapse in={isSectionOpen}>
        <div>
          <Card.Body>
            {isFetching && <Spinner variant="primary" animation="border" />}
            {!isFetching && userInfo && (
              <table className={style.userInformation}>
                <tbody>
                  <tr>
                    <td>Snapshots:</td>
                    <td>{userInfo.snapshots}</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      SRS List
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <div className={style.innerTableContainer}>
                        <table>
                          <thead>
                            <tr>
                              <th>
                                SRS
                              </th>
                              <th>
                                Number
                              </th>
                              <th>
                                Last Snapshot
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {userInfo.srsList.map((entry) => (
                              <tr key={entry.timestamp}>
                                <td>
                                  {`${entry.section}-${entry.row}-${entry.seat}`}
                                </td>
                                <td>
                                  {entry.snapshots}
                                </td>
                                <td>
                                  {timezone ? convertTime(entry.timestamp, timezone, 'MMM DD HH:mm:ss') : '-'}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                  {userInfo.socialData && (
                    <tr>
                      <td colSpan="2">
                        User Data
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan="2">
                      {
                        userInfo.socialData && userInfo.socialData.map((socialInfo) => (
                          <div className={style.innerTableContainer}>
                            <table className={style.innerTable}>
                              <tbody>
                                <tr key={`socialSource-${socialInfo.socialSource}-${socialInfo.userName}-${socialInfo.userEmail}`}>
                                  <td className={style.titleCell}>Platform</td>
                                  <td>{socialInfo.socialSource}</td>
                                </tr>
                                {socialInfo.userEmail && (
                                  <tr key={`userEmail-${socialInfo.socialSource}-${socialInfo.userName}-${socialInfo.userEmail}`}>
                                    <td className={style.titleCell}>User Email</td>
                                    <td>{socialInfo.userEmail}</td>
                                  </tr>
                                )}
                                {socialInfo.userName && (
                                  <tr key={`userName-${socialInfo.socialSource}-${socialInfo.userName}-${socialInfo.userEmail}`}>
                                    <td className={style.titleCell}>userName</td>
                                    <td>{socialInfo.userName}</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        ))
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </Card.Body>
        </div>
      </Collapse>
    </Card>
  );
};

export default connect(
  ({
    lightbox: {
      info: {
        userInfo: {
          open: isSectionOpen,
          data: userInfo,
          isFetching,
        },
      },
      snapshot: {
        data: snapshot,
      },
    },
    events: { allEvents },
  }) => ({
    userInfo,
    snapshot,
    isSectionOpen,
    isFetching,
    event: allEvents.find(({ slugDate }) => slugDate === snapshot.slugDate),
  }),
  {
    toggleInfoSectionAction: toggleInfoSection,
    fetchUserDataAction: fetchUserData,
  },
)(UserDataSection);
