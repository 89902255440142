import { OPEN_EVENT_SELECTOR_MODAL, CLOSE_EVENT_SELECTOR_MODAL } from './types';

const initialState = { isOpen: false };

export default (state = initialState, { type }) => {
  switch (type) {
  case OPEN_EVENT_SELECTOR_MODAL: {
    return {
      ...state,
      isOpen: true,
    };
  }
  case CLOSE_EVENT_SELECTOR_MODAL: {
    return {
      ...state,
      isOpen: false,
    };
  }
  default: {
    return state;
  }
  }
};
