import { batch } from 'react-redux';

import {
  SET_SNAPSHOT_LIST_ITEMS,
  SET_FULLSCREEN_SNAPSHOT,
  UNSET_FULLSCREEN_SNAPSHOT,
  SET_LIST_ITEMS_FILTER_DATA,
  SET_LARGE_IMAGE_URL,
  SET_SELECTED_FACE_IND,
  SET_USER_PHOTOS_ID,
  FETCH_FULLSCREEN_SNAPSHOT,
} from './type';

import { fetchSnapshot } from '../../../helpers/api';

const setItems = (items) => ({
  type: SET_SNAPSHOT_LIST_ITEMS,
  payload: [...items],
});

export const setFsSnapshot = (snapshot) => ({
  type: SET_FULLSCREEN_SNAPSHOT,
  payload: snapshot,
});

export const setLargeImageUrl = (payload) => ({
  type: SET_LARGE_IMAGE_URL,
  payload,
});

export const setUserId = (userId) => ({
  type: SET_USER_PHOTOS_ID,
  payload: userId,
});

const requestFullScreenSnapshot = () => ({ type: FETCH_FULLSCREEN_SNAPSHOT });
const recieveFullScreenSnapshot = (error, data) => ({
  type: FETCH_FULLSCREEN_SNAPSHOT,
  payload: { error, data },
});

export const setFullscreenSnapshot = (snapshotId, userTabSelected) => (dispatch, getState) => {
  const { snapshots: { items: snapshots } } = getState();
  const snapshot = snapshots.find(({ _id }) => _id === snapshotId);

  if (snapshot && !userTabSelected) {
    dispatch(setItems(snapshots));
    dispatch(setFsSnapshot(snapshot));
    return;
  }

  dispatch(requestFullScreenSnapshot());

  fetchSnapshot(snapshotId)
    .then((snap) => {
      batch(() => {
        dispatch(setUserId(snap.userId));
        dispatch(recieveFullScreenSnapshot(null, snap));
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch(recieveFullScreenSnapshot(err));
    });
};

export const unsetFullscreenSnapshot = () => ({ type: UNSET_FULLSCREEN_SNAPSHOT });

export const setListItemsFilterData = (filter) => ({
  type: SET_LIST_ITEMS_FILTER_DATA,
  payload: filter,
});

export const setSelectedFaceInd = (index) => ({
  type: SET_SELECTED_FACE_IND,
  payload: index,
});

export const toggleUserPhotosId = () => (dispatch, getState) => {
  const {
    lightbox: {
      snapshot: {
        data: {
          userId,
        },
      },
      snapshotList: {
        userId: snapshotListUserId,
      },
    },
  } = getState();

  if (snapshotListUserId) {
    dispatch(setUserId(null));
  } else {
    dispatch(setUserId(userId));
  }
};
