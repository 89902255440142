import {
  FETCH_SNAPSHOTS,
  REMOVE_SNAPSHOTS,
  SET_FAVORITE,
  ADD_SNAPSHOT,
  SET_BANNED_USER,
  SET_BANNED_SNAPSHOTS,
  SET_SHARED,
  TOGGLE_SELECTED_SNAPSHOTS,
  SELECT_INTERVAL,
  FETCH_SNAPSHOTS_FILTER_OPTIONS,
} from '../types/snapshots';

import { getFilterOptions } from '../../helpers/api';

export const requestSnapshots = () => ({ type: FETCH_SNAPSHOTS });

export const recieveSnapshots = (error, data) => ({
  type: FETCH_SNAPSHOTS,
  payload: { error, data },
});

export const addSnapshot = (snapshot, itemsPerPage) => ({
  type: ADD_SNAPSHOT,
  payload: { snapshot, itemsPerPage },
});

export const removeSnapshots = (snapshotIds) => ({
  type: REMOVE_SNAPSHOTS,
  payload: snapshotIds,
});

export const setFavorite = (updateObjects) => ({
  type: SET_FAVORITE,
  payload: updateObjects,
});

export const setBannedUser = ({ userId, banStatus }) => ({
  type: SET_BANNED_USER,
  payload: { userId, hidden: banStatus },
});

export const setBannedSnapshots = (updateObjects) => ({
  type: SET_BANNED_SNAPSHOTS,
  payload: updateObjects,
});

export const setSharedSnapshot = (snapshot) => ({
  type: SET_SHARED,
  payload: snapshot,
});

export const toggleSnapshotSelection = (snapshotId) => ({
  type: TOGGLE_SELECTED_SNAPSHOTS,
  payload: snapshotId,
});

export const selectInterval = (start, end) => ({
  type: SELECT_INTERVAL,
  payload: {
    start: Math.min(start, end),
    end: Math.max(start, end),
  },
});

export const toggleSelection = (snapshotId) => (dispatch, getState) => {
  const {
    keys: { shiftKey },
    snapshots: { items: snapshots, lastSelected },
  } = getState();

  if (shiftKey && lastSelected) {
    const start = snapshots.findIndex(({ _id }) => _id === lastSelected);
    const end = snapshots.findIndex(({ _id }) => _id === snapshotId);

    if (start >= 0 && end >= 0) {
      return dispatch(selectInterval(start, end));
    }
  }

  return dispatch(toggleSnapshotSelection(snapshotId));
};

const fetchFilterFormData = (error, data) => ({
  type: FETCH_SNAPSHOTS_FILTER_OPTIONS,
  payload: { error, data },
});

export const fetchFilterOptions = () => async (dispatch, getState) => {
  const { events: { selectedEvents } } = getState();
  const slugDates = selectedEvents.map(({ slugDate }) => slugDate);

  dispatch(fetchFilterFormData());

  if (slugDates.length === 0) {
    return;
  }

  try {
    const filterOptions = await getFilterOptions(slugDates);
    dispatch(fetchFilterFormData(null, filterOptions));
  } catch (err) {
    dispatch(fetchFilterFormData(err));
  }
};
