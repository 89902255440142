import React, { useContext, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { SocketContext } from 'react-socket-io';
import { toast } from 'react-toastify';
import { faCamera } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import RoundButton from '../../../../components/RoundButton';
import Authorize from '../../../../components/Authorize';

import { openKickDialog, openBanDialog } from './warningDialogs';
import { MAGICSHOTS_WRITE } from '../../../../components/Authorize/permissions/resources';

import style from './style.module.scss';

const Controls = ({ slugDate, cameraName, queue = [] }) => {
  const socket = useContext(SocketContext);
  const dialogRef = useRef();

  const kickBtnHandler = () => {
    dialogRef.current = openKickDialog(() => {
      socket.emit('boot user', queue[0]);
    });
  };

  const banBtnHandler = () => openBanDialog(() => {
    socket.emit('boot user', queue[0]);
    socket.emit('ban user', queue[0], null, true);
  });

  useEffect(() => {
    if (dialogRef.current) {
      dialogRef.current.close();
      dialogRef.current = null;
    }
  }, [queue]);

  const takeMagicshotHandler = () => {
    socket.emit('take MagicShot', slugDate, cameraName);
    toast('Taking Magicshot', { containerId: 'magicshot' });
  };

  return (
    <div className={style.controls}>
      <Authorize permissions={MAGICSHOTS_WRITE}>
        <RoundButton className={style.cameraBtn} onClick={takeMagicshotHandler}>
          <FontAwesomeIcon size="lg" icon={faCamera} className="align-middle" />
        </RoundButton>
      </Authorize>
      <div className={style.separator} />
      {queue.length > 0 && (
        <>
          <RoundButton onClick={kickBtnHandler}>
            Kick
          </RoundButton>
          <RoundButton onClick={banBtnHandler}>
            Ban
          </RoundButton>
        </>
      )}
    </div>
  );
};

export default connect(
  ({
    cameras: {
      queue,
    },
    events: {
      currentEvent: {
        slugDate,
      } = {},
    },
  }, {
    cameraName,
  }) => ({
    slugDate,
    cameraName,
    queue: queue[cameraName],
  }),
)(Controls);
