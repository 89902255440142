import { TOGGLE_INFO_SECTION, FETCH_IMAGE_MINING_DATA, FETCH_USER_DATA } from '../type';

const initialState = {
  imageInfo: {
    open: true,
  },
  exifData: {
    open: false,
  },
  imageMiningData: {
    open: false,
    snapshotId: undefined,
  },
  userInfo: {
    isFetching: false,
    open: false,
    userId: undefined,
  },
};

export default (state = initialState, { type, payload = {} } = {}) => {
  switch (type) {
  case TOGGLE_INFO_SECTION: {
    return {
      ...state,
      [payload]: {
        ...state[payload],
        open: !state[payload].open,
      },
    };
  }

  case FETCH_IMAGE_MINING_DATA: {
    const { snapshotId } = payload;

    return {
      ...state,
      imageMiningData: {
        ...state.imageMiningData,
        snapshotId,
      },
    };
  }

  case FETCH_USER_DATA: {
    const { data, error } = payload;
    return {
      ...state,
      userInfo: {
        ...state.userInfo,
        ...payload,
        isFetching: !error && !data,
      },
    };
  }

  default: {
    return state;
  }
  }
};
