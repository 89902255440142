import React, { useState, useEffect } from 'react';
import { Card, Collapse, Spinner } from 'react-bootstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

import { getMappingInfo } from './api';

import tableStyle from '../table.module.scss';

const numberFormat = Intl.NumberFormat();

const rowsMap = [
  ['numberOfSections', 'Number of Sections'],
  ['numberOfBoxes', 'Number of Boxes'],
  ['numberOfSuites', 'Number of Suites'],
  ['numberOfCustomType1', 'Number of Custom Type 1'],
  ['numberOfCustomType2', 'Number of Custom Type 2'],
  ['numberOfCustomType3', 'Number of Custom Type 3'],
  ['numberOfRegions', 'Number of Regions'],
  ['numberOfSeats', 'Number of Seats'],
  ['referencePan', 'Reference Pan'],
  ['referenceTilt', 'Reference Tilt'],
  ['maxZoom', 'Max Zoom'],
  ['avgZoom', 'Avg Zoom'],
  ['minZoom', 'Min Zoom'],
  ['maxAngle', 'Max Angle'],
  ['avgAngle', 'Avg Angle'],
  ['minAngle', 'Min Angle'],
  ['maxPan', 'Max Pan'],
  ['minPan', 'Min Pan'],
  ['maxTilt', 'Max Tilt'],
  ['minTilt', 'Min Tilt'],
];

const ParsedMappingInfo = () => {
  const [data, setData] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (open) {
      setLoading(true);
      setHasError(false);

      (async () => {
        try {
          const mappingData = await getMappingInfo();
          const rows = rowsMap.map(([rowKey, rowLabel]) => {
            const rowObj = { label: rowLabel };
            Object.entries(mappingData).forEach(([key, section]) => {
              rowObj[key] = section[rowKey] && numberFormat.format(section[rowKey]);
            });
            return rowObj;
          });

          setData(rows);
          setColumns([
            { text: 'Cameras', dataField: 'label', classes: 'font-weight-bold' },
            ...Object.keys(mappingData)
              .filter((key) => key.startsWith('cam'))
              .map((dataField) => ({
                dataField,
                text: `${dataField[0].toUpperCase()}${dataField.slice(1)}`,
              })),
            { text: 'Total', dataField: 'total' },
          ]);
        } catch (err) {
          setHasError(true);
          console.error(err);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [open]);

  return (
    <div className={classnames('mt-3', tableStyle.tableWrapper)}>
      <Card>
        <Card.Header onClick={() => setOpen(!open)}>
          <h5 className="mb-0">Parsed Mapping Info</h5>
          <FontAwesomeIcon
            className={classnames({ [tableStyle.rotated]: open })}
            icon={faChevronDown}
          />
        </Card.Header>
        <Collapse in={open}>
          <Card.Body className="p-0">
            {loading && (
              <div className="text-center p-4">
                <Spinner as="span" animation="border" aria-hidden="true" role="status" />
              </div>
            )}

            {hasError && (
              <div className="p-4 text-center">
                <span>Error trying to load Mapping Info</span>
              </div>
            )}

            {!hasError && !loading && data.length === 0 && (<span>No Mapping Info found</span>)}

            {!hasError && !loading && data.length > 0 && (
              <ToolkitProvider
                keyField="label"
                data={data}
                columns={columns}
                bootstrap4
              >
                {(props) => (
                  <BootstrapTable
                    {...props.baseProps}
                    wrapperClasses="table-responsive react-bs-table-card"
                    classes="card-table border-top"
                    striped
                  />
                )}
              </ToolkitProvider>
            )}
          </Card.Body>
        </Collapse>
      </Card>
    </div>
  );
};

export default ParsedMappingInfo;
