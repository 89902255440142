import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import InfoSection from './InfoSection';
import Image from './Image';
import Carousel from './Carousel';
import RightBar from '../../RightBar';

import { displayErrorModal } from '../../../helpers/swal';
import {
  setLargeImageUrl,
  setUserId,
  unsetFullscreenSnapshot,
  toggleUserPhotosId,
} from './actions';

import style from './style.module.scss';

const LightBox = ({
  isFetching,
  error,
  snapshot,
  userId,
  setUserIdAction,
  unsetFullscreenSnapshotAction,
  setLargeImageUrlAction,
  toggleUserPhotosIdAction,
}) => {
  const closeHandler = () => {
    if (userId) {
      toggleUserPhotosIdAction();
    }
    unsetFullscreenSnapshotAction();
    setLargeImageUrlAction(null);
  };

  useEffect(() => {
    const keydownHandler = (evt) => {
      if (evt.key === 'Escape') {
        closeHandler();
      }
    };

    if (snapshot) {
      document.addEventListener('keydown', keydownHandler);
    } else {
      document.removeEventListener('keydown', keydownHandler);
    }

    return () => {
      document.removeEventListener('keydown', keydownHandler);
    };
  }, [snapshot]);

  if (error) {
    displayErrorModal('Something went wrong. We couldn\'t load the snapshot. Please try again');
    setUserIdAction(null);
    return null;
  }

  if (!snapshot && !isFetching) {
    return null;
  }

  return (
    <Modal dialogClassName={style.lightBoxDialog} backdropClassName={style.lightBoxBackDrop} show>
      <div className={style.lightBoxContainer}>
        {isFetching && (
          <div className={style.loaderWrapper}>
            <Spinner animation="border" variant="primary" />
          </div>
        )}

        {!isFetching && (
          <>
            <div className={style.contentContainer}>
              <button type="button" onClick={closeHandler} className={style.closeButton}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <div className={style.imageContainer}>
                <Image />
              </div>
              <div className={style.infoContainer}>
                <InfoSection />
              </div>
            </div>
            <RightBar
              resizableOptions={{ top: true, left: false, minHeight: '150px' }}
              resizable
              showBar
              minHeight="150px"
              height="260px"
              defaultSize={{ height: 250 }}
            >
              <div className={style.carouselContainer}>
                <Carousel />
              </div>
            </RightBar>
          </>
        )}
      </div>
    </Modal>
  );
};

export default connect(
  ({
    lightbox: {
      snapshotList: {
        userId,
      },
      snapshot: {
        isFetching,
        error,
        data: snapshot,
      },
    },
  }) => ({
    isFetching,
    userId,
    snapshot,
    error,
  }),
  {
    setUserIdAction: setUserId,
    unsetFullscreenSnapshotAction: unsetFullscreenSnapshot,
    setLargeImageUrlAction: setLargeImageUrl,
    toggleUserPhotosIdAction: toggleUserPhotosId,
  },
)(LightBox);
