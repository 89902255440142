import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { openModal } from '../Modal/actions';
import style from './style.module.scss';

const PromotionButton = ({ openModalAction }) => (
  <Button
    className={style.promotionButton}
    size="sm"
    variant="outline-primary"
    onClick={openModalAction}
  >
    Promo
  </Button>
);

export default connect(null, { openModalAction: openModal })(PromotionButton);
