import {
  set,
  get,
  isEmpty,
} from 'lodash';
import {
  SELECT_TAB,
  UPDATE_CORNER_PROPERTY,
  UPDATE_PATH,
  UPDATE_MULTIPLE_PATHS,
  UPDATE_REGION_TYPE,
  RESTORE_CLONED_VALUES,
  UPDATE_CLONED_VALUES_AFTER_SAVE,
  UPDATE_POSITION,
} from './types';


const cornerInitialState = {
  pan: '',
  tilt: '',
  row: '',
  seat: '',
};

const maxPosition = 1000000000;
const minPosition = -1 * maxPosition;

const initialState = {
  availableCameras: [],
  connectedCamera: null,
  preview: {
    camera: null,
    referenceImage: null,
    pan: {
      min: minPosition,
      max: maxPosition,
      value: 0,
    },
    tilt: {
      min: minPosition,
      max: maxPosition,
      value: 0,
    },
    zoom: {
      min: 0,
      max: 1000,
      value: 0,
    },
    angle: {
      min: -30,
      max: 30,
      value: 0,
    },
    stepSpeed: {
      pan: {
        min: 1000000,
        max: 100000000,
        value: 10000000,
      },
      tilt: {
        min: 1000000,
        max: 100000000,
        value: 10000000,
      },
    },
    continuousSpeed: {
      pan: {
        min: 1,
        max: 100,
        value: 2,
      },
      tilt: {
        min: 1,
        max: 100,
        value: 2,
      },
      updatePosition: true,
    },
  },
  selectedTab: 'map',
  // this will determine where to set ptz when position is called
  positionPath: null,
  map: {
    type: 'section',
    name: '',
    regionType: 'default',
    topLeft: { ...cornerInitialState },
    topRight: { ...cornerInitialState },
    bottomLeft: { ...cornerInitialState },
    bottomRight: { ...cornerInitialState },
  },
  check: {
    selectedRegion: { index: null, value: '' },
    sectionName: null,
    type: null,
    topLeft: { ...cornerInitialState },
    topRight: { ...cornerInitialState },
    bottomLeft: { ...cornerInitialState },
    bottomRight: { ...cornerInitialState },
    clonedValues: {},
    disableSelect: false,
  },
  browse: {
    reference: {
      pan: '',
      tilt: '',
      zoom: '',
    },
    test: {
      section: '',
      row: '',
      seat: '',
    },
  },
  loadingStatus: '',
  overlayURL: '',
};

const updateIsSelectDisabled = (state) => {
  const clonedValues = get(state, 'check.clonedValues');
  if (!isEmpty(clonedValues)) {
    const isDisabled = Object.entries(clonedValues).some(([k, v]) => (
      get(state, k))?.toString() !== v?.toString());
    set(state, 'check.disableSelect', isDisabled);
  } else {
    set(state, 'check.disableSelect', false);
  }
};

const updatePanTiltLogic = (state, activeTab, property, corner, value, regionType) => {
  // if region is specified its the map tab, we update based on region type
  if (regionType) {
    set(state, `${activeTab}.${corner}.${property}`, value);

    if (regionType === 'singleSeat' || (regionType === 'singleRow' && corner.includes('Left'))) {
      set(state, `${activeTab}.topLeft.${property}`, value);
      set(state, `${activeTab}.bottomLeft.${property}`, value);
    }
    if (regionType === 'singleSeat' || (regionType === 'singleRow' && corner.includes('Right'))) {
      set(state, `${activeTab}.topRight.${property}`, value);
      set(state, `${activeTab}.bottomRight.${property}`, value);
    }
  }
  // if region is not specified (check tab), update all corners with same row and seat values
  if (!regionType) {
    const { row, seat } = get(state, `${activeTab}.${corner}`);
    if (get(state, `${activeTab}.topLeft.row`) === row && get(state, `${activeTab}.topLeft.seat`) === seat) {
      set(state, `${activeTab}.topLeft.${property}`, value);
    }

    if (get(state, `${activeTab}.topRight.row`) === row && get(state, `${activeTab}.topRight.seat`) === seat) {
      set(state, `${activeTab}.topRight.${property}`, value);
    }

    if (get(state, `${activeTab}.bottomLeft.row`) === row && get(state, `${activeTab}.bottomLeft.seat`) === seat) {
      set(state, `${activeTab}.bottomLeft.${property}`, value);
    }

    if (get(state, `${activeTab}.bottomRight.row`) === row && get(state, `${activeTab}.bottomRight.seat`) === seat) {
      set(state, `${activeTab}.bottomRight.${property}`, value);
    }
  }
};

export default function (state = initialState, { type, payload } = {}) {
  switch (type) {
  case UPDATE_PATH: {
    const { path, value } = payload;
    const updatedState = { ...state };
    set(updatedState, path, value);
    updateIsSelectDisabled(updatedState);

    return updatedState;
  }
  case UPDATE_MULTIPLE_PATHS: {
    const { pathsObj } = payload;
    const updatedState = { ...state };
    Object.entries(pathsObj).forEach(([path, value]) => set(updatedState, path, value));
    updateIsSelectDisabled(updatedState);
    return updatedState;
  }
  case SELECT_TAB: {
    const updatedState = { ...state };
    set(updatedState, 'selectedTab', payload);
    set(updatedState, 'check.clonedValues', {});
    return updatedState;
  }
  case UPDATE_CORNER_PROPERTY: {
    const { corner, property, value } = payload;
    const updatedState = { ...state };
    const activeTab = state.selectedTab;
    const regionType = get(state, `${activeTab}.regionType`);

    if (property === 'seat') {
      if (corner.includes('Right') || regionType === 'singleSeat') {
        set(updatedState, `${activeTab}.topRight.${property}`, value);
        set(updatedState, `${activeTab}.bottomRight.${property}`, value);
      }
      if (corner.includes('Left') || regionType === 'singleSeat') {
        set(updatedState, `${activeTab}.topLeft.${property}`, value);
        set(updatedState, `${activeTab}.bottomLeft.${property}`, value);
      }
    }

    if (property === 'row') {
      if (corner.includes('top') || ['singleSeat', 'singleRow'].includes(regionType)) {
        set(updatedState, `${activeTab}.topLeft.${property}`, value);
        set(updatedState, `${activeTab}.topRight.${property}`, value);
      }
      if (corner.includes('bottom') || ['singleSeat', 'singleRow'].includes(regionType)) {
        set(updatedState, `${activeTab}.bottomLeft.${property}`, value);
        set(updatedState, `${activeTab}.bottomRight.${property}`, value);
      }
    }

    if (['pan', 'tilt'].includes(property)) {
      updatePanTiltLogic(updatedState, activeTab, property, corner, value, regionType);
    }
    updateIsSelectDisabled(updatedState);
    return updatedState;
  }
  case UPDATE_REGION_TYPE: {
    const { regionType } = payload;
    const activeTab = state.selectedTab;
    const updatedState = { ...state };
    const topLeft = get(state, `${activeTab}.topLeft`);
    const topRight = get(state, `${activeTab}.topRight`);
    set(updatedState, `${activeTab}.regionType`, regionType);

    if (regionType === 'singleSeat') {
      // if seat or row are empty default them to 1
      topLeft.row = isEmpty(topLeft.row) ? '1' : topLeft.row;
      topLeft.seat = isEmpty(topLeft.seat) ? '1' : topLeft.seat;

      set(updatedState, `${activeTab}.topRight`, { ...topLeft });
      set(updatedState, `${activeTab}.bottomLeft`, { ...topLeft });
      set(updatedState, `${activeTab}.bottomRight`, { ...topLeft });
    }

    if (regionType === 'singleRow') {
      set(updatedState, `${activeTab}.bottomLeft`, { ...topLeft });
      set(updatedState, `${activeTab}.bottomRight`, { ...topRight });
    }

    updateIsSelectDisabled(updatedState);
    return updatedState;
  }
  case RESTORE_CLONED_VALUES: {
    const updatedState = { ...state };
    const clonedValues = get(updatedState, 'check.clonedValues', {});
    Object.entries(clonedValues).forEach(([path, value]) => set(updatedState, path, value));
    updateIsSelectDisabled(updatedState);
    return updatedState;
  }
  case UPDATE_CLONED_VALUES_AFTER_SAVE: {
    const updatedState = { ...state };
    const clonedValues = get(updatedState, 'check.clonedValues', {});
    const updatedClonedValues = {};

    Object.entries(clonedValues).forEach(([path]) => {
      updatedClonedValues[path] = get(updatedState, path);
    });
    set(updatedState, 'check.clonedValues', updatedClonedValues);
    updateIsSelectDisabled(updatedState);
    return updatedState;
  }
  case UPDATE_POSITION: {
    const updatedState = { ...state };
    const {
      pan,
      tilt,
      zoom,
      reference,
    } = payload;
    const { positionPath, selectedTab } = updatedState;
    const regionType = get(state, `${selectedTab}.regionType`);

    /* note:
      preview pos: always show raw position without considering ref point
      Browse tab: always show raw position without considering ref point
      Map tab: always show raw position without considering ref point
      Check tab ONLY: always show position with ref point calc
      position to sent to map file: mapFilePos = camPos - refPos
    */

    // set the preview values
    set(updatedState, 'preview.pan.value', pan);
    set(updatedState, 'preview.tilt.value', tilt);
    set(updatedState, 'preview.zoom.value', zoom);

    if (selectedTab === 'browse' && positionPath === 'browse.reference') {
      set(updatedState, 'browse.reference.pan', pan);
      set(updatedState, 'browse.reference.tilt', tilt);
      set(updatedState, 'browse.reference.zoom', zoom);
    }

    if (selectedTab === 'map' && positionPath) {
      updatePanTiltLogic(updatedState, selectedTab, 'pan', positionPath, pan, regionType);
      updatePanTiltLogic(updatedState, selectedTab, 'tilt', positionPath, tilt, regionType);
    }
    if (selectedTab === 'check' && positionPath) {
      // subtract ref point from position if we are on check tab
      const calculatedPan = pan - reference.pan;
      const calculatedTilt = tilt - reference.tilt;
      updatePanTiltLogic(updatedState, selectedTab, 'pan', positionPath, calculatedPan, null);
      updatePanTiltLogic(updatedState, selectedTab, 'tilt', positionPath, calculatedTilt, null);
      updateIsSelectDisabled(updatedState);
    }
    set(updatedState, 'positionPath', null);
    return updatedState;
  }
  default:
    return state;
  }
}
