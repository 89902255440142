import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignJustify, faTh } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import RoundButton from '../../../components/RoundButton';
import StandIcon from '../../../assets/srsIcons/stand.svg';
import BoxIcon from '../../../assets/srsIcons/box.svg';
import SuiteIcon from '../../../assets/srsIcons/suite.svg';
import { updateSeat } from './actions';

import style from './style.module.scss';

const srsIcon = {
  stand: {
    icon: StandIcon,
    text: 'Stand',
  },
  custom_type_1: {
    icon: StandIcon,
    text: 'Custom 1',
  },
  custom_type_2: {
    icon: StandIcon,
    text: 'Custom 2',
  },
  custom_type_3: {
    icon: StandIcon,
    text: 'Custom 3',
  },
  box: {
    icon: BoxIcon,
    text: 'Box',
  },
  suite: {
    icon: SuiteIcon,
    text: 'Suite',
  },
};

const SeatOption = ({ seat, type, updateSeatAction }) => {
  const { enabled, mode, forcedDisable } = seat;
  const { icon, text } = srsIcon[type];

  const update = (updateObj) => () => {
    updateSeatAction(type, updateObj);
  };

  return (
    <div className={style.seatOption} key={`${type}-${mode}-${enabled}`}>
      <div className={style.header}>
        <span className={style.platform}>{text}</span>
        <Button variant="link pr-0 pb-0">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="switcher mr-0">
            <input
              type="checkbox"
              defaultChecked={enabled}
              className="switcher-input"
              disabled={!enabled && !forcedDisable}
              onChange={update({
                enabled: !seat.enabled,
                forcedDisable: !forcedDisable ? true : forcedDisable,
              })}
            />
            <span className="switcher-indicator">
              <span className="switcher-yes" />
              <span className="switcher-no" />
            </span>
          </label>
        </Button>
      </div>
      <div className={style.body}>
        <img src={icon} alt="srs icon" />
        <div>
          Mode
        </div>
        <div className={style.iconContainer}>
          <div>
            <RoundButton
              onClick={update({ mode: 'grid' })}
              className={classnames(style.actionButton, { [style.active]: mode === 'grid' })}
            >
              <FontAwesomeIcon icon={faTh} size="1x" />
            </RoundButton>
          </div>
          <RoundButton
            onClick={update({ mode: 'list' })}
            className={classnames(style.actionButton, { [style.active]: mode === 'list' })}
          >
            <FontAwesomeIcon icon={faAlignJustify} size="1x" />
          </RoundButton>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { updateSeatAction: updateSeat })(SeatOption);
