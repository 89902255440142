import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  memo,
} from 'react';
import { Event } from 'react-socket-io';
import { useSelector } from 'react-redux';
import TakeSnapshotButton from './TakeSnapshotButton';

import style from './style.module.scss';

const getCropCoordinates = (angleInRadians, imageDimensions) => {
  const ang = angleInRadians;
  const img = imageDimensions;

  // eslint-disable-next-line no-bitwise
  const quadrant = Math.floor(ang / (Math.PI / 2)) & 3;
  // eslint-disable-next-line no-bitwise
  const signAlpha = (quadrant & 1) === 0 ? ang : Math.PI - ang;
  const alpha = ((signAlpha % Math.PI) + Math.PI) % Math.PI;

  const bb = {
    w: img.w * Math.cos(alpha) + img.h * Math.sin(alpha),
    h: img.w * Math.sin(alpha) + img.h * Math.cos(alpha),
  };

  const gamma = img.w < img.h ? Math.atan2(bb.w, bb.h) : Math.atan2(bb.h, bb.w);

  const delta = Math.PI - alpha - gamma;

  const length = img.w < img.h ? img.h : img.w;
  const d = length * Math.cos(alpha);
  const a = (d * Math.sin(alpha)) / Math.sin(delta);

  const y = a * Math.cos(gamma);
  const x = y * Math.tan(gamma);

  return {
    x: Math.floor(x),
    y: Math.floor(y),
    w: Math.floor(bb.w - 2 * x),
    h: Math.floor(bb.h - 2 * y),
  };
};


const StreamPreview = () => {
  const containerRef = useRef();
  const imageRef = useRef();
  const connectedCamera = useSelector(({ mapping: { connectedCamera: cam } }) => cam);
  const angle = useSelector(({ mapping: { preview: { angle: { value } } } }) => value);
  const referenceImage = useSelector(({ mapping: { preview: { referenceImage: img } } }) => img);
  const overlay = useSelector(({ mapping: { overlayURL } }) => overlayURL);
  const [cropCoordinates, setCropCoordinates] = useState();
  const frameRateRef = useRef();
  const frameTimestamp = useRef();

  const liveMapHandler = useCallback((arrayBuffer) => {
    const base64String = Buffer.from(arrayBuffer).toString('base64');
    imageRef.current.src = `data:image/jpeg;base64,${base64String}`;

    const currentTime = performance.now();
    if (frameTimestamp?.current) {
      const deltaTimeInSeconds = (currentTime - frameTimestamp.current) / 1000;
      frameRateRef.current.innerHTML = deltaTimeInSeconds.toFixed(3);
    }
    frameTimestamp.current = currentTime;
  }, []);

  useEffect(() => {
    if (imageRef?.current) {
      imageRef.current.src = '';
    }
  }, [connectedCamera]);

  useEffect(() => {
    const updateCropCoordinates = () => {
      if (containerRef.current) {
        const height = containerRef.current.offsetHeight;
        if (angle === 0) {
          setCropCoordinates({ w: height, h: height });
        }
        const rotationAngle = (-1 * angle) * (Math.PI / 180);
        setCropCoordinates(getCropCoordinates(rotationAngle, { w: height, h: height }));
      }
    };

    // Initial call
    updateCropCoordinates();

    let resizeObserver;
    if (typeof ResizeObserver !== 'undefined') {
      // Update on resize
      resizeObserver = new ResizeObserver(() => updateCropCoordinates());
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [angle]);

  return (
    <div ref={containerRef} className={style.streamContainer}>
      {(connectedCamera && cropCoordinates) && (
        <div
          className={style.cropBox}
          style={{
            width: cropCoordinates.w,
            height: cropCoordinates.h,
            borderWidth: `${cropCoordinates.h}px`,
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M232 72c0-4.4-3.6-8-8-8s-8 3.6-8 8V248H40c-4.4 0-8 3.6-8 8s3.6 8 8 8H216V440c0 4.4 3.6 8 8 8s8-3.6 8-8V264H408c4.4 0 8-3.6 8-8s-3.6-8-8-8H232V72z" />
          </svg>
          <div style={{ transform: `scale(${1 / process.env.DEFAULT_SCALE})` }}>
            {overlay && <img className={style.overlay} src={overlay} alt="overlay" />}
          </div>
        </div>
      )}
      {connectedCamera && <TakeSnapshotButton cam={connectedCamera} />}
      <div className={style.stream} style={{ transform: `rotateZ(${angle}deg)` }}>
        <Event event="liveMap" handler={liveMapHandler} />
        <span ref={frameRateRef} />
        <img ref={imageRef} src="" alt="Preview" id="streamPreview" />
        {referenceImage && <img src={referenceImage} className={style.referenceImg} alt="reference" />}
      </div>
    </div>
  );
};

export default memo(StreamPreview);
