import React from 'react';

import EventUsersTable from './EventUsersTable';
import ExportButton from './ExportButton';
import EventSelectorButton from '../../components/EventSelector/Button';
import EventSelector from '../../components/EventSelector/Modal';

import style from './style.module.scss';

const EventUsers = () => (
  <div className={style.container}>
    <div className="pageHeader">
      <h4>Event Users</h4>
      <EventSelectorButton />
      <ExportButton all />
    </div>

    <EventUsersTable />
    <EventSelector />
  </div>
);

export default EventUsers;
