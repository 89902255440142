import React, {
  useCallback,
  memo,
  useState,
  useEffect,
} from 'react';
import { useDispatch } from 'react-redux';

import Nouislider from 'nouislider-react';
import { updatePath } from '../actions';

const parseValue = (step, val) => (step < 1 ? val : parseInt(val, 10));
const Slider = ({
  path,
  val,
  min,
  max,
  step = 1,
  basic = false,
  onReleaseCB,
}) => {
  const dispatch = useDispatch();
  const update = useCallback((...args) => dispatch(updatePath(...args)), []);
  const [value, setValue] = useState(val);

  const onChangeHandler = (values) => {
    update(path, parseValue(step, values[0]));
    if (onReleaseCB) {
      onReleaseCB();
    }
  };

  const onUpdateHandler = (values) => {
    setValue(parseValue(step, values[0]));
    if (basic) {
      update(path, parseValue(step, values[0]));
    }
  };

  useEffect(() => {
    setValue(parseValue(step, val));
  }, [val]);

  return (
    <>
      <div>
        {basic ? (
          <input
            type="range"
            style={{ width: '100%', outline: 'none' }}
            value={value?.toString()}
            min={min}
            max={max}
            step={step}
            onChange={(e) => {
              onChangeHandler([e.target.value]);
              onUpdateHandler([e.target.value]);
            }}
          />
        ) : (
          <Nouislider
            connect="lower"
            step={step}
            range={{ min, max }}
            start={[val]}
            onChange={onChangeHandler}
            onUpdate={onUpdateHandler}
          />
        )}
      </div>
      <span>{value}</span>
    </>
  );
};

export default memo(Slider);
