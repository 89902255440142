import React, { useState } from 'react';
import { connect } from 'react-redux';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import classnames from 'classnames';

import { setFullscreenSnapshot } from '../../../../../../components/Snapshot/LightBox/actions';
import { listSnapshots } from '../../../../../../helpers/api';
import { displayErrorModal } from '../../../../../../helpers/swal';

import style from './style.module.scss';

const PhotoBtn = ({
  children,
  className,
  title,
  userId,
  onClick,
  setFullscreenSnapshotAction,
}) => {
  const [isPhotoLoading, setIsPhotoLoading] = useState();

  const clickHander = async () => {
    setIsPhotoLoading(true);

    try {
      const [snap] = await listSnapshots([], 'all', 1, 1, { userIDs: [{ value: userId }] });

      if (!snap) {
        displayErrorModal({ text: 'User has no photos.' });
      } else {
        setFullscreenSnapshotAction(snap._id, true);
      }
    } catch (err) {
      console.error(err);
      displayErrorModal();
    } finally {
      setIsPhotoLoading(false);
      if (onClick) {
        onClick();
      }
    }
  };

  return (
    <LaddaButton
      className={classnames(style.btn, className)}
      data-style={ZOOM_OUT}
      onClick={clickHander}
      loading={isPhotoLoading}
      data-spinner-size={24}
      data-spinner-color="#3373E4"
      title={title}
    >
      {children}
    </LaddaButton>
  );
};

export default connect(
  null,
  { setFullscreenSnapshotAction: setFullscreenSnapshot },
)(PhotoBtn);
