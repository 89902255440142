/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useState,
  forwardRef,
  useEffect,
  useRef,
} from 'react';
import classnames from 'classnames';
import {
  FormControl,
  Dropdown,
  Button,
  FormCheck,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import { faFilter as faFilterSolid } from '@fortawesome/pro-solid-svg-icons';

import style from './style.module.scss';

const closeMenu = () => {
  document.dispatchEvent(new MouseEvent('click'));
};

const ToggleBtn = forwardRef(({ isFiltered, onClick }, ref) => {
  const icon = isFiltered ? faFilterSolid : faFilter;

  const clickHandler = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    onClick(evt);
  };

  return (
    <Button ref={ref} size="sm" variant="link" onClick={clickHandler} className="toggle-button">
      <FontAwesomeIcon icon={icon} />
    </Button>
  );
});

const SelectMenu = forwardRef(({
  options,
  className,
  show,
  // canClearAll means that some rows can have empty values, so this component
  // starts with all items deselected
  // in case this is false
  canClearAll,
  sortBlock,
  style: menuStyle,
  'aria-labelledby': labeledBy,
  onFilter,
}, ref) => {
  const [filterValue, setFilterValue] = useState('');
  const [checkedMap, setCheckedMap] = useState({});
  const prevState = useRef();
  const listEl = useRef();

  const filterFn = (option) => option.toLowerCase().startsWith(filterValue.toLowerCase());

  const submitHandler = () => {
    const checkedValues = Object.entries(checkedMap)
      .filter((entry) => entry[1])
      .map(([label]) => label);
    onFilter(checkedValues);
    prevState.current = { ...checkedMap };
    closeMenu();
  };

  const setAll = (value) => {
    const newMap = { ...checkedMap };
    options.filter(filterFn).forEach((option) => {
      newMap[option] = value;
    });
    setCheckedMap(newMap);
    return newMap;
  };

  const selectAllHandler = () => {
    setAll(true);
  };

  const clearAllHandler = () => {
    setAll(false);
  };

  // select all (non filtered) on mount
  useEffect(() => {
    // if canClearAll is set to true, we should start with all deselected items
    const newMap = setAll(!canClearAll);
    prevState.current = { ...newMap };
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!show) {
      setCheckedMap(prevState.current);
    } else {
      try {
        const el = listEl.current.closest('.react-bootstrap-table');
        const menu = listEl.current.closest('.dropdown-menu');
        const { height: menuHeight } = menu.getBoundingClientRect();
        const { height } = el.getBoundingClientRect();
        const prevHeight = el.style.height;
        const newHeight = menuHeight + 120;

        if (height < newHeight) {
          el.style.height = `${newHeight}px`;
          return () => {
            el.style.height = prevHeight;
          };
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [show]);

  return (
    <div
      ref={ref}
      style={menuStyle}
      className={className}
      aria-labelledby={labeledBy}
    >
      {sortBlock}

      <div className="border-top border-bottom px-2 pt-1 pb-2">
        <div className={style.filterSelectBtns}>
          <Button size="sm" variant="link" onClick={selectAllHandler}>Select All</Button>
          <Button size="sm" variant="link" onClick={clearAllHandler}>Clear All</Button>
        </div>
        <FormControl
          autoFocus
          size="sm"
          className="w-100"
          placeholder="Type to filter..."
          onChange={(evt) => setFilterValue(evt.target.value)}
          value={filterValue}
        />
      </div>

      <div className="px-1 py-2 border-bottom">
        <ul ref={listEl} className={classnames('list-unstyled', 'mb-0', style.list)}>
          {options
            .filter(filterFn)
            .map((option) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                key={option}
                className={style.listItem}
                onClick={() => setCheckedMap({ ...checkedMap, [option]: !checkedMap[option] })}
              >
                <span>
                  <FormCheck
                    inline
                    label=""
                    custom
                    readOnly
                    type="checkbox"
                    checked={checkedMap[option]}
                  />
                </span>
                <span className={style.label}>{option}</span>
              </li>
            ))}
        </ul>
      </div>

      <div className="text-right p-2">
        <Button size="sm" variant="default" className="mr-2" onClick={closeMenu}>Cancel</Button>
        <Button
          size="sm"
          variant="primary"
          onClick={submitHandler}
          disabled={!canClearAll && !Object.values(checkedMap).some((val) => val !== false)}
        >
          Apply
        </Button>
      </div>
    </div>
  );
});

const TextMenu = forwardRef(({
  className,
  sortBlock,
  show,
  style: menuStyle,
  'aria-labelledby': labeledBy,
  onFilter,
}, ref) => {
  const [filter, setFilter] = useState('');
  const prevState = useRef('');
  const domEl = useRef();

  const submitHandler = () => {
    onFilter(filter);
    prevState.current = filter;
    closeMenu();
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!show) {
      setFilter(prevState.current);
    } else {
      const el = domEl.current.closest('.react-bootstrap-table');
      const prevHeight = el.style.height;
      const { height } = el.getBoundingClientRect();
      if (height < 200) {
        el.style.height = '200px';
        return () => {
          el.style.height = prevHeight;
        };
      }
    }
  }, [show]);

  return (
    <div
      ref={ref}
      style={menuStyle}
      className={className}
      aria-labelledby={labeledBy}
    >
      {sortBlock}

      <div ref={domEl} className="border-top border-bottom px-2 py-2">
        <FormControl
          autoFocus
          size="sm"
          className="w-auto"
          placeholder="Filter..."
          onChange={(evt) => setFilter(evt.target.value)}
          value={filter}
        />
      </div>

      <div className="text-right p-2">
        <Button size="sm" variant="default" className="mr-2" onClick={closeMenu}>Cancel</Button>
        <Button size="sm" variant="primary" onClick={submitHandler}>Apply</Button>
      </div>
    </div>
  );
});

const RadioMenu = forwardRef(({
  className,
  sortBlock,
  options,
  show,
  style: menuStyle,
  'aria-labelledby': labeledBy,
  onFilter,
}, ref) => {
  const [filter, setFilter] = useState('');
  const prevState = useRef('');
  const listEl = useRef();

  const submitHandler = () => {
    onFilter(filter);
    prevState.current = filter;
    closeMenu();
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!show) {
      setFilter(prevState.current);
    } else {
      const el = listEl.current.closest('.react-bootstrap-table');
      const prevHeight = el.style.height;
      const { height } = el.getBoundingClientRect();
      if (height < 350) {
        el.style.height = '350px';
        return () => {
          el.style.height = prevHeight;
        };
      }
    }
  }, [show]);

  return (
    <div
      ref={ref}
      style={menuStyle}
      className={className}
      aria-labelledby={labeledBy}
    >
      {sortBlock}

      <div className="border-top border-bottom px-2 py-1">
        <div className={style.filterSelectBtns}>
          <span />
          <Button size="sm" variant="link" onClick={() => setFilter(undefined)}>Clear</Button>
        </div>
      </div>

      <div className="px-1 py-2 border-bottom">
        <ul ref={listEl} className={classnames('list-unstyled', 'mb-0', style.list)}>
          {options.map((option) => (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <li key={option} className={style.listItem} onClick={() => setFilter(option)}>
              <span>
                <FormCheck
                  inline
                  label=""
                  custom
                  readOnly
                  type="checkbox"
                  checked={option === filter}
                />
              </span>
              <span className={style.label}>{option}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="text-right p-2">
        <Button size="sm" variant="default" className="mr-2" onClick={closeMenu}>Cancel</Button>
        <Button size="sm" variant="primary" onClick={submitHandler}>Apply</Button>
      </div>
    </div>
  );
});

const SortOnlyMenu = forwardRef(({
  className,
  sortBlock,
  style: menuStyle,
  'aria-labelledby': labeledBy,
}, ref) => (
  <div
    ref={ref}
    style={menuStyle}
    className={className}
    aria-labelledby={labeledBy}
  >
    {sortBlock}
  </div>
));

const Sort = ({ onSort }) => {
  const onClick = (order) => {
    onSort(order);
    document.dispatchEvent(new MouseEvent('click'));
  };

  return (
    <div className={classnames(style.sortBtns, 'pt-2', 'px-2', 'pb-1')}>
      <div>
        <Button size="sm" variant="link" onClick={() => onClick('asc')}>Sort A - Z</Button>
      </div>
      <div>
        <Button size="sm" variant="link" onClick={() => onClick('desc')}>Sort Z - A</Button>
      </div>
    </div>
  );
};

const Filter = ({
  options,
  canClearAll,
  menu,
  onFilter,
  onSort,
}) => {
  const [isFiltered, setIsFiltered] = useState(false);

  const dropdownClickHandler = (evt) => {
    evt.stopPropagation();
  };

  const onFilterHandler = (value) => {
    if (options && Array.isArray(value)) {
      setIsFiltered(canClearAll ? value.length !== 0 : value.length !== options.length);
    } else {
      setIsFiltered(value);
    }
    onFilter(value);
  };

  const sort = onSort && <Sort onSort={onSort} />;

  return (
    <Dropdown onClick={dropdownClickHandler} drop="right">
      <Dropdown.Toggle as={ToggleBtn} isFiltered={isFiltered} id="dropdown-custom-components" />
      <Dropdown.Menu
        as={menu}
        canClearAll={canClearAll}
        options={options}
        onFilter={onFilterHandler}
        sortBlock={sort}
      />
    </Dropdown>
  );
};

export const headerFormatter = (column, colIndex, components) => (
  <div className={style.headerFormatter}>
    {column.text}
    {components.filterElement}
  </div>
);

export const SelectFilter = ({
  options,
  canClearAll,
  onFilter,
  onSort,
}) => (
  <Filter
    options={options}
    menu={SelectMenu}
    onFilter={onFilter}
    onSort={onSort}
    canClearAll={canClearAll}
  />
);

export const TextFilter = ({ onFilter, onSort }) => (
  <Filter menu={TextMenu} onFilter={onFilter} onSort={onSort} />
);

export const RadioFilter = ({ options, onFilter, onSort }) => (
  <Filter options={options} menu={RadioMenu} onFilter={onFilter} onSort={onSort} />
);

export const SortOnly = ({ onSort }) => (
  <Filter menu={SortOnlyMenu} onSort={onSort} />
);
