import React from 'react';
import classnames from 'classnames';
import LaddaButton from 'react-ladda/dist/LaddaButton';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import FileUpload from './FileUpload';
import ParsedMappingTable from './ParsedMappingTable';
import ParsedMappingInfo from './ParsedMappingInfo';

import { updateConfig } from './actions';

const MappingInfo = ({ isConfigUpdating, updateConfigAction }) => (
  <div>
    <div className="pageHeader">
      <h4>Mapping</h4>
      <LaddaButton
        loading={isConfigUpdating}
        className={classnames('btn', 'btn-primary', 'ml-auto', 'small', { 'btn-outline-primary': !isConfigUpdating })}
        onClick={updateConfigAction}
      >
        Update Config
      </LaddaButton>
    </div>

    <div className="px-3 pb-3">
      <FileUpload />
      <ParsedMappingInfo />
      <ParsedMappingTable />
    </div>

    <ToastContainer enableMultiContainer containerId="mapping" position="bottom-right" autoClose={3000} />
  </div>
);

export default connect(
  ({ mappingInfo: { config: { isFetching: isConfigUpdating } } }) => ({ isConfigUpdating }),
  { updateConfigAction: updateConfig },
)(MappingInfo);
