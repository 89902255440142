import { SET_SEAT_OPTIONS, POSITION_SEAT_OPTION, UPDATE_SEAT_OPTION } from './types';

export const setSeatOptions = (payload) => ({
  type: SET_SEAT_OPTIONS,
  payload,
});

export const changeOrder = (sourceIndex, destinationIndex) => ({
  type: POSITION_SEAT_OPTION,
  payload: { sourceIndex, destinationIndex },
});

export const updateSeat = (seatType, data) => ({
  type: UPDATE_SEAT_OPTION,
  payload: { seatType, data },
});
