import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

import { getPlatformIcon } from './appIcons';

import style from './style.module.scss';

const ShareOption = ({ sharePlatform }) => (
  <div className={style.shareOption}>
    <FontAwesomeIcon
      icon={getPlatformIcon(sharePlatform)}
      className={classNames(style.icon, style[sharePlatform.toLowerCase()])}
    />
    <span className={style.platform}>{sharePlatform}</span>
    <FontAwesomeIcon icon={faGripVertical} size="sm" className={style.dragIcon} />
  </div>
);

export default ShareOption;
