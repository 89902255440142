import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { banHandler } from '../../../../../../components/Snapshot/actionHelper';
import { dismissNotification } from '../../actions';

import style from './style.module.scss';

const BanBtn = ({
  children,
  className,
  title,
  userId,
  onBan,
  onCancel,
  dismissNotificationAction,
}) => {
  const clickHandler = async () => {
    const banned = await banHandler(true, null, userId);
    if (banned) {
      dismissNotificationAction();
    }

    if (banned && onBan) {
      onBan();
    }
    if (!banned && onCancel) {
      onCancel();
    }
  };

  return (
    <button
      type="button"
      className={classnames(style.btn, className)}
      title={title}
      onClick={clickHandler}
    >
      {children}
    </button>
  );
};

export default connect(
  null,
  (dispatch, { notificationId }) => ({
    dismissNotificationAction: () => dispatch(dismissNotification(notificationId)),
  }),
)(BanBtn);
