import { put } from '../../../../helpers/fetch';
import { buildURL } from '../../../../helpers/urls';

export const setEventStatus = (slugDate, eventStatus) => {
  const url = buildURL('/events/');
  return put(url, { slugDate, data: { eventStatus } });
};

export const setEventPromotion = (slugDate, promo) => {
  const url = buildURL('/events/');
  return put(url, { slugDate, data: { promo } });
};
