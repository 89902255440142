const initialState = {
  logged: false,
  sessionChecked: false,
  userData: null,
  roles: undefined,
};

export default function (state = initialState, action) {
  if (action.type === 'SET_USER_DATA') {
    return {
      ...state,
      userData: action.payload,
      logged: !!action.payload,
      sessionChecked: true,
      roles: action.payload && action.payload.roles,
    };
  }

  return state;
}
