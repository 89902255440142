import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Button } from 'react-bootstrap';
import BlockedSeatModal from './BlockedSeatModal';
import BlockedSeatTable from './BlockedSeatTable';

import { openBlockedSeatModal, setBlockedSeats } from './actions';

import style from './style.module.scss';

const BlockedSeats = ({ event, openBlockedSeatModalAction, setBlockedSeatsAction }) => {
  useEffect(() => {
    if (event) {
      setBlockedSeatsAction(event.blockedSeats);
    }
  }, [event]);

  if (isEmpty(event)) {
    return null;
  }

  return (
    <div className={style.blockedSeatsContainer}>
      <div className={style.blockedSeatsActionBar}>
        <h2>Blocked seats</h2>
        <Button className="small" variant="outline-primary" onClick={openBlockedSeatModalAction}>Add Seat</Button>
      </div>
      <BlockedSeatTable />
      <BlockedSeatModal />
    </div>

  );
};

export default connect(
  ({ events: { selectedEvents } }) => ({ event: selectedEvents[0] }),
  { openBlockedSeatModalAction: openBlockedSeatModal, setBlockedSeatsAction: setBlockedSeats },
)(BlockedSeats);
