import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { connect } from 'react-redux';

import { resizeObserver } from '../../../helpers/browser';

import Camera from '../Camera';
import CameraThumbnail from './CameraThumbnail';

import style from './style.module.scss';
import { selectCamera } from '../actions';

let mostRecentQueues;
let wasQueueEmpty;

const CameraBlocksBig = (props) => {
  const {
    selectedCameraName,
    cameras,
    queue,
    selectCameraAction,
  } = props;
  const ref = useRef();
  const userSelectedRef = useRef();
  const [timeoutRef, setTimeoutRef] = useState();
  const availableCameras = Object.keys(cameras);

  const calcAndSetCamSize = (parentDomEl) => {
    const thumbsPerRow = 8;
    const margin = 4;
    const containerHeight = parentDomEl.clientHeight - margin;
    const containerWidth = parentDomEl.clientWidth - margin;
    const thumbCamSize = Math.min(containerHeight / thumbsPerRow - margin, 150);
    const thumbRows = Math.ceil(availableCameras.length / thumbsPerRow);
    // 40 is for the margin between thumbnails and large camera
    const camSize = Math.min(containerHeight, containerWidth - 40 - thumbCamSize * thumbRows);

    parentDomEl.style.setProperty('--camSize', `${camSize}px`);
    parentDomEl.style.setProperty('--thumbCamSize', `${thumbCamSize}px`);
    parentDomEl.style.setProperty('--controllsFontSize', `${Math.min(camSize / 40, 12)}px`);
  };

  useEffect(() => {
    requestAnimationFrame(() => calcAndSetCamSize(ref.current));
  }, []);

  const camThumbClickHandler = useCallback(() => {
    userSelectedRef.current = true;
    clearTimeout(timeoutRef);
    setTimeoutRef(null);
  }, []);

  const shuffle = () => {
    const nonEmptyQueues = Object.entries(mostRecentQueues)
      .filter((entry) => entry[1].length > 0);
    const cameraKeys = nonEmptyQueues.length !== 0
      ? nonEmptyQueues.map(([key]) => key)
      : availableCameras;
    const selectedInd = cameraKeys.indexOf(selectedCameraName) + 1;
    const nextInd = selectedInd % cameraKeys.length;
    selectCameraAction(cameraKeys[nextInd]);
    setTimeoutRef(null);
  };

  useEffect(() => {
    const isQueueEmpty = Object.values(queue).every((q) => q.length === 0);

    if (wasQueueEmpty && !isQueueEmpty) {
      clearTimeout(timeoutRef);

      if (!userSelectedRef.current) {
        setTimeout(shuffle, 0);
      }
    }

    wasQueueEmpty = isQueueEmpty;
    mostRecentQueues = queue;
  }, [queue]);

  useEffect(() => {
    if (!timeoutRef && !userSelectedRef.current) {
      const timeout = setTimeout(shuffle, 30 * 1000);
      setTimeoutRef(timeout);
    }
  }, [timeoutRef]);

  useEffect(() => resizeObserver(ref.current, calcAndSetCamSize), [ref.current]);

  return (
    <div ref={ref} className={style.camContainer}>
      <div className={style.inner}>
        <div className={style.leftSide}>
          {availableCameras.map((camName) => (
            <CameraThumbnail
              key={camName}
              camName={camName}
              selected={camName === selectedCameraName}
              onClick={camThumbClickHandler}
            />
          ))}
        </div>

        <div className={style.bigCamera}>
          <Camera key={selectedCameraName} name={selectedCameraName} />
        </div>
      </div>
    </div>
  );
};

export default connect(
  ({
    cameras: {
      status: camerasStatus,
      queue,
    },
    monitor: {
      options: {
        selectedCameraName,
      },
    },
  }) => ({
    queue,
    cameras: camerasStatus,
    selectedCameraName,
  }),
  { selectCameraAction: selectCamera },
)(CameraBlocksBig);
