import React from 'react';
import { isEmpty } from 'lodash';

import ChartCard from '../ChartCard';
import HorizontalChart from '../HorizontalChart';
import BarChart from '../BarChart';
import GANoDataFallback from '../GANoDataFallback';

const barColor = '#5e97f6';

const GAChartWrapper = ({
  chartData,
  cardTitle,
  chartType,
  fallbackTitle,
  barKey = 'total',
}) => (
  <ChartCard title={cardTitle}>
    {isEmpty(chartData) && <GANoDataFallback title={fallbackTitle} />}
    {!isEmpty(chartData) && chartType === 'horizontal' && (
      <HorizontalChart
        data={chartData}
        yAxisDatakey="name"
        xAxisDatakey="percentage"
        barColor={barColor}
      />
    )}
    {!isEmpty(chartData) && chartType === 'bar' && (
      <BarChart
        barColor={barColor}
        data={chartData}
        xAxisKey="name"
        barKey={barKey}
        labelKey="percentage"
        hideYAxis
        renderLabel
        isPercentage
      />
    )}
  </ChartCard>
);

export default GAChartWrapper;
