import { combineReducers } from 'redux';
import theme from './themeStore';
import authentication from './authentication';
import events from './events';
import snapshots from './snapshots';
import overlays from './overlays';
import cameras from './cameras';
import keys from '../../components/KeyListener/reducer';
import album from '../../pages/Album/reducer';
import monitor from '../../pages/Monitor/reducer';
import modals from '../../components/Modals/reducer';
import lightbox from '../../components/Snapshot/LightBox/reducer';
import eventMetrics from '../../shared/layouts/LayoutNavbar/EventMetrics/reducer';
import location from '../../pages/Template/Geofence/reducer';
import mappingInfo from '../../pages/Config/MappingInfo/reducer';
import languages from '../../pages/Template/Languages/reducer';
import roles from '../../pages/Admin/Roles/reducer';
import trivia from '../../pages/Template/Trivia/reducer';
import graphics from '../../pages/Template/Graphics/reducer';
import cameraConfigs from '../../pages/Config/CameraConfigs/reducer';
import eventUsers from '../../pages/EventUsers/reducer';
import templateExport from '../../pages/Template/Export/reducer';
import analytics from '../../pages/Analytics/reducer';
import seatOptions from '../../pages/Template/SeatOptions/reducer';
import blockedSeats from '../../pages/Template/SeatOptions/BlockedSeat/reducer';
import colors from '../../pages/Template/Colors/reducer';
import mapping from '../../pages/Mapping/reducer';

export default combineReducers({
  theme,
  authentication,
  events,
  snapshots,
  overlays,
  cameras,
  album,
  monitor,
  modals,
  lightbox,
  eventMetrics,
  keys,
  location,
  mappingInfo,
  languages,
  roles,
  trivia,
  graphics,
  cameraConfigs,
  eventUsers,
  analytics,
  templateExport,
  seatOptions,
  blockedSeats,
  colors,
  mapping,
});
