import {
  useLayoutEffect,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import { logGAPageView } from './ga';

export const useLogPageViews = () => {
  const location = useLocation();
  useEffect(() => {
    const { pathname, search } = location;

    if (search) {
      logGAPageView(`${pathname}${encodeURIComponent(search)}`);
    }

    logGAPageView(pathname);
  }, [location]);
};

export const useWindowResize = () => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const useDelayedRender = () => {
  const canRenderRef = useRef(false);

  useEffect(() => {
    canRenderRef.current = true;
  }, []);

  return canRenderRef.current;
};

export const useIsLoaded = (isFetching) => {
  const [loaded, setLoaded] = useState();

  useEffect(() => {
    if (loaded !== undefined && !isFetching) {
      setLoaded(true);
    } else if (isFetching) {
      setLoaded(false);
    }
  }, [isFetching]);

  return !isFetching && loaded;
};

export const usePage = () => {
  const location = useLocation();
  return location.pathname.split('/')[1];
};
