import { setFavorite } from '../../store/actions/snapshots';
import { favoriteSnapshots } from '../../helpers/api';
import { displayErrorModal } from '../../helpers/swal';
// TODO: try to copy the snapshot object and remove this
import { setFavoriteOnStats } from '../../pages/Album/actions';

// eslint-disable-next-line import/prefer-default-export
export const toggleFavorite = (updateObj) => (dispatch) => {
  const { _id: snapshotId } = updateObj;

  dispatch(setFavorite([{ ...updateObj, favorite: !updateObj.favorite }]));
  dispatch(setFavoriteOnStats(updateObj.favorite ? -1 : 1));

  favoriteSnapshots([snapshotId]).catch((err) => {
    console.error(err);
    // revert the action in case of failed request
    dispatch(setFavorite([updateObj]));
    dispatch(setFavoriteOnStats(updateObj.favorite ? 1 : -1));
    displayErrorModal();
  });
};
