import { SET_SEAT_OPTIONS, POSITION_SEAT_OPTION, UPDATE_SEAT_OPTION } from './types';

const initialState = {};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case SET_SEAT_OPTIONS: {
    return payload;
  }

  case POSITION_SEAT_OPTION: {
    const { sourceIndex, destinationIndex } = payload;
    let entries = Object.entries(state);
    const removed = entries[sourceIndex];
    entries = entries.filter((entry) => entry !== removed);
    return Object.fromEntries([
      ...entries.slice(0, destinationIndex),
      removed,
      ...entries.slice(destinationIndex),
    ]);
  }

  case UPDATE_SEAT_OPTION: {
    const { seatType, data } = payload;
    return {
      ...state,
      [seatType]: { ...state[seatType], ...data },
    };
  }

  default: {
    return state;
  }
  }
};
