import React from 'react';
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { Form } from 'react-bootstrap';

import { setFilterField } from './actions';
import { strToReactSelectItem } from './helpers';
import style from './style.module.scss';

const CameraField = ({ cameras, availableCameras, setCameraAction }) => (
  <div className={style.customMultiValueInput}>
    <Form.Label className={style.FormLabel}>Camera</Form.Label>
    <CreatableSelect
      isMulti
      value={cameras}
      options={availableCameras.map(strToReactSelectItem)}
      classNamePrefix="react-select"
      onChange={setCameraAction}
    />
  </div>
);

export default connect(
  ({
    album: {
      filterForm: {
        formOptions: { cameras: availableCameras },
        tempData: { cameras },
      },
    },
  }) => ({
    cameras,
    availableCameras,
  }),
  { setCameraAction: setFilterField('cameras') },
)(CameraField);
