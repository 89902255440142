import Geocode from 'react-geocode';

const GMapsAPIKey = 'AIzaSyABTEAeRX_u5VO6W0OFPr_JxDY4oFfBch0';
Geocode.setApiKey(GMapsAPIKey);

export const fromAddress = async (address) => {
  const geoAddress = await Geocode.fromAddress(address);
  return geoAddress;
};

export const fromGeolocation = async (lat, lng) => {
  const geoAddress = await Geocode.fromLatLng(lat, lng);
  return geoAddress;
};

export const generateGoogleMapsImage = (lat, lng, rad, ratio) => {
  const radiusInKM = rad * 1000;
  const earthRadius = 6371;
  const pi = Math.PI;

  const latRadius = (lat * pi) / 180;
  const lngRadius = (lng * pi) / 180;
  const height = Math.round(640 / ratio); // 640 is the max according to google maps

  const d = (radiusInKM / 1000) / earthRadius;

  const paths = new Array(361).fill().map((_, step) => {
    const brng = (step * pi) / 180;
    let pathLat = Math.asin(
      Math.sin(latRadius) * Math.cos(d) + Math.cos(latRadius) * Math.sin(d) * Math.cos(brng),
    );

    const pathLng = (
      (lngRadius + Math.atan2(
        Math.sin(brng) * Math.sin(d) * Math.cos(latRadius),
        Math.cos(d) - Math.sin(latRadius) * Math.sin(pathLat),
      )) * 180
    ) / pi;
    pathLat = (pathLat * 180) / pi;

    return `${pathLat},${pathLng}`;
  }).join('|');

  return `https://maps.googleapis.com/maps/api/staticmap?scale=2&center=${lat},${lng}&size=640x${height}&path=color:0xff0000ff:weight:1${paths}&markers=size:mid%7Ccolor:0xff0000%7Clabel:%7C${lat},${lng}&key=${GMapsAPIKey}`;
};
