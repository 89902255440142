import React from 'react';

const ChartLabel = ({
  x,
  y,
  width,
  value,
}) => (
  <text
    x={x + width + 25}
    y={y + 15}
    fill="#616165"
    textAnchor="middle"
    dominantBaseline="middle"
    style={{
      fontSize: '12px',
      lineHeight: '18px',
      color: '#616165',
    }}
  >
    {`${value.toFixed(2)}%`}
  </text>
);

export default ChartLabel;
