import React, { useEffect } from 'react';
import { connect, batch } from 'react-redux';
import { Spinner, Form, Button } from 'react-bootstrap';

import {
  fetchSnapshots,
  toggleFilterBar,
  fetchSnapshotStats,
  resetCurrentPage,
  resetCurrentTab,
} from '../actions';
import { resetFilterForm, setFilter } from './actions';
import { fetchFilterOptions } from '../../../store/actions/snapshots';
import { dividerMargin } from './constants';

import Divider from '../../../components/Divider';
import UserIDsField from './UserIDsField';
import OverlaysField from './OverlaysField';
import SRSBlock from './SRSBlock';
import CameraField from './CameraField';
import AgeRangeField from './AgeRangeField';
import GroupSizeField from './GroupSizeField';
import GenderField from './GenderField';
import TimeRangeField from './TimeRangeField';
import EmotionsField from './EmotionsField';

import style from './style.module.scss';

const FilterForm = (props) => {
  const {
    selectedEvents,
    error,
    isFetching,
    imageMiningDataAvailable,
    fetchFilterOptionsAction,
    toggleFilterBarAction,
    resetFilterFormAction,
    fetchSnapshotsAction,
    fetchSnapshotStatsAction,
    resetCurrentPageAction,
    resetCurrentTabAction,
    setFilterAction,
  } = props;

  useEffect(() => {
    fetchFilterOptionsAction();

    if (selectedEvents.length === 0) {
      toggleFilterBarAction();
    }
  }, [selectedEvents]);

  if (error) {
    return <p>Error loading filter options</p>;
  }

  if (isFetching) {
    return (
      <span className={style.filterWrapper}>
        <Spinner as="span" animation="border" role="status" aria-hidden="true" className="m-auto" />
      </span>
    );
  }

  const filterHandle = () => {
    batch(() => {
      setFilterAction();
      resetCurrentPageAction();
      fetchSnapshotStatsAction();
      fetchSnapshotsAction();
    });
  };

  const resetFilterHandler = () => {
    batch(() => {
      resetFilterFormAction();
      resetCurrentPageAction();
      resetCurrentTabAction();
      fetchSnapshotStatsAction();
      fetchSnapshotsAction();
    });
  };

  return (
    <div className={style.filterWrapper}>
      <Form.Group className={style.filterForm}>
        <UserIDsField />
        <Divider className={style.divider} margin={dividerMargin} />
        <OverlaysField />
        <Divider className={style.divider} margin={dividerMargin} />
        <SRSBlock />
        <Divider className={style.divider} margin={dividerMargin} />
        <CameraField />
        <Divider className={style.divider} margin={dividerMargin} />
        <TimeRangeField />

        {imageMiningDataAvailable && (
          <>
            <Divider className={style.divider} margin={dividerMargin} />
            <AgeRangeField />
            <Divider className={style.divider} margin={dividerMargin} />
            <GenderField />
            <Divider className={style.divider} margin={dividerMargin} />
            <GroupSizeField />
            <Divider className={style.divider} margin={dividerMargin} />
            <EmotionsField />
          </>
        )}
      </Form.Group>
      <div className={style.bottomSection}>
        <Button variant="outline-primary" onClick={resetFilterHandler}>Reset</Button>
        <Button onClick={filterHandle}>Search</Button>
      </div>
    </div>
  );
};

export default connect(
  ({
    events: { selectedEvents },
    album: {
      filterForm: {
        error,
        isFetching,
        formOptions: {
          imageMiningDataAvailable,
        },
      },
    },
  }) => ({
    selectedEvents,
    error,
    isFetching,
    imageMiningDataAvailable,
  }),
  {
    fetchFilterOptionsAction: fetchFilterOptions,
    resetFilterFormAction: resetFilterForm,
    fetchSnapshotsAction: fetchSnapshots,
    fetchSnapshotStatsAction: fetchSnapshotStats,
    toggleFilterBarAction: toggleFilterBar,
    resetCurrentPageAction: resetCurrentPage,
    resetCurrentTabAction: resetCurrentTab,
    setFilterAction: setFilter,
  },
)(FilterForm);
