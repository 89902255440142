import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

export const ReactSwal = withReactContent(Swal.mixin({
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-primary btn-lg',
    cancelButton: 'btn btn-default btn-lg',
    actions: 'text-center',
  },
  allowOutsideClick: true,
  showCancelButton: false,
  showConfirmButton: false,
  showCloseButton: true,
}));

export const confirmationModal = (type, title, text, html, timer) => {
  ReactSwal.fire({
    type,
    title,
    text,
    html,
    timer,
  });
};

export const displayErrorModal = ({
  title = 'Oops...',
  text = 'Something went wrong, please try again.',
  html,
  timer,
} = {}) => confirmationModal('error', title, text, html, timer);

export const displaySuccessModal = ({
  title = 'Success',
  html,
  text,
  timer,
} = {}) => confirmationModal('success', title, text, html, timer);
