import React, { useContext, useCallback } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Event, SocketContext } from 'react-socket-io';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCamera } from '@fortawesome/pro-regular-svg-icons';
import {
  Row,
  Col,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';

import { BLOCKS_LAYOUT, BIG_BLOCK_LAYOUT } from '../constants';
import { setLayout, toggleSidebar } from '../actions';
import { displayErrorModal } from '../../../helpers/swal';
import { socketEventNames } from '../../../helpers/constants';
import { MAGICSHOTS_WRITE } from '../../../components/Authorize/permissions/resources';

import RoundButton from '../../../components/RoundButton';
import Authorize from '../../../components/Authorize';

import style from './style.module.scss';
import NotificationsButton from './Notifications/Button';

const { MONITOR_MAGICSHOT_ERROR } = socketEventNames;

const MonitorNav = (props) => {
  const {
    layout,
    slugDate,
    sidebarDisplayed,
    disabledBigBlockLayout,
    setLayoutAction,
    toggleSidebarAction,
    camStatuses,
  } = props;
  const socket = useContext(SocketContext);

  const takeMagicshotHandler = () => {
    socket.emit('take MagicShot', slugDate);
    toast('Taking Magicshot', { containerId: 'magicshot' });
  };

  const magicShotErrorHander = useCallback(({ errorMsg }) => {
    displayErrorModal({
      title: 'Error taking magicshot',
      text: `Server responded with error message "${errorMsg}"`,
      timer: 5000,
    });
  }, []);

  return (
    <div className={classnames('layout-navbar', style.navContainer)}>
      <Event event={MONITOR_MAGICSHOT_ERROR} handler={magicShotErrorHander} />
      <Row>
        <Col>
          <h2>Monitor</h2>
        </Col>
        <Col>
          <div className={style.btns}>
            <Authorize permissions={MAGICSHOTS_WRITE}>
              {Object.values(camStatuses).some((val) => val === 'on') && (
                <OverlayTrigger
                  placement="top"
                  overlay={(
                    <Popover id="magicShots">
                      <Popover.Content>
                        Trigger a photo from all the cameras manually using the dashboard
                      </Popover.Content>
                    </Popover>
                  )}
                >
                  <span className="mr-2">
                    <RoundButton className={style.roundBtn} onClick={takeMagicshotHandler}>
                      <FontAwesomeIcon size="lg" icon={faCamera} className="align-middle" />
                    </RoundButton>
                  </span>
                </OverlayTrigger>
              )}
            </Authorize>

            <NotificationsButton className={style.roundBtn} />

            <span className="mr-4" />

            <button
              type="button"
              className={classnames(style.blocksBtn, {
                [style.active]: layout === BLOCKS_LAYOUT,
              })}
              onClick={() => setLayoutAction(BLOCKS_LAYOUT)}
            >
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </button>

            <div className={style.btnSeparator} />

            <button
              type="button"
              disabled={disabledBigBlockLayout}
              className={classnames(
                style.bigBlockBtn,
                { [style.active]: layout === BIG_BLOCK_LAYOUT },
              )}
              onClick={() => setLayoutAction(BIG_BLOCK_LAYOUT)}
            >
              <div>
                <span />
                <span />
              </div>
              <span />
            </button>

            {!sidebarDisplayed && (
              <button
                type="button"
                className={style.arrowBtn}
                onClick={toggleSidebarAction}
              >
                <FontAwesomeIcon icon={faArrowLeft} size="lg" />
              </button>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default connect(
  ({
    monitor: {
      options: {
        layout,
        sidebarDisplayed,
      },
    },
    cameras: {
      status: camStatuses,
    },
    events: {
      currentEvent: {
        slugDate,
      } = {},
    },
  }) => ({
    layout,
    sidebarDisplayed,
    slugDate,
    camStatuses,
    disabledBigBlockLayout: Object.keys(camStatuses).length <= 1,
  }),
  {
    setLayoutAction: setLayout,
    toggleSidebarAction: toggleSidebar,
  },
)(MonitorNav);
