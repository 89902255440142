import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import style from './style.module.scss';

const TraitItem = ({
  icon,
  value,
  title,
  type,
}) => (
  <div className={style.traitContainer}>
    <div className={style.iconContainer}>
      {type === 'icon' && <FontAwesomeIcon icon={icon} />}
      {type === 'image' && <img src={icon} alt="icon" />}
    </div>
    <span className={style.value}>{value}</span>
    <span className={style.title}>{title}</span>
  </div>
);

export default TraitItem;
