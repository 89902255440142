export const socketEventNames = Object.freeze({
  MONITOR_EVENT_UPDATED: 'monitor eventUpdated',
  MONITOR_SOCKET_CONNECTED: 'monitor socketConnected',
  MONITOR_SOCKET_DISCONNECTED: 'monitor socketDisconnected',
  MONITOR_TAKE_SNAPSHOT: 'monitor takeSnapshot',
  MONITOR_NEW_SNAPSHOT: 'monitor newSnapshot',
  MONITOR_LIVESTREAM: 'livestream',
  MONITOR_QUEUE_ARRAY: 'monitor queueArray',
  MONITOR_POWER_CAMPOOL: 'monitor power campool',
  MONITOR_CHECK_DISTINCT: 'monitor checkDistinct',
  MONITOR_FIRST_SRS: 'monitor firstSRS',
  MONITOR_SNAPSHOT_SHARED: 'monitor snapshotShared',
  MONITOR_MAGICSHOT_ERROR: 'monitor magic shot error',
  MONITOR_PAN_ZOOM: 'monitor panZoom',
  MONITOR_DEMO_MODE_CHANGED: 'monitor demoModeUpdated',
  MONITOR_GET_DEMO_MODE: 'monitor getDemoMode',
  MONITOR_NOTIFICATION_ADDED: 'notificationAdded',
  MONITOR_NOTIFICATION_DISMISSED: 'notificationDismissed',
});

export const eventStatus = Object.freeze({
  PRE: 'pre',
  LIVE: 'live',
  POST: 'post',
});

export const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
