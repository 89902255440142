import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import ChartCard from '../../ChartComponents/ChartCard';
import BarChart from '../../ChartComponents/BarChart';

import { getPercentage } from '../../utils';
import Info from '../../../../components/Info';

const GenderChart = ({ emotions }) => {
  const emotionsObj = {};

  Object.entries(emotions).forEach(([key, values]) => {
    const value = values.reduce((prev, next) => (prev + next.y), 0);
    emotionsObj[key] = value;
  });

  const totalAmmount = Object.values(emotionsObj).reduce((prev, curr) => (prev + curr), 0);

  const chartData = Object.entries(emotionsObj).map(([key, value]) => ({
    name: key,
    total: value,
    percentage: getPercentage(value, totalAmmount),
  }));

  if (!isEmpty(chartData)) {
    return (
      <ChartCard
        title={<Info label="Emotions" content="The number of different facial expressions captured in AmplifiCam photos" className="ml-2" />}
      >
        <BarChart
          barColor="var(--theme-red)"
          data={chartData}
          xAxisKey="name"
          barKey="total"
          labelKey="percentage"
          hideYAxis
          renderLabel
        />
      </ChartCard>
    );
  }

  return null;
};

export default connect(
  ({ analytics: { briziVision: { data: { emotions } } } }) => ({ emotions }),
)(GenderChart);
