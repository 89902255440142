import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';
import classnames from 'classnames';

import EventSelectorButton from '../../../components/EventSelector/Button';
import EventSelector from '../../../components/EventSelector/Modal';
import ExportFields from './ExportFields';
import UpdateModal from '../UpdateButton/UpdateModal';

import { buildURL } from '../../../helpers/urls';
import { useUpdateEvent } from '../hooks';
import { importFile } from './api';
import { displayErrorModal } from '../../../helpers/swal';

import style from './style.module.scss';

const Export = ({
  triviaEnabled,
  geofence,
  overlays,
  startEndTime,
  slugDate,
}) => {
  const isEventUpdating = useUpdateEvent();
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [fileData, setFileData] = useState();

  const getUrl = () => {
    const searchParams = new URLSearchParams({ triviaEnabled });

    if (!geofence) {
      searchParams.append('disabledEventFields[]', 'geofence');
    }

    if (!overlays) {
      searchParams.append('disabledEventFields[]', 'overlays');
    }

    if (!startEndTime) {
      searchParams.append('disabledEventFields[]', 'startTime');
      searchParams.append('disabledEventFields[]', 'endTime');
    }

    return buildURL(`/events/${slugDate}/export`, `?${searchParams}`);
  };

  const onImportFileChange = useCallback((evt) => {
    evt.persist();
    const [file] = evt.target.files;
    const reader = new FileReader();

    reader.addEventListener('load', (event) => {
      const { result } = event.target;
      try {
        // eslint-disable-next-line no-param-reassign
        evt.target.value = '';
        const data = JSON.parse(result);
        if (!data.event) {
          displayErrorModal({ text: 'Invalid file. Missing "event" property' });
          return;
        }
        setFileData(data);
        setUpdateModalOpen(true);
      } catch (err) {
        console.error(err);
        if (err instanceof SyntaxError) {
          displayErrorModal({ text: 'Invalid JSON in the file' });
        } else {
          displayErrorModal();
        }
      }
    });

    reader.readAsText(file);
  }, [setUpdateModalOpen, setFileData]);

  const onModalHide = useCallback(() => setUpdateModalOpen(false), [setUpdateModalOpen]);
  const updateHandler = useCallback((eventIds) => importFile(eventIds, fileData), [fileData]);

  return (
    <div className={classnames('flex-grow-1', 'd-flex', 'flex-column', style.exportPage)}>
      <div className="pageHeader">
        <h4>Export event data</h4>
        <EventSelectorButton showFutureEvents />
        <div className={style.buttons}>
          <Button variant="default" className={classnames('mr-2', 'small', style.importBtn)}>
            Import Event
            <input type="file" onChange={onImportFileChange} accept=".json" />
          </Button>
          <Button href={getUrl()} className="small">Export Event</Button>
        </div>
      </div>

      {isEventUpdating && (
        <div className="flex-grow-1 d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!isEventUpdating && <ExportFields />}

      <EventSelector singleSelection showTemplate showFutureEvents />
      <UpdateModal
        show={isUpdateModalOpen}
        onHide={onModalHide}
        updateHandler={updateHandler}
        serverErrorMessage
      />
    </div>
  );
};

export default connect(
  ({
    templateExport: {
      triviaEnabled,
      eventFields: {
        geofence,
        overlays,
        startEndTime,
      },
    },
    events: {
      selectedEvents: [{ slugDate } = {}],
    },
  }) => ({
    triviaEnabled,
    geofence,
    overlays,
    startEndTime,
    slugDate,
  }),
)(Export);
