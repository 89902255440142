import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDownload, faImage } from '@fortawesome/pro-regular-svg-icons';
import { Button } from 'react-bootstrap';

import SectionSelector from './SectionSelector';
import previewImage from '../../../assets/images/preview.jpg';
import Loader from '../../../shared/Loader';

import { removeOrigin } from '../../../helpers/urls';

import style from './style.module.scss';

const Overlay = ({
  addMode,
  loading,
  hide,
  overlay,
  openDialog,
  onChange,
  onDelete,
}) => {
  if (addMode) {
    return (
      <div className={classnames(style.emptyContainer, { [style.hide]: hide })}>
        {!loading && (
          <div className={style.contentWrapper}>
            <div className={style.imageIcon}><FontAwesomeIcon icon={faImage} /></div>
            <p>
              <span>Type: PNG (8 bits - png8)</span>
              <br />
              <span>Size: 800 KB or less</span>
              <br />
              <span>Resolution: 2160 x 2160 pixel</span>
            </p>
            <Button variant="outline-primary" onClick={openDialog} disabled={hide && loading} className="small">Upload</Button>
          </div>
        )}
        {loading && <Loader />}
      </div>
    );
  }

  return (
    <div className={style.overlayContainer}>
      <img src={overlay.urlSmallSize} alt="" style={{ backgroundImage: `url(${previewImage})` }} />
      <div className={style.controls}>
        <Button variant="link pr-0">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="switcher labelFirst mr-0">
            <input
              type="checkbox"
              defaultChecked={overlay.disabled}
              className="switcher-input"
              onChange={() => onChange({ disabled: !overlay.disabled })}
            />
            <span className="switcher-indicator">
              <span className="switcher-yes" />
              <span className="switcher-no" />
            </span>
            <span className="switcher-label">Disabled</span>
          </label>
        </Button>
        <a className="ml-auto" href={removeOrigin(overlay.urlRaw)} download>
          <FontAwesomeIcon icon={faDownload} size="sm" />
        </a>
        <button type="button" onClick={() => onDelete({ deleted: true })}>
          <FontAwesomeIcon icon={faTrash} size="sm" />
        </button>
        <SectionSelector
          onChange={(sections) => onChange({ sections })}
          preSelected={overlay.sections}
        />
      </div>
    </div>
  );
};

export default Overlay;
