import React, { useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import classnames from 'classnames';
import { Event } from 'react-socket-io';

import { fetchNotifications, setAddressed, fetchMoreNotifications } from './actions';
import { socketEventNames } from '../../../../helpers/constants';
import { useIsLoaded } from '../../../../helpers/hooks';
import { PAGE_SIZE } from './constants';

import Notification from './Notification';

import style from './style.module.scss';

const { MONITOR_NOTIFICATION_DISMISSED } = socketEventNames;

const Notifications = ({
  isFetching,
  isFetchingMore,
  hasMore,
  notifications,
  fetchNotificationsAction,
  fetchMoreNotificationsAction,
  setAddressedAction,
}) => {
  const listRef = useRef();
  const listEndRef = useRef();
  const offsetRef = useRef(0);
  const observerRef = useRef();
  const isLoaded = useIsLoaded(isFetching);

  useEffect(() => {
    fetchNotificationsAction();
  }, []);

  useEffect(() => {
    if (!observerRef.current && listRef.current && listEndRef.current) {
      observerRef.current = new IntersectionObserver(([{ isIntersecting }]) => {
        if (isIntersecting) {
          offsetRef.current += PAGE_SIZE;
          fetchMoreNotificationsAction(offsetRef.current);
        }
      }, {
        root: listRef.current,
        rootMargin: '10px',
      });
    }

    if (observerRef.current && isLoaded && hasMore && !isFetchingMore) {
      observerRef.current.observe(listEndRef.current);
    } else if (observerRef.current && isFetchingMore) {
      observerRef.current.unobserve(listEndRef.current);
    }
  }, [
    notifications,
    listRef.current,
    listEndRef.current,
    observerRef.current,
    isLoaded,
    isFetchingMore,
  ]);

  const onDismissed = useCallback(({ id }) => {
    setAddressedAction(id, true);
  }, []);

  if (!isLoaded) {
    return (
      <div className={classnames('p-4', 'text-center', style.list)}>
        <Spinner as="span" animation="border" role="status" aria-hidden="true" />
      </div>
    );
  }

  if (notifications.length === 0) {
    return (
      <div className={classnames('p-4', 'text-center', style.list)}>
        <p className="m-0">There are no new notifications.</p>
      </div>
    );
  }

  return (
    <div className={style.list} ref={listRef}>
      <Event event={MONITOR_NOTIFICATION_DISMISSED} handler={onDismissed} />

      {notifications.map(({
        _id,
        addressed,
        data: {
          userId,
          snapshotsTaken,
          distinctSRS,
          allSRS,
        },
      }) => (
        <Notification
          key={_id}
          id={_id}
          addressed={addressed}
          userId={userId}
          snapshotsTaken={snapshotsTaken}
          uniqueSeats={distinctSRS.length}
          allSRS={allSRS}
        />
      ))}

      {isFetchingMore && (
        <div className="text-center">
          <Spinner as="span" animation="border" role="status" aria-hidden="true" />
        </div>
      )}
      <span ref={listEndRef} />
    </div>
  );
};

export default connect(
  ({
    monitor: {
      notifications: {
        isFetching,
        isFetchingMore,
        hasMore,
        items: notifications,
      },
    },
  }) => ({
    isFetching,
    isFetchingMore,
    hasMore,
    notifications,
  }),
  {
    fetchNotificationsAction: fetchNotifications,
    fetchMoreNotificationsAction: fetchMoreNotifications,
    setAddressedAction: setAddressed,
  },
)(Notifications);
