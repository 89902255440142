import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import CameraSettingsTable from './CameraSettingsTable';

import { fetchConfigs } from './actions';

import style from './style.module.scss';

const CameraConfigs = ({
  cameras,
  error,
  fetchConfigsAction,
}) => {
  useEffect(() => {
    fetchConfigsAction();
  }, []);

  if (error) {
    return (
      <h3>
        Error Fetching Cameras:
        {' '}
        {error.message}
      </h3>
    );
  }

  return (
    <>
      <div className="pageHeader">
        <h4>Camera Configs</h4>
      </div>
      <div className={style.MainContent}>
        <CameraSettingsTable cameras={cameras} />
      </div>
    </>
  );
};

export default connect(
  ({ cameraConfigs: { cameras, error } }) => ({ cameras, error }),
  { fetchConfigsAction: fetchConfigs },
)(CameraConfigs);
