import React, { useContext, memo, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowDown, faCircleArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { get, isNull } from 'lodash';

import { SocketContext } from 'react-socket-io';
import { inRange, socketEventNames } from '../../helper';
import { updatePath, updateCornerValue } from '../../actions';
import camMap from '../../mapLogic';

import style from './style.module.scss';


const { ROVER_MOVE, ROVER_GET_POSITION } = socketEventNames;

const isDisabled = (input, corner, regionType) => {
  if (regionType === 'default' || isNull(regionType)) {
    return ['row', 'seat'].includes(input) && ['topRight', 'bottomLeft'].includes(corner);
  }

  if (regionType === 'singleRow') {
    return ['bottomRight', 'bottomLeft'].includes(corner);
  }

  if (regionType === 'singleSeat') {
    return ['topRight', 'bottomLeft', 'bottomRight'].includes(corner);
  }

  return false;
};

const CornerPosition = ({ name, path }) => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const pan = useSelector(({ mapping }) => get(mapping, `${path}.pan`));
  const tilt = useSelector(({ mapping }) => get(mapping, `${path}.tilt`));
  const zoom = useSelector(({ mapping: { preview: { zoom: { value } } } }) => value);
  const row = useSelector(({ mapping }) => get(mapping, `${path}.row`));
  const seat = useSelector(({ mapping }) => get(mapping, `${path}.seat`));
  const connectedCamera = useSelector(({ mapping: { connectedCamera: cam } }) => cam);
  const [tab, corner] = useMemo(() => path.split('.'), []);
  const regionType = useSelector(({ mapping }) => get(mapping, `${tab}.regionType`, null));

  const requestGetPosition = () => {
    dispatch(updatePath('positionPath', corner));
    socket.emit(ROVER_GET_POSITION, 'host', connectedCamera, '#');
  };

  const sendPositionToCamera = () => {
    console.info('send position to camera');
    let safePan = Number(pan);
    let safeTilt = Number(tilt);
    const safeZoom = Number(zoom / 1000);

    // subtract ref point from position if we are on check tab
    /* note:
      Rove tab: always show raw position without considering ref point
      Browse tab: always show raw position without considering ref point
      preview pos: always show raw position without considering ref point
      Check tab ONLY: always show position with ref point calc
      position to sent to camera: camPos = mapFilePos + refPos
    */
    if (tab === 'check') {
      const camReferencePoint = camMap.getReference();
      safePan += camReferencePoint.pan;
      safeTilt += camReferencePoint.tilt;
    }

    if (Number.isInteger(safePan) && Number.isInteger(safeTilt) && inRange(safeZoom, 0, 1)) {
      console.log('sending move command to camera', safePan, safeTilt, safeZoom);
      socket.emit(ROVER_MOVE, 'host', connectedCamera, `!${safePan},${safeTilt},${safeZoom}`);
    } else {
      console.error('Error sending move command to camera', safePan, safeTilt, safeZoom);
    }
  };

  return (
    <div className={style.cornerBlock}>
      <div>
        <span>{name}</span>
        <div>
          <button
            className={style.circleButton}
            onClick={requestGetPosition}
            type="button"
            title="Get position from camera"
          >
            <FontAwesomeIcon icon={faCircleArrowDown} />
          </button>
          <button
            id={`${corner}-goToPosition`}
            className={style.circleButton}
            onClick={sendPositionToCamera}
            type="button"
            title="Send position to camera"
          >
            <FontAwesomeIcon icon={faCircleArrowUp} />
          </button>
        </div>
      </div>
      <div>
        <div>
          <span>Pan</span>
          <input
            type="number"
            disabled={isDisabled('pan', corner, regionType)}
            value={pan ?? ''}
            onChange={({ target: { value } }) => dispatch(updateCornerValue(corner, 'pan', value))}
          />
        </div>
        <div>
          <span>Tilt</span>
          <input
            type="number"
            disabled={isDisabled('tilt', corner, regionType)}
            value={tilt ?? ''}
            onChange={({ target: { value } }) => dispatch(updateCornerValue(corner, 'tilt', value))}
          />
        </div>
        <div>
          <span>Row</span>
          <input
            type="text"
            disabled={isDisabled('row', corner, regionType)}
            value={row}
            onChange={({ target: { value } }) => dispatch(updateCornerValue(corner, 'row', value.toUpperCase()))}
          />
        </div>
        <div>
          <span>Seat</span>
          <input
            type="number"
            value={seat}
            disabled={isDisabled('seat', corner, regionType)}
            onChange={({ target: { value } }) => dispatch(updateCornerValue(corner, 'seat', value))}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(CornerPosition);
