import React from 'react';
import { connect } from 'react-redux';

import Camera from '../Camera';

import { selectCamera } from '../actions';

const CameraThumbnail = ({
  camName,
  selected,
  onClick,
  selectCameraAction,
}) => {
  const clickHandler = () => {
    onClick();
    selectCameraAction(camName);
  };

  return (
    // eslint-disable-next-line
    <div key={camName} onClick={clickHandler}>
      <Camera
        name={camName}
        controlsEnabled={false}
        selected={selected}
      />
    </div>
  );
};

export default connect(
  null,
  { selectCameraAction: selectCamera },
)(CameraThumbnail);
