import React from 'react';
import classnames from 'classnames';

import style from './style.module.scss';

const SelectionButton = ({
  label,
  selected,
  imageSrc,
  className,
  onClick,
}) => {
  const classes = classnames(style.selectionBtn, className, { [style.selected]: selected });

  return (
    <button type="button" className={classes} onClick={onClick}>
      <span>{label}</span>
      {imageSrc && <span><img src={imageSrc} alt="" /></span>}
    </button>
  );
};

export default SelectionButton;
