import React, { useCallback } from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import CornerPosition from '../CornerControls';
import { customSelectStylesOptions, seatOptions } from '../../helper';
import { updatePath, updateRegionType } from '../../actions';

import style from './style.module.scss';

const MapTab = () => {
  const dispatch = useDispatch();
  const update = useCallback((...args) => dispatch(updatePath(...args)), []);
  const name = useSelector(({ mapping: { map } }) => map.name);
  const regionType = useSelector(({ mapping: { map } }) => map.regionType);
  const type = useSelector(({ mapping: { map } }) => map.type);

  const regionTypeChangeHandler = (e) => {
    dispatch(updateRegionType(e.target.id));
  };

  return (
    <div className={style.mapTab}>
      <div className={style.selectRow}>
        <span>
          Type
        </span>
        <Select
          placeholder="Section"
          value={seatOptions.find(({ value }) => value === type)}
          options={seatOptions}
          styles={customSelectStylesOptions}
          onChange={({ value }) => update('map.type', value)}
        />
      </div>
      <div className={style.selectRow}>
        <span>
          Name
        </span>
        <input
          type="text"
          className={style.input}
          value={name}
          onChange={({ currentTarget: { value } }) => update('map.name', value)}
        />
      </div>
      <div className={style.regionTypeRadioButtons}>
        <Form.Check
          inline
          custom
          type="radio"
          id="default"
          label="Default"
          name="regionType"
          checked={regionType === 'default'}
          onChange={regionTypeChangeHandler}
        />
        <Form.Check
          inline
          custom
          type="radio"
          id="singleRow"
          label="Single Row"
          name="regionType"
          checked={regionType === 'singleRow'}
          onChange={regionTypeChangeHandler}
        />
        <Form.Check
          inline
          custom
          type="radio"
          id="singleSeat"
          label="Single Seat"
          name="regionType"
          checked={regionType === 'singleSeat'}
          onChange={regionTypeChangeHandler}
        />
      </div>
      <div className={style.cornersWrapper}>
        <CornerPosition name="Top Left" path="map.topLeft" />
        <CornerPosition name="Top Right" path="map.topRight" />
      </div>
      <div className={style.cornersWrapper}>
        <CornerPosition name="Bottom Left" path="map.bottomLeft" />
        <CornerPosition name="Bottom Right" path="map.bottomRight" />
      </div>
    </div>
  );
};

export default MapTab;
