import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChair, faSquare } from '@fortawesome/pro-regular-svg-icons';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons';

import { isEmpty } from 'lodash';
import { getFilterOptions } from '../../../helpers/api';

import style from './style.module.scss';
import Loader from '../../../shared/Loader';

const SectionSelector = ({ preSelected, onChange, event: { slugDate } = {} }) => {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [allSections, setAllSections] = useState([]); // includes all seat types
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (showModal) {
      getFilterOptions([slugDate])
        .then((data) => {
          const {
            sections = [], suites = [], boxes = [], customType1 = [],
          } = data;
          const options = [...sections, ...suites, ...boxes, ...customType1];
          setAllSections(options);
          setSelected(preSelected[0] === '*' ? options : preSelected);
        })
        .catch((error) => console.error(error));
    }
  }, [showModal]);

  const toggleSelection = (section) => () => {
    if (selected.includes(section)) {
      const index = selected.indexOf(section);

      setSelected([
        ...selected.slice(0, index),
        ...selected.slice(index + 1),
      ]);
      return;
    }

    setSelected([...selected, section]);
  };

  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        className={classnames({ [style.customSeats]: preSelected.indexOf('*') === -1 })}
      >
        <FontAwesomeIcon icon={faChair} />
      </Button>
      <Modal
        dialogClassName={style.sectionModal}
        show={showModal}
        centered
        onHide={() => setShowModal(false)}
      >
        <Modal.Body className={style.body}>
          <div className="d-flex justify-content-between">
            <Button key="select" variant="link" onClick={() => setSelected(allSections)}>
              <span>Select All</span>
            </Button>
            <Button key="clear" variant="link" onClick={() => setSelected([])}>
              <span>Clear All</span>
            </Button>
          </div>
          <input type="text" placeholder="Type to filter ..." onKeyUp={(e) => setFilter(e.target.value)} />
          <div className={style.list}>
            {!isEmpty(allSections) && allSections.map((section) => (
              <Button
                key={section}
                variant="link"
                onClick={toggleSelection(section)}
                className={classnames({ 'd-none': filter.length && section.indexOf(filter) === -1 })}
              >
                <FontAwesomeIcon icon={selected.includes(section) ? faCheckSquare : faSquare} />
                <span>{section}</span>
              </Button>
            ))}
            {isEmpty(allSections) && <Loader />}
          </div>
        </Modal.Body>
        <Modal.Footer className={style.footer}>
          <Button variant="outline-primary" onClick={() => setShowModal(false)}>Cancel</Button>
          <Button
            variant="primary"
            disabled={!selected.length}
            onClick={() => {
              onChange(selected.length === allSections.length ? ['*'] : selected);
              setShowModal(false);
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default connect(
  ({
    events: { selectedEvents },
  }) => ({
    event: selectedEvents[0],
  }),
)(SectionSelector);
