import {
  SET_SHARED,
  SET_DISABLE_UPDATES,
  SET_SHOULD_RECENT_REFRESH,
  SET_SHOULD_SHARED_REFRESH,
  UNSET_SHOULD_REFRESH,
  MONITOR_TOGGLE_OVERLAYS,
} from './types';

const initialState = {
  displayingShared: false,
  updatesDisabled: false,
  overlaysDisplayed: false,
  shouldRefresh: {
    recent: false,
    shared: false,
  },
};

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
  case SET_SHARED: {
    return {
      ...state,
      displayingShared: payload,
    };
  }

  case SET_DISABLE_UPDATES: {
    return {
      ...state,
      updatesDisabled: payload,
    };
  }

  case SET_SHOULD_RECENT_REFRESH: {
    return {
      ...state,
      shouldRefresh: {
        ...state.shouldRefresh,
        recent: true,
      },
    };
  }

  case SET_SHOULD_SHARED_REFRESH: {
    return {
      ...state,
      shouldRefresh: {
        ...state.shouldRefresh,
        shared: true,
      },
    };
  }

  case UNSET_SHOULD_REFRESH: {
    return {
      ...state,
      shouldRefresh: initialState.shouldRefresh,
    };
  }

  case MONITOR_TOGGLE_OVERLAYS: {
    return {
      ...state,
      overlaysDisplayed: !state.overlaysDisplayed,
    };
  }

  default: {
    return state;
  }
  }
};
