import React, { useMemo, Fragment } from 'react';
import { FormCheck, OverlayTrigger, Popover } from 'react-bootstrap';
import { connect } from 'react-redux';

import { addPermission, removePermission } from '../actions';
import { can } from '../../../../components/Authorize';
import { ROLES_WRITE } from '../../../../components/Authorize/permissions/resources';

import style from './style.module.scss';

const Permissions = ({
  permissions,
  selectedPermissions = [],
  level = 0,
  roleName,
  addPermissionAction,
  removePermissionAction,
}) => {
  const selectedPermissionsObj = useMemo(() => {
    const pObj = {};
    selectedPermissions.forEach((permissionKey) => {
      pObj[permissionKey] = true;
    });
    return pObj;
  }, [selectedPermissions]);

  return permissions.map(({ name, children, description }) => {
    const isSelected = !!selectedPermissionsObj[name];
    const clickHandler = () => (isSelected
      ? removePermissionAction(name)
      : addPermissionAction(name));

    return (
      <Fragment key={name}>
        <div className={style.permission}>
          <button
            type="button"
            onClick={clickHandler}
            disabled={roleName === 'admin' || !can(ROLES_WRITE)}
            className="d-inline-flex align-items-center border-0"
            style={{ paddingLeft: `${level * 32}px` }}
          >
            <FormCheck
              inline
              label=""
              custom
              readOnly
              type="checkbox"
              checked={isSelected}
            />
            <OverlayTrigger
              placement="right"
              overlay={(
                <Popover id="Calendar">
                  <Popover.Content>
                    {description}
                  </Popover.Content>
                </Popover>
              )}
            >
              <h6 className="mb-0">{name}</h6>
            </OverlayTrigger>
          </button>
          {children.length > 0 && <ConnectedPermissions permissions={children} level={level + 1} />}
        </div>
      </Fragment>
    );
  });
};

const ConnectedPermissions = connect(
  ({ roles: { roles, selectedRoleInd } }) => ({
    selectedPermissions: roles[selectedRoleInd] && roles[selectedRoleInd].permissions,
    roleName: roles[selectedRoleInd] && roles[selectedRoleInd].name,
  }),
  {
    addPermissionAction: addPermission,
    removePermissionAction: removePermission,
  },
)(Permissions);

export default ConnectedPermissions;
