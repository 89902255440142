import React, { memo } from 'react';
import classnames from 'classnames';
import styles from './style.module.scss';

const Divider = (props) => {
  const {
    marginTop,
    marginBottom,
    margin,
    className,
  } = props;

  const style = {
    marginTop: marginTop || margin,
    marginBottom: marginBottom || margin,
  };

  return <div style={style} className={classnames(styles.divider, className)} />;
};

export default memo(Divider);
