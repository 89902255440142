export const FETCH_ROLES = 'FETCH_ROLES';
export const ADD_ROLE = 'ADD_ROLE';
export const SET_SELECTED_ROLE = 'SET_SELECTED_ROLE';
export const SAVE_ROLE = 'SAVE_ROLE';
export const SAVE_ROLE_DONE = 'SAVE_ROLE_DONE';
export const ADD_PERMISSION = 'ADD_PERMISSION';
export const REMOVE_PERMISSION = 'REMOVE_PERMISSION';
export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_ROLE_DONE = 'DELETE_ROLE_DONE';
export const OPEN_NEW_ROLE_MODAL = 'OPEN_NEW_ROLE_MODAL';
export const CLOSE_NEW_ROLE_MODAL = 'CLOSE_NEW_ROLE_MODAL';
