import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Card, Collapse, Spinner } from 'react-bootstrap';

import SharedList from '../SharedList';
import Exif from '../Exif';
import UserData from '../UserDataSection';
import Authorize from '../../../Authorize';
import CopyLink from './CopyLink';

import { setSelectedFaceInd } from '../actions';
import { toggleInfoSection, fetchImageMining } from './actions';
import { convertTime } from '../../../../helpers/events';
import { dateTimeFormat } from '../../../../helpers/constants';
import { USER_INFO_ACCORDITION, EXIF_ACCORDITION, BRIZI_VISION_ACCORDITION } from '../../permissions';

import style from './style.module.scss';

const InfoSection = ({
  snapshot,
  event = {},
  imageInfo,
  exifData,
  imageMiningData,
  imageMiningDataInfo,
  toggleInfoSectionAction,
  fetchImageMiningDataAction,
  setSelectedFaceIndAction,
}) => {
  const { timezone } = event;
  const {
    _id: snapshotId,
    userId,
    camera,
    section,
    row,
    seat,
    rawCroppedURI,
    date,
    postObj,
  } = snapshot;

  const [linkCopied, setLinkCopied] = useState();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (linkCopied) {
      const timeout = setTimeout(() => {
        setLinkCopied(false);
      }, 1500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [linkCopied]);

  useEffect(() => () => {
    setSelectedFaceIndAction(0);
  }, []);

  useEffect(() => {
    const miningDataFetched = imageMiningData.data && imageMiningDataInfo.snapshotId === snapshotId;
    if (imageMiningDataInfo.open && !miningDataFetched) {
      fetchImageMiningDataAction(snapshotId);
    }
  }, [imageMiningDataInfo.open]);

  const snapshotTime = timezone ? convertTime(date, timezone, dateTimeFormat) : '-';

  let personData;
  let emotions;

  if (imageMiningData.data) {
    personData = imageMiningData.data[imageMiningData.selectedInd];
    if (personData && personData.Emotions) {
      emotions = personData.Emotions.filter(({ Confidence: confidence }) => confidence > 60);
    }
  }

  return (
    <div className={style.infoSection}>
      <Card>
        <Card.Header>
          <button
            type="button"
            onClick={() => toggleInfoSectionAction('imageInfo')}
            aria-expanded={imageInfo.open}
          >
            Image Information
          </button>
        </Card.Header>
        <Collapse in={imageInfo.open}>
          <div>
            <Card.Body>
              <table>
                <tbody>
                  <tr>
                    <td>UserID:</td>
                    <td>{userId}</td>
                  </tr>
                  <tr>
                    <td>
                      Light Box
                    </td>
                    <td>
                      <CopyLink link={`${window.location.origin}/snapshot/${snapshotId}`} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href={rawCroppedURI} target="_blank" rel="noopener noreferrer">Link</a>
                    </td>
                    <td>
                      <CopyLink link={rawCroppedURI} />
                    </td>
                  </tr>
                  <tr>
                    <td>Camera:</td>
                    <td>{camera}</td>
                  </tr>
                  <tr>
                    <td>Time:</td>
                    <td>{snapshotTime}</td>
                  </tr>
                  <tr>
                    <td>Section:</td>
                    <td>
                      {section}
                    </td>
                  </tr>
                  <tr>
                    <td>Row:</td>
                    <td>
                      {row}
                    </td>
                  </tr>
                  <tr>
                    <td>Seat:</td>
                    <td>
                      {seat}
                    </td>
                  </tr>
                  <tr className={classnames({ 'd-none': isEmpty(postObj) })}>
                    <td>Shared:</td>
                    <td>
                      <SharedList postObj={postObj} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card.Body>
          </div>
        </Collapse>
      </Card>

      <Authorize permissions={USER_INFO_ACCORDITION}>
        <UserData />
      </Authorize>

      <Authorize permissions={EXIF_ACCORDITION}>
        <Card>
          <Card.Header>
            <button
              type="button"
              onClick={() => toggleInfoSectionAction('exifData')}
              aria-expanded={exifData.open}
            >
              Exif Data
            </button>
          </Card.Header>
          <Collapse in={exifData.open}>
            <div>
              <Card.Body>
                <Exif />
              </Card.Body>
            </div>
          </Collapse>
        </Card>
      </Authorize>

      <Authorize permissions={BRIZI_VISION_ACCORDITION}>
        <Card>
          <Card.Header>
            <button
              type="button"
              onClick={() => toggleInfoSectionAction('imageMiningData')}
              aria-expanded={imageMiningDataInfo.open}
            >
              Amplifi Vision
            </button>
          </Card.Header>
          <Collapse in={imageMiningDataInfo.open}>
            <div>
              <Card.Body>
                {imageMiningData.isFetching && <Spinner variant="primary" animation="border" />}
                {!imageMiningData.isFetching && personData && (
                  <>
                    <span className="badge badge-danger">
                      Age
                      {' '}
                      {personData.AgeRange.Low}
                      -
                      {personData.AgeRange.High}
                    </span>
                    {personData.Smile.Confidence > 60 && <span className="badge badge-warning">Smiling</span>}
                    {personData.Gender.Confidence > 60 && (
                      <span className="badge badge-primary">{personData.Gender.Value}</span>
                    )}
                    {personData.Eyeglasses.Value && personData.Eyeglasses.Confidence > 60 && (
                      <span className="badge badge-info">Eyeglasses</span>
                    )}
                    {personData.Sunglasses.Value && personData.Sunglasses.Confidence > 60 && (
                      <span className="badge badge-info">Sunglasses</span>
                    )}
                    {personData.Beard.Value && personData.Beard.Confidence > 60 && (
                      <span className="badge badge-info">Beard</span>
                    )}
                    {personData.Mustache.Value && personData.Mustache.Confidence > 60 && (
                      <span className="badge badge-info">Mustache</span>
                    )}
                    {personData.EyesOpen.Value && personData.EyesOpen.Confidence > 60 && (
                      <span className="badge badge-info">Eyes Open</span>
                    )}
                    {personData.MouthOpen.Value && personData.MouthOpen.Confidence > 60 && (
                      <span className="badge badge-info">Mouth Open</span>
                    )}

                    {emotions.map(({ Type: emotion }) => (
                      <span key={emotion} className="badge badge-secondary">
                        {emotion[0]}
                        {emotion.slice(1).toLocaleLowerCase()}
                      </span>
                    ))}
                  </>
                )}
              </Card.Body>
            </div>
          </Collapse>
        </Card>
      </Authorize>
    </div>
  );
};

export default connect(
  ({
    lightbox: {
      snapshot: {
        data: snapshot,
        imageMiningData,
      },
      info: {
        imageInfo,
        exifData,
        imageMiningData: imageMiningDataInfo,
      },
    },
    events: { allEvents },
  }) => ({
    snapshot,
    imageInfo,
    exifData,
    imageMiningData,
    imageMiningDataInfo,
    event: allEvents.find(({ slugDate }) => slugDate === snapshot.slugDate),
  }),
  {
    toggleInfoSectionAction: toggleInfoSection,
    fetchImageMiningDataAction: fetchImageMining,
    setSelectedFaceIndAction: setSelectedFaceInd,
  },
)(InfoSection);
